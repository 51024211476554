import VehicleIncident from '../models/VehicleIncident';
import BaggageIncident from '../models/BaggageIncident';
import GeneralIncident from '../models/GeneralIncident';
import TripIncident from '../models/TripIncident';
import PersonalPropertyIncident from '../models/PersonalPropertyIncident';
import PropertyIncident from '../models/PropertyIncident';
import InjuryIncident from '../models/InjuryIncident';

import BaggageIncidentComponent from '../components/incidents/BaggageIncident/BaggageIncident';
import GeneralIncidentComponent from '../components/incidents/GeneralIncident/GeneralIncident';
import InjuryIncidentComponent from '../components/incidents/InjuryIncident/InjuryIncident';
import PersonalPropertyIncidentComponent from '../components/incidents/PersonalPropertyIncident/PersonalPropertyIncident';
import PropertyIncidentComponent from '../components/incidents/PropertyIncident/PropertyIncident';
import TripIncidentComponent from '../components/incidents/TripIncident/TripIncident';
import VehicleIncidentComponent from '../components/incidents/vehicle/VehicleIncident/VehicleIncident';

const INCIDENT_TYPES = {
    VehicleIncident: {
        constructorClassName: 'VehicleIncident',
        ConstructorClass: VehicleIncident,
        collectionName: 'vehicleIncidents',
        componentClass: VehicleIncidentComponent
    },
    BaggageIncident: {
        constructorClassName: 'BaggageIncident',
        ConstructorClass: BaggageIncident,
        collectionName: 'baggageIncidents',
        componentClass: BaggageIncidentComponent
    },
    FixedPropertyIncident: {
        constructorClassName: 'PropertyIncident',
        ConstructorClass: PropertyIncident,
        collectionName: 'propertyIncidents',
        componentClass: PropertyIncidentComponent
    },
    PersonalPropertyIncident_Cnd: {
        constructorClassName: 'PersonalPropertyIncident',
        ConstructorClass: PersonalPropertyIncident,
        collectionName: 'personalPropertyIncidents',
        componentClass: PersonalPropertyIncidentComponent
    },
    GeneralIncident_Cnd: {
        constructorClassName: 'GeneralIncident',
        ConstructorClass: GeneralIncident,
        collectionName: 'generalIncidents',
        componentClass: GeneralIncidentComponent
    },
    InjuryIncident: {
        constructorClassName: 'InjuryIncident',
        ConstructorClass: InjuryIncident,
        collectionName: 'injuryIncidents',
        componentClass: InjuryIncidentComponent
    },
    TripIncident: {
        constructorClassName: 'TripIncident',
        ConstructorClass: TripIncident,
        collectionName: 'tripIncidents',
        componentClass: TripIncidentComponent
    }
};

export default INCIDENT_TYPES;
