import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import EmailLink from '../../../../components/EmailLink/EmailLink';
import messages from './DocumentUploadNotice.messages';
import styles from './DocumentsUploadNotice.module.scss';

function FormattedEmailLink(props) {
    const { children, ...message } = props;
    return (
        <FormattedMessage
            {...message}
            values={{
                contactEmail: <EmailLink>{children}</EmailLink>,
            }}
        />
    );
}

FormattedEmailLink.propTypes = {
    children: PropTypes.string.isRequired,
};

function DocumentUploadNoticeHU(props) {
    const { className } = props;
    return (
        <>
            <p className={className}>
                <FormattedMessage {...messages.uploadNoticeHu} />
            </p>

            <ul className={styles.contactlist}>
                <li>
                    <FormattedEmailLink {...messages.tvlInsuranceClaimsSettlement}>
                    assistance@colonnade.hu
                    </FormattedEmailLink>
                </li>
                <li>
                    <FormattedEmailLink
                        {...messages.tvlCancellationLuggageTravelSettlement}
                    >
                    utaskar@colonnade.hu
                    </FormattedEmailLink>
                </li>
                <li>
                    <FormattedEmailLink {...messages.tvlHomeAccidentSicknessInsurance}>
                    karrendezes@colonnade.hu
                    </FormattedEmailLink>
                </li>
                <li>
                    <FormattedEmailLink {...messages.tvlGapInsurance}>
                    gepjarmukar@colonnade.hu
                    </FormattedEmailLink>
                </li>
                <li>
                    <FormattedEmailLink {...messages.tvlCorporateClaims}>
                    vagyonkar@colonnade.hu
                    </FormattedEmailLink>
                </li>
                <li>
                    <FormattedEmailLink {...messages.tvlClaimsSettlementHomeInsurance}>
                    lakaskar@colonnade.hu
                    </FormattedEmailLink>
                </li>
                <li>
                    <FormattedEmailLink {...messages.tvlConsumerFriendlyHomeInsurance}>
                    otthonkar@colonnade.hu
                    </FormattedEmailLink>
                </li>
            </ul>
        </>
    );
}

DocumentUploadNoticeHU.propTypes = {
    className: PropTypes.string,
};

DocumentUploadNoticeHU.defaultProps = {
    className: undefined,
};

export default DocumentUploadNoticeHU;
