import _ from 'lodash';
import React, {
    useCallback, useState, useMemo, useEffect, useContext, useRef
} from 'react';
import {
    WizardPage,
    wizardProps,
    WizardPageTemplate
} from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { ModalNextProvider } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { messages as platformMessages } from 'gw-platform-translations';
import { SectionHeader } from 'cnd-common-components-platform-react';
import { TagManagerService, TrackingConstants } from 'cnd-portals-util-js';
import StepHeader from '../../components/StepHeader/StepHeader';
import Consents from '../../components/Consents/Consents';
import INCIDENT_TYPES from '../../utils/IncidentsMapperUtil';
import useErrorHandler from '../../hooks/useErrorHandler';
import metadata from './LossDetailsPage.metadata.json5';
import styles from './LossDetailsPage.module.scss';
import messages from './LossDetailsPage.messages';
import Claim from '../../models/Claim';

function FNOLLossDetailsPage(props) {
    const translator = useContext(TranslatorContext);
    const [availableIncidentTypes, setAvailableIncidentTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const { FNOLService } = useDependencies('FNOLService');
    const [currentlyOpenedIncidentId, setCurrentlyOpenedIncidentId] = useState(null);
    const ErrorHandler = useErrorHandler();
    const stateRef = useRef();
    stateRef.current = currentlyOpenedIncidentId;
    const currentFocusedSectionRef = useRef();

    const {
        wizardData: claimVM,
        updateWizardData
    } = props;

    const {
        onValidate,
        isComponentValid,
        initialValidation,
        disregardFieldValidation,
        registerComponentValidation
    } = useValidation('LossDetailsPage');

    const handleSaveClaimData = useCallback(() => {
        setIsLoading(true);
        return FNOLService.saveClaim(claimVM.value)
            .then((response) => {
                claimVM.value = new Claim(response);
                return claimVM;
            })
            .catch((error) => {
                const claimNumber = claimVM.claimNumber.value;
                ErrorHandler.handleError(error, claimNumber);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, ErrorHandler, claimVM]);

    useEffect(() => {
        registerComponentValidation(() => {
            const { aspects } = claimVM.incidentsData;
            const incidents = Object.values(INCIDENT_TYPES)
                .flatMap((incidentTypeValue) => {
                    return _.get(claimVM, `incidentsData.value.${incidentTypeValue.collectionName}`);
                });
            return aspects.valid && aspects.subtreeValid && incidents.length > 0;
        });
    }, [
        claimVM,
        claimVM.incidentsData,
        claimVM.incidentsData.aspects.valid,
        claimVM.incidentsData.aspects.subtreeValid,
        registerComponentValidation
    ]);

    const onNext = useCallback(() => {
        TagManagerService.pushNextButtonClick(TrackingConstants.STEPS.LOSS_DETAIL);
        return handleSaveClaimData();
    }, [handleSaveClaimData]);

    const isCurrentlyOpenedIncidentValid = useCallback(
        () => {
            setShowErrors(false);
            if (stateRef.current === null) {
                return true;
            }

            let incident;
            _.forEach(INCIDENT_TYPES, (value) => {
                const foundIncident = claimVM.incidentsData[value.collectionName].children
                    .find((inc) => inc.value.publicID === stateRef.current
                    || inc.value.tempID === stateRef.current);
                if (foundIncident) {
                    incident = foundIncident;
                }
            });

            if (incident && incident.aspects.valid && incident.aspects.subtreeValid) {
                return true;
            }

            setShowErrors(true);
            return false;
        },
        [claimVM]
    );

    useEffect(() => {
        claimVM.value.createIncidentsData();
        const vehicleIncidents = _.get(claimVM, 'incidentsData.vehicleIncidents.children');
        _.forEach(vehicleIncidents, (vehicleIncident) => {
            const isVehicleStolen = _.get(vehicleIncident, 'isVehicleStolen.value');
            if (isVehicleStolen) {
                _.set(vehicleIncident, 'driver', null);
            }
        });
        FNOLService.getAvailableIncidentTypesForClaim(claimVM.value.lossType)
            .then((types) => {
                setAvailableIncidentTypes(types.map((code) => ({
                    code,
                    name: translator({
                        id: `typekey.Incident.${code}`,
                        defaultMessage: code
                    })
                })));
            });
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const executeScrollToCurrentlyFocusedSection = () => {
        if (currentFocusedSectionRef.current) {
            currentFocusedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const openIncident = useCallback(
        (id) => {
            setTimeout(() => {
                executeScrollToCurrentlyFocusedSection();
            }, 0);
            if (isCurrentlyOpenedIncidentValid()) {
                setCurrentlyOpenedIncidentId(id);
            }
        },
        [setCurrentlyOpenedIncidentId, isCurrentlyOpenedIncidentValid]
    );

    const deleteIncident = useCallback(
        (id, name, index) => {
            ModalNextProvider.showConfirm({
                title: messages.fnolLossDetailsPageDeleteIncidentModalTitle,
                message: translator(messages.fnolLossDetailsPageDeleteIncidentModalMessage),
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.yesModel,
                cancelButtonText: platformMessages.cancelModel
            }).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                const newClaimVM = _.clone(claimVM);
                newClaimVM.value.incidentsData.removeIncident(id);
                if (stateRef.current === id) {
                    setCurrentlyOpenedIncidentId(null);
                }
                disregardFieldValidation(`fnolLossDetailsPage${name}${index}`);
                return updateWizardData(newClaimVM);
            }, _.noop);
        },
        [
            claimVM,
            updateWizardData,
            setCurrentlyOpenedIncidentId,
            translator,
            disregardFieldValidation
        ]
    );

    const addIncident = useCallback(
        (type) => {
            setTimeout(() => {
                executeScrollToCurrentlyFocusedSection();
            }, 0);
            if (!isCurrentlyOpenedIncidentValid()) {
                return;
            }
            const { tempID } = claimVM.value.incidentsData.createIncident(type);
            updateWizardData(claimVM);
            setCurrentlyOpenedIncidentId(tempID);
        },
        [claimVM, updateWizardData, setCurrentlyOpenedIncidentId, isCurrentlyOpenedIncidentValid]
    );

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const newClaimVM = _.clone(claimVM);
            _.set(newClaimVM, `${changedPath}.value`, value);
            updateWizardData(newClaimVM);
        },
        [claimVM, updateWizardData]
    );

    const sortIncidentsByTempOrPublicID = (incA, incB) => {
        if (incA.publicID === undefined && incB.publicID !== undefined) {
            return 1;
        }
        if (incA.publicID !== undefined && incB.publicID === undefined) {
            return -1;
        }
        return incA.tempID - incB.tempID || incA.publicID.localeCompare(incB.publicID);
    };

    const generateIncidents = useCallback(
        () => {
            const incidentsDataVM = _.get(claimVM, 'incidentsData');
            const incidents = Object.values(INCIDENT_TYPES)
                .flatMap((incidentTypeValue) => _.get(claimVM.value, `incidentsData.${incidentTypeValue.collectionName}`))
                .filter((incident) => incident !== undefined)
                .sort(sortIncidentsByTempOrPublicID);

            return [(
                <div>
                    { incidents && incidents.length > 0 && incidents.map((incident) => {
                        const {
                            componentClass: IncidentComponent,
                            collectionName,
                            constructorClassName: incidentTypeName
                        } = INCIDENT_TYPES[incident.getTypeCode()];
                        const index = incidentsDataVM[collectionName].children
                            .findIndex((x) => (x.value.publicID
                                    && x.value.publicID === incident.publicID)
                                || (x.value.tempID && x.value.tempID === incident.tempID));
                        const incidentVM = incidentsDataVM[collectionName].children[index];
                        const currentlyOpened = (incident.publicID || incident.tempID)
                            === currentlyOpenedIncidentId;

                        return (
                            <div className="cndMarginTopXXL cndMarginBottomXXL">
                                { currentlyOpened
                                    && (
                                        <div ref={currentFocusedSectionRef} />
                                    )
                                }
                                <SectionHeader
                                    onDeleteClick={() => {
                                        const incId = incident.publicID || incident.tempID;
                                        return deleteIncident(incId, incidentTypeName, index);
                                    }}
                                    onOpenClick={() => {
                                        return openIncident(incident.publicID || incident.tempID);
                                    }}
                                    isOpened={currentlyOpened}
                                    currentlyOpenedId={currentlyOpenedIncidentId}
                                    sectionTitle={translator(messages[`fnolLossDetailsPage${incidentTypeName}SectionName`])}
                                />
                                { currentlyOpened
                                    && (
                                        <IncidentComponent
                                            id={`fnolLossDetailsPage${incidentTypeName}${index}`}
                                            data={incidentVM}
                                            onValidate={onValidate}
                                            onValueChange={handleValueChange}
                                            isTripOnPolicy={_.get(claimVM.value, 'isTripOnPolicy')}
                                            path={`incidentsData.${collectionName}.children.${index}`}
                                            policyCurrency={_.get(claimVM.value, 'policyCurrency')}
                                            showErrors={showErrors}
                                        />
                                    )
                                }
                            </div>
                        );
                    })}
                </div>
            )];
        },
        [
            claimVM,
            deleteIncident,
            currentlyOpenedIncidentId,
            openIncident,
            translator,
            showErrors,
            handleValueChange,
            onValidate
        ]
    );

    const generateAddIncidentButtonOverrides = useMemo(() => {
        let overrides = null;
        if (availableIncidentTypes && availableIncidentTypes.length > 0) {
            overrides = availableIncidentTypes
                .map((incidentType, index) => {
                    return {
                        [`fnolLossDetailsPageAddIncidentLabel${index}`]: {
                            message: translator(messages[`fnolLossDetailsPage${INCIDENT_TYPES[incidentType.code].constructorClassName}`])
                        },
                        [`fnolLossDetailsPageAddIncidentLink${index}`]: {
                            onClick: () => addIncident(incidentType.code)
                        }
                    };
                });
            return Object.assign({}, ...overrides);
        }

        return null;
    }, [availableIncidentTypes, translator, addIncident]);

    const generateIncidentsGuideOverrides = useCallback(() => {
        const overrides = availableIncidentTypes.map((incidentType, index) => ({
            [`fnolLossDetailsPageIncidentsGuideLi${index}`]: {
                content: translator(messages[`fnolLossDetailsPageIncidentsGuide${INCIDENT_TYPES[incidentType.code].constructorClassName}`])
            }
        }));
        return Object.assign({}, ...overrides);
    }, [availableIncidentTypes, translator]);

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        fnolLossDetailsPageLoader: {
            loaded: !isLoading
        },
        fnolLossDetailsPageContainer: {
            visible: !isLoading
        },
        fnolLossDetailsPageAddIncidentDiv: {
            data: availableIncidentTypes
        },
        fnolLossDetailsPageIncidents: {
            content: generateIncidents()
        },
        fnolLossDetailsPageStepHeader: {
            claimNumber: _.get(claimVM.value, 'claimNumber'),
            title: translator(messages.fnolLossDetails)
        },
        fnolLossDetailsPageIncidentsGuideUl: {
            data: availableIncidentTypes.map((incidentType) => incidentType.code)
        },
        fnolLossDetailsPageIncidentsGuideAccordionCard: {
            chevron: true,
            header: (
                <span className="smallerText">
                    {translator(messages.fnolLossDetailsPageSectionIncidentsGuide)}
                </span>
            )
        },
        fnolLossDetailsPageConsents: {
            value: _.get(claimVM, 'consents'),
            onValidate,
            locationFilter: 'fnol_step_3'
        },
        ...generateAddIncidentButtonOverrides,
        ...generateIncidentsGuideOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles,
        resolveComponentMap: {
            stepheader: StepHeader,
            consents: Consents
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={resolvers.resolveValue}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLLossDetailsPage.propTypes = wizardProps;
export default withRouter(FNOLLossDetailsPage);
