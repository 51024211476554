import React, {
    useEffect, useContext, useState, useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Wizard } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-platform-translations';
import { ErrorBoundary } from 'gw-portals-error-react';
import { ModalNextProvider, Loader } from '@jutro/components';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import wizardConfig from '../../config/fnol-wizard-config.json5';
import FNOLContext from '../Context/Context';
import Claim from '../../models/Claim';
import fnolCommonMessages from '../../FNOL.messages';
import useErrorHandler from '../../hooks/useErrorHandler';

const ACTION_MSG = {
    cancelAction: {
        title: commonMessages.wantToCancel,
        message: fnolCommonMessages.cancelModalMessage,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    },
    previousAction: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    }
};

function FNOLWizard(props) {
    const { steps, title } = wizardConfig;
    const { location, history } = props;
    const [getRedirectPath, setRedirectPath] = useState();
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialClaim, setInitialClaim] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const constants = useContext(FNOLContext);
    const ErrorHandler = useErrorHandler();
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    
    useEffect(() => {
        if (!location.state) {
            return history.push('/');
        }
        const { claimData, redirectPath } = location.state;
        setRedirectPath(redirectPath);
        const claimValue = new Claim(claimData);
        const viewModelContext = {
            PolicyCountryContext: appCountry
        };
        const claimVM = viewModelService.create(
            claimValue,
            'cc',
            'com.colonnade.edge.capabilities.claim.customfnol.dto.FnolDTO',
            viewModelContext
        );
        return setInitialClaim(claimVM);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnCancel = useCallback(
        ({ wizardSnapshot, param: nextPath = '' }) => {
            if (
                (!_.isEmpty(nextPath) && nextPath.startsWith('/fnol'))
                || nextPath.startsWith(constants.ERROR_PAGE_PATH)
                || nextPath.startsWith('/contact-agent')
                || nextPath.startsWith('/confirmation')
            ) {
                return true;
            }
            let toRedirectpath = nextPath;
            if (_.isEmpty(nextPath)) {
                toRedirectpath = getRedirectPath;
            }
            return ModalNextProvider.showConfirm(ACTION_MSG.cancelAction).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                setIsLoading(true);
                if (!wizardSnapshot.claimNumber.value) {
                    history.push({
                        pathname: toRedirectpath,
                        state: 'claims'
                    });
                    setIsLoading(false);
                    // returning undefined since arrow functions require return value
                    return undefined;
                }
                history.push('/');
                return _.noop();
            }, _.noop);
        },
        [getRedirectPath, history, constants]
    );

    const handleError = useCallback(
        (error) => {
            ErrorHandler.handleError(error);
            return false;
        },
        [ErrorHandler]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} text={fnolCommonMessages.fnolSaveDraftClaim} />;
    }

    if (!initialClaim) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialClaim}
                onCancel={handleOnCancel}
            />
        </ErrorBoundary>
    );
}
FNOLWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            claimData: PropTypes.shape({}),
            redirectPath: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(withViewModelService(FNOLWizard));
