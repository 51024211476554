import { defineMessages } from 'react-intl';

export default defineMessages({
    platformSectionHeaderOpen: {
        id: 'platform.section-header.Open',
        defaultMessage: 'Open'
    },
    platformSectionHeaderDelete: {
        id: 'platform.section-header.Delete',
        defaultMessage: 'Delete'
    },
    platformSectionHeaderEdit: {
        id: 'platform.section-header.Edit',
        defaultMessage: 'Edit'
    }
});
