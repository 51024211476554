import _ from 'lodash';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import useStaticInformation from './hooks/useStaticInformation';
import EmailLink from '../EmailLink/EmailLink';
import messages from './StaticInformation.messages';
import styles from './StaticInformation.module.scss';


function StaticInformation(props) {
    const { step, country } = props;
    const [expanded, setExpanded] = useState(country !== 'pl');
    const information = useStaticInformation(step, country);
    const translator = useContext(TranslatorContext);

    return (
        <div>
            {_.map(information, ({
                link, linkDescription, email, email2, ...message
            }) => {
                return (
                    <div>
                        <div className={expanded ? styles.expanded : styles.collapsed}>
                            <FormattedMessage
                                {...message.id}
                                values={{
                                    email: <EmailLink target="_blank">{translator(email)}</EmailLink>,
                                    email2: <EmailLink target="_blank">{translator(email2)}</EmailLink>,
                                    link: <Link href={translator(link)} target="_blank">{translator(linkDescription)}</Link>
                                }}
                            />
                        </div>
                        {country === 'pl'
                        && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <Link
                                onClick={() => setExpanded(!expanded)}
                            >
                                {expanded ? messages.staticInformationReadLess : messages.staticInformationReadMore}
                            </Link>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

StaticInformation.propTypes = {
    country: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired
};

export default StaticInformation;
