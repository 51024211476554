import { useState, useCallback } from 'react';
import { useAppInsights } from 'cnd-common-hooks-platform-react';

export default function useErrors() {
    const [, updateErrorState] = useState();
    const appInsights = useAppInsights();

    const throwReactError = useCallback((err) => {
        appInsights.trackException(err, 'reactError'); // Cnd
        // in order to make React aware of the error thrown outside of the rendering lifecycle
        // we trigger an error in a state update.
        // This will make sure the errors are intercepted by the closest error boundary.
        updateErrorState(() => { throw err; });
    }, []);

    return { throwReactError };
}
