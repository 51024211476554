import React, {
    useCallback, useState, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewModelServiceContext, ViewModelForm, withViewModelService } from 'gw-portals-viewmodel-react';
import metadata from './TokenStep.metadata.json5';
import styles from '../../pages/SmsVerificationPage/SmsVerificationPage.module.scss';

function FNOLSmsVerificationTokenStep(props) {
    const [isAdvicesVisible, setIsAdvicesVisible] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        tokenRequestVM,
        model: claimRequestVM,
        setClaimRequestVM,
        onValidate,
        sendSms
    } = props;


    const onValueChange = useCallback((value, path) => {
        const updatedVM = viewModelService.clone(claimRequestVM);
        _.set(updatedVM.value, path, value);
        setClaimRequestVM(updatedVM);
    }, [viewModelService, setClaimRequestVM, claimRequestVM]);

    const sendAnotherSms = useCallback(async () => {
        await sendSms();
    }, [sendSms]);

    const overrideProps = {
        fnolTokenStepPhone: {
            value: _.get(tokenRequestVM, 'phone'),
            visible: _.get(tokenRequestVM, 'phone') !== undefined,
            onValidate,
            disabled: true
        },
        fnolTokenStepTokenAdvicesContainer: {
            visible: isAdvicesVisible
        },
        fnolTokenStepTokenLink1: {
            onClick: sendAnotherSms
        },
        fnolTokenStepTokenLink2: {
            onClick: () => setIsAdvicesVisible(!isAdvicesVisible)
        },
        fnolTokenStepTokenAdvicesLi3Link: {
            to: '/contact-call-center'
        },
        fnolTokenStepTokenAdvicesLi3Separator: {
            content: ' '
        }
    };

    const resolvers = {
        resolveComponentMap: {
        },
        resolveCallbackMap: {
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={claimRequestVM}
            onValidationChange={onValidate}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
        />
    );
}
FNOLSmsVerificationTokenStep.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    phoneVM: PropTypes.shape({}).isRequired,
    tokenRequestVM: PropTypes.shape({}).isRequired,
    model: PropTypes.shape({}).isRequired,
    setClaimRequestVM: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    sendSms: PropTypes.func.isRequired
};
export default withViewModelService(FNOLSmsVerificationTokenStep);
