import _ from 'lodash';
import { EntityUtil } from 'gw-portals-util-js';
import INCIDENT_TYPES from '../utils/IncidentsMapperUtil';

function IncidentsData(data) {
    if (data) {
        _.extend(this, data);
        _.forEach(INCIDENT_TYPES, (value) => {
            const IncidentClass = value.ConstructorClass;
            this[value.collectionName] = _.map(this[value.collectionName], (incident) => {
                return new IncidentClass(incident);
            });
        });
    } else {
        _.forEach(INCIDENT_TYPES, (value) => {
            this[value.collectionName] = [];
        });
    }
}

IncidentsData.prototype.removeIncident = function removeIncident(id) {
    _.forEach(INCIDENT_TYPES, (value) => {
        const incidentToDelete = this[value.collectionName]
            .find((inc) => inc.tempID === id || inc.publicID === id);
        const idx = _.indexOf(this[value.collectionName], incidentToDelete);
        if (idx >= 0) {
            this[value.collectionName].splice(idx, 1);
        }
    });
};

IncidentsData.prototype.createIncident = function createIncident(incidentTypeCode) {
    const incident = new (INCIDENT_TYPES[incidentTypeCode].ConstructorClass)();
    incident.tempID = EntityUtil.nextId();
    this[INCIDENT_TYPES[incidentTypeCode].collectionName].push(incident);
    return incident;
};

export default IncidentsData;
