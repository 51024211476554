/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, {
    useRef,
    useCallback,
    useMemo,
    useEffect,
    useContext,
    useState
} from 'react';
import PropTypes from 'prop-types';
import isSameSecond from 'date-fns/isSameSecond';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import {
    DATA_TYPE_OBJECT,
    DATA_TYPE_DATE_TIME,
} from '@jutro/prop-types';
import { ServiceManager } from '@jutro/services';
import { TranslatorContext } from '@jutro/locale';
import { CountryLayerService } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import CustomInput from './CustomInput';
import CustomHeader from './CustomHeader';
import {
    adjustDate,
    parseDate,
    formatDate,
    valueToDate as valueToDateUtil,
    getFnsLocale
} from './utils';
import messages from './DatePicker.messages';
import styles from './DatePicker.module.scss';

const popperModifiers = {
    preventOverflow: {
        enabled: true
    },
    flip: {
        enabled: false
    }
};

function DatePicker(props) {
    const {
        id,
        placeholder,
        required,
        disabled,
        value,
        format,
        autoComplete,
        onValueChange,
        onBlur,
        className,
        dataType,
        todayButtonText,
        maxDate,
        minDate,
        showTime,
        timeFormat,
        timeIntervals,
        popperPlacement,
        disabledKeyboardNavigation
    } = props;

    const datePickerRef = useRef();
    const translator = useContext(TranslatorContext);

    const localeService = ServiceManager.getService('locale');
    const locale = localeService.getStoredLocale();
    const fnsLocale = useMemo(() => getFnsLocale(locale), [locale]);

    const [currentValue, setCurrentValue] = useState(null);
    const [initialValue, setInitialValue] = useState(value);

    const country = useStoredCountry()?.toUpperCase();
    const {
        format: countryFormat,
        timeFormat: countryTimeFormat = '',
        separator: timeSeparator = '',
        placeholder: countrySpecificPlaceholder
    } = CountryLayerService.getDateFormats(country, showTime);

    const dateFormat = `${countryFormat}${timeSeparator}${countryTimeFormat}`;

    const valueToDate = useCallback((val) => valueToDateUtil(val, showTime), [showTime]);

    useEffect(() => {
        registerLocale(locale, fnsLocale);
    }, [locale, fnsLocale]);

    useEffect(() => {
        if (value !== initialValue) {
            const date = valueToDate(value);
            const initialDate = valueToDate(initialValue);
            if (!isSameSecond(initialDate, date)) {
                setInitialValue(value);
                setCurrentValue(null);
            }
        }
    }, [initialValue, value, valueToDate]);

    const minDateObject = useMemo(() => {
        return _.isNil(minDate) ? new Date(1900, 0) : valueToDate(minDate);
    }, [minDate, valueToDate]);

    const maxDateObject = useMemo(() => {
        return _.isNil(maxDate) ? new Date(2100, 0) : valueToDate(maxDate);
    }, [maxDate, valueToDate]);

    const isInRange = useCallback(
        (date) => {
            const meetsMinRequirement = isEqual(date, minDateObject)
                || isAfter(date, minDateObject);
            const meetsMaxRequirement = isEqual(date, maxDateObject)
                || isBefore(date, maxDateObject);

            return meetsMinRequirement && meetsMaxRequirement;
        }, [minDateObject, maxDateObject]
    );

    const dateValue = useMemo(() => valueToDate(initialValue), [initialValue, valueToDate]);

    const placeholderText = useMemo(() => {
        return translator(placeholder ?? countrySpecificPlaceholder);
    }, [placeholder, countrySpecificPlaceholder, translator]);

    const renderCustomHeader = useCallback((headerProps) => {
        const minYear = minDateObject.getUTCFullYear();
        const maxYear = maxDateObject.getUTCFullYear();
        return (
            <CustomHeader
                {...headerProps}
                locale={fnsLocale}
                minYear={minYear}
                maxYear={maxYear}
            />
        );
    }, [minDateObject, maxDateObject, fnsLocale]);

    const renderPopperContainer = useCallback(({ children }) => {
        return (
            <div role="dialog">{children}</div>
        );
    }, []);

    const formatValue = useCallback((date) => {
        if (!date) return undefined;

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        if (!showTime) {
            if (dataType === DATA_TYPE_OBJECT) {
                return {
                    year,
                    month,
                    day
                };
            }
            const dateString = new Date(Date.UTC(year, month, day)).toISOString();
            return dataType === DATA_TYPE_DATE_TIME
                ? dateString
                : dateString.substr(0, 10);
        }

        return dataType === DATA_TYPE_OBJECT ? {
            year,
            month,
            day,
            hour: date.getHours(),
            minute: date.getMinutes()
        } : (date.setSeconds(0), date.setMilliseconds(0), date.toISOString());
    }, [dataType, showTime]);

    const handleUpdate = useCallback((date) => {
        const isEmpty = _.isNil(date);
        const adjustedDate = adjustDate(date, minDateObject, maxDateObject, showTime);
        const isValidDate = !isEmpty && isValid(adjustedDate) && isInRange(adjustedDate);
        const validDate = isValidDate ? adjustedDate : undefined;
        const val = validDate ? formatValue(validDate) : validDate;
        setInitialValue(val);
        onValueChange(val, isValidDate, isEmpty);
    }, [minDateObject, maxDateObject, showTime, isInRange, formatValue, onValueChange]);

    const handleChange = useCallback((date, evt) => {
        if (evt === false) {
            // this is the case when we call datePickerRef.current.setSelected
            // we only want to update month and day dropdowns but not values itself
            return;
        }
        setCurrentValue(null);
        handleUpdate(date);
    }, [handleUpdate]);

    const handleRawChange = useCallback((evt) => {
        const val = evt.target.value;
        setCurrentValue(val);
        let parsedDate = null;
        if (!_.isEmpty(val)) {
            parsedDate = parseDate(val, dateFormat, fnsLocale);
            if (isValid(parsedDate)) {
                datePickerRef.current.setSelected(parsedDate, false);
            } else if (showTime) {
                // try to parse partial date part without time
                const partialDate = parseDate(val, countryFormat, fnsLocale);
                if (isValid(partialDate)) {
                    parsedDate = partialDate;
                    datePickerRef.current.setSelected(parsedDate, false);
                }
            }
        }
        handleUpdate(parsedDate);
        evt.preventDefault();
    }, [dateFormat, fnsLocale, countryFormat, showTime, handleUpdate, datePickerRef]);

    const handleFocus = useCallback((evt) => {
        datePickerRef.current.setFocus(true);
    }, [datePickerRef]);

    const handleBlur = useCallback((evt) => {
        if (isValid(dateValue)) {
            const formattedDate = formatDate(dateValue, dateFormat, fnsLocale);
            if (currentValue !== formattedDate) {
                setCurrentValue(formattedDate);
            }
        }
        if (_.isFunction(onBlur)) {
            return onBlur(evt);
        }
        return true;
    }, [dateValue, currentValue, dateFormat, fnsLocale, onBlur, setCurrentValue]);

    return (
        <ReactDatePicker
            id={id}
            ref={datePickerRef}
            showTimeSelect={showTime}
            locale={locale}
            timeCaption={translator(messages.time)}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            minDate={minDateObject}
            maxDate={maxDateObject}
            selected={dateValue}
            value={currentValue}
            isClearable={false}
            required={required}
            disabled={disabled}
            placeholderText={placeholderText}
            autoComplete={autoComplete ? 'on' : 'off'}
            onBlur={handleBlur}
            onChange={handleChange}
            onChangeRaw={handleRawChange}
            onInputClick={handleFocus}
            popperModifiers={popperModifiers}
            popperContainer={renderPopperContainer}
            popperClassName={styles.popper}
            popperPlacement={popperPlacement}
            todayButton={translator(todayButtonText || messages.today)}
            className={className}
            customInput={(
                <CustomInput
                    placeholder={placeholderText}
                    currentValue={currentValue}
                />
            )}
            renderCustomHeader={renderCustomHeader}
            disabledKeyboardNavigation={disabledKeyboardNavigation}
        />
    );
}


DatePicker.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        })
    ]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    onValueChange: PropTypes.func,
    onBlur: PropTypes.func,
    autoComplete: PropTypes.bool,
    className: PropTypes.string,
    todayButtonText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        })
    ]),
    maxDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    minDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    format: PropTypes.oneOf(['short', 'long', 'abbreviated', 'full']),
    dataType: PropTypes.oneOf(['object', 'string', 'date-time']).isRequired,
    showTime: PropTypes.bool,
    timeFormat: PropTypes.string,
    timeIntervals: PropTypes.number,
    popperPlacement: PropTypes.oneOf([
        'auto',
        'auto-left',
        'auto-right',
        'bottom',
        'bottom-end',
        'bottom-start',
        'left',
        'left-end',
        'left-start',
        'right',
        'right-end',
        'right-start',
        'top',
        'top-end',
        'top-start'
    ]),
    disabledKeyboardNavigation: PropTypes.bool
};

DatePicker.defaultProps = {
    placeholder: undefined,
    required: false,
    disabled: false,
    value: undefined,
    onValueChange: _.noop,
    onBlur: undefined,
    autoComplete: false,
    className: undefined,
    todayButtonText: undefined,
    maxDate: undefined,
    minDate: undefined,
    popperPlacement: undefined,
    format: 'long',
    showTime: false,
    timeFormat: 'HH:mm',
    timeIntervals: 60,
    disabledKeyboardNavigation: true,
};

export default DatePicker;
