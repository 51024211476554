import _ from 'lodash';
import React, {
    useEffect, useContext, useState, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { InputField, TypeaheadMultiSelectField } from '@jutro/components';
import { useBreakpoint } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { ThemeContext } from '@jutro/theme';
import { useValidation } from 'gw-portals-validation-react';
import { PhoneNumberService } from 'cnd-common-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import InputMaskFieldCnd from '../InputMaskFieldCnd';
import styles from './PhoneNumberWithCountryCode.module.scss';
import labelMessages from '../CheckboxWithLink/Label.messages';
import { labelStyles } from '../CheckboxWithLink/styles';

function PhoneNumberWithCountryCode(props) {
    const theme = useContext(ThemeContext);
    const translator = useContext(TranslatorContext);
    const appCountry = useStoredCountry()?.toUpperCase();
    const [errorsOnBlur, setErrorsOnBlur] = useState(false);
    const { breakpointProps, applyBreakpoint } = useBreakpoint(props);
    const overrideProps = applyBreakpoint(props, breakpointProps);

    const {
        id,
        path,
        value: phoneVM,
        onValueChange,
        onValidate,
        label,
        hideLabel,
        showErrors,
        readOnly,
        disabled,
        validationMessages
    } = props;

    const { labelPosition } = overrideProps;
    const themeStyles = theme.getStyles('FieldLabel', labelStyles);

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const isInvalid = (showErrors || errorsOnBlur)
    && (phoneVM.number.aspects.validationMessages.length > 0
        || validationMessages?.length > 0);

    const phoneNumberMask = useMemo(
        () => PhoneNumberService.getPhoneNumberMask(phoneVM.value.countryCode), [phoneVM.value.countryCode]
    );

    const phoneNumberRequiredMinLength = useMemo(() => {
        return phoneNumberMask.replaceAll('0', '').replaceAll(' ', '').length;
    }, [phoneNumberMask]);

    const getFormValidity = useCallback(() => {
        const phoneNumber = _.get(phoneVM, 'number.value')?.replaceAll(' ', '');
        const hasMinPhoneNumberLength = !phoneNumber
            || phoneNumber.length >= phoneNumberRequiredMinLength;

        return hasMinPhoneNumberLength && phoneVM.aspects.valid && phoneVM.aspects.subtreeValid;
    }, [phoneVM, phoneNumberRequiredMinLength]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (phoneVM.value.countryCode === undefined) {
            phoneVM.value.countryCode = appCountry;
        }
    }, [phoneVM.value.countryCode, appCountry]);

    const getCodeNamePair = useCallback((typecode) => {
        const name = translator({
            id: typecode.name,
            defaultMessage: typecode.name
        });
        return {
            code: typecode.code,
            name: `${name.substring(0, name.indexOf('('))} +${name.substring(name.indexOf('(') + 1, name.indexOf(')'))}`
        };
    }, [translator]);

    const availableValues = _.get(phoneVM, 'countryCode.aspects.availableValues');

    const availableCountryCodes = useMemo(() => {
        return _.map(availableValues, (typecode) => getCodeNamePair(typecode))
            .filter((elem) => elem.name.length > 2).sort((a, b) => a.name.localeCompare(b.name));
    }, [availableValues, getCodeNamePair]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const handleOnBlur = useCallback(
        () => {
            setErrorsOnBlur(true);
        }, []
    );

    // to avoid InputMask problem with empty data
    const [masks, setMasks] = useState({ phoneNumberMask: '' });
    const setPhoneNumberMask = useCallback(() => {
        setMasks({ ...masks, phoneNumberMask: phoneNumberMask });
    }, [setMasks, masks, phoneNumberMask]);

    const createInputNumberComponent = () => {
        if (!readOnly) {
            return (
                <InputMaskFieldCnd
                    onFocus={setPhoneNumberMask}
                    id={`${id}Number`}
                    mask={masks.phoneNumberMask}
                    hideLabel
                    disabled={disabled}
                    path="number"
                    onBlur={handleOnBlur}
                    onValueChange={handleValueChange}
                    value={phoneVM.value.number}
                />
            );
        }
        return (
            <InputField
                id={`${id}Number`}
                readOnly
                hideLabel
                path="number"
                value={phoneVM.value.number}
            />
        );
    };

    return (
        <TranslatorContext.Consumer>
            {(translate) => (
                <div className={`jut__FieldComponent__fieldComponent jut__FieldComponent__${labelPosition}`}>
                    { label && !hideLabel
                        && (
                            <div className={`${themeStyles.fieldLabelContainer} ${themeStyles[labelPosition]}`}>
                                <label className={themeStyles.fieldLabel} htmlFor={`${id}Number`}>
                                    <span className={themeStyles.primaryLabel}>
                                        {translate(label)}
                                    </span>
                                    {!phoneVM.number.aspects.required && !readOnly
                                        && (
                                            <span className={themeStyles.optional}>
                                                {translate(labelMessages.optional)}
                                            </span>
                                        )
                                    }
                                </label>
                            </div>
                        )
                    }
                    <div className="jut__FieldComponent__contentContainer jut__FieldComponent__controls">
                        <div className={cx(styles.container, { invalid: isInvalid })}>
                            { availableCountryCodes && phoneVM
                                && (
                                    <div className={readOnly
                                        ? styles.countryCodeElementReadOnly
                                        : styles.countryCodeElement}
                                    >
                                        <TypeaheadMultiSelectField
                                            availableValues={availableCountryCodes}
                                            hideLabel
                                            readOnly={readOnly}
                                            disabled={disabled}
                                            onValueChange={handleValueChange}
                                            id={`${id}CountryCode`}
                                            singleSelect
                                            path="countryCode"
                                            value={[phoneVM.value.countryCode]}
                                            onValidationChange={setComponentValidation}
                                        />
                                    </div>
                                )
                            }
                            { phoneVM
                                && (
                                    <div className={styles.phoneNumberElement}>
                                        { createInputNumberComponent() }
                                    </div>
                                )
                            }
                        </div>
                        { isInvalid
                            && (
                                <div className="jut__FieldMessage__fieldMessage jut__FieldMessage__error">
                                    { phoneVM.number.aspects.validationMessages
                                        .concat(validationMessages)
                                        .map((error) => (
                                            <div>{error}</div>
                                        ))}
                                </div>
                            )
                        }
                    </div>
                </div>
            )}
        </TranslatorContext.Consumer>
    );
}
PhoneNumberWithCountryCode.propTypes = {
    label: PropTypes.shape({}).isRequired,
    hideLabel: PropTypes.bool,
    phone: PropTypes.shape({
        labelPosition: PropTypes.oneOf(['top', 'left'])
    }),
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    showErrors: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    validationMessages: PropTypes.arrayOf({})
};
PhoneNumberWithCountryCode.defaultProps = {
    hideLabel: false,
    phone: {
        labelPosition: 'top'
    },
    readOnly: false,
    disabled: false,
    validationMessages: undefined
};
export default PhoneNumberWithCountryCode;
