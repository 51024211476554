import React, {
    Fragment, useContext, useCallback, useEffect, useMemo, useState, useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useErrors, ErrorBoundary } from 'gw-portals-error-react';
import { VMLoggingUtil } from 'cnd-common-portals-util-js';
import { WizardContext } from './WizardContext';
import { TranslatorContext } from '@jutro/locale';
import wizardMessages from './Wizard.messages';
import { checkIsDataValid } from './Wizard';

const TRANSACTION_NEXT = 'next';
const TRANSACTION_PREVIOUS = 'previous';
const TRANSACTION_CANCEL = 'cancel';

function checkContinue(result) {
    if (result === false) {
        return Promise.reject(new Error(false));
    }
    return Promise.resolve(result);
}

function WizardPage(props) {
    const { throwReactError } = useErrors();
    const wizardContext = useContext(WizardContext);
    const translator = useContext(TranslatorContext);
    const [isLoadingCancel, updateLoadingCancel] = useState(false);
    const [isLoadingPrevious, updateLoadingPrevious] = useState(false);
    const [isLoadingNext, updateLoadingNext] = useState(false);
    const [transitionDirection, updateTransitionDirection] = useState(undefined);

    const {
        isSkipping,
        stopSkipping,
        goNext: wizardGoNext,
        goPrevious: wizardGoPrevious,
        cancel: wizardCancel,
        finish: wizardFinish,
        markStepSubmitted,
        wizardData,
        wizardSnapshot,
        updateWizardData,
        updateWizardSnapshot,
        currentStepIndex,
        currentStep = {},
        steps,
        hasNewErrors,
        errorsForStep,
        ...otherWizardProps
    } = wizardContext;

    const { stepProps = {} } = currentStep;
    const { template: templateFromConfig } = stepProps;

    const {
        template,
        skipWhen,
        onPrevious: pageOnPrevious,
        onCancel: pageOnCancel,
        onNext: pageOnNext,
        finish,
        children,
        updateSnapshot,
        ...otherProps
    } = props;

    const Template = templateFromConfig || template;

    useLayoutEffect (() => {
        document.title = translator(steps[currentStepIndex].title) + ' | Colonnade Insurance S.A.';
    }, []);

    useEffect(() => {
        if (isSkipping) {
            Promise.resolve(skipWhen(wizardData)).then((shouldSkip) => {
                if (shouldSkip) {
                    wizardGoNext();
                } else {
                    stopSkipping();
                }
            }).catch(throwReactError);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = translator(steps[currentStepIndex].title) + ' | Colonnade Insurance S.A.';
    }, []);

    const transitionNext = useCallback(() => {
        if (!hasNewErrors && _.isEmpty(errorsForStep)) {
            if (finish) {
                wizardFinish();
            } else {
                wizardGoNext();
            }
        } else {
            updateTransitionDirection(undefined);
        }
    }, [errorsForStep, finish, hasNewErrors, wizardFinish, wizardGoNext]);

    useEffect(() => {
        switch (transitionDirection) {
            case TRANSACTION_NEXT:
                transitionNext();
                break;
            case TRANSACTION_PREVIOUS:
                wizardGoPrevious();
                break;
            case TRANSACTION_CANCEL:
                wizardCancel();
                break;
            default:
        }
    }, [transitionDirection, transitionNext, wizardCancel, wizardGoPrevious]);

    const onCancel = useCallback(() => {
        updateLoadingCancel(true);
        return Promise.resolve(pageOnCancel(wizardData))
            .then(updateWizardData)
            .then(() => updateTransitionDirection(TRANSACTION_CANCEL))
            .catch(throwReactError)
            .finally(() => {
                updateLoadingCancel(false);
                updateTransitionDirection(undefined);
            });
    }, [wizardData, updateWizardData, pageOnCancel, throwReactError]);

    const onPrevious = useCallback(() => {
        updateLoadingPrevious(true);
        return Promise.resolve(pageOnPrevious(wizardData))
            .then(checkContinue)
            .then(() => updateTransitionDirection(TRANSACTION_PREVIOUS))
            .catch((error) => {
                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }
                throwReactError(error);
            })
            .finally(() => {
                updateLoadingPrevious(false);
                updateTransitionDirection(undefined);
            });
    }, [pageOnPrevious, wizardData, throwReactError]);

    const isLastStep = useMemo(() => currentStepIndex === steps.length - 1, [
        currentStepIndex,
        steps
    ]);

    const nextStepVisitedAlready = useMemo(() => {
        if (isLastStep) {
            return false;
        }
        return steps[currentStepIndex + 1].visited;
    }, [currentStepIndex, isLastStep, steps]);

    const wizardUpdateOperation = useMemo(() => {
        const operation = updateSnapshot ? updateWizardSnapshot : updateWizardData;

        return (newWizardData) => operation(newWizardData, false);
    }, [updateSnapshot, updateWizardData, updateWizardSnapshot]);

    const onNext = useCallback(() => {
        // Cnd custom
        if (!checkIsDataValid(wizardData)) {
            VMLoggingUtil.logInvalidNodes(wizardData);
        }

        if (nextStepVisitedAlready) {
            wizardGoNext();
            return Promise.resolve();
        }
        markStepSubmitted();
        updateLoadingNext(true);
        return Promise.resolve(pageOnNext(wizardData))
            .then(checkContinue)
            .then(wizardUpdateOperation)
            .then(() => updateTransitionDirection(TRANSACTION_NEXT))
            .catch((error) => {
                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }
                throwReactError(error);
            })
            .finally(() => {
                updateLoadingNext(false);
            });
    }, [
        nextStepVisitedAlready,
        markStepSubmitted,
        pageOnNext,
        wizardData,
        wizardUpdateOperation,
        wizardGoNext,
        throwReactError
    ]);

    const handleError = useCallback(
        (error) => {
            // eslint-disable-next-line no-param-reassign
            error.gwInfo = wizardSnapshot;
            throw error;
        },
        [wizardSnapshot]
    );

    const takesNoProps = Template === Fragment || _.isString(Template);
    const pageProps = {
        ...otherProps,
        ...otherWizardProps,
        errorsForStep,
        isLoadingCancel,
        onCancel,
        isLoadingPrevious,
        onPrevious,
        isLoadingNext,
        onNext,
        wizardData,
        wizardSnapshot
    };
    const templateProps = takesNoProps ? {} : pageProps;
    const renderProps = _.isFunction(children);

    return (
        <ErrorBoundary onError={handleError} shouldSetUnhandledRejection={false}>
            <Template {...templateProps}>{renderProps ? children(pageProps) : children}</Template>
        </ErrorBoundary>
    );
}

const messageShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string
});

WizardPage.propTypes = {
    /**
     * The template to be used to render this Wizard page
     * The template will be responsible for rendering the children of this page
     */
    template: PropTypes.elementType,
    /**
     * If true the *onNext* will also finish the wizard
     * */
    finish: PropTypes.bool,

    /**
     * The function that should be called when going to the next step
     *
     * The function can return either a value or a promise.
     * The function will be given the current wizardData and *must return/resolve*
     * the next wizard data
     * If the function returns/resolves to false, the wizard won't continue to the next page
     */
    onNext: PropTypes.func,
    /**
     * whether the Next button should be disabled in the wizard
     */
    disableNext: PropTypes.bool,
    /**
     * whether the next button should be shown or not
     */
    showNext: PropTypes.bool,
    /**
     * the label that should be displayed on the next button
     */
    nextLabel: messageShape,
    /**
     * the tooltip message that should be displayed on the next button on hover
     */
    nextButtonTooltip: PropTypes.string,

    /**
     * The function that should be called when canceling the wizard.
     *
     * The function can return either a value or a promise.
     * The function will be given the current wizardData and *must return/resolve*
     * the next (for the cancelation state) wizard data
     * If the function returns/resolves to false, the wizard won't continue to the previous page
     */
    onPrevious: PropTypes.func,
    /**
     * whether the Previous button should be disabled in the wizard
     */
    disablePrevious: PropTypes.bool,
    /**
     * whether the previous button should be shown or not
     */
    showPrevious: PropTypes.bool,
    /**
     * the label that should be displayed on the previous button
     */
    previousLabel: messageShape,

    /**
     * The function that should be called when going to the previous step.
     *
     * The function can return either a value or a promise.
     * The function will be given the current wizardData and *must return/resolve*
     * the next (for the previous step) wizard data *There is no way to prevent the cancelation*
     * once it is triggered.
     */
    onCancel: PropTypes.func,
    /**
     * whether the Cancel button should be disabled in the wizard
     */
    disableCancel: PropTypes.bool,
    /**
     * whether the cancel button should be shown or not
     */
    showCancel: PropTypes.bool,
    /**
     * the label that should be displayed on the previous button
     */
    cancelLabel: messageShape,

    /**
     * gives the skip condition to the wizard page (when a page should be skipped)
     * This only applies if the wizard is trying to skip pages
     */
    skipWhen: PropTypes.func,
    /** whether the onNext should also update the wizard snapshot */
    updateSnapshot: PropTypes.bool,
    /** children can be passed as react object or through render props */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

WizardPage.defaultProps = {
    template: Fragment,
    finish: false,

    onNext: (wizardData) => Promise.resolve(wizardData),
    disableNext: false,
    showNext: true,
    nextLabel: wizardMessages.next,
    nextButtonTooltip: '',

    onPrevious: (wizardData) => Promise.resolve(wizardData),
    disablePrevious: false,
    showPrevious: true,
    previousLabel: wizardMessages.previous,

    onCancel: (wizardData) => Promise.resolve(wizardData),
    disableCancel: false,
    showCancel: true,
    cancelLabel: wizardMessages.cancel,

    updateSnapshot: true,
    skipWhen: () => Promise.resolve(false),
    children: null
};

export default WizardPage;
