import React, {
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { SectionHeader } from 'cnd-common-components-platform-react';
import metadata from './SummarySection.metadata.json5';

function FNOLSummarySection(props) {
    const {
        value: claimVM,
        sectionTitle,
        component: content,
        onEditClick,
        contentComponentProps,
        isExpanded,
        isIncident,
        printContext
    } = props;

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentsMap: {
            sectionheader: SectionHeader,
            content
        }
    };

    const overrideProps = {
        fnolSummarySectionHeader: {
            sectionTitle,
            visibleButtons: printContext ? [] : ['edit'],
            onEditClick,
            visible: !isIncident
        },
        fnolTitleHeader: {
            content: sectionTitle,
            visible: isIncident
        },
        fnolSummarySectionContent: {
            value: claimVM,
            ...contentComponentProps,
            visible: isExpanded
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={claimVM}
                componentMap={resolvers.resolveComponentsMap}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLSummarySection.propTypes = {
    value: PropTypes.shape({}).isRequired,
    sectionTitle: PropTypes.string.isRequired,
    component: PropTypes.shape({}).isRequired,
    contentComponentProps: PropTypes.shape({}),
    onEditClick: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    isIncident: PropTypes.bool.isRequired,
    printContext: PropTypes.bool.isRequired
};

FNOLSummarySection.defaultProps = {
    contentComponentProps: {}
};

export default FNOLSummarySection;
