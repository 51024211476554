import { cloneDeep as _cloneDeep, extend as _extend } from 'lodash';
import { EntityUtil } from 'gw-portals-util-js';
import Person from './Person';
import Company from './Company';

function CheckPayeeData(data) {
    if (data) {
        _extend(this, data);
    }
    this.bankAccountDetails = this.bankAccountDetails || {};
}

CheckPayeeData.prototype.nextId = () => {
    return EntityUtil.nextId();
};

CheckPayeeData.prototype.removeIndividual = function removeIndividual() {
    this.individual = undefined;
};

CheckPayeeData.prototype.removeCompany = function removeCompany() {
    this.company = undefined;
};

CheckPayeeData.prototype.createIndividual = function createIndividual(p) {
    const copiedPerson = _cloneDeep(p);
    const individual = new Person(copiedPerson);
    individual.publicID = undefined;
    individual.tempID = EntityUtil.nextId();
    this.individual = individual;
    return individual;
};

CheckPayeeData.prototype.createCompany = function createCompany(c) {
    const copiedCompany = _cloneDeep(c);
    const company = new Company(copiedCompany);
    company.publicID = undefined;
    company.tempID = EntityUtil.nextId();
    this.company = company;
};

export default CheckPayeeData;
