import _ from 'lodash';

function Person(data) {
    if (data) {
        _.extend(this, data);
    }
    this.address = this.address || {};
    this.phone = this.phone || {};
}

export default Person;
