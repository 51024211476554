import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolBankAccountDetailsAccountOwner: {
        id: 'fnol.components.bank-account-details.Bank Account Owner',
        defaultMessage: 'Bank Account Owner'
    },
    fnolBankAccountDetailsAccountCountry: {
        id: 'fnol.components.bank-account-details.Bank Account Country',
        defaultMessage: 'Bank Account Country'
    },
    fnolBankAccountDetailsAccountNumber: {
        id: 'fnol.components.bank-account-details.Account Number',
        defaultMessage: 'Account Number'
    },
    fnolBankAccountDetailsSwiftBic: {
        id: 'fnol.components.bank-account-details.SWIFT/BIC',
        defaultMessage: 'SWIFT/BIC'
    },
    fnolBankAccountDetailsPrefix: {
        id: 'fnol.components.bank-account-details.Prefix',
        defaultMessage: 'Prefix'
    },
    fnolBankAccountDetailsInfo: {
        id: 'fnol.components.bank-account-details.Bank account number and SWIFT/BIC code, you can check on your monthly bank statement, online banking or on the bank\'s website.',
        defaultMessage: 'Bank account number and SWIFT/BIC code, you can check on your monthly bank statement, online banking or on the bank\'s website.'
    },
    fnolBankAccountDetailsBankInvalidAccountNumber: {
        id: 'fnol.components.bank-account-details.Please make sure that the Bank Account number provided is correct or enter valid Bank Account number.',
        defaultMessage: 'Please make sure that the Bank Account number provided is correct or enter valid Bank Account number.'
    },
    fnolBankAccountDetailsBankAccountNumberType: {
        id: 'fnol.components.bank-account-details.Bank Account Number Type',
        defaultMessage: 'Bank Account Number Type'
    },
    fnolBankAccountDetailsBankCode: {
        id: 'fnol.components.bank-account-details.Bank Code',
        defaultMessage: 'Bank Code'
    }
});
