import _ from 'lodash';
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import metadata from './ContactDetailsSummary.metadata.json5';
import SimplePerson from '../../SimplePerson/SimplePerson';
import Company from '../../Company/Company';
import Person from '../../Person/Person';
import './ContactDetailsSummary.messages';

function FNOLContactDetailsSummary(props) {
    const {
        value: claimVM,
    } = props;

    const [availableRelatedContacts, setAvailableRelatedContacts] = useState([]);
    const CLAIMANT_ROLE_CODE = 'claimant';
    const REPORTER_ROLE_CODE = 'reporter';
    const [reporter, setReporter] = useState('');
    const translator = useContext(TranslatorContext);

    const generateRelatedContactName = useCallback((rolePerson) => {
        const name = `${rolePerson.contact.firstName} ${rolePerson.contact.lastName}`;
        const roleNames = [];
        rolePerson.roles.forEach(role => {
            if (role && role !== CLAIMANT_ROLE_CODE) {
                roleNames.push(translator({ id: `typekey.ContactRole.${role}` }));
            }
        });
        const roles = roleNames.length ? ` (${roleNames.join(', ')})` : '';
        return `${name}${roles}`;
    }, []);

    const hasRole = (roleContact, role) => roleContact.roles.find(r => r === role);

    const chooseDefaultRelatedContactIfReporterExists = useCallback(
        () => {
            const alreadyChosenReporter = claimVM.value.relatedContacts
                .find((roleContact) => hasRole(roleContact, REPORTER_ROLE_CODE));
            if (alreadyChosenReporter) {
                setReporter(alreadyChosenReporter.contact.publicID);
            }
        },
        [setReporter, claimVM]
    );

    const createAvailableOptions = useCallback(
        () => {
            const availableOptions = claimVM.value.relatedContacts.filter(e => e.contact).map((rolePerson) => (
                {
                    code: rolePerson.contact.publicID,
                    name: generateRelatedContactName(rolePerson)
                }
            ));
            setAvailableRelatedContacts(availableOptions);
        },
        [generateRelatedContactName, claimVM.value.relatedContacts]
    );

    useEffect(() => {
        createAvailableOptions();
        chooseDefaultRelatedContactIfReporterExists();
    }, [createAvailableOptions, chooseDefaultRelatedContactIfReporterExists]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            readOnly: true
        },
        fnolContactDetailsSummaryPersonOrCompany: {
            value: _.get(claimVM.value, 'claimantData.claimantType'),
        },
        fnolContactDetailsSummaryRelatedContacts: {
            value: reporter,
            availableValues: availableRelatedContacts,
        },
        fnolContactDetailsSummaryPersonWhoRegistersClaim: {
            visible: _.get(claimVM.value, 'claimantData.companyRepresentative') !== undefined
        },
        fnolContactDetailsSummaryCompanyRepresentative: {
            visible: _.get(claimVM.value, 'claimantData.companyRepresentative') !== undefined,
            value: _.get(claimVM, 'claimantData.companyRepresentative'),
            isEmailNotificationInfoVisible: true,
            countryCode: _.get(claimVM, 'claimantData.company.address.value.country')
        },
        fnolContactDetailsSummaryRelationToInsured: {
            visible: _.get(claimVM.value, 'claimantData.relationToInsured') !== undefined,
            value: _.get(claimVM.value, 'claimantData.relationToInsured'),
        },
        fnolContactDetailsSummaryRelationToCompany: {
            visible: _.get(claimVM.value, 'claimantData.companyRepresentative') !== undefined,
            value: _.get(claimVM.value, 'claimantData.relationToCompany'),
        },
        fnolContactDetailsSummaryCompanyDetailsTitle: {
            visible: _.get(claimVM.value, 'claimantData.company') !== undefined
        },
        fnolContactDetailsSummaryCompany: {
            value: _.get(claimVM, 'claimantData.company'),
            visible: _.get(claimVM.value, 'claimantData.company') !== undefined
        },
        fnolContactDetailsSummaryPerson: {
            visible: _.get(claimVM.value, 'claimantData.individual') !== undefined,
            value: _.get(claimVM, 'claimantData.individual'),
            isEmailNotificationInfoVisible: true
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentsMap: {
            simpleperson: SimplePerson,
            person: Person,
            company: Company
        },
        resolveValue: readValue
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={claimVM.claimantData}
                resolveValue={resolvers.resolveValue}
                componentMap={resolvers.resolveComponentsMap}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLContactDetailsSummary.propTypes = {
    value: PropTypes.shape({}).isRequired,
};

export default FNOLContactDetailsSummary;
