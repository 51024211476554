import React, {
    useEffect, useCallback, useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import metadata from './SimplePerson.metadata.json5';
import './SimplePerson.messages';
import SummaryContext from '../summary/SummaryContext';

function FNOLSimplePerson(props) {
    const {
        id,
        onValidate,
        value: simplePersonVM,
        onValueChange,
        path,
        isEmailNotificationInfoVisible,
        isPhoneOrEmailInfoVisible,
        isClaimantContactInfoMessageVisible,
        countryCode,
        showErrors,
        validationErrors
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        registerComponentValidation(() => {
            return simplePersonVM.aspects.valid && simplePersonVM.aspects.subtreeValid;
        });
    }, [simplePersonVM.aspects.subtreeValid,
        simplePersonVM.aspects.valid,
        registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        fnolSimplePersonPhone: {
            visible: !readOnly || !_.isNil(simplePersonVM.value.phone.number),
            showErrors: !_.isNil(validationErrors.phone),
            validationMessages: validationErrors.phone?.messages || simplePersonVM.phone.number.aspects.validationMessages,
            countryCode
        },
        fnolSimplePersonEmail: {
            visible: !readOnly || !_.isNil(simplePersonVM.value.email)
        },
        fnolSimplePersonEmailNotificationInfo: {
            visible: isEmailNotificationInfoVisible
        },
        fnolSimplePersonPhoneOrEmailInfo: {
            visible: !readOnly && isPhoneOrEmailInfoVisible
        },
        fnolSimplePersonProvideClaimantContactInfo: {
            visible: !readOnly && isClaimantContactInfoMessageVisible
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={simplePersonVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

FNOLSimplePerson.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    isEmailNotificationInfoVisible: PropTypes.bool,
    isPhoneOrEmailInfoVisible: PropTypes.bool,
    isClaimantContactInfoMessageVisible: PropTypes.bool,
    countryCode: PropTypes.string,
    showErrors: PropTypes.bool.isRequired,
    validationErrors: PropTypes.objectOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            messages: PropTypes.arrayOf(PropTypes.string).isRequired,
        })
    )
};
FNOLSimplePerson.defaultProps = {
    isEmailNotificationInfoVisible: false,
    isPhoneOrEmailInfoVisible: false,
    isClaimantContactInfoMessageVisible: false,
    countryCode: undefined,
    validationErrors: {}
};
export default FNOLSimplePerson;
