import _ from 'lodash';
import Person from './Person';

function InjuryIncident(data) {
    if (data) {
        _.extend(this, data);
    }
    this.injured = this.injured || new Person();
    this.subtype = 'InjuryIncident';
}

InjuryIncident.prototype.getTypeCode = function getTypeCode() {
    return 'InjuryIncident';
};

export default InjuryIncident;
