import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { withTracking } from 'gw-portals-tracking-react';
import FieldLinkComponent from '../FieldLinkComponent/FieldLinkComponent';

class HeaderLinkComponent extends Component {
    /**
     * @memberof gw-components-platform-react.HeaderLinkComponent
     * @prop {string} propTypes.icon - Font awesome icon name
     * @prop {string} propTypes.title - Title of the link
     * @prop {string} propTypes.description - Short descrition about the title
     * @prop {boolean} propTypes.link - URL link
     */

    static propTypes = {
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        toolTipTextCnd: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        linkId: PropTypes.string.isRequired,
        onClick: PropTypes.func
    };

    static defaultProps = {
        onClick: _.noop
    };

    render() {
        const {
            title, icon, link, description, linkId, onClick, toolTipTextCnd
        } = this.props;

        return (
            <TranslatorContext.Consumer>
                {(translator) => (
                    <div>
                        <span>
                            {translator(description)}
                        </span>
                        <FieldLinkComponent
                            id={linkId}
                            value={translator(title)}
                            icon={icon}
                            title={translator(toolTipTextCnd)}
                            onClick={onClick}
                            href={link}
                        />
                    </div>
                )}
            </TranslatorContext.Consumer>
        );
    }
}

export default withTracking(HeaderLinkComponent);
