import _ from 'lodash';
import React, {
    useContext
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import Officials from '../../Officials/Officials';
import Witnesses from '../../Witnesses/Witnesses';
import InsuredPerson from '../../InsuredPerson/InsuredPerson';
import SchoolInformation from '../../SchoolInformation/SchoolInformation';
import metadata from './AdditionalInformationSummary.metadata.json5';
import styles from './AdditionalInformationSummary.module.scss';

function FNOLAdditionalInformationSummary(props) {
    const translator = useContext(TranslatorContext);
    const {
        value: claimVM,
    } = props;


    const resolvers = {
        resolveCallbackMap: styles,
        resolveComponentMap: {
            officials: Officials,
            witnesses: Witnesses,
            insuredperson: InsuredPerson,
            schoolinformation: SchoolInformation
        }
    };

    const getNameColumn = (rowData, index) => {
        const value = _.get(claimVM.value, `documents[${index}].name`);
        return (
            <span>{value}</span>
        );
    };

    const getTypeColumn = (rowData, index) => {
        const type = _.get(claimVM.value, `documents[${index}].documentType`);
        const value = translator({
            id: `typekey.DocumentType.${type}`,
            defaultMessage: type
        });

        return (
            <span>{value}</span>
        );
    };

    const getTableOverrides = () => {
        return {
            fnolAdditionalInformationPageDocumentsTableNameColumn: {
                onCell: getNameColumn
            },
            fnolAdditionalInformationPageDocumentsTableTypeColumn: {
                onCell: getTypeColumn
            }
        };
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            readOnly: true
        },
        fnolAdditionalInformationSummaryOfficialsTitle: {
            visible: _.get(claimVM.value, 'relatedOfficials').length > 0
        },
        fnolAdditionalInformationSummaryOfficials: {
            visible: _.get(claimVM.value, 'relatedOfficials').length > 0
        },
        fnolAdditionalInformationSummaryWitnessesTitle: {
            visible: _.get(claimVM.value, 'witnesses').length > 0
        },
        fnolAdditionalInformationSummaryWitnesses: {
            visible: _.get(claimVM.value, 'witnesses').length > 0
        },
        fnolAdditionalInformationPageDocumentsTable: {
            data: _.get(claimVM.value, 'documents'),
            visible: _.get(claimVM.value, 'documents').length > 0
        },
        fnolAdditionalInformationSummaryInsuredPerson: {
            visible: _.get(claimVM.value, 'insuredPerson') !== undefined
        },
        fnolAdditionalInformationPageTitle: {
            visible: _.get(claimVM.value, 'documents').length > 0
        },
        fnolAdditionalInformationSchoolInformationContainer: {
            visible: _.get(claimVM.value, 'isSchoolPolicy')
        },
        ...getTableOverrides()
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={claimVM}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrideProps}
            />
        </div>
    );
}

FNOLAdditionalInformationSummary.propTypes = {
    value: PropTypes.shape({}).isRequired,
    component: PropTypes.shape({}).isRequired
};

export default FNOLAdditionalInformationSummary;
