import React, { useCallback } from 'react';

import VariableLenghtInputMask from '../VariableLenghtInputMask';


function InputMaskFieldCnd(props) {
    const getValidationMessages = useCallback(() => null, []);

    return <VariableLenghtInputMask {...props} getValidationMessages={getValidationMessages} />;
}
export default InputMaskFieldCnd;
