import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolCallContactCenterPageTitle: {
        id: 'fnol.components.call-contact-center-page.Contact Call Center',
        defaultMessage: 'Contact Call Center'
    },
    fnolCallContactCenterPageInfo: {
        id: 'fnol.components.call-contact-center-page.Based on the information you provided, we are unable to process your request online',
        defaultMessage: 'Based on the information you provided, we are unable to process your request online'
    },
    fnolCallContactCenterPageSubinfo: {
        id: 'fnol.components.call-contact-center-page.Please call our Call Center, using the number { phonenumber }, to register a claim.',
        defaultMessage: 'Please call our Call Center, using the number { phonenumber }, to register a claim.'
    },
    fnolCallContactCenterPageNumber: {
        id: 'fnol.components.call-contact-center-page.123-456-789',
        defaultMessage: '123-456-789'
    }
});
