import { defineMessages } from 'react-intl';

export default defineMessages({
    today: {
        id: 'cnd.common.components.DatePicker.Today',
        defaultMessage: 'Today'
    },
    time: {
        id: 'cnd.common.components.DatePicker.Time',
        defaultMessage: 'Time'
    },
    incorrectInput: {
        id: 'cnd.common.components.DatePicker.Incorrect date format',
        defaultMessage: 'Incorrect date format'
    }
});
