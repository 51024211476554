import { defineMessages } from 'react-intl';

export default defineMessages({
    placeholder: {
        id: 'cnd.modules.CountryLayerServices.Placeholder.DD.MM.YYYY',
        defaultMessage: 'DD.MM.YYYY'
    },
    placeholderWithTime: {
        id: 'cnd.modules.CountryLayerServices.Placeholder.DD.MM.YYYY, HH:MM',
        defaultMessage: 'DD.MM.YYYY, HH:MM'
    },
    placeholderVariantYear: {
        id: 'cnd.modules.CountryLayerServices.Placeholder.YYYY/MM/DD',
        defaultMessage: 'YYYY/MM/DD'
    },
    placeholderVariantYearWithTime: {
        id: 'cnd.modules.CountryLayerServices.Placeholder.YYYY/MM/DD, HH:MM',
        defaultMessage: 'YYYY/MM/DD, HH:MM'
    }
});
