import PropTypes from 'prop-types';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useContext
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TranslatorContext } from '@jutro/locale';
import { ContactInfoService, TagManagerService } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import metadata from './DefaultErrorPage.metadata.json5';
import messages from './DefaultErrorPage.messages';
import styles from './DefaultErrorPage.module.scss';

function FNOLDefaultErrorPage() {
    const history = useHistory();
    const location = useLocation();
    const { claimNumber } = location.state;
    const translator = useContext(TranslatorContext);
    const country = useStoredCountry();
    const countryCode = country?.toUpperCase();

    useLayoutEffect(() => {
        document.title = translator(messages.fnolDefaultErrorPageError) + ' | Colonnade Insurance S.A.';
    }, []);

    useEffect(() => {
        TagManagerService.pushActionEvent('error-pageview');
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideProps = {
        fnolDefaultErrorPageInfo: {
            content: <FormattedMessage
                {...messages.fnolDefaultErrorPageInfo}
                values={{
                    phonenumber: ContactInfoService.getContactPhoneNumber(countryCode)
                }}
            />
        },
        fnolDefaultErrorPageSubinfo: {
            content: <FormattedMessage
                {...messages.fnolDefaultErrorPageSubinfo}
                values={{
                    claimDraftNumber: claimNumber
                }}
            />,
            visible: claimNumber !== undefined
        }
    };

    const goBack = useCallback(() => {
        history.replace('/');
    }, [history]);

    const resolvers = {
        resolveComponentMap: {
        },
        resolveCallbackMap: {
            goBack,
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

FNOLDefaultErrorPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
        replace: PropTypes.func,
    }).isRequired
};

export default FNOLDefaultErrorPage;
