import { defineMessages } from 'react-intl';

export default defineMessages({
    requiredField: {
        id: 'jutro-components.widgets.inputs.This is a required field',
        defaultMessage: 'This is a required field'
    },
    optional: {
        id: 'jutro-components.widgets.inputs.Optional',
        defaultMessage: '(Optional)'
    },
    incompleteInput: {
        id: 'jutro-components.widgets.inputs.incomplete',
        defaultMessage: 'Incomplete input'
    },
    recentlyViewed: {
        id: 'jutro-components.widgets.inputs.LookupField.recentlyViewed',
        defaultMessage: 'Recently Viewed'
    },
    pleaseSelect: {
        id: 'jutro-components.widgets.inputs.DropdownSelectField.placeholder',
        defaultMessage: 'Please Select'
    },
    dragAndDrop: {
        id: 'jutro-components.widgets.input.FileUploadField.emptyUploadAreaMessage',
        defaultMessage: 'Drag and drop files'
    },
    back: {
        id: 'portal.index.Back',
        defaultMessage: 'Back'
    },
    noOptions: {
        id: 'jutro-components.widgets.inputs.GenericSelectControl.noOptionsMessage',
        defaultMessage: 'No options'
    },
    purchaseDateBeforeClaim: {
        id: 'displaykey.Edge.Capabilities.Incident.PurchaseDateMustBeBeforeLossDate_Cnd',
        defaultMessage: 'The purchase date must be before the claim\'s loss date.'
    },
    validEmail: {
        id: 'displaykey.Edge.Web.Api.Model.Email',
        defaultMessage: 'Value must be valid e-mail address'
    },
    notNull: {
        id: 'displaykey.Edge.Web.Api.Model.NotNull',
        defaultMessage: 'This is a required field'
    }
});
