import _ from 'lodash';
import React, {
    useCallback, useState, useEffect, useContext, useMemo
} from 'react';
import {
    WizardPage,
    wizardProps,
    WizardPageTemplate
} from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TagManagerService, TrackingConstants } from 'cnd-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import StepHeader from '../../components/StepHeader/StepHeader';
import Claim from '../../models/Claim';
import Person from '../../components/Person/Person';
import Consents from '../../components/Consents/Consents';
import SimplePerson from '../../components/SimplePerson/SimplePerson';
import Company from '../../components/Company/Company';
import { AddressMode } from '../../components/Address/Address';
import BankAccountDetails from '../../components/BankAccountDetails/BankAccountDetails';
import useErrorHandler from '../../hooks/useErrorHandler';
import useValidationErrors from '../../hooks/useValidationErrors';
import { CND_DTO_VALIDATION_ERROR } from '../../constants/errorCodes';
import metadata from './PaymentDetailsPage.metadata.json5';
import styles from './PaymentDetailsPage.module.scss';
import messages from './PaymentDetailsPage.messages';

const VM_PREFIX = 'checkPayeeData';
const CREATE_NEW_OPTION_CODE = 'new';
const INDIVIDUAL_CODE = 'individual';
const COMPANY_CODE = 'company_entrepreneur';
const CLAIMANT_ROLE_CODE = 'claimant';

function FnolPaymentDetailsPage(props) {
    const translator = useContext(TranslatorContext);
    const [isLoading, setIsLoading] = useState(false);
    const [
        filterValidationErrors,
        setValidationErrors,
        resetValidationError
    ] = useValidationErrors();
    const { FNOLService } = useDependencies('FNOLService');
    const [checkPayee, setCheckPayee] = useState('');
    const [availableRelatedContacts, setAvailableRelatedContacts] = useState([]);
    const [isNewContact, setIsNewContact] = useState(false);
    const ErrorHandler = useErrorHandler();

    const {
        wizardData: claimVM,
        updateWizardData
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        initialValidation,
        disregardFieldValidation,
        registerComponentValidation
    } = useValidation('PaymentDetailsPage');

    useEffect(() => {
        registerComponentValidation(() => {
            return claimVM.checkPayeeData.aspects.valid && claimVM.checkPayeeData.aspects.subtreeValid;
        });
    }, [
        claimVM.checkPayeeData,
        registerComponentValidation
    ]);

    const handleSaveClaimData = useCallback(() => {
        setIsLoading(true);
        return FNOLService.saveClaim(claimVM.value)
            .then((response) => {
                claimVM.value = new Claim(response);
                claimVM.value.createCheckPayeeData();
                return claimVM;
            })
            .catch((error) => {
                const { appErrorCode, appData } = error;
                if (appErrorCode === CND_DTO_VALIDATION_ERROR) {
                    setValidationErrors(appData);
                } else {
                    const claimNumber = claimVM.claimNumber.value;
                    ErrorHandler.handleError(error, claimNumber);
                }
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, ErrorHandler, claimVM, setValidationErrors]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(claimVM, `${VM_PREFIX}.${path}`, value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const generateRelatedContactName = useCallback((roleContact) => {
        let name;
        if (roleContact.relatedCompany) {
            name = roleContact.relatedCompany.name;
        } else if (roleContact.contact) {
            name = `${roleContact.contact.firstName} ${roleContact.contact.lastName}`;
        } else {
            name = roleContact.company.name;
        }
        const roleNames = [];
        roleContact.roles.forEach(role => {
            if (role && role !== CLAIMANT_ROLE_CODE) {
                roleNames.push(translator({ id: `typekey.ContactRole.${role}` }));
            }
        });
        const roles = roleNames.length ? ` (${roleNames.join(', ')})` : '';
        return `${name}${roles}`;
    }, [translator]);

    const hasRole = (roleContact, role) => roleContact.roles.find(r => r === role);

    const chooseDefaultRelatedContactIfCheckPayeeExists = useCallback(() => {
        const alreadyChosenCheckPayee = claimVM.value.relatedContacts
            .find((roleContact) => hasRole(roleContact, _.get(claimVM.value, 'checkPayeeData.paymentReceiverType')));
        if (alreadyChosenCheckPayee) {
            setCheckPayee(alreadyChosenCheckPayee.relatedCompany?.publicID ||
                alreadyChosenCheckPayee.contact?.publicID ||
                alreadyChosenCheckPayee.company?.publicID);
        }
    }, [setCheckPayee, claimVM]);

    const createAvailableOptions = useCallback(() => {
        const availableOptions = claimVM.value.relatedContacts.map((roleContact) => ({
            code: roleContact.relatedCompany?.publicID || roleContact.contact?.publicID || roleContact.company?.publicID,
            name: generateRelatedContactName(roleContact)
        }));
        const createNewOption = {
            code: CREATE_NEW_OPTION_CODE,
            name: translator(messages.fnolPaymentDetailsPageNewContact)
        };
        availableOptions.push(createNewOption);
        setAvailableRelatedContacts(availableOptions);
    }, [generateRelatedContactName, claimVM.value.relatedContacts, translator]);

    useEffect(() => {
        createAvailableOptions();
        chooseDefaultRelatedContactIfCheckPayeeExists();
    }, [claimVM.value.relatedContacts, createAvailableOptions, chooseDefaultRelatedContactIfCheckPayeeExists]);

    const checkPayeeTypeChanged = useCallback(
        (value, changedPath) => {
            writeValue(value, changedPath);
            const { checkPayeeData } = claimVM;
            if (checkPayeeData.value.checkPayeeType === INDIVIDUAL_CODE) {
                checkPayeeData.value.removeCompany();
                checkPayeeData.value.createIndividual();
                disregardFieldValidation('fnolPaymentDetailsCompany');
            } else {
                checkPayeeData.value.removeIndividual();
                checkPayeeData.value.createCompany();
                disregardFieldValidation('fnolPaymentDetailsPerson');
            }
            updateWizardData(claimVM);
        },
        [
            claimVM,
            updateWizardData,
            writeValue,
            disregardFieldValidation
        ]
    );

    useEffect(() => {
        claimVM.value.createCheckPayeeData();
        if (!_.get(claimVM.value, 'checkPayeeData.checkPayeeType')) {
            checkPayeeTypeChanged(INDIVIDUAL_CODE, 'checkPayeeType');
        }
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const relatedContactChanged = useCallback((value) => {
        setCheckPayee(value);
        const { relatedContacts: relatedContactsVM, checkPayeeData: checkPayeeDataVM } = claimVM;
        let chosenOption = {};
        if (value !== CREATE_NEW_OPTION_CODE) {
            const roleContactVM = relatedContactsVM.children
                .find((c) => c.relatedCompany.value?.publicID === value || c.contact.value?.publicID === value || c.company.value?.publicID === value);
            disregardFieldValidation('fnolPaymentDetailsCompany');
            disregardFieldValidation('fnolPaymentDetailsPerson');

            if (roleContactVM.contact.value && !roleContactVM.relatedCompany.value) {
                claimVM.checkPayeeData.value.removeIndividual();
                chosenOption = roleContactVM.contact.value;
                checkPayeeTypeChanged(INDIVIDUAL_CODE, 'checkPayeeType');
            } else {
                claimVM.checkPayeeData.value.removeCompany();
                chosenOption = roleContactVM.company.value || roleContactVM.relatedCompany.value;
                checkPayeeTypeChanged(COMPANY_CODE, 'checkPayeeType');
            }
            setIsNewContact(false);
        } else {
            setIsNewContact(true);
        }
        if (checkPayeeDataVM.value.checkPayeeType === INDIVIDUAL_CODE) {
            checkPayeeDataVM.value.createIndividual(chosenOption);
            checkPayeeDataVM.value.individual.publicID = chosenOption.publicID;
        } else {
            checkPayeeDataVM.value.createCompany(chosenOption);
            checkPayeeDataVM.value.company.publicID = chosenOption.publicID;
        }
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    const bankAccountChanged = useCallback((value, path) => {
        writeValue(value, path);
        resetValidationError(`${VM_PREFIX}.${path}`);
    }, [writeValue, resetValidationError]);

    const checkPayeeDataChanged = useCallback((value, path) => {
        writeValue(value, path);
        resetValidationError(`claimantData.${path}`);
    }, [writeValue, resetValidationError]);

    const availablePayeeTypes = useMemo(() => {
        const values = _.get(claimVM.checkPayeeData.paymentReceiverType, 'aspects.availableValues');
        const mappedValues = _.map(values, (typecode) => ({
                code: typecode.code,
                name: translator({
                    id: typecode.name,
                    defaultMessage: typecode.name
                })
            })
        ).sort((a, b) => a.name.localeCompare(b.name));
        if (mappedValues && mappedValues.length) {
            const otherOptionIndex = mappedValues.findIndex((e) => e.code === "other");
            mappedValues.push(mappedValues.splice(otherOptionIndex, 1)[0]);
        }
        return mappedValues;
    }, [claimVM.checkPayeeData.paymentReceiverType, translator]);

    const onNext = useCallback(() => {
        TagManagerService.pushNextButtonClick(TrackingConstants.STEPS.PAYMENT_DETAILS);
        return handleSaveClaimData();
    }, [handleSaveClaimData]);

    const generateReceiverTypesGuideOverrides = useMemo(() => {
        let overrides = null;
        if (availablePayeeTypes && availablePayeeTypes.length > 0) {
            overrides = availablePayeeTypes
                .map((receiverType, index) => {
                    return {
                        [`fnolPaymentDetailsPageTypeOfReceiverGuideLi${index}`]: {
                            content: translator(messages[`fnolPaymentDetailsPagePaymentReceiverTypeInfo_${receiverType.code}`])
                        }
                    };
                });
            return Object.assign({}, ...overrides);
        }
        return null;
    }, [availablePayeeTypes, translator]);

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        fnolPaymentDetailsPageLoader: {
            loaded: !isLoading
        },
        fnolPaymentDetailsPageContainer: {
            visible: !isLoading
        },
        fnolPaymentDetailsPagePersonOrCompany: {
            visible: isNewContact,
            value: _.get(claimVM.value, 'checkPayeeData.checkPayeeType'),
            onValueChange: checkPayeeTypeChanged
        },
        fnolPaymentDetailsPageRelatedContacts: {
            value: checkPayee,
            availableValues: availableRelatedContacts,
            onValueChange: relatedContactChanged
        },
        fnolPaymentDetailsPagePaymentReceiverType: {
            availableValues: availablePayeeTypes,
            value: _.get(claimVM.value, 'checkPayeeData.paymentReceiverType')
        },
        fnolPaymentDetailsOwnerOfBankAccountTitle: {
            visible: isNewContact && _.get(claimVM.value, 'checkPayeeData.individual') !== undefined
        },
        fnolPaymentDetailsPerson: {
            value: _.get(claimVM, 'checkPayeeData.individual'),
            visible: isNewContact && _.get(claimVM.value, 'checkPayeeData.individual') !== undefined,
            // claimantData is used because single path can be set for exception in API
            validationErrors: filterValidationErrors('claimantData.individual'),
            onValueChange: checkPayeeDataChanged,
            isEmailNotificationInfoVisible: true,
            mode: AddressMode.PERSON_BANK_ACCOUNT_OWNER
        },
        fnolPaymentDetailsCompanyDetailsTitle: {
            visible: isNewContact && _.get(claimVM.value, 'checkPayeeData.company') !== undefined
        },
        fnolPaymentDetailsCompany: {
            value: _.get(claimVM, 'checkPayeeData.company'),
            visible: isNewContact && _.get(claimVM.value, 'checkPayeeData.company') !== undefined,
            mode: AddressMode.COMPANY_BANK_ACCOUNT_OWNER
        },
        fnolPaymentDetailsBankAccountDetails: {
            value: _.get(claimVM, 'checkPayeeData.bankAccountDetails'),
            visible: _.get(claimVM.value, 'checkPayeeData.checkPayeeType') !== undefined,
            policyCountry: _.get(claimVM.value, 'policyCountry'),
            validationErrors: filterValidationErrors('checkPayeeData.bankAccountDetails'),
            onValueChange: bankAccountChanged
        },
        fnolPaymentDetailsBankAccountDetailsTitle: {
            visible: _.get(claimVM.value, 'checkPayeeData.checkPayeeType') !== undefined
        },
        fnolPaymentDetailsPageStepHeader: {
            claimNumber: _.get(claimVM.value, 'claimNumber'),
            title: translator(messages.fnolPaymentDetails)
        },
        fnolPaymentDetailsPageTypeOfReceiverGuideAccordionCard: {
            chevron: true,
            header: (
                <span className="smallerText">
                    {translator(messages.fnolPaymentDetailsPagePaymentReceiverTypeInfo)}
                </span>
            )
        },
        fnolPaymentDetailsPageTypeOfReceiverGuideUl: {
            data: availablePayeeTypes?.map((receiverType) => receiverType.code)
        },
        ...generateReceiverTypesGuideOverrides
    };

    const readValue = useCallback((id, path) => {
        return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
    }, [claimVM, overrides]);

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            person: Person,
            simpleperson: SimplePerson,
            company: Company,
            bankaccountdetails: BankAccountDetails,
            stepheader: StepHeader,
            consents: Consents
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM.checkPayeeData}
                resolveValue={resolvers.resolveValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                overrideProps={overrides}
                onValidationChange={setComponentValidation}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

FnolPaymentDetailsPage.propTypes = wizardProps;
export default withRouter(FnolPaymentDetailsPage);
