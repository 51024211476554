import _ from 'lodash';

function Company(data) {
    if (data) {
        _.extend(this, data);
    }
    this.address = this.address || {};
}

export default Company;
