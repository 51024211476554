import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolVehicleIncidentLossPartyType: {
        id: 'fnol.components.vehicle-incident.Loss Party',
        defaultMessage: 'Loss Party'
    },
    fnolVehicleIncidentClaimMileage: {
        id: 'fnol.components.vehicle-incident.Claim mileage (km)',
        defaultMessage: 'Claim mileage (km)'
    },
    fnolVehicleIncidentVehicleType: {
        id: 'fnol.components.vehicle-incident.Relationship to claim',
        defaultMessage: 'Relationship to claim'
    },
    fnolVehicleIncidentDamageHeader: {
        id: 'fnol.components.vehicle-incident.Damage',
        defaultMessage: 'Damage'
    },
    fnolVehicleIncidentDamageDescription: {
        id: 'fnol.components.vehicle-incident.Damage description',
        defaultMessage: 'Damage description'
    },
    fnolVehicleIncidentVehicleStolen: {
        id: 'fnol.components.vehicle-incident.Was the vehicle stolen?',
        defaultMessage: 'Was the vehicle stolen?'
    },
    fnolVehicleIncidentTheftDetection: {
        id: 'fnol.components.vehicle-incident.How was the theft detected?',
        defaultMessage: 'How was the theft detected?'
    },
    fnolVehicleIncidentLastVehicleUsage: {
        id: 'fnol.components.vehicle-incident.Last vehicle usage',
        defaultMessage: 'Last vehicle usage'
    },
    fnolVehicleIncidentLastVehicleUserFirstName: {
        id: 'fnol.components.vehicle-incident.Last vehicle user\'s First Name',
        defaultMessage: 'Last vehicle user\'s First Name'
    },
    fnolVehicleIncidentLastVehicleUserLastName: {
        id: 'fnol.components.vehicle-incident.Last vehicle user\'s Last Name',
        defaultMessage: 'Last vehicle user\'s last name'
    },
    fnolVehicleIncidentVehicleLocked: {
        id: 'fnol.components.vehicle-incident.Vehicle locked',
        defaultMessage: 'Vehicle locked'
    },
    fnolVehicleIncidentUsedAntiTheftSystem: {
        id: 'fnol.components.vehicle-incident.Used anti-theft device',
        defaultMessage: 'Used anti-theft device'
    },
    fnolVehicleIncidentVehicleLocation: {
        id: 'fnol.components.vehicle-incident.Vehicle location',
        defaultMessage: 'Vehicle location'
    },
    fnolVehicleIncidentAlcoholNarcotics: {
        id: 'fnol.components.vehicle-incident.Alcohol/Narcotics result (Driver)',
        defaultMessage: 'Alcohol/Narcotics result (Driver)'
    },
    fnolVehicleIncidentPositive: {
        id: 'fnol.components.vehicle-incident.Positive',
        defaultMessage: 'Positive'
    },
    fnolVehicleIncidentNegative: {
        id: 'fnol.components.vehicle-incident.Negative',
        defaultMessage: 'Negative'
    },
    fnolVehicleIncidentDriversHeader: {
        id: 'fnol.components.vehicle-incident.Driver details',
        defaultMessage: 'Driver details'
    },
    fnolVehicleIncidentAddDriverButton: {
        id: 'fnol.components.vehicle-incident.Add driver',
        defaultMessage: 'Add driver'
    },
    fnolVehicleIncidentRemoveDriverButton: {
        id: 'fnol.components.vehicle-incident.Remove driver',
        defaultMessage: 'Remove driver'
    },
    fnolVehicleIncidentPassengersHeader: {
        id: 'fnol.components.vehicle-incident.Passengers',
        defaultMessage: 'Passengers'
    },
    fnolVehicleIncidentAddPassengerButton: {
        id: 'fnol.components.vehicle-incident.Add passenger',
        defaultMessage: 'Add passenger'
    },
    fnolVehicleIncidentVehicleDetails: {
        id: 'fnol.components.vehicle-incident.Vehicle details',
        defaultMessage: 'Vehicle details'
    }
});
