import React, {
    useCallback, useEffect, useContext, useState
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import metadata from './InsuredPerson.metadata.json5';
import messages from './InsuredPerson.messages';
import SummaryContext from '../summary/SummaryContext';
import styles from './InsuredPerson.module.scss';

function FNOLSimplePerson(props) {
    const CREATE_NEW_OPTION_CODE = 'new';
    const CLAIMANT_ROLE_CODE = 'claimant';

    const {
        id,
        onValidate,
        value: simplePersonVM,
        onValueChange,
        showErrors,
        relatedContacts
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);
    const translator = useContext(TranslatorContext);
    const { readOnly } = useContext(SummaryContext);

    const [availableRelatedContacts, setAvailableRelatedContacts] = useState([]);
    const [insured, setInsured] = useState('');

    const generateRelatedContactName = useCallback((rolePerson) => {
        const name = `${rolePerson.contact.firstName} ${rolePerson.contact.lastName}`;
        const roleNames = [];
        rolePerson.roles.forEach(role => {
            if (role && role !== CLAIMANT_ROLE_CODE) {
                roleNames.push(translator({ id: `typekey.ContactRole.${role}` }));
            }
        });
        const roles = roleNames.length ? ` (${roleNames.join(', ')})` : '';
        return `${name}${roles}`;
    }, []);

    const createAvailableOptions = useCallback(() => {
        const availableOptions = relatedContacts.filter(e => e.contact).map((rolePerson) => (
            {
                code: rolePerson.contact.publicID,
                name: generateRelatedContactName(rolePerson)
            }
        ));
        const insuredPublicID = _.get(simplePersonVM, 'value.publicID');
        if (insuredPublicID && availableOptions.findIndex(o => o.code === insuredPublicID) === -1) {
            const existingInsured = {
                code: insuredPublicID,
                name: `${simplePersonVM.value.firstName} ${simplePersonVM.value.lastName}`
            }
            availableOptions.push(existingInsured);
        }
        const createNewOption = {
            code: CREATE_NEW_OPTION_CODE,
            name: translator(messages.fnolInsuredPersonNewContact)
        };
        availableOptions.push(createNewOption);
        setAvailableRelatedContacts(availableOptions);
    }, [generateRelatedContactName, relatedContacts, translator]);

    const relatedContactChanged = useCallback((value) => {
        setInsured(value);
        let chosenOption = {};
        if (value && value !== CREATE_NEW_OPTION_CODE) {
            const roleContact = relatedContacts.filter(c => c.contact)
                .find((c) => c.contact.publicID === value);
            chosenOption = roleContact.contact;
        }
        simplePersonVM.value = chosenOption;
    }, []);

    useEffect(() => {
        registerComponentValidation(() => {
            return simplePersonVM.aspects.valid && simplePersonVM.aspects.subtreeValid;
        });
    }, [simplePersonVM.aspects.subtreeValid,
        simplePersonVM.aspects.valid,
        registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (!_.get(relatedContacts, 'length')) {
            return;
        }
        createAvailableOptions();
        setInsured(_.get(simplePersonVM, 'value.publicID'));
    }, [createAvailableOptions]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showErrors,
            readOnly
        },
        fnolInsuredPersonComponentTitle: {
            visible: !readOnly
        },
        fnolInsuredPersonComponentSummaryTitle: {
            visible: readOnly
        },
        fnolInsuredPersonComponentRelatedContacts: {
            visible: !readOnly && !!_.get(availableRelatedContacts, 'length'),
            value: insured,
            availableValues: availableRelatedContacts,
            onValueChange: relatedContactChanged
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={simplePersonVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValueChange={onValueChange}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

FNOLSimplePerson.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    realtedContacts: PropTypes.array
};
FNOLSimplePerson.defaultProps = {
    realtedContacts: undefined
};
export default FNOLSimplePerson;
