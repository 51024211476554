import { defineMessages } from 'react-intl';

export default defineMessages({
    addNotePopupDisclaimer: {
        id: 'fnol.components.add-note-popup.Dear customer, please provide any useful information, but have in mind that no personal data should be here.',
        defaultMessage: 'Dear customer, please provide any useful information, but have in mind that no personal data should be here.'
    },
    addNotePopupNote: {
        id: 'fnol.components.add-note-popup.Note',
        defaultMessage: 'Note'
    }
});
