import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolContactDetails: {
        id: 'fnol.components.contact-details.Contact details',
        defaultMessage: 'Contact details'
    },
    fnolContactDetailsPagePersonOrCompany: {
        id: 'fnol.components.contact-details.Contact Type',
        defaultMessage: 'Contact Type'
    },
    fnolContactDetailsPageNewContact: {
        id: 'fnol.components.contact-details.New Contact',
        defaultMessage: 'New Contact'
    },
    fnolContactDetailsPageIndividual: {
        id: 'fnol.components.contact-details.Individual',
        defaultMessage: 'Individual'
    },
    fnolContactDetailsPageIndividualEntrepreneurCompany: {
        id: 'fnol.components.contact-details.Individual Entrepreneur/Company',
        defaultMessage: 'Individual Entrepreneur/Company'
    },
    fnolContactDetailsPageReportedBy: {
        id: 'fnol.components.contact-details.Reported by',
        defaultMessage: 'Reported by'
    },
    fnolContactDetailsPageReportersContactDetails: {
        id: 'fnol.components.contact-details.Reporter\'s contact details',
        defaultMessage: 'Reporter\'s contact details'
    },
    fnolContactDetailsOnlyAdultPerson: {
        id: 'fnol.components.contact-details.Remember that claim be registered only by an adult person',
        defaultMessage: 'Remember that claim be registered only by an adult person'
    },
    fnolContactDetailsPageRelationToInsured: {
        id: 'fnol.components.contact-details.Relation to insured',
        defaultMessage: 'Relation to insured'
    },
    fnolContactDetailsPageRelationToCompany: {
        id: 'fnol.components.contact-details.Relation to company',
        defaultMessage: 'Relation to company'
    },
    fnolContactDetailsPageCompanyDetails: {
        id: 'fnol.components.contact-details.Company details',
        defaultMessage: 'Company details'
    }
});
