import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptcha = React.forwardRef((props, ref) => {
    return <ReCAPTCHA ref={ref} {...props} />;
});

/**
 * This is a thin wrapper for react-google-recaptcha
 * For usage and documentation please visit https://github.com/dozoisch/react-google-recaptcha
 */
ReCaptcha.propTypes = {
    /**
     * The API client key
     */
    sitekey: PropTypes.string.isRequired,

    /**
     * The function to be called when the user successfully completes the normal
     * or the compact captcha. It will also be called with null, when captcha expires.
     * @param token string or null
     */
    onChange: PropTypes.func,

    /**
     *  If you are using the barebone component you need to provide access to the
     *  google grecaptcha object.
     */
    grecaptcha: PropTypes.shape({}),

    /**
     * The theme of the widget.
     * @default "light"
     */
    theme: PropTypes.oneOf(['light', 'dark']),

    /**
     * The type of initial captcha.
     * @default "image"
     */
    type: PropTypes.oneOf(['image', 'audio']),

    /**
     * The tabindex of the element.
     * @default 0
     */
    tabindex: PropTypes.number,

    /**
     * Callback called when a challenge expires and has to be redone by the user.
     */
    onExpired: PropTypes.func,

    /**
     *  Optional callback, called when reCAPTCHA encounters an error (usually network connectivity)
     *  and cannot continue until connectivity is restored. If you specify a function here, you are
     *  responsible for informing the user that they should retry.
     */
    onErrored: PropTypes.func,

    /**
     * Set the stoken parameter, which allows the captcha to be used from different domains.
     * @see reCAPTCHA secure-token
     */
    stoken: PropTypes.string,

    /**
     *  Forces the widget to render in a specific language.
     *  Auto-detects the user's language if unspecified.
     */
    hl: PropTypes.string,

    /**
     * This allows you to change the size or do an invisible captcha.
     * @default "normal"
     */
    size: PropTypes.oneOf(['compact', 'normal', 'invisible']),

    /**
     * The badge location for g-recaptcha with size of "invisible".
     * @default "bottomright"
     */
    badge: PropTypes.oneOf(['bottomright', 'bottomleft', 'inline'])
};

ReCaptcha.defaultProps = {
    onChange: _.noop,
    grecaptcha: undefined,
    theme: 'light',
    type: 'image',
    tabindex: 0,
    onExpired: _.noop,
    onErrored: _.noop,
    stoken: undefined,
    hl: undefined,
    size: 'normal',
    badge: 'bottomright'
};

export default ReCaptcha;
