import _ from 'lodash';
import React, {
    useState,
    useEffect,
    useContext,
    useCallback
} from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import './App.scss';

import { subscribe, unsubscribeAll, JUTRO_TOPICS } from '@jutro/events';
import vmTranslator, { messages as commonMessages } from 'gw-platform-translations';

import { ErrorBoundary } from 'gw-portals-error-react';

import { setComponentMapOverrides } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { routeConfirmationModal } from '@jutro/router';

import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';

import { ClaimService } from 'gw-capability-claim';
import { FNOLService, NoteService } from 'cnd-capability-customfnol';
import { ClaimDocumentService } from 'gw-capability-claimdocument';

import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-react';

import FaqPage from 'gw-capability-faq-react';
import {
    FNOLContext,
    FNOLSearchPolicyPage,
    FNOLWizard,
    FNOLCallContactCenterPage,
    FNOLConfirmationPage,
    FNOLDefaultErrorPage,
    FNOLSmsVerificationPage
} from 'cnd-capability-customfnol-react';

import {
    platformComponentMap,
    platformComponents,
    ImageComponent,
} from 'gw-components-platform-react';

import {
    platformComponentMap as customPlatformComponentMap,
    platformComponents as customPlatformComponents
} from 'cnd-components-platform-react';

import {
    platformComponentMap as customCommonPlatformComponentMap,
    platformComponents as customCommonPlatformComponents,
    TelemetryProvider,
    SeverityLevel
} from 'cnd-common-components-platform-react';

import {
    Main,
    Icon,
    Chevron,
    ModalNextProvider
} from '@jutro/components';
import { Table, TableColumn } from '@jutro/datatable';

import PageContainer from '../components/PageContainer/PageContainer';
import messages from './App.messages';
import * as constants from '../constants';

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...customPlatformComponentMap,
        ...customCommonPlatformComponentMap,
        Icon: { component: 'Icon' },
        Table: { component: 'Table' },
        TableColumn: { component: 'TableColumn' },
        Chevron: { component: 'Chevron' },
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
        // replace the native IMG component with a proxied version
        img: { component: 'img' }
    },
    {
        ...platformComponents,
        ...customPlatformComponents,
        ...customCommonPlatformComponents,
        Icon,
        Table,
        TableColumn,
        Chevron,
        WizardPageTemplateWithTitle,
        img: ImageComponent
    }
);

let appInsights;

export default function App() {
    const translator = useContext(TranslatorContext);
    const [viewModelService, setViewModelService] = useState(undefined);

    // Cnd custom
    useEffect(() => {
        const { FETCH_ERROR, FETCH_RESPONSE } = JUTRO_TOPICS;
        subscribe([
            FETCH_RESPONSE,
            FETCH_ERROR
        ], (data, event) => {
            const logs = [event, data];
            if (event === FETCH_ERROR && data.error?.baseError) {
                logs.push(data.error?.baseError);
            }
            // eslint-disable-next-line no-console
            console.debug(...logs);
        });

        return () => {
            unsubscribeAll();
        };
    });

    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(result, translatorFn)
            );
        });
    }, [translator]);

    const handleError = useCallback((error = "") => {
        let errorMsg;
        switch (true) {
            case (typeof error === "string"): {
                errorMsg = error;
                break;
            }
            case (!!error.stack): {
                errorMsg = JSON.stringify({message: error.message, stack: error.stack});
                break;
            }
            default:
                errorMsg = JSON.stringify(error)
        }

        appInsights.trackException({
            error: errorMsg,
            severityLevel: SeverityLevel.Error
        });
        
        return ModalNextProvider.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, []);

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider value={{
                FNOLService,
                ClaimService,
                NoteService,
                ClaimDownloadService: ClaimDocumentService
            }}
            >
                <ErrorBoundary onError={handleError}>
                    <ViewModelServiceContext.Provider value={viewModelService}>
                        <FNOLContext.Provider value={constants}>
                            <ModalNextProvider />
                            <Router
                                basename={process.env.PUBLIC_PATH}
                                getUserConfirmation={routeConfirmationModal}
                            >
                                <TelemetryProvider
                                    disableTelemetry={process.env.REACT_APP_DISABLE_TELEMETRY === 'true'}
                                    connectionString={process.env.REACT_APP_TELEMETRY_CONNECTION_STRING}
                                    after={(ai) => { appInsights = ai; }}
                                >
                                    <Main className="fnolMain" contentClassName="fnolMainContent" fluid>
                                        <PageContainer>
                                            <Switch>
                                                <Route path="/faq" component={FaqPage} />
                                                <Route path="/fnol" component={FNOLWizard} />
                                                <Route exact path="/" component={FNOLSearchPolicyPage} />
                                                <Route path="/contact-call-center" component={FNOLCallContactCenterPage} />
                                                <Route path="/confirmation/:claimNumber" component={FNOLConfirmationPage} />
                                                <Route
                                                    path={constants.ERROR_PAGE_PATH}
                                                    component={FNOLDefaultErrorPage}
                                                />
                                                <Route
                                                    path={constants.SMS_VERIFICATION_PAGE_PATH}
                                                    component={FNOLSmsVerificationPage}
                                                />
                                                <Route render={() => <Redirect to="/" />} />
                                            </Switch>
                                        </PageContainer>
                                    </Main>
                                </TelemetryProvider>
                            </Router>
                        </FNOLContext.Provider>
                    </ViewModelServiceContext.Provider>
                </ErrorBoundary>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}
