import { defineMessages } from 'react-intl';

export default defineMessages({
    referenceNumber: {
        id: 'fnol.components.confirmation-page.Claim Reference number',
        defaultMessage: 'Claim reference number: { claimNumber }'
    },
    colonnadeWebsite: {
        id: 'fnol.components.confirmation-page.COLONNADE WEBSITE',
        defaultMessage: 'COLONNADE WEBSITE'
    },
    listOfDocumentsNotice: {
        id: 'fnol.components.confirmation-page.To find complete list of required documents needed to properly and quickly solve your claim go to { documentsUrl }.',
        defaultMessage: 'To find complete list of required documents needed to properly and quickly solve your claim go to { documentsUrl }.'
    },
    successfullySubmitted: {
        id: 'fnol.components.confirmation-page.views.confirmation.Claim was successfully submitted',
        defaultMessage: 'Claim was successfully submitted'
    },
    thankYou: {
        id: 'fnol.components.confirmation-page.Thank you for submitting a claim.',
        defaultMessage: 'Thank you for submitting a claim.'
    },
    confirmationWasSent: {
        id: 'fnol.components.confirmation-page.Confirmation was sent to provided email address. Our team will be in contact shortly.',
        defaultMessage: 'Confirmation was sent to provided email address. Our team will be in contact shortly.'
    },
    kindRegards: {
        id: 'fnol.components.confirmation-page.confirmation.Kind regards,',
        defaultMessage: 'Kind regards,'
    },
    colonnadeInsurance: {
        id: 'fnol.components.confirmation-page.confirmation.Colonnade Insurance',
        defaultMessage: 'Colonnade Insurance'
    },
    backToColonnade: {
        id: 'fnol.components.confirmation-page.Back to Colonnade',
        defaultMessage: 'Back to Colonnade'
    },
    printConfirmation: {
        id: 'fnol.components.confirmation-page.Print Confirmation',
        defaultMessage: 'Print Confirmation'
    },
    successfulSubmissionTitle: {
        id: 'fnol.common.views.confirmation.Successful submission',
        defaultMessage: 'Successful submission'
    },
});
