import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import EmailLink from '../../../../components/EmailLink/EmailLink';
import messages from './DocumentUploadNotice.messages';

function DocumentUploadNotice(props) {
    const { className, contactEmail } = props;
    return (
        <p className={className}>
            <FormattedMessage
                {...messages.uploadNotice}
                values={{
                    contactEmail: <EmailLink>{contactEmail}</EmailLink>,
                }}
            />
        </p>
    );
}

DocumentUploadNotice.propTypes = {
    className: PropTypes.string,
    contactEmail: PropTypes.string.isRequired
};

DocumentUploadNotice.defaultProps = {
    className: undefined
};

export default DocumentUploadNotice;
