import { defineMessages } from 'react-intl';

export default defineMessages({
    stepHeaderClaimDraftNumber: {
        id: 'fnol.step.header.Draft number',
        defaultMessage: 'Draft number'
    },
    stepHeaderClaimDraftNumberTooltip: {
        id: 'fnol.step.header.A draft number given during the claim submission process. If you experience problems during the application form, you can use this number when calling the Call Center to retrieve the information you previously provided and complete the process over the phone.',
        defaultMessage: 'A draft number given during the claim submission process. If you experience problems during the application form, you can use this number when calling the Call Center to retrieve the information you previously provided and complete the process over the phone.'
    }
});
