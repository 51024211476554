import TrackingConstants from './TrackingConstants';
import { CommonTagManagerService } from 'cnd-common-portals-util-js';

const init = (countryCode) => {
    const trackingId = TrackingConstants.GTM_IDS[countryCode];
    CommonTagManagerService.init(trackingId);
}

const pushNextButtonClick = (stepName) => {
    CommonTagManagerService.pushActionButtonClick(stepName, TrackingConstants.BUTTON_ACTION.NEXT);
}

const pushSubmitButtonClick = (stepName) => {
    CommonTagManagerService.pushActionButtonClick(stepName, TrackingConstants.BUTTON_ACTION.SUBMIT);
}

const pushSearchButtonClick = (stepName) => {
    CommonTagManagerService.pushActionButtonClick(stepName, TrackingConstants.BUTTON_ACTION.SEARCH);
}

export default {
    init: init,
    pushActionEvent: CommonTagManagerService.pushActionEvent,
    pushNextButtonClick: pushNextButtonClick,
    pushSubmitButtonClick: pushSubmitButtonClick,
    pushSearchButtonClick: pushSearchButtonClick
}