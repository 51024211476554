import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolVehicleIncidentVehicleMake: {
        id: 'fnol.components.vehicle-incident.vehicle.Make',
        defaultMessage: 'Make'
    },
    fnolVehicleIncidentVehicleModel: {
        id: 'fnol.components.vehicle-incident.vehicle.Model',
        defaultMessage: 'Model'
    },
    fnolVehicleIncidentVehicleFuelType: {
        id: 'fnol.components.vehicle-incident.vehicle.Fuel type',
        defaultMessage: 'Fuel type'
    },
    fnolVehicleIncidentVehicleEngineCapacity: {
        id: 'fnol.components.vehicle-incident.vehicle.Engine capacity (cm3)',
        defaultMessage: 'Engine capacity (cm3)'
    },
    fnolVehicleIncidentVehicleNumberOfSeats: {
        id: 'fnol.components.vehicle-incident.vehicle.Number of seats',
        defaultMessage: 'Number of seats'
    },
    fnolVehicleIncidentVehicleVin: {
        id: 'fnol.components.vehicle-incident.vehicle.VIN',
        defaultMessage: 'VIN'
    },
    fnolVehicleIncidentVehicleLicensePlate: {
        id: 'fnol.components.vehicle-incident.vehicle.License plate',
        defaultMessage: 'License plate'
    },
    fnolVehicleIncidentVehicleFirstRegistrationDate: {
        id: 'fnol.components.vehicle-incident.vehicle.First Registration date',
        defaultMessage: 'First registration date'
    }
});
