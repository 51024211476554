import _ from 'lodash';
import Person from './Person';

function GeneralIncident(data) {
    if (data) {
        _.extend(this, data);
    }
    this.claimant = this.claimant || new Person();
}

GeneralIncident.prototype.getTypeCode = function getTypeCode() {
    return 'GeneralIncident_Cnd';
};

export default GeneralIncident;
