import { CountryLayerCore } from 'cnd-common-portals-util-js';
import messages from './CountryLayerService.messages';

export default {
    getDateFormats: (country, showTime) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => {
                    if (showTime) {
                        return {
                            format: 'yyyy/MM/dd',
                            timeFormat: 'HH:mm',
                            separator: ', ',
                            placeholder: messages.placeholderVariantYearWithTime,
                        };
                    }
                    return {
                        format: 'yyyy/MM/dd',
                        placeholder: messages.placeholderVariantYear,
                    };
                }
            },
            {
                countries: [
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => {
                    if (showTime) {
                        return {
                            format: 'dd.MM.yyyy',
                            timeFormat: 'HH:mm',
                            separator: ', ',
                            placeholder: messages.placeholderWithTime,
                        };
                    }
                    return {
                        format: 'dd.MM.yyyy',
                        placeholder: messages.placeholder,
                    };
                }
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },
    isClaimantContactInfoMessageVisible: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => true
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => false
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },
    showGroupInsuranceTextOnAdditionalInfoPage: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => true
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => false
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },
    isCompanyIdRequired: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => false
            },
            {
                countries: [
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.SK
                ],
                action: () => true
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },
    isTaxIdRequired: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.SK
                ],
                action: () => false
            },
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => true
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },
    showAnimalsQuestion: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => false
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => true
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },
    isPetInfoApplicable: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.CZ],
                action: () => true
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => false
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },
    isEmailContactCenterChannel: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => true
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => false
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    }
};
