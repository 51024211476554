import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { DropdownSelectField, GenericSelectControl } from '@jutro/components';
import { getInputSizeClassnames } from '../utils';
import styles from './styles';

const isOther = (value) => (value == null ? undefined : value.toLowerCase) && value.toLowerCase() === 'other';
const shouldRenderDetails = (value) => (_.isArray(value) ? _.some(value, isOther) : isOther(value));

class ClearableDropdown extends DropdownSelectField {
    handleValueChange = (val) => {
        const value = val || { value: null };
        return this.handleOnChange(value);
    };

    renderControl(breakpointProps, theme, { isValid, labelId }) {
        const {
            id,
            disabled,
            required,
            size,
            children,
            defaultValue,
            className,
            searchable,
            usePortal
        } = breakpointProps;
        const { options, renderValue } = this;
        const themeStyles = theme.getStyles('DropdownSelect', styles);
        const selectClasses = cx(themeStyles.dropdownSelect, themeStyles.sizeableDropdown,
            getInputSizeClassnames(themeStyles, size), className);
        const defaultValueStringified = defaultValue ? defaultValue.toString() : null;
        const selectDefaultValue = defaultValueStringified
            ? _.find(options, (el) => el.value === defaultValueStringified) : null;
        const detail = shouldRenderDetails(renderValue) && children;
        const renderValueForComponentValue = _.isObject(renderValue)
            ? renderValue.id || renderValue.code : renderValue;
        const componentValue = renderValueForComponentValue
            ? _.filter(options, (el) => el.value === renderValueForComponentValue) : null;

        return (
            <div onClick={this.handleOnClick} role="presentation">
                <GenericSelectControl
                    isClearable
                    id={id}
                    availableValues={options}
                    className={selectClasses}
                    defaultValue={selectDefaultValue}
                    disabled={disabled}
                    getOptionLabel={this.getOptionLabel}
                    getOptionValue={this.getOptionValue}
                    labelId={labelId}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    onValueChange={this.handleValueChange}
                    placeholder={this.placeholderText}
                    required={required}
                    searchable={searchable}
                    value={componentValue}
                    valid={isValid}
                    usePortal={usePortal}
                />
                {detail && (<div className={themeStyles.detail}>{detail}</div>)}
            </div>
        );
    }
}

export default ClearableDropdown;
