import {
    useContext
} from 'react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useAppInsights } from 'cnd-common-hooks-platform-react';
import { jsPDF as JsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { TranslatorContext } from '@jutro/locale';
import FNOLMessages from '../FNOL.messages';


const BLOB_TYPE_NAME = 'blob';
const IMAGE_ALIAS = 'fnolSummarySnapshot';
const FILE_COMPRESSION_MEDIUM = 'MEDIUM';
const FNOL_DOC_TYPE = 'fnol';
const PDF_ORIENTATION_PORTRAIT = 'p';
const PDF_MEASURE_UNIT_MILIMITERS = 'mm';
const PNG_FILE_EXT = 'PNG';
const PNG_FILE_TYPE = 'image/png';
const IMAGE_OFFSET_IN_MM = 5;
const A4_FORMAT_DIMENSIONS_IN_MM = {
    width: 210,
    height: 297
};
const MAX_IMAGE_DIMENSIONS_IN_MM = {
    width: A4_FORMAT_DIMENSIONS_IN_MM.width - 2 * IMAGE_OFFSET_IN_MM,
    height: A4_FORMAT_DIMENSIONS_IN_MM.height - IMAGE_OFFSET_IN_MM
};

const handlePdfSnapshotUpload = async (
    canvas, claimNumber, translator, ClaimService, authHeader
) => {
    const dataURL = canvas.toDataURL(PNG_FILE_TYPE);
    const fileName = `${translator(FNOLMessages.fnolSummaryFileNameMessage)}.pdf`;
    const pdf = new JsPDF({
        orientation: PDF_ORIENTATION_PORTRAIT,
        format: 'a4',
        unit: PDF_MEASURE_UNIT_MILIMITERS,
        putOnlyUsedFonts: true
    });
    const imageHeightInMM = canvas.height * MAX_IMAGE_DIMENSIONS_IN_MM.width / canvas.width;
    let heightLeft = imageHeightInMM;
    let position = IMAGE_OFFSET_IN_MM;

    pdf.addImage(
        dataURL,
        PNG_FILE_EXT,
        IMAGE_OFFSET_IN_MM,
        position,
        MAX_IMAGE_DIMENSIONS_IN_MM.width,
        imageHeightInMM,
        IMAGE_ALIAS,
        FILE_COMPRESSION_MEDIUM
    );
    heightLeft -= MAX_IMAGE_DIMENSIONS_IN_MM.height;

    while (heightLeft > 0) {
        position = heightLeft - imageHeightInMM;
        pdf.addPage();
        pdf.addImage(
            dataURL,
            PNG_FILE_EXT,
            IMAGE_OFFSET_IN_MM,
            position,
            MAX_IMAGE_DIMENSIONS_IN_MM.width,
            imageHeightInMM,
            IMAGE_ALIAS,
            FILE_COMPRESSION_MEDIUM
        );
        heightLeft -= MAX_IMAGE_DIMENSIONS_IN_MM.height;
    }

    const pdfBlob = pdf.output(BLOB_TYPE_NAME);
    const pdfFile = new File([pdfBlob], fileName, {
        type: pdfBlob.type,
    });
    const documentMetaDataTemplate = {
        DocUID: '001',
        DocumentType: FNOL_DOC_TYPE,
        SecurityType: 'unrestricted',
        Status: 'approved',
        Author: 'Policy Holder',
        documentType_Cnd: FNOL_DOC_TYPE,
        claimNumber: claimNumber,
        name: fileName,
        mimeType: pdfFile.type,
        sessionID: await ClaimService.claimsDocUploadToken([], authHeader)
    };
    return ClaimService.uploadDocument(pdfFile, documentMetaDataTemplate, { authHeader });
};

export default function useSummarySnapshotHandler(summaryContainerID, shouldThrowError = false) {
    const { ClaimService } = useDependencies('ClaimService');
    const { authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const appInsights = useAppInsights();

    const handleScreenSnapshot = async (claimNumber) => {
        try {
            let uploaded = false;
            const pageContainer = document.querySelector(summaryContainerID);
            const canvas = await html2canvas(pageContainer);
            await handlePdfSnapshotUpload(
                canvas, claimNumber, translator, ClaimService, authHeader
            ).then(() => {
                uploaded = true;
            });
            return uploaded;
        } catch (err) {
            appInsights.trackException(err, claimNumber);
            if (shouldThrowError) {
                throw err;
            }
        }
    };

    return {
        handleScreenSnapshot
    };
}
