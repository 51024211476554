import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolInjuryIncidentDescription: {
        id: 'fnol.components.injury-incident.Injury/Diseases description',
        defaultMessage: 'Injury/Diseases description'
    },
    fnolInjuryIncidentLossPartyType: {
        id: 'fnol.components.injury-incident.Loss Party',
        defaultMessage: 'Loss Party'
    },
    fnolInjuryIncidentSeverity: {
        id: 'fnol.components.injury-incident.Severity',
        defaultMessage: 'Severity'
    },
    fnolInjuryIncidentReceivedTreatment: {
        id: 'fnol.components.injury-incident.Received treatment?',
        defaultMessage: 'Received treatment?'
    },
    fnolInjuryIncidentTreatmentType: {
        id: 'fnol.components.injury-incident.Treatment type',
        defaultMessage: 'Treatment type'
    },
    fnolInjuryIncidentAmbulanceUsed: {
        id: 'fnol.components.injury-incident.Ambulance used?',
        defaultMessage: 'Ambulance used?'
    }
});
