import _ from 'lodash';

function ContentLineItem(data) {
    if (data) {
        _.extend(this, data);
    } else {
        this.quantity = 1;
    }
}

export default ContentLineItem;
