import { defineMessages } from 'react-intl';

export default defineMessages({
    uploadNotice: {
        id: 'fnol.components.document-upload-notice.If you haven\'t uploaded any documents or have additional ones please sent them using claim reference number in the message subject to the following email address { contactEmail }.',
        defaultMessage: 'If you haven\'t uploaded any documents or have additional ones please sent them using claim reference number in the message subject to the following email address { contactEmail }.'
    },
    uploadNoticeMultiAppliances: {
        id: 'fnol.components.document-upload-notice.If your claim is connected to Multiappliances use { contactEmail }.',
        defaultMessage: 'If your claim is connected to Multiappliances use { contactEmail }.'
    },
    uploadNoticeHu: {
        id: 'fnol.components.document-upload-notice.If you haven\'t uploaded any documents or have additional ones please sent them using the claim reference number in the message subject for one of the below email addresses, depends on the claim type:',
        defaultMessage: 'If you haven\'t uploaded any documents or have additional ones please sent them using the claim reference number in the message subject for one of the below email addresses, depends on the claim type:'
    },
    tvlInsuranceClaimsSettlement: {
        id: 'fnol.components.document-upload-notice.Travel insurance claims settlement: { contactEmail }',
        defaultMessage: 'Travel insurance claims settlement: { contactEmail }'
    },
    tvlCancellationLuggageTravelSettlement: {
        id: 'fnol.components.document-upload-notice.Travel cancellation insurance, luggage damage notification, domestic travel insurance claims settlement: { contactEmail }',
        defaultMessage: 'Travel cancellation insurance, luggage damage notification, domestic travel insurance claims settlement: { contactEmail }'
    },
    tvlHomeAccidentSicknessInsurance: {
        id: 'fnol.components.document-upload-notice.Claims settlement for home, accident and sickness insurance: { contactEmail }',
        defaultMessage: 'Claims settlement for home, accident and sickness insurance: { contactEmail }'
    },
    tvlGapInsurance: {
        id: 'fnol.components.document-upload-notice.GAP insurance claims settlement: { contactEmail }',
        defaultMessage: 'GAP insurance claims settlement: { contactEmail }'
    },
    tvlCorporateClaims: {
        id: 'fnol.components.document-upload-notice.Corporate claims: { contactEmail }',
        defaultMessage: 'Corporate claims: { contactEmail }'
    },
    tvlClaimsSettlementHomeInsurance: {
        id: 'fnol.components.document-upload-notice.Claims settlement for home insurance: { contactEmail }',
        defaultMessage: 'Claims settlement for home insurance: { contactEmail }'
    },
    tvlConsumerFriendlyHomeInsurance: {
        id: 'fnol.components.document-upload-notice.Certified Consumer-friendly Home Insurance: { contactEmail }',
        defaultMessage: 'Certified Consumer-friendly Home Insurance: { contactEmail }'
    },
});
