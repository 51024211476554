import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolGeneralIncidentDamageDescription: {
        id: 'fnol.components.general-incident.Damage description',
        defaultMessage: 'Damage description'
    },
    fnolGeneralIncidentPetInjuryDescription: {
        id: 'fnol.components.general-incident.Pet injury description',
        defaultMessage: 'Pet injury description'
    },
    fnolGeneralIncidentEstimationReceived: {
        id: 'fnol.components.general-incident.Repair estimate received?',
        defaultMessage: 'Repair estimate received?'
    },
    fnolGeneralIncidentTreatmentCostReceived: {
        id: 'fnol.components.general-incident.Treatment cost received?',
        defaultMessage: 'Treatment cost received?'
    },
    fnolGeneralIncidentTreatmentEstimationAmount: {
        id: 'fnol.components.general-incident.Estimated treatment cost',
        defaultMessage: 'Estimated treatment cost'
    },
    fnolGeneralIncidentRepairEstimationAmount: {
        id: 'fnol.components.general-incident.Estimated repair cost',
        defaultMessage: 'Estimated repair cost'
    },
    fnolGeneralIncidentAlreadyPaid: {
        id: 'fnol.components.general-incident.Already paid',
        defaultMessage: 'Already paid'
    },
    fnolGeneralIncidentAlreadyRepaired: {
        id: 'fnol.components.general-incident.Already repaired',
        defaultMessage: 'Already repaired'
    },
    fnolGeneralIncidentCarrierCompensated: {
        id: 'fnol.components.general-incident.Carrier Compensated',
        defaultMessage: 'Carrier compensated'
    },
    fnolGeneralIncidentCompensationAmount: {
        id: 'fnol.components.general-incident.Compensation amount',
        defaultMessage: 'Compensation amount'
    },
    fnolGeneralIncidentDescriptionTooltip: {
        id: 'fnol.components.general-incident.If your claim is connected to a wallet or bag please provide a detailed list of its contents',
        defaultMessage: 'If your claim is connected to a wallet or bag please provide a detailed list of its contents'
    },
    fnolGeneralIncidentPetConnected: {
        id: 'fnol.components.general-incident.Pet-connected claim?',
        defaultMessage: 'Pet-connected claim?'
    },
    fnolGeneralIncidentCompensatedByThirdParty: {
        id: 'fnol.components.general-incident.Compensated by Third Party?',
        defaultMessage: 'Compensated by Third Party?'
    },
    fnolGeneralIncidentLossParty: {
        id: 'fnol.components.general-incident.Loss Party',
        defaultMessage: 'Loss Party'
    },
    fnolGeneralIncidentAmountCompensated: {
        id: 'fnol.components.general-incident.Amount compensated?',
        defaultMessage: 'Amount compensated?'
    }
});
