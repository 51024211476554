import _ from 'lodash';
import { useState, useCallback } from 'react';

const firstLetterToLowerCase = function camelCase(text) {
    return text.charAt(0).toLowerCase() + text.slice(1);
};
const firstLowerCaseMap = (path) => _.join(_.map(_.split(path, '.'), firstLetterToLowerCase), '.');

const formatValidationError = (appData) => {
    return _.reduce(appData, (prev, curr) => {
        const { path, message } = curr;
        const normalizedPath = path ? firstLowerCaseMap(path) : '';
        const translatorPath = _.last(_.split(normalizedPath, '.').slice(-1));
        const error = _.set({}, normalizedPath, { messages: [message], path: translatorPath });
        return _.mergeWith(prev, error, (objValue, srcValue) => {
            if (_.isArray(objValue)) {
                return [...objValue, ...srcValue];
            }
        });
    }, {});
};

function useValidationErrors(initialAppData = []) {
    const [validationErrors, _setValidationErrors] = useState(
        () => formatValidationError(initialAppData)
    );

    const setValidationErrors = useCallback(
        (appData) => {
            _setValidationErrors(formatValidationError(appData));
        },
        [_setValidationErrors],
    );

    const filterValidationErrors = useCallback(
        (forPath) => {
            if (_.isEmpty(forPath)) return validationErrors;
            return _.get(validationErrors, forPath);
        }, [validationErrors]
    );

    const resetValidationError = useCallback(
        (forPath) => {
            const errors = _.omit(validationErrors, forPath);
            _setValidationErrors(errors);
        }, [_setValidationErrors, validationErrors]
    );

    return [filterValidationErrors, setValidationErrors, resetValidationError];
}

export default useValidationErrors;
