import { defineMessages } from 'react-intl';

export default defineMessages({
    incidentListLossDetails: {
        id: 'incident.list.section.title.Loss details',
        defaultMessage: 'Loss details'
    },
    incidentListGeneralIncidentSectionTitle: {
        id: 'incident.list.section.title.General Incident',
        defaultMessage: 'General Incident'
    },
    incidentListGeneralPetIncidentSectionTitle: {
        id: 'incident.list.section.title.General Incident (Pet)',
        defaultMessage: 'General Incident (Pet)'
    },
    incidentListGeneralNonPetIncidentSectionTitle: {
        id: 'incident.list.section.title.General Incident (Non-Pet)',
        defaultMessage: 'General Incident (Non-Pet)'
    },
    incidentListVehicleIncidentSectionTitle: {
        id: 'incident.list.section.title.Vehicle Incident',
        defaultMessage: 'Vehicle Incident'
    },
    incidentListBaggageIncidentSectionTitle: {
        id: 'incident.list.section.title.Baggage Incident',
        defaultMessage: 'Baggage Incident'
    },
    incidentListInjuryIncidentSectionTitle: {
        id: 'incident.list.section.title.Injury Incident',
        defaultMessage: 'Injury Incident'
    },
    incidentListTripIncidentSectionTitle: {
        id: 'incident.list.section.title.Trip Incident',
        defaultMessage: 'Trip Incident'
    },
    incidentListPersonalPropertyIncidentSectionTitle: {
        id: 'incident.list.section.title.Personal Property Incident',
        defaultMessage: 'Personal Property Incident'
    },
    incidentListPropertyIncidentSectionTitle: {
        id: 'incident.list.section.title.Property Incident',
        defaultMessage: 'Property Incident'
    },
    incidentListExpandAll: {
        id: 'incident.list.Expand all',
        defaultMessage: 'Expand all'
    },
    incidentListCollapseAll: {
        id: 'incident.list.Collapse all',
        defaultMessage: 'Collapse all'
    },
    incidentListWhatHappened: {
        id: 'incident.list.What happened',
        defaultMessage: 'What happened'
    },
});
