import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { ModalNextProvider, Link } from '@jutro/components';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import messages from '../../Claims.messages';
import styles from '../../Claims.module.scss';
import ClaimServicesPrimaryContactPopupMetaData from './ContactDetailsPopup.metadata.json5';


function ContactLinkComponent(props) {
    const translator = useContext(TranslatorContext);
    const contactDetails = _.get(props, 'contactDetails');

    const getPrimaryMobilePhoneNumber = useCallback((item) => {
        if (item.cellNumber) {
            if (item.primaryPhoneType === 'mobile') {
                return `${item.cellNumber}(${translator(messages.modalPrimary)})`;
            }
            return item.cellNumber;
        }
        return null;
    }, [translator]);

    const getPrimaryWorkPhoneNumber = useCallback((item) => {
        if (item.workNumber) {
            if (item.primaryPhoneType === 'work') {
                return `${item.workNumber}(${translator(messages.modalPrimary)})`;
            }
            return item.workNumber;
        }
        return null;
    }, [translator]);

    const getPrimaryHomePhoneNumber = useCallback((item) => {
        if (item.homeNumber) {
            if (item.primaryPhoneType === 'home') {
                return `${item.homeNumber}(${translator(messages.modalPrimary)})`;
            }
            return item.homeNumber;
        }
        return null;
    }, [translator]);

    const getPrimaryContactPopupModalContent = useCallback((item) => {
        const isFieldVisible = item.contactType !== 'Company';
        const overrides = {
            primaryContactHomeNumber: {
                value: getPrimaryHomePhoneNumber(item),
                visible: isFieldVisible
            },
            primaryContactWorkNumber: {
                value: getPrimaryWorkPhoneNumber(item),
                visible: isFieldVisible
            },
            primaryContactMobileNumber: {
                value: getPrimaryMobilePhoneNumber(item),
                visible: isFieldVisible
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(
                ClaimServicesPrimaryContactPopupMetaData.pageContent,
                item,
                id,
                path,
                overrides
            );
        };

        const resolvers = {
            resolveValue: readValue,
            resolveClassNameMap: styles
        };
        return renderContentFromMetadata(
            ClaimServicesPrimaryContactPopupMetaData.pageContent,
            overrides,
            resolvers
        );
    }, []);

    const openContactPopup = useCallback(
        (event, item) => {
            event.preventDefault();
            const displayName = _.get(item, 'displayName', '');
            const renderServiceVendorContent = getPrimaryContactPopupModalContent(item);
            ModalNextProvider.showAlert({
                title: displayName,
                message: renderServiceVendorContent,
                status: 'info',
                icon: 'mi-error-outline'
            });
        },
        [getPrimaryContactPopupModalContent]
    );

    return (
        <Link onClick={(event) => openContactPopup(event, contactDetails)} href="/">
            {contactDetails.displayName}
        </Link>
    );
}

export default ContactLinkComponent;
