import _ from 'lodash';
import { EntityUtil } from 'gw-portals-util-js';
import ContentLineItem from './ContentLineItem';

function PersonalPropertyIncident(data) {
    if (data) {
        _.extend(this, data);

        if (Array.isArray(data.contentLineItems) && data.contentLineItems.length) {
            this.contentLineItems = data.contentLineItems.map((item) => new ContentLineItem(item));
        }
    }
}

PersonalPropertyIncident.prototype.createContentLineItem = function createContentLineItem() {
    if (!this.contentLineItems) {
        this.contentLineItems = [];
    }
    const item = new ContentLineItem();
    item.contentSchedule = 'personalproperty_Cnd';
    item.tempID = EntityUtil.nextId();
    this.contentLineItems.push(item);
    return item;
};

PersonalPropertyIncident.prototype.removeContentLineItem = function removeContentLineItem(item) {
    const idx = _.indexOf(this.contentLineItems, item);
    if (idx >= 0) {
        this.contentLineItems.splice(idx, 1);
    }
};

PersonalPropertyIncident.prototype.getTypeCode = function getTypeCode() {
    return 'PersonalPropertyIncident_Cnd';
};

export default PersonalPropertyIncident;
