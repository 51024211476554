import { defineMessages } from 'react-intl';

export default defineMessages({
    platformYes: {
        id: 'platform.inputs.Yes',
        defaultMessage: 'Yes'
    },
    platformNo: {
        id: 'platform.inputs.No',
        defaultMessage: 'No'
    },
    platformCancel: {
        id: 'platform.inputs.Cancel',
        defaultMessage: 'Cancel'
    },
    platformOk: {
        id: 'platform.inputs.OK',
        defaultMessage: 'Ok'
    },
    claimInformation: {
        id: 'fnol.views.Claim Information',
        defaultMessage: 'Claim Information'
    },
    typeOfIncident: {
        id: 'fnol.wizard.steps.Type of incident',
        defaultMessage: 'Type of incident'
    },
    lossDetails: {
        id: 'fnol.wizard.steps.Loss details',
        defaultMessage: 'Loss details'
    },
    additionalInformation: {
        id: 'fnol.wizard.steps.Additional information',
        defaultMessage: 'Additional information'
    },
    contactDetails: {
        id: 'fnol.wizard.steps.Contact details',
        defaultMessage: 'Contact details'
    },
    paymentDetails: {
        id: 'fnol.wizard.steps.Payment details',
        defaultMessage: 'Payment details'
    },
    summary: {
        id: 'fnol.wizard.steps.Summary',
        defaultMessage: 'Summary'
    },
    fnolSaveDraftErrorTitle: {
        id: 'fnol.common.config.FlowConfig.Unable to save draft claim information',
        defaultMessage: 'Unable to save draft claim information'
    },
    fnolSaveDraftErrorMessage: {
        id: 'fnol.common.config.FlowConfig.An error occurred while attempting to save the claim submission.',
        defaultMessage: 'An error occurred while attempting to save the claim submission.'
    },
    cancelModalMessage: {
        id: 'fnol.common.config.FlowConfig.The information you have entered will not be saved.',
        defaultMessage: 'The information you have entered will not be saved.'
    },
    saveAndExitModalTitle: {
        id: 'fnol.common.config.FlowConfig.Save and Exit?',
        defaultMessage: 'Save and Exit?'
    },
    saveAndExitModalMessage: {
        id: 'fnol.common.config.FlowConfig.We will save the information you have entered up to this point as a draft claim.',
        defaultMessage: 'We will save the information you have entered up to this point as a draft claim.'
    },
    fnolSaveDraftClaim: {
        id: 'fnol.common.config.FlowConfig.Saving draft claim details...',
        defaultMessage: 'Saving draft claim details...'
    },
    fnolRemoveUploadedDocument: {
        id: 'platform.widgets.fileupload.templates.Are you sure you want to remove:',
        defaultMessage: 'Are you sure you want to remove: {documentName}?'
    },
    dialogOk: {
        id: 'fnol.common.views.note-dialog.OK',
        defaultMessage: 'Ok'
    },
    fnolErrorSubmitClaimTitle: {
        id: 'fnol.common.config.FlowConfig.Error submitting claim',
        defaultMessage: 'Error submitting claim'
    },
    fnolErrorSubmitClaimMessage: {
        id: 'fnol.common.config.FlowConfig.An error occurred attempting to submit the claim.',
        defaultMessage: 'An error occurred attempting to submit the claim.'
    },
    fnolSummaryFileNameMessage: {
        id: 'fnol.common.config.FlowConfig.FNOL Summary',
        defaultMessage: 'Claim_summary'
    },
});
