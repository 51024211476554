import React, {
    useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelServiceContext, ViewModelForm, withViewModelService } from 'gw-portals-viewmodel-react';
import metadata from './PhoneStep.metadata.json5';

function FNOLSmsVerificationPhoneStep(props) {
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        model: tokenRequestVM,
        setTokenRequestVM,
        onValidate,
        resetValidationError,
        filterValidationErrors
    } = props;

    const onValueChange = useCallback((value, path) => {
        const updatedVM = viewModelService.clone(tokenRequestVM);
        _.set(updatedVM.value, path, value);
        if (path.startsWith('phone')) {
            resetValidationError('phone');
        }
        setTokenRequestVM(updatedVM);
    }, [viewModelService, tokenRequestVM, setTokenRequestVM, resetValidationError]);

    const overrideProps = {
        fnolPhoneStepPhone: {
            value: tokenRequestVM?.phone,
            onValidate,
            countryCode: _.upperCase(_.get(tokenRequestVM?.value, 'phone.countryCode')),
            /* eslint-disable camelcase */
            showErrors: filterValidationErrors && filterValidationErrors('phone')?.messages?.length > 0,
            validationMessages: (filterValidationErrors && filterValidationErrors('phone')?.messages)
                || tokenRequestVM.phone.aspects.validationMessages
        }
    };
    const resolvers = {
        resolveComponentMap: {
        },
        resolveCallbackMap: {
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={tokenRequestVM}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
        />
    );
}
FNOLSmsVerificationPhoneStep.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    model: PropTypes.shape({}).isRequired,
    setTokenRequestVM: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    resetValidationError: PropTypes.func,
    filterValidationErrors: PropTypes.func
};
FNOLSmsVerificationPhoneStep.defaultProps = {
    resetValidationError: undefined,
    filterValidationErrors: undefined
};
export default withViewModelService(FNOLSmsVerificationPhoneStep);
