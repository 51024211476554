import { SeverityLevel } from '@microsoft/applicationinsights-web';
import PhoneNumberWithCountryCode from './PhoneNumberWithCountryCode/PhoneNumberWithCountryCode';
import SectionHeader from './SectionHeader/SectionHeader';
import ReCaptcha from './ReCaptcha/ReCaptcha';
import AddressAutocompleteInput from './AddressAutocompleteInput/AddressAutocompleteInput';
import DatePicker from './DatePicker';
import { valueToDate, formatDate, getFnsLocale } from './DatePicker/utils';
import CheckboxWithLink from './CheckboxWithLink';
import ClearableDropdown from './ClearableDropdown';
import VariableLenghtInputMask from './VariableLenghtInputMask';
import TelemetryProvider from './Telemetry/TelemetryProvider';

const platformComponentMap = {
    PhoneNumberWithCountryCode: { component: 'PhoneNumberWithCountryCode' },
    SectionHeader: { component: 'SectionHeader' },
    ReCaptcha: { component: 'ReCaptcha' },
    CheckboxWithLink: { component: 'CheckboxWithLink' },
    AddressAutocompleteInput: { component: 'AddressAutocompleteInput' },
    ClearableDropdown: { component: 'ClearableDropdown' },
    date: { component: 'date' },
    VariableLenghtInputMask: { component: 'VariableLenghtInputMask' },
};

const platformComponents = {
    PhoneNumberWithCountryCode,
    SectionHeader,
    ReCaptcha,
    CheckboxWithLink,
    AddressAutocompleteInput,
    ClearableDropdown,
    date: DatePicker,
    VariableLenghtInputMask
};

export {
    DatePicker,
    valueToDate,
    formatDate,
    getFnsLocale,
    ReCaptcha,
    CheckboxWithLink,
    SectionHeader,
    PhoneNumberWithCountryCode,
    ClearableDropdown,
    VariableLenghtInputMask,
    TelemetryProvider,
    SeverityLevel,
    platformComponentMap,
    platformComponents
};
