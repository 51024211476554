import _ from 'lodash';

function TripIncident(data) {
    if (data) {
        _.extend(this, data);
    }
    this.lossRelatedTo = this.lossRelatedTo || [];
}

TripIncident.prototype.getTypeCode = function getTypeCode() {
    return 'TripIncident';
};

export default TripIncident;
