import _ from 'lodash';
import { InputMaskField } from '@jutro/components';

class VariableLenghtInputMask extends InputMaskField {
    isComplete(value) {
        const { mask } = this.props;
        const { patternMap } = this.state;

        return !mask || (value && _.every(patternMap, (pattern, index) => {
            const char = (value[index] ?? '').replace('_', '');
            return char.match(pattern);
        }));
    }

    // eslint-disable-next-line class-methods-use-this
    getValidationMessages(...args) {
        const { getValidationMessages } = this.props;
        if (_.isFunction(getValidationMessages)) {
            return getValidationMessages(...args);
        }
        return super.getValidationMessages(...args);
    }
}

export default VariableLenghtInputMask;
