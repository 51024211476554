import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolTripIncidentLossRelatedTo: {
        id: 'fnol.components.trip-incident.Loss related to',
        defaultMessage: 'Loss related to'
    },
    fnolTripIncidentTransportationCancellation: {
        id: 'fnol.components.trip-incident.Cancellation only?',
        defaultMessage: 'Cancellation only?'
    },
    fnolTripIncidentAccommodationCancellation: {
        id: 'fnol.components.trip-incident.Accommodation cancellation?',
        defaultMessage: 'Accommodation cancellation?'
    },
    fnolTripIncidentAccommodationCancellationCosts: {
        id: 'fnol.components.trip-incident.accommodation.Expected costs',
        defaultMessage: 'Expected costs'
    },
    fnolTripOriginalTransportationType: {
        id: 'fnol.components.trip-incident.Original transportation type',
        defaultMessage: 'Original transportation type'
    },
    fnolTripIncidentEarlyReturnOnly: {
        id: 'fnol.components.trip-incident.Early return only?',
        defaultMessage: 'Early return only?'
    },
    fnolTripIncidentDescription: {
        id: 'fnol.components.trip-incident.Reason for incident',
        defaultMessage: 'Reason for incident'
    },
    fnolTripIncidentTripStartDate: {
        id: 'fnol.components.trip-incident.Start date',
        defaultMessage: 'Start date'
    },
    fnolTripIncidentTripEndDate: {
        id: 'fnol.components.trip-incident.End date',
        defaultMessage: 'End date'
    },
    fnolTripIncidentTripRegion: {
        id: 'fnol.components.trip-incident.Geographical region',
        defaultMessage: 'Geographical region'
    },
    fnolTripIncidentScheduledDatesHeader: {
        id: 'fnol.components.trip-incident.Scheduled dates',
        defaultMessage: 'Scheduled dates'
    },
    fnolTripIncidentScheduledDepartureDate: {
        id: 'fnol.components.trip-incident.Departure date/time',
        defaultMessage: 'Departure date/time'
    },
    fnolTripIncidentScheduledArrivalDate: {
        id: 'fnol.components.trip-incident.Arrival date/time',
        defaultMessage: 'Arrival date/time'
    },
    fnolTripIncidentTransportationCancellationCosts: {
        id: 'fnol.components.trip-incident.transportation.Expected costs',
        defaultMessage: 'Expected costs'
    },
    fnolTripIncidentRealDatesHeader: {
        id: 'fnol.components.trip-incident.Real dates',
        defaultMessage: 'Real dates'
    },
    fnolTripIncidentRealDepartureDate: {
        id: 'fnol.components.trip-incident.Real Departure date/time',
        defaultMessage: 'Departure date/time'
    },
    fnolTripIncidentRealArrivalDate: {
        id: 'fnol.components.trip-incident.Real Arrival date/time',
        defaultMessage: 'Arrival date/time'
    },
    fnolTripIncidentEarlyReturnDate: {
        id: 'fnol.components.trip-incident.Early return date',
        defaultMessage: 'Early return date'
    },
    fnolTripIncidentLossRelatedTooltip: {
        id: 'fnol.components.trip-incident.If the claim is for cancellation of a travel event select "transportation" as a "loss related to", and "yes" as a "Cancellation Only".',
        defaultMessage: 'If the claim is for cancellation of a travel event select "transportation" as a "loss related to", and "yes" as a "Cancellation Only".'
    },
    fnolTripIncidentCancellationOnlyTooltip: {
        id: 'fnol.components.trip-incident."Cancellation Only" is when your trip has been canceled before the scheduled departure date and no other unexpected event occurred.',
        defaultMessage: '"Cancellation Only" is when your trip has been canceled before the scheduled departure date and no other unexpected event occurred.'
    },
    fnolTripIncidentEarlyReturnOnlyTooltip: {
        id: 'fnol.components.trip-incident."Early Return Only" is when your trip was interrupted after the scheduled departure date, so during the trip and no other unexpected event occurred.',
        defaultMessage: '"Early Return Only" is when your trip was interrupted after the scheduled departure date, so during the trip and no other unexpected event occurred.'
    },
    fnolTripIncidentEarlyReturnDateTooltip: {
        id: 'fnol.components.trip-incident.Please provide here the actual (real) date of your return.',
        defaultMessage: '"Please provide here the actual (real) date of your return.'
    },
    fnolTripIncidentAccommodation: {
        id: 'fnol.components.trip-incident.Accommodation',
        defaultMessage: 'Accommodation'
    },
    fnolTripIncidentTransportation: {
        id: 'fnol.components.trip-incident.Transportation',
        defaultMessage: 'Transportation'
    },
    fnolTripIncidentScheduledDepartureDateTooltip1: {
        id: 'fnol.components.trip-incident.Please provide here the planned date and time when your trip should have started (e.g. from the airline ticket, rental contract). The departure date should be consistent with the one on the insurance policy.',
        defaultMessage: 'Please provide here the planned date and time when your trip should have started (e.g. from the airline ticket, rental contract). The departure date should be consistent with the one on the insurance policy.'
    },
    fnolTripIncidentScheduledArrivalDateTooltip1: {
        id: 'fnol.components.trip-incident.Provide here the planned date and time of arrival at your destination (e.g. from airline ticket, rental agreement).',
        defaultMessage: 'Provide here the planned date and time of arrival at your destination (e.g. from airline ticket, rental agreement).'
    },
    fnolTripIncidentScheduledDepartureDateTooltip2: {
        id: 'fnol.components.trip-incident.Please provide here the date and time when your trip has started - time of departing the home place (e.g. from the airline ticket, rental contract).',
        defaultMessage: 'Please provide here the date and time when your trip has started - time of departing the home place (e.g. from the airline ticket, rental contract).'
    },
    fnolTripIncidentScheduledArrivalDateTooltip2: {
        id: 'fnol.components.trip-incident.Please provide here the planned date and time when your trip should have ended - time of arriving at the home place (e.g. from the airline ticket, rental contract).',
        defaultMessage: 'Please provide here the planned date and time when your trip should have ended - time of arriving at the home place (e.g. from the airline ticket, rental contract).'
    },
    fnolTripIncidentScheduledDepartureDateTooltip3: {
        id: 'fnol.components.trip-incident.Please provide here the planned date and time that your trip was scheduled to begin according to tickets or other documents that can confirm the correctness of the information.',
        defaultMessage: 'Please provide here the planned date and time that your trip was scheduled to begin according to tickets or other documents that can confirm the correctness of the information.'
    },
    fnolTripIncidentScheduledArrivalDateTooltip3: {
        id: 'fnol.components.trip-incident.Please provide here the planned date and time that you should have arrived at your destination according to tickets or other documents that can confirm the correctness of the information.',
        defaultMessage: 'Please provide here the planned date and time that you should have arrived at your destination according to tickets or other documents that can confirm the correctness of the information.'
    },
    fnolTripIncidentRealDepartureDateTooltip1: {
        id: 'fnol.components.trip-incident.Please provide here the actual start time of the trip in relation to the given scheduled departure date.',
        defaultMessage: 'Please provide here the actual start time of the trip in relation to the given scheduled departure date.'
    },
    fnolTripIncidentRealArrivalDateTooltip1: {
        id: 'fnol.components.trip-incident.Please provide here the actual time you arrived at your destination in relation to the given scheduled departure date.',
        defaultMessage: 'Please provide here the actual time you arrived at your destination in relation to the given scheduled departure date.'
    },
    fnolTripIncidentRoundTripDelays: {
        id: 'fnol.components.trip-incident.If you experienced round-trip delays during your trip, please report two events - one for the trip to your intended destination and one for the return trip.',
        defaultMessage: 'If you experienced round-trip delays during your trip, please report two events - one for the trip to your intended destination and one for the return trip.'
    },
    fnolTripIncidentFinancialImpact: {
        id: 'fnol.components.trip-incident.Please include here information about the financial impact',
        defaultMessage: 'Please include here information about the financial impact'
    }
});
