import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    InputField,
    DropdownSelectField,
    CurrencyField,
    IconButton
} from '@jutro/components';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { DatePicker } from 'cnd-common-components-platform-react';
import metadata from './BaggageIncident.metadata.json5';
import summaryMetadata from '../../summary/incidents/BaggageIncidentSummary.metadata.json5';
import styles from './BaggageIncident.module.scss';
import messages from './BaggageIncident.messages';
import SummaryContext from '../../summary/SummaryContext';

function FNOLBaggageIncident(props) {
    const {
        data: incidentVM,
        onValueChange,
        path,
        id,
        policyCurrency,
        onValidate,
        showErrors
    } = props;

    const translator = useContext(TranslatorContext);
    const { readOnly } = useContext(SummaryContext);

    const {
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );
    const onMonetaryFieldDriverChange = (driverValue, driverPath, fieldPath) => {
        handleValueChange(driverValue, driverPath);
        if (!driverValue && !_.get(incidentVM.value, `${fieldPath}.amount`)) {
            handleValueChange(undefined, fieldPath);
        }
    };

    const onCarrierCompensatedChange = (value) => {
        onMonetaryFieldDriverChange(value, 'carrierCompensated', 'carrierCompensatedAmount');
    };

    const onEstimationReceivedChange = (value) => {
        onMonetaryFieldDriverChange(value, 'estimationReceived', 'estimatedRepairCost');
    };

    useEffect(() => {
        if (_.isNil(_.get(incidentVM.value, 'delayOnly'))) {
            _.set(incidentVM.value, 'delayOnly', false);
        }

        if (_.isNil(_.get(incidentVM.value, 'carrierCompensated'))) {
            _.set(incidentVM.value, 'carrierCompensated', false);
        }

        if (_.isNil(_.get(incidentVM.value, 'estimationReceived'))) {
            _.set(incidentVM.value, 'estimationReceived', false);
        }

        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        registerComponentValidation(() => {
            return incidentVM.aspects.valid && incidentVM.aspects.subtreeValid;
        });
    }, [incidentVM.aspects.subtreeValid, incidentVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleContentLineItemChange = useCallback((value, index, key) => {
        handleValueChange(value, `contentLineItems.children.${index}.${key}`);
    }, [handleValueChange]);

    const addContentLineItem = useCallback(() => {
        incidentVM.value.createContentLineItem();
        handleValueChange(incidentVM.value.contentLineItems, 'contentLineItems');
    }, [incidentVM, handleValueChange]);

    const removeContentLineItem = useCallback((item) => {
        incidentVM.value.removeContentLineItem(item);
        handleValueChange(incidentVM.value.contentLineItems, 'contentLineItems');
    }, [incidentVM, handleValueChange]);

    const getDescriptionColumn = (rowData, index) => {
        const descriptionVM = _.get(incidentVM, `contentLineItems.children.${index}.description`);
        return (
            <InputField
                value={rowData.description}
                readOnly={readOnly}
                required={descriptionVM.aspects.required}
                validationMessages={descriptionVM.aspects.validationMessages}
                id={`fnolBaggageIncidentContentLineItem${index}Description`}
                onValueChange={(val) => handleContentLineItemChange(val, index, 'description')}
            />
        );
    };

    const getQuantityColumn = (rowData, index) => {
        return (
            <InputField
                value={rowData.quantity}
                disabled
                id={`fnolBaggageIncidentContentLineItem${index}Quantity`}
                readOnly={readOnly}
            />
        );
    };

    const getCategoryColumn = (rowData, index) => {
        const availableValues = _.get(incidentVM, `contentLineItems.children.${index}.category.aspects.availableValues`)
            .map((typecode) => ({
                code: typecode.code,
                name: translator({
                    id: `typekey.ContentLineItemCategory.${typecode.code}`,
                    defaultMessage: typecode.name
                })
            }));

        const categoryVM = _.get(incidentVM, `contentLineItems.children.${index}.category`);
        return (
            <DropdownSelectField
                value={rowData.category}
                readOnly={readOnly}
                className={styles.fullWidth}
                required={categoryVM.aspects.required}
                id={`fnolBaggageIncidentContentLineItem${index}Category`}
                availableValues={availableValues}
                onValueChange={(val) => handleContentLineItemChange(val, index, 'category')}
            />
        );
    };

    const getPurchaseCostColumn = (rowData, index) => {
        const purchaseCostVM = _.get(incidentVM, `contentLineItems.children.${index}.purchaseCost`);
        return (
            <CurrencyField
                readOnly={readOnly}
                value={rowData.purchaseCost}
                required={purchaseCostVM.aspects.required}
                validationMessages={purchaseCostVM.aspects.validationMessages}
                defaultCurrency={policyCurrency}
                id={`fnolBaggageIncidentContentLineItem${index}PurchaseCost`}
                onValueChange={(val) => handleContentLineItemChange(val, index, 'purchaseCost')}
            />
        );
    };

    const getPurchaseDateColumn = (rowData, index) => {
        const purchaseDateVM = _.get(incidentVM, `contentLineItems.children.${index}.purchaseDate`);
        return (
            <DatePicker
                readOnly={readOnly}
                value={rowData.purchaseDate}
                className={styles.overflowVisible}
                required={purchaseDateVM.aspects.required}
                validationMessages={purchaseDateVM.aspects.validationMessages}
                id={`fnolBaggageIncidentContentLineItem${index}PurchaseDate`}
                onValueChange={(val) => handleContentLineItemChange(val, index, 'purchaseDate')}
            />
        );
    };

    const getRemoveIcon = (item) => {
        return (
            <IconButton
                icon="mi-delete"
                className={styles.removeIcon}
                onClick={() => removeContentLineItem(item)}
            />
        );
    };

    const getTableOverrides = () => {
        return {
            fnolBaggageIncidentQuantityColumn: {
                onCell: getQuantityColumn
            },
            fnolBaggageIncidentDescriptionColumn: {
                onCell: getDescriptionColumn
            },
            fnolBaggageIncidentCategoryColumn: {
                onCell: getCategoryColumn
            },
            fnolBaggageIncidentPurchaseCostColumn: {
                onCell: getPurchaseCostColumn
            },
            fnolBaggageIncidentPurchaseDateColumn: {
                onCell: getPurchaseDateColumn
            },
            fnolBaggageIncidentRemoveColumn: {
                onCell: getRemoveIcon
            }
        };
    };

    const getLabelForDescription = () => {
        const isDelayOnly = _.get(incidentVM.value, 'delayOnly') === true;
        const {
            fnolBaggageIncidentDelayReason,
            fnolBaggageIncidentDamageDescription
        } = messages;
        return translator(
            isDelayOnly ? fnolBaggageIncidentDelayReason : fnolBaggageIncidentDamageDescription
        );
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        fnolBaggageIncidentBaggageRecoveredOn: {
            visible: _.get(incidentVM.value, 'delayOnly') === true && (!readOnly || !_.isNil(incidentVM.value.baggageRecoveredOn))
        },
        fnolBaggageIncidentBaggageMissingFrom: {
            visible: _.get(incidentVM.value, 'delayOnly') === true
        },
        fnolBaggageIncidentCompensatedByCarrier: {
            onValueChange: onCarrierCompensatedChange
        },
        fnolBaggageIncidentCarrierCompensatedAmount: {
            visible: _.get(incidentVM.value, 'carrierCompensated'),
            defaultCurrency: policyCurrency
        },
        fnolBaggageIncidentEstimationReceived: {
            onValueChange: onEstimationReceivedChange
        },
        fnolBaggageIncidentEstimatedRepairCost: {
            visible: _.get(incidentVM.value, 'estimationReceived'),
            defaultCurrency: policyCurrency
        },
        fnolBaggageIncidentContentLineItemsContainer: {
            visible: !_.isNil(incidentVM.value.contentLineItems) && _.get(incidentVM.value, 'contentLineItems').length > 0,
        },
        fnolBaggageIncidentContentLineItemsTable: {
            data: _.get(incidentVM.value, 'contentLineItems'),
            visible: _.get(incidentVM.value, 'contentLineItems') !== undefined && _.get(incidentVM.value, 'contentLineItems').length > 0,
            columnsProportion: [1.5, 6, 6, 4, 5.5, 1]
        },
        fnolBaggageIncidentDamageDescription: {
            label: getLabelForDescription()
        },
        fnolBaggageRecoverInfo: {
            visible: _.get(incidentVM.value, 'delayOnly') === true
        },
        ...getTableOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            addContentLineItem
        },
        resolveClassNameMap: styles
    };

    return (
        <div>
            <ViewModelForm
                uiProps={readOnly ? summaryMetadata.componentContent : metadata.componentContent}
                model={incidentVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLBaggageIncident.propTypes = {
    value: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.shape(() => {}).isRequired,
    path: PropTypes.shape('').isRequired,
    id: PropTypes.string.isRequired,
    policyCurrency: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired
};

export default FNOLBaggageIncident;
