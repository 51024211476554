import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolPropertyIncidentDamageDescription: {
        id: 'fnol.components.property-incident.Damage description',
        defaultMessage: 'Damage description'
    },
    fnolPropertyIncidentLossEstimation: {
        id: 'fnol.components.property-incident.Estimate of the loss',
        defaultMessage: 'Estimate of the loss'
    },
    fnolPropertyIncidentEstimationReceived: {
        id: 'fnol.components.property-incident.Repair estimate received?',
        defaultMessage: 'Repair estimate received?'
    },
    fnolPropertyIncidentEstimatedRepairCost: {
        id: 'fnol.components.property-incident.Estimated repair cost',
        defaultMessage: 'Estimated repair cost'
    },
    fnolPropertyIncidentEstimatedAlreadyRepaired: {
        id: 'fnol.components.property-incident.Already repaired?',
        defaultMessage: 'Already repaired?'
    },
});
