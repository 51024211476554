import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useCallback } from 'react';
import cx from 'classnames';
import { intlMessageShape } from '@jutro/prop-types';
import { getMessageProp } from '@jutro/platform';
import { ThemeContext } from '@jutro/theme';
import { Link } from '@jutro/components';
import messages from './Label.messages';
import { labelStyles } from './styles';

const linkStyle = {
    display: 'inline',
    verticalAlign: 'baseline'
};

function Label(props) {
    const theme = useContext(ThemeContext);
    const createLabel = useCallback((label, classes, translator) => {
        const translatedLabel = label && translator(label);
        if (!translatedLabel) {
            return null;
        }
        const tokens = _.trim(translatedLabel).split(/\s/);
        const contents = _.map(tokens, (token, i) => {
            const hasSpace = i !== tokens.length - 1;
            const maybeSpace = hasSpace ? ' ' : '';

            let url;
            let urlScheme = '';

            let matchUrl = token.match(/https?:\/\/[^ )]*/);
            if (matchUrl) {
                [url] = matchUrl;
            } else {
                matchUrl = token.match(/[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/);
                if (matchUrl) {
                    urlScheme = 'mailto:';
                    [url] = matchUrl;
                }
            }
            if (!matchUrl) {
                return `${token}${maybeSpace}`;
            }
            const beforeUrl = token.slice(0, matchUrl.index);
            const afterUrl = token.slice(matchUrl.index + matchUrl[0].length);
            return (
                <span>
                    {beforeUrl}
                    <Link
                        key={i}
                        style={linkStyle}
                        target="_blank"
                        href={`${urlScheme}${url}`}
                    >
                        {url}
                    </Link>
                    {`${afterUrl}${maybeSpace}`}
                </span>
            );
        });
        return <span className={classes}>{contents}</span>;
    }, []);

    const { translator, messageProps } = props;

    const themeProps = theme.getProps('FieldLabel', props);
    const themeStyles = theme.getStyles('FieldLabel', labelStyles);

    const {
        id,
        label,
        hideLabel,
        required,
        disabled,
        readOnly,
        className,
        showOptional,
        showRequired,
        showInlineLabel,
        labelPosition,
        labelClassName,
        secondaryLabel,
        secondaryLabelClassName,
        size,
        htmlFor,
        tooltipIcon,
    } = themeProps;

    if (hideLabel) {
        return null;
    }

    const translatedLabel = createLabel(
        label,
        cx(themeStyles.primaryLabel, labelClassName),
        translator
    );
    const translatedSecondaryLabel = createLabel(
        secondaryLabel,
        cx(themeStyles.secondaryLabel, secondaryLabelClassName),
        translator
    );
    const optional = getMessageProp('optional', messageProps, messages);
    const optionalIndicator = showOptional && !required && !readOnly
        && createLabel(optional, themeStyles.optional, translator);
    const requiredIndicator = showRequired && required && !readOnly && !disabled
        && (
            <span aria-hidden className={cx(themeStyles.requiredSymbol)}>
                *
            </span>
        );
    const labelContainerClasses = cx(themeStyles.fieldLabelContainer, {
        [themeStyles[labelPosition]]: !showInlineLabel,
        [themeStyles.fieldLabelContainerLarge]:
            size === 'large' && labelPosition === 'left',
    });
    const labelClasses = cx(
        themeStyles.fieldLabel,
        {
            [themeStyles.fieldLabelSmall]: size === 'small',
            [themeStyles.fieldLabelLarge]: size === 'large',
            [themeStyles.readOnly]: readOnly,
        },
        className
    );

    return (
        <div className={labelContainerClasses}>
            <label id={id} className={labelClasses} htmlFor={htmlFor}>
                {requiredIndicator}
                {translatedLabel}
                {optionalIndicator}
                {tooltipIcon}
            </label>
            {translatedSecondaryLabel}
        </div>
    );
}

Label.propTypes = {
    translator: PropTypes.func.isRequired,
    messageProps: PropTypes.shape({
        optional: intlMessageShape
    }).isRequired
};

Label.defaultProps = {};

export default React.memo(Label);
