import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { usePrevious } from 'cnd-common-hooks-platform-react';
import VariableLenghtInputMask from './VariableLenghtInputMask';

const cleanValue = (val, maskChar) => val?.replaceAll(maskChar, '');

function VariableLenghtInputMaskComponent(props) {
    const {
        value,
        maskChar,
        model,
        path,
        onValueChange,
        getValidationMessages,
    } = props;
    const previousValue = usePrevious(value);
    const [inputValue, setInputValue] = useState(value);
    const modelOrPath = model ?? path;

    useEffect(() => {
        if (value === previousValue) {
            return;
        }
        const cleanVal = cleanValue(inputValue, maskChar);
        if (value !== cleanVal) {
            setInputValue(value);
        }
    }, [value, inputValue, maskChar, previousValue]);

    const handleValueChange = useCallback(
        (val) => {
            setInputValue(val);
        },
        []
    );

    const handleBlur = useCallback(() => {
        const cleanVal = cleanValue(inputValue, maskChar);
        return (
            _.isFunction(onValueChange) && onValueChange(cleanVal, modelOrPath)
        );
    }, [inputValue, maskChar, modelOrPath, onValueChange]);

    return (
        <VariableLenghtInputMask
            {...props}
            value={inputValue}
            onBlur={handleBlur}
            onValueChange={handleValueChange}
            getValidationMessages={getValidationMessages}
        />
    );
}

VariableLenghtInputMaskComponent.propTypes = {
    value: PropTypes.string.isRequired,
    model: PropTypes.shape({}),
    path: PropTypes.string,
    maskChar: PropTypes.string,
    formatChars: PropTypes.shape({}),
    onValueChange: PropTypes.func,
    getValidationMessages: PropTypes.func
};

VariableLenghtInputMaskComponent.defaultProps = {
    maskChar: '_',
    model: undefined,
    path: undefined,
    formatChars: {
        9: '[0-9]',
        0: '[0-9]?',
        c: '[A-Za-z]',
        '*': '[A-Za-z0-9]'
    },
    onValueChange: undefined,
    getValidationMessages: undefined
};

export default VariableLenghtInputMaskComponent;
