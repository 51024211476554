import React, {
    useEffect,
    useContext,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { CountryLayerService } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import _ from 'lodash';
import messages from './GeneralIncident.messages';
import metadata from './GeneralIncident.metadata.json5';
import summaryMetadata from '../../summary/incidents/GeneralIncidentSummary.metadata.json5';
import Person from '../../Person/Person';
import SummaryContext from '../../summary/SummaryContext';


function FNOLGeneralIncident(props) {
    const {
        data: incidentVM,
        onValueChange,
        path,
        id,
        policyCurrency,
        onValidate,
        showErrors
    } = props;

    const {
        isComponentValid,
        registerComponentValidation,
        onValidate: setComponentValidation
    } = useValidation(id);

    const country = useStoredCountry()?.toUpperCase();

    const translator = useContext(TranslatorContext);
    const { readOnly } = useContext(SummaryContext);
    const YES_CODE = 'Yes';
    const NO_CODE = 'No';

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    useEffect(() => {
        registerComponentValidation(() => {
            return incidentVM.aspects.valid && incidentVM.aspects.subtreeValid;
        });
    }, [incidentVM.aspects.subtreeValid, incidentVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (_.isNil(_.get(incidentVM.value, 'isPetConnected'))) {
            _.set(incidentVM.value, 'isPetConnected', (_.isNil(_.get(incidentVM.value, 'carrierCompensated')) && !_.isNil(_.get(incidentVM.value, 'estimationReceived'))));
        }

        if (!_.get(incidentVM.value, 'isPetConnected') && _.isNil(_.get(incidentVM.value, 'carrierCompensated'))) {
            _.set(incidentVM.value, 'estimationReceived', NO_CODE);
            handleValueChange(incidentVM.value.estimationReceived, 'estimationReceived');
            _.set(incidentVM.value, 'carrierCompensated', false);
            handleValueChange(incidentVM.value.carrierCompensated, 'carrierCompensated');
        }

        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLabelForDescription = () => {
        const {
            fnolGeneralIncidentDamageDescription: damageLabel,
            fnolGeneralIncidentPetInjuryDescription: injuryDescription
        } = messages;
        return translator(
            !_.get(incidentVM.value, 'isPetConnected') ? damageLabel : injuryDescription
        );
    };

    const getLabelForEstimationReceived = () => {
        const {
            fnolGeneralIncidentEstimationReceived: estimationLabel,
            fnolGeneralIncidentTreatmentCostReceived: treatmentLabel
        } = messages;
        return translator(
            !_.get(incidentVM.value, 'isPetConnected') ? estimationLabel : treatmentLabel
        );
    };

    const getLabelForEstimationAmount = () => {
        const {
            fnolGeneralIncidentRepairEstimationAmount: repairAmount,
            fnolGeneralIncidentTreatmentEstimationAmount: treatmentAmount
        } = messages;
        return translator(
            !_.get(incidentVM.value, 'isPetConnected') ? repairAmount : treatmentAmount
        );
    };

    const getTooltipForDescription = () => {
        const {
            fnolGeneralIncidentDescriptionTooltip
        } = messages;
        return translator(
            _.get(incidentVM.value, 'isPetConnected') ? '' : fnolGeneralIncidentDescriptionTooltip
        );
    };

    const isPetConnectedChanged = useCallback((value) => {
        _.set(incidentVM.value, 'isPetConnected', value);
        _.set(incidentVM.value, 'estimationReceived', NO_CODE);
        handleValueChange(incidentVM.value.estimationReceived, 'estimationReceived');
        _.set(incidentVM.value, 'carrierCompensated', value === true ? undefined : false);
        handleValueChange(incidentVM.value.carrierCompensated, 'carrierCompensated');
    }, [handleValueChange, incidentVM]);

    const onCarrierCompensatedChange = (value) => {
        handleValueChange(value, 'carrierCompensated');
        if (!value && !_.get(incidentVM.value, 'compensationAmount.amount')) {
            handleValueChange(undefined, 'compensationAmount');
        }
    };

    const onRepairEstimationReceivedChange = (value) => {
        handleValueChange(value, 'estimationReceived');
        if (value !== YES_CODE && !_.get(incidentVM.value, 'estimationAmount.amount')) {
            handleValueChange(undefined, 'estimationAmount');
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        fnolGeneralIncidentDetailsContainer: {
            visible: _.get(incidentVM.value, 'isPetConnected') !== undefined
        },
        fnolGeneralIncidentDescription: {
            label: getLabelForDescription(),
            tooltip: readOnly ? null : getTooltipForDescription()
        },
        fnolGeneralIncidentPetConnected: {
            value: _.get(incidentVM.value, 'isPetConnected'),
            onValueChange: isPetConnectedChanged,
            visible: CountryLayerService.showAnimalsQuestion(country),
        },
        fnolGeneralIncidentEstimationReceived: {
            label: getLabelForEstimationReceived(),
            value: _.get(incidentVM.value, 'estimationReceived'),
            onValueChange: onRepairEstimationReceivedChange
        },
        fnolGeneralIncidentEstimationAmount: {
            visible: _.get(incidentVM.value, 'estimationReceived') === YES_CODE,
            label: getLabelForEstimationAmount(),
            defaultCurrency: policyCurrency
        },
        fnolGeneralIncidentCarrierCompensated: {
            visible: !_.get(incidentVM.value, 'isPetConnected'),
            value: _.get(incidentVM.value, 'carrierCompensated'),
            onValueChange: onCarrierCompensatedChange
        },
        fnolGeneralIncidentCompensationAmount: {
            visible: !_.get(incidentVM.value, 'isPetConnected') && _.get(incidentVM.value, 'carrierCompensated') === true,
            defaultCurrency: policyCurrency
        },
        fnolGeneralIncidentLossParty: {
            value: _.get(incidentVM.value, 'lossParty')
        },
        fnolGeneralIncidentLossPartyDetails: {
            visible: _.get(incidentVM.value, 'lossParty') !== undefined,
            isEmailPhoneInfoMessageVisible: _.get(incidentVM.value, 'lossParty') !== 'insured'
        }
    };

    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            person: Person
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={readOnly ? summaryMetadata.componentContent : metadata.componentContent}
                model={incidentVM}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLGeneralIncident.propTypes = {
    value: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.shape(() => {}).isRequired,
    path: PropTypes.shape('').isRequired,
    id: PropTypes.string.isRequired,
    policyCurrency: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired
};

export default FNOLGeneralIncident;
