import _ from 'lodash';
import React, {
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './BasicInformationSummary.metadata.json5';
import Address, { AddressMode } from '../../Address/Address';

function FNOLBasicInformationSummary(props) {
    const {
        value: claimVM
    } = props;


    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            address: Address
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            readOnly: true
        },
        fnolBasicInformationSummaryLossLocation: {
            readOnly: true,
            mode: AddressMode.LOSS_LOCATION
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={claimVM}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrideProps}
            />
        </div>
    );
}

FNOLBasicInformationSummary.propTypes = {
    value: PropTypes.shape({}).isRequired,
    component: PropTypes.shape({}).isRequired
};

export default FNOLBasicInformationSummary;
