import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo
} from 'react';

import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ServiceManager } from '@jutro/services';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { BreakpointTrackerContext } from '@jutro/layout';

import { ContactInfoService, CountryLayerService, TagManagerService } from 'cnd-portals-util-js';

import metadata from './PageContainer.metadata.json5';
import styles from './PageContainer.module.scss';

function PageContainer(props) {
    const { children } = props;
    const localeService = ServiceManager.getService('locale-service');
    const countryService = ServiceManager.getService('country-service-cnd');
    const breakpoint = useContext(BreakpointTrackerContext);
    const [showPhoneMenu, setShowPhoneMenu] = useState(false);
    const [phoneMenuRef, setPhoneMenuRef] = useState(false);
    const country = countryService.getStoredCountry();
    const countryCode = country?.toUpperCase();
    const isEmailContactChannel = CountryLayerService.isEmailContactCenterChannel(countryCode);

    const togglePhoneMenu = useCallback(() => {
        setShowPhoneMenu(!showPhoneMenu);
    }, [showPhoneMenu]);

    const shouldTogglePhoneMenu = useCallback((e) => {
        if (phoneMenuRef === e.target) {
            togglePhoneMenu();
        }
    }, [phoneMenuRef, togglePhoneMenu]);

    const clickOnFAQ = useCallback((event) => {
        event.preventDefault();
        window.open(
            // after translation, the link will be replaced dynamically based on Clients location
            ContactInfoService.getFaqLink(countryCode),
            '_blank'
        );
    }, [countryCode]);

    const clickOnFurtherQuestions = useCallback((event) => {
        event.preventDefault();
        var actionLocation = isEmailContactChannel ?
            `mailto:${ContactInfoService.getContactEmail(countryCode)}` :
            `tel:${ContactInfoService.getContactPhoneNumber(countryCode)}`;
        window.location = actionLocation;
    }, [countryCode]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            // a listener so we to the phone menu so we can
            // close the menu if there's a click outside the menu
            document.addEventListener('mousedown', shouldTogglePhoneMenu, false);
            // If a function is returned from a useEffect,
            // it will be used to clean up when component is unmounted
            return () => document.removeEventListener('mousedown', shouldTogglePhoneMenu, false);
        }
    }, [breakpoint, shouldTogglePhoneMenu, togglePhoneMenu]);

    const getMenuRef = useCallback((node) => {
        setPhoneMenuRef(node);
    }, []);

    useEffect(() => {
        TagManagerService.init(countryCode);
    }, [countryCode]);

    const handleLanguageValueChange = useCallback((language) => {
        localeService.saveLanguage(language);
        const languageCountry = (language === 'cs' || language === 'en-US') ? 'cz' : language;
        countryService.saveCountry(languageCountry);
    }, [localeService, countryService]);

    const overrideProps = {
        globalizationChooserGridItem: {
            visible: process.env.REACT_APP_SITE_LANG_SELECTOR_ENABLED === 'true'
        },
        globalizationChooserField: {
            onLanguageValueChange: handleLanguageValueChange,
            languageValue: localeService.getStoredLanguage()
        },
        phoneMenu: {
            ref: getMenuRef,
            className: classNames('gw-phone-header', {
                'gw-phone-header-open': showPhoneMenu
            })
        },
        mobilePhoneNumber: {
            content: isEmailContactChannel ? 
                ContactInfoService.getContactEmail(countryCode) :
                ContactInfoService.getContactPhoneNumber(countryCode)
        },
        furtherquestions: {
            title: isEmailContactChannel ? 
                ContactInfoService.getContactEmail(countryCode) :
                ContactInfoService.getContactPhoneNumber(countryCode),
            icon: isEmailContactChannel ? "mi-email" : "mi-phone"
        },
        checkanswers: {
            link: ContactInfoService.getFaqLink(countryCode),
            target: '_blank'
        },
        mobileFaq: {
            href: ContactInfoService.getFaqLink(countryCode),
            target: '_blank'
        },
        mobilePrivacyPolicy: {
            href: ContactInfoService.getPrivacyPolicyLink(countryCode),
            target: '_blank'
        },
        footerNavPrivacyPolicy: {
            href: ContactInfoService.getPrivacyPolicyLink(countryCode),
            target: '_blank'
        },
        mobileTermsOfUse: {
            href: ContactInfoService.getTermsOfUseLink(countryCode),
            target: '_blank'
        },
        footerNavTermsOfUse: {
            href: ContactInfoService.getTermsOfUseLink(countryCode),
            target: '_blank'
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            togglePhoneMenu: togglePhoneMenu,
            clickOnFurtherQuestions,
            clickOnFAQ: clickOnFAQ
        }
    };

    const metadataToRender = useMemo(() => {
        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            return metadata.pageContent.content.filter((item) => item.id === 'phoneHeader');
        }
        return metadata.pageContent.content.filter((item) => item.id === 'desktopHeader');
    }, [breakpoint]);

    const footerMetadata = metadata.pageContent.content.filter((item) => item.id === 'footer');

    return (
        <React.Fragment>
            { renderContentFromMetadata(
                metadataToRender, overrideProps, resolvers
            )}
            <div className={styles.pageContainer}>
                {children}
            </div>
            { renderContentFromMetadata(
                footerMetadata, overrideProps
            )}
        </React.Fragment>
    );
}
PageContainer.propTypes = {
    children: PropTypes.node.isRequired
};

export default withRouter(PageContainer);
