import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @returns {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
    /**
     * Initialize the Application Insights class
     * @param {string} connectionString - Application Insights Connection String
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @param {boolean} disableTelemetry - If true, telemetry isn't collected or sent.
     * @returns {void}
     */
    const initialize = (connectionString, browserHistory, disableTelemetry) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!disableTelemetry && !connectionString) {
            throw new Error('Connection string not provided');
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: '',
                connectionString,
                disableTelemetry,
                maxBatchInterval: 0,
                enableAutoRouteTracking: true,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        appInsights.loadAppInsights();
        return { reactPlugin, appInsights };
    };

    return { initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
