export const Countries = Object.freeze({
    PL: 'PL',
    RO: 'RO',
    BG: 'BG',
    CZ: 'CZ',
    HU: 'HU',
    SK: 'SK',
    JP: 'JP',
    CA: 'CA',
    US: 'US',
    AU: 'AU',
    FR: 'FR',
    DE: 'DE',
    GB: 'GB',
    Other: 'Other'
});

export function callCorrectImpl(variants, country) {
    let countryToCheck = country;
    const allCountriesFromVariants = variants.flatMap((x) => x.countries);
    const countryExists = allCountriesFromVariants.find((code) => countryToCheck === code);
    if (!countryExists) {
        countryToCheck = Countries.Other;
    }

    let countrySpecificVariant = null;
    variants.forEach((variant) => {
        if (variant.countries.find((countryCode) => countryCode === countryToCheck)) {
            countrySpecificVariant = variant;
        }
    });

    return countrySpecificVariant.action();
}

export default {
    Countries,
    callCorrectImpl
};
