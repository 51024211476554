import PropTypes from 'prop-types';
import React from 'react';
import styles from './EmailLink.module.scss'

function EmailLink(props) {
    const { children } = props;
    return <a className={styles.link} href={`mailto:${children}`}>{children}</a>;
}

EmailLink.propTypes = {
    children: PropTypes.string.isRequired
};

export default EmailLink;
