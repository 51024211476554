import React, {
    useEffect, useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { CountryLayerService } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import Address from '../Address/Address';
import metadata from './Company.metadata.json5';
import SummaryContext from '../summary/SummaryContext';
import './Company.messages';

function FNOLCompany(props) {
    const {
        id,
        onValidate,
        value: companyVM,
        onValueChange,
        path,
        showErrors,
        mode
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);

    const selectedCountry = useStoredCountry()?.toUpperCase();

    useEffect(() => {
        registerComponentValidation(() => {
            return companyVM.aspects.valid && companyVM.aspects.subtreeValid;
        });
    }, [companyVM.aspects.subtreeValid, companyVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const overrideProps = {
        '@field': {
            readOnly,
            labelPosition: 'left',
            showOptional: true,
            showErrors
        },
        fnolCompanyID: {
            required: CountryLayerService.isCompanyIdRequired(selectedCountry)
        },
        fnolCompanyTaxID: {
            required: CountryLayerService.isTaxIdRequired(selectedCountry)
        },
        fnolCompanyAddress: {
            mode: mode
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
        resolveComponentMap: {
            address: Address
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={companyVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLCompany.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    showErrors: PropTypes.bool.isRequired,
    mode: PropTypes.string
};

export default FNOLCompany;
