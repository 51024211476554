import _ from 'lodash';
import React, { useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './InjuryIncident.metadata.json5';
import summaryMetadata from '../../summary/incidents/InjuryIncidentSummary.metadata.json5';
import styles from './InjuryIncident.module.scss';
import Person from '../../Person/Person';
import './InjuryIncident.messages';
import SummaryContext from '../../summary/SummaryContext';

function FNOLInjuryIncident(props) {
    const {
        id,
        onValidate,
        data: incidentVM,
        onValueChange,
        path,
        showErrors
    } = props;

    const {
        isComponentValid,
        registerComponentValidation,
        onValidate: setComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        registerComponentValidation(() => {
            return incidentVM.aspects.valid && incidentVM.aspects.subtreeValid;
        });
    }, [incidentVM.aspects.subtreeValid, incidentVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        const treatmentReceived = _.get(incidentVM.value, 'treatmentReceived');
        if (_.isNil(treatmentReceived)) {
            _.set(incidentVM.value, 'treatmentReceived', false);
        }

        const ambulanceUsed = _.get(incidentVM.value, 'ambulanceUsed');
        if (_.isNil(ambulanceUsed)) {
            _.set(incidentVM.value, 'ambulanceUsed', false);
        }
    }, []);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const cleanMedicalTreatmentType = useCallback(() => {
        _.set(incidentVM.value, 'medicalTreatmentType', undefined);
        handleValueChange(incidentVM.value.medicalTreatmentType, 'medicalTreatmentType');
    }, [incidentVM, handleValueChange]);

    const receivedTreatmentChanged = useCallback((value, changedPath) => {
        if (value === false) {
            cleanMedicalTreatmentType();
        }
        handleValueChange(value, changedPath);
    }, [cleanMedicalTreatmentType, handleValueChange]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        fnolInjuryIncidentLossPartyType: {
            value: _.get(incidentVM.value, 'lossPartyType')
        },
        fnolInjuryIncidentReceivedTreatment: {
            onValueChange: receivedTreatmentChanged
        },
        fnolInjuryIncidentLossParty: {
            visible: !_.isNil(_.get(incidentVM.value, 'lossPartyType')),
            isEmailPhoneInfoMessageVisible: _.get(incidentVM.value, 'lossPartyType') !== 'insured'
        },
        fnolInjuryIncidentTreatmentType: {
            visible: _.get(incidentVM.value, 'treatmentReceived') === true
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            person: Person
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={readOnly ? summaryMetadata.componentContent : metadata.componentContent}
                model={incidentVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

FNOLInjuryIncident.propTypes = {
    data: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    showErrors: PropTypes.bool.isRequired
};

export default FNOLInjuryIncident;
