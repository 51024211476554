import _ from 'lodash';
import React, {
    useEffect, useState, useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import { LookupField } from '@jutro/components';
import { useDependencies } from 'gw-portals-dependency-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';

function AddressAutocompleteInput(props) {
    const { FNOLService } = useDependencies('FNOLService');
    const translator = useContext(TranslatorContext);
    let timeout;

    const {
        fieldId,
        value: addressVM,
        onValueChange,
        onValidate,
        label,
        conditions,
        fieldName,
        readOnly
    } = props;

    const getInitialValue = useCallback(() => {
        const initialValue = _.get(addressVM.value, fieldId);
        return initialValue ? {
            id: initialValue,
            displayName: initialValue,
            type: 'address'
        } : null;
    }, [addressVM.value, fieldId]);

    const [selectedValue, setSelectedValue] = useState(getInitialValue());

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation(fieldId);

    const getFormValidity = useCallback(() => {
        return addressVM[fieldId].aspects.valid && addressVM[fieldId].aspects.subtreeValid;
    }, [addressVM, fieldId]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, fieldId);
        }
    }, [fieldId, isComponentValid, onValidate, selectedValue]);

    const handleValueChange = useCallback(
        (val) => {
            if (onValueChange) {
                setSelectedValue(val);
                onValueChange(val?.id, fieldId);
            }
        },
        [onValueChange, fieldId]
    );

    useEffect(() => {
        const modelValue = _.get(addressVM.value, fieldId);
        if ((_.isNil(modelValue) && !_.isNil(selectedValue))) {
            handleValueChange(null);
        } else if (!_.isNil(modelValue)
                    && (_.isNil(selectedValue) || modelValue !== selectedValue)) {
            disregardFieldValidation(fieldId);
            handleValueChange(getInitialValue());
        }
    }, [addressVM.value, fieldId, getInitialValue, selectedValue, handleValueChange,
        disregardFieldValidation]);

    const getSuggestions = async (query) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        return new Promise((resolve) => {
            timeout = setTimeout(async () => {
                const results = await FNOLService.getSuggestions([
                    fieldName, conditions, query
                ]);
                resolve(
                    _.uniq([_.capitalize(query).trim(), ...results])
                        .map((result) => ({ id: result, displayName: result, type: 'address' }))
                );
            }, 500);
        });
    };

    return (
        <LookupField
            onLoadValues={getSuggestions}
            onValueChange={handleValueChange}
            label={label}
            labelPosition="left"
            showOptional
            required={addressVM[fieldId].aspects.required}
            model={addressVM.value[fieldId]}
            readOnly={readOnly}
            value={selectedValue}
            optionTypes={[
                {
                    className: 'jut__lookup__lookupOptionAddress',
                    displayName: translator({
                        id: `fnol.components.address.${_.capitalize(fieldId)}`,
                        defaultMessage: _.capitalize(fieldId),
                    }),
                    icon: 'mi-home_work',
                    type: 'address'
                }
            ]}
            id={`fnol${_.capitalize(fieldId)}Autocomplete`}
            onValidate={onValidate}
            path={fieldId}
            onValidationChange={setComponentValidation}
        />
    );
}
AddressAutocompleteInput.propTypes = {
    label: PropTypes.shape({}).isRequired,
    value: PropTypes.shape({}).isRequired,
    fieldId: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    conditions: PropTypes.bool.isRequired,
    fieldName: PropTypes.string.isRequired
};
AddressAutocompleteInput.defaultProps = {
    readOnly: false
};
export default AddressAutocompleteInput;
