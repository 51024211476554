import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolPaymentDetailsSummaryReportersContactDetails: {
        id: 'fnol.components.payment-details-summary.Type of contact',
        defaultMessage: 'Type of contact'
    },
    fnolPaymentDetailsSummaryReportersContactDetails: {
        id: 'fnol.components.payment-details-summary.Type of payment receiver',
        defaultMessage: 'Type of payment receiver'
    },
    fnolPaymentDetailsSummaryReportersContactDetails: {
        id: 'fnol.components.payment-details-summary.Owner of bank account',
        defaultMessage: 'Owner of bank account'
    },
    fnolPaymentDetailsSummaryCompanyDetails: {
        id: 'fnol.components.payment-details-summary.Company details',
        defaultMessage: 'Company details'
    },
    fnolPaymentDetailsSummaryBankAccountDetailsTitle: {
        id: 'fnol.components.payment-details-summary.Bank Account Details (where we will send payment)',
        defaultMessage: 'Bank Account Details (where we will send payment)'
    }
});
