import _ from 'lodash';
import React, {
    useCallback, useMemo, useContext
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import BankAccountDetails from '../../BankAccountDetails/BankAccountDetails';
import Company from '../../Company/Company';
import Person from '../../Person/Person';
import metadata from './PaymentDetailsSummary.metadata.json5';
import './PaymentDetailsSummary.messages';

function FNOLPaymentDetailsSummary(props) {
    const {
        value: claimVM,
    } = props;

    const translator = useContext(TranslatorContext);

    const availablePayeeTypes = useMemo(() => {
        const values = _.get(claimVM.checkPayeeData.paymentReceiverType, 'aspects.availableValues');
        return _.map(values, (typecode) => ({
                code: typecode.code,
                name: translator({
                    id: typecode.name,
                    defaultMessage: typecode.name
                })
            })
        );
    }, [claimVM.checkPayeeData.paymentReceiverType, translator]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            readOnly: true
        },
        fnolPaymentDetailsSummaryPersonOrCompany: {
            value: _.get(claimVM.value, 'checkPayeeData.checkPayeeType'),
        },
        fnolPaymentDetailsSummaryPaymentReceiverType: {
            availableValues: availablePayeeTypes,
            value: _.get(claimVM.value, 'checkPayeeData.paymentReceiverType')
        },
        fnolPaymentDetailsSummaryCompanyDetailsTitle: {
            visible: _.get(claimVM.value, 'checkPayeeData.company') !== undefined
        },
        fnolPaymentDetailsSummaryCompany: {
            value: _.get(claimVM, 'checkPayeeData.company'),
            visible: _.get(claimVM.value, 'checkPayeeData.company') !== undefined
        },
        fnolPaymentDetailsSummaryPersonTitle: {
            visible: _.get(claimVM.value, 'checkPayeeData.individual') !== undefined
        },
        fnolPaymentDetailsSummaryPerson: {
            visible: _.get(claimVM.value, 'checkPayeeData.individual') !== undefined,
            value: _.get(claimVM, 'checkPayeeData.individual'),
            isEmailNotificationInfoVisible: true
        },
        fnolPaymentDetailsSummaryBankAccountDetails: {
            value: _.get(claimVM, 'checkPayeeData.bankAccountDetails'),
            policyCountry: _.get(claimVM.value, 'policyCountry')
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentsMap: {
            person: Person,
            company: Company,
            bankaccountdetails: BankAccountDetails
        },
        resolveValue: readValue
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={claimVM.checkPayeeData}
                resolveValue={resolvers.resolveValue}
                componentMap={resolvers.resolveComponentsMap}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLPaymentDetailsSummary.propTypes = {
    value: PropTypes.shape({}).isRequired,
};

export default FNOLPaymentDetailsSummary;
