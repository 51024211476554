import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import React, {
    useCallback,
    useEffect,
    useContext,
    useLayoutEffect,
    useMemo
} from 'react';
import { withRouter } from 'react-router-dom';
import { ContactInfoService, CountryLayerService, TagManagerService } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import metadata from './CallContactCenterPage.metadata.json5';
import messages from './CallContactCenterPage.messages';
import styles from './CallContactCenterPage.scss';

function FNOLCallContactCenterPage(props) {
    const { history } = props;
    const country = useStoredCountry();
    const countryCode = country?.toUpperCase();
    const translator = useContext(TranslatorContext);
    const { isEmailContactChannel, contactPhoneNumber, contactEmailAddress}  = useMemo(() => {
        return {
            isEmailContactChannel: CountryLayerService.isEmailContactCenterChannel(countryCode),
            contactPhoneNumber: ContactInfoService.getContactPhoneNumber(countryCode),
            contactEmailAddress: ContactInfoService.getContactEmail(countryCode)
        };
    }, [countryCode]);

    useLayoutEffect(() => {
        document.title = translator(messages.fnolCallContactCenterPageTitle) + ' | Colonnade Insurance S.A.';
    }, []);

    useEffect(() => {
        TagManagerService.pushActionEvent('contactCallCenter-pageview');
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideProps = {
        fnolCallContactCenterPageEnvelopeIcon: {
            visible: isEmailContactChannel
        },
        fnolCallContactCenterPagePhoneIcon: {
            visible: !isEmailContactChannel
        },
        fnolCallContactCenterPageSubinfo: {
            content: <FormattedMessage
                {...messages.fnolCallContactCenterPageSubinfo}
                values={{
                    phonenumber: contactPhoneNumber,
                    emailAddress: contactEmailAddress
                }}
            />
        },
        fnolCallContactCenterPageCallBtn: {
            visible: !isEmailContactChannel,
            content: contactPhoneNumber
        },
        fnolCallContactCenterPageEmailBtn: {
            visible: isEmailContactChannel,
            content: contactEmailAddress
        }
    };

    const goBack = useCallback(() => {
        history.push('/');
    }, [history]);

    const call = () => {
        window.location.href = `tel:${contactPhoneNumber}`;
    };

    const email = () => {
        window.location.href = `mailto:${contactEmailAddress}`;
    };

    const resolvers = {
        resolveComponentMap: {
        },
        resolveCallbackMap: {
            goBack,
            call,
            email
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}
FNOLCallContactCenterPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(FNOLCallContactCenterPage);
