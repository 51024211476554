import { setComponentMapOverrides } from '@jutro/uiconfig';

import FNOLContext from './components/Context/Context';
import FNOLWizard from './components/FNOLWizard/FNOLWizard';
import FNOLSearchPolicyPage from './pages/SearchPolicyPage/SearchPolicyPage';
import FNOLBasicInformationPage from './pages/BasicInformationPage/BasicInformationPage';
import FNOLLossDetailsPage from './pages/LossDetailsPage/LossDetailsPage';
import FNOLAdditionalInformationPage from './pages/AdditionalInformationPage/AdditionalInformationPage';
import FNOLSummaryPage from './pages/SummaryPage/SummaryPage';
import FNOLContactDetailsPage from './pages/ContactDetailsPage/ContactDetailsPage';
import FNOLPaymentDetailsPage from './pages/PaymentDetailsPage/PaymentDetailsPage';
import FNOLCallContactCenterPage from './pages/CallContactCenterPage/CallContactCenterPage';
import FNOLConfirmationPage from './pages/ConfirmationPage/ConfirmationPage';
import FNOLDefaultErrorPage from './pages/DefaultErrorPage/DefaultErrorPage';
import FNOLSmsVerificationPage from './pages/SmsVerificationPage/SmsVerificationPage';

setComponentMapOverrides(
    {
        FNOLWizard: { component: 'FNOLWizard' },
        FNOLBasicInformationPage: { component: 'FNOLBasicInformationPage' },
        FNOLLossDetailsPage: { component: 'FNOLLossDetailsPage' },
        FNOLAdditionalInformationPage: { component: 'FNOLAdditionalInformationPage' },
        FNOLContactDetailsPage: { component: 'FNOLContactDetailsPage' },
        FNOLPaymentDetailsPage: { component: 'FNOLPaymentDetailsPage' },
        FNOLSummaryPage: { component: 'FNOLSummaryPage' }
    },
    {
        FNOLWizard,
        FNOLBasicInformationPage,
        FNOLLossDetailsPage,
        FNOLAdditionalInformationPage,
        FNOLContactDetailsPage,
        FNOLPaymentDetailsPage,
        FNOLSummaryPage
    }
);

export {
    FNOLContext,
    FNOLSearchPolicyPage,
    FNOLWizard,
    FNOLCallContactCenterPage,
    FNOLConfirmationPage,
    FNOLDefaultErrorPage,
    FNOLSmsVerificationPage
};
