import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolSearchPolicyTitle: {
        id: 'fnol.components.search-policy.Please provide info about your insurance',
        defaultMessage: 'Please provide info about your insurance'
    },
    fnolSearchPolicyWhoIsRegsitering: {
        id: 'fnol.components.search-policy.Who is the Policyholder?',
        defaultMessage: 'Who is the Policyholder?'
    },
    fnolSearchPolicyInsuredBirthDate: {
        id: 'fnol.components.search-policy.Date of birth of Insured',
        defaultMessage: 'Date of Birth of Insured'
    },
    fnolSearchPolicyHolderSameAsInsured: {
        id: 'fnol.components.search-policy.Is the Insured and the Policy Holder the same?',
        defaultMessage: 'Is the Insured and the Policy Holder the same?'
    },
    fnolSearchPolicyOwnerBirthDate: {
        id: 'fnol.components.search-policy.Date of birth of Policy Holder',
        defaultMessage: 'Date of birth of Policy Holder'
    },
    fnolSearchPolicyCompanyId: {
        id: 'fnol.components.search-policy.Company ID or VAT ID',
        defaultMessage: 'Company ID or VAT ID'
    },
    fnolSearchPolicyLossDate: {
        id: 'fnol.components.search-policy.When did the loss occur?',
        defaultMessage: 'When did the loss occur?'
    },
    fnolSearchPolicyLossDateTooltip: {
        id: 'fnol.components.search-policy.If you are not sure what was the exact date and time of loss, please choose your best estimate.',
        defaultMessage: 'If you are not sure what was the exact date and time of loss, please choose your best estimate.'
    },
    fnolLossDateMustBeInThePass: {
        id: 'fnol.components.search-policy.Loss Date must be in the past',
        defaultMessage: 'Loss Date must be in the past'
    },
    fnolSearchPolicyIDontKnow: {
        id: 'fnol.components.search-policy.I don\'t know',
        defaultMessage: 'I don\'t know'
    },
    fnolSearchPolicyPerson: {
        id: 'fnol.components.search-policy.Individual',
        defaultMessage: 'Individual'
    },
    fnolSearchPolicyCompany: {
        id: 'fnol.components.search-policy.Company/Entrepreneur',
        defaultMessage: 'Company/Entrepreneur'
    },
    fnolSearchPolicyNumber: {
        id: 'fnol.components.search-policy.Policy Number',
        defaultMessage: 'Policy Number'
    },
    fnolSearchNext: {
        id: 'fnol.components.search-policy.Next',
        defaultMessage: 'Next'
    },
    fnolSearchPolicyTitle: {
        id: 'fnol.components.search-policy.Claim',
        defaultMessage: 'Claim'
    }
});
