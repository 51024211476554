import _ from 'lodash';
import React, {
    useCallback, useState, useContext, useEffect, useRef, useMemo
} from 'react';
import {
    WizardPage,
    wizardProps,
    WizardPageTemplate
} from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { CountryLayerService, TagManagerService, TrackingConstants } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { TranslatorContext } from '@jutro/locale';
import StepHeader from '../../components/StepHeader/StepHeader';
import useErrorHandler from '../../hooks/useErrorHandler';
import metadata from './BasicInformationPage.metadata.json5';
import messages from './BasicInformationPage.messages';
import styles from './BasicInformationPage.module.scss';
import Claim from '../../models/Claim';
import Address, { AddressMode } from '../../components/Address/Address';

function FNOLBasicInformationPage(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [availableLossTypes, setAvailableLossTypes] = useState([]);
    const [availableLossCauses, setAvailableLossCauses] = useState([]);
    const translator = useContext(TranslatorContext);
    const { FNOLService } = useDependencies('FNOLService');
    const ErrorHandler = useErrorHandler();
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        wizardData,
        updateWizardData
    } = props;

    const {
        onValidate,
        isComponentValid,
        initialValidation
    } = useValidation('TypeOfIncidentPage');

    const claimVMRef = useRef(viewModelService.create(
        wizardData.value,
        'cc',
        'com.colonnade.edge.capabilities.claim.customfnol.dto.FnolDTO'
    ));

    const claimVM = claimVMRef.current;

    const isLossTypeDisclaimerApplicable = useMemo(() => {
        return claimVM.value.availableLossTypes && claimVM.value.availableLossTypes.length == 2
            && claimVM.value.availableLossTypes.find((lc) => lc.code === "PERPR_CND")
                && claimVM.value.availableLossTypes.find((lc) => lc.code === "PERACC_CND")
    }, [claimVM.value.availableLossTypes]);
    
    useEffect(() => {
        if (claimVM.value.lossType) {
            FNOLService.getAvailableLossCauses(claimVM.value.lossType)
                .then((causes) => {
                    const translatedCauses = causes.map((code) => ({
                        code,
                        name: translator({
                            id: `typekey.LossCause.${code}`,
                            defaultMessage: code
                        })
                    }));
                    setAvailableLossCauses(_.sortBy(translatedCauses, ['name']));
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimVM.value.lossType]);

    useEffect(() => {
        FNOLService.getAvailableConsents(appCountry)
            .then((consents) => {
                const currentConsents = _.get(claimVM, 'consents.value');
                _.set(claimVM, 'consents.value',
                    consents.map((loadedConsent) => ({
                        ...loadedConsent,
                        selected: currentConsents
                            ?.find((c) => c.consentTypeID
                            === loadedConsent.consentTypeID)?.selected
                                || false
                    })));
                updateWizardData(claimVM);
            });
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAvailableLossTypes(claimVM.value.availableLossTypes.map((code) => ({
            code,
            name: translator({
                id: `typekey.LossType.${code}`,
                defaultMessage: code
            })
        })));
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSaveClaimData = useCallback(() => {
        setIsLoading(true);
        return FNOLService.saveClaim(claimVM.value)
            .then((response) => {
                claimVM.value = new Claim(response);
                return claimVM;
            })
            .catch((error) => {
                const claimNumber = claimVM.claimNumber.value;
                ErrorHandler.handleError(error, claimNumber);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, ErrorHandler, claimVM]);

    const onNext = useCallback(() => {
        TagManagerService.pushNextButtonClick(TrackingConstants.STEPS.BASIC_INFORMATION);
        return handleSaveClaimData();
    }, [handleSaveClaimData]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(claimVM, path, value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const setLossTypeAndUnsetLossCause = useCallback((value) => {
        _.set(claimVM.value, 'lossType', value);
        _.unset(claimVM.value, 'lossCause');
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        fnolBasicInformationPageLoader: {
            loaded: !isLoading
        },
        fnolBasicInformationPageContainer: {
            visible: !isLoading
        },
        fnolBasicInformationPageLossType: {
            availableValues: availableLossTypes,
            visible: availableLossTypes.length > 2,
            onValueChange: (value) => {
                setLossTypeAndUnsetLossCause(value);
            }
        },
        fnolBasicInformationPageLossTypeRadio: {
            value: _.get(claimVM.value, 'lossType'),
            availableValues: availableLossTypes,
            visible: availableLossTypes.length == 2,
            onValueChange: (value) => {
                setLossTypeAndUnsetLossCause(value);
            }
        },
        fnolBasicInformationLossTypeDisclaimer: {
            visible: CountryLayerService.isPetInfoApplicable(appCountry) && isLossTypeDisclaimerApplicable
        },
        fnolBasicInformationLossCause: {
            visible: !!claimVM.value.lossType,
            availableValues: availableLossCauses,
            value: _.get(claimVM.value, 'lossCause')
        },
        fnolBasicInformationLossLocation: {
            mode: AddressMode.LOSS_LOCATION
        },
        fnolBasicInformationPageStepHeader: {
            claimNumber: _.get(claimVM.value, 'claimNumber'),
            title: translator(messages.fnolBasicInformation)
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
        resolveComponentMap: {
            address: Address,
            stepheader: StepHeader
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={resolvers.resolveValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

FNOLBasicInformationPage.propTypes = wizardProps;
export default withRouter(FNOLBasicInformationPage);
