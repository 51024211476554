import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolCompanyName: {
        id: 'fnol.components.company.Company Name',
        defaultMessage: 'Company Name'
    },
    fnolCompanyID: {
        id: 'fnol.components.company.Company ID',
        defaultMessage: 'Company ID'
    },
    fnolCompanyTaxID: {
        id: 'fnol.components.company.Tax ID',
        defaultMessage: 'Tax ID'
    }
});
