import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolSchoolInformationSchoolName: {
        id: 'fnol.components.school-information.School name',
        defaultMessage: 'School name'
    },
    fnolSchoolInformationSchoolAddress: {
        id: 'fnol.components.school-information.School address',
        defaultMessage: 'School address'
    },
    fnolSchoolInformationPremiumPaidConfirmation: {
        id: 'fnol.components.school-information.As an authorized person (parent/legal guardian), I confirm that the premium under the indicated insurance policy has been paid. I also empower Colonnade to contact the above indicated school to confirm that the premium has been paid. At the same time, I declare that I am the entitled person (parent/guardian) to give the above empowerment.',
        defaultMessage: 'As an authorized person (parent/legal guardian), I confirm that the premium under the indicated insurance policy has been paid. I also empower Colonnade to contact the above indicated school to confirm that the premium has been paid. At the same time, I declare that I am the entitled person (parent/guardian) to give the above empowerment.'
    },
    fnolSchoolInformationNoReimbursementReceived: {
        id: 'fnol.components.school-information.I confirm that I have not received reimbursement for medical treatment, rehabilitation or medical equipment from another insurance company/business entity/NFZ, as well as I am not making efforts to obtain reimbursement from the above entities for the reported event. The submission of the statement applies only in the case of a claim for reimbursement.',
        defaultMessage: 'I confirm that I have not received reimbursement for medical treatment, rehabilitation or medical equipment from another insurance company/business entity/NFZ, as well as I am not making efforts to obtain reimbursement from the above entities for the reported event. The submission of the statement applies only in the case of a claim for reimbursement.'
    }
});