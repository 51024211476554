import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolAddressCountry: {
        id: 'fnol.components.address.Country',
        defaultMessage: 'Country'
    },
    fnolAddressStreet: {
        id: 'fnol.components.address.Street',
        defaultMessage: 'Street'
    },
    fnolAddressStreetNumber: {
        id: 'fnol.components.address.Street number',
        defaultMessage: 'Street number'
    },
    fnolAddressApartmentNumber: {
        id: 'fnol.components.address.Apartment number',
        defaultMessage: 'Apartment number'
    },
    fnolAddressCity: {
        id: 'fnol.components.address.City',
        defaultMessage: 'City'
    },
    fnolAddressPostcode: {
        id: 'fnol.components.address.Postcode',
        defaultMessage: 'Postcode'
    },
    fnolAddressVoivodeship: {
        id: 'fnol.components.address.Voivodeship',
        defaultMessage: 'Voivodeship'
    },
    fnolAddressCounty: {
        id: 'fnol.components.address.County',
        defaultMessage: 'County'
    },
    fnolAddressState: {
        id: 'fnol.components.address.State',
        defaultMessage: 'State'
    },
    fnolAddressProvince: {
        id: 'fnol.components.address.Province',
        defaultMessage: 'Province'
    },
    fnolAddressPrefecture: {
        id: 'fnol.components.address.Prefecture',
        defaultMessage: 'Prefecture'
    },
    fnolAddressGps: {
        id: 'fnol.components.address.GPS Location',
        defaultMessage: 'GPS Location'
    },
    fnolAddressCountryOfLoss: {
        id: 'fnol.components.address.Country of loss',
        defaultMessage: 'Country of loss'
    },
    fnolAddressStreetOfLoss: {
        id: 'fnol.components.address.Street of loss',
        defaultMessage: 'Street of loss'
    },
    fnolAddressStreetNumberOfLoss: {
        id: 'fnol.components.address.Street number of loss',
        defaultMessage: 'Street number of loss'
    },
    fnolAddressApartmentNumberOfLoss: {
        id: 'fnol.components.address.Apartment number of loss',
        defaultMessage: 'Apartment number of loss'
    },
    fnolAddressCityOfLoss: {
        id: 'fnol.components.address.City of loss',
        defaultMessage: 'City of loss'
    },
    fnolAddressPostcodeOfLoss: {
        id: 'fnol.components.address.Postcode of loss',
        defaultMessage: 'Postcode of loss'
    },
    fnolAddressVoivodeshipOfLoss: {
        id: 'fnol.components.address.Voivodeship of loss',
        defaultMessage: 'Voivodeship of loss'
    },
    fnolAddressCountyOfLoss: {
        id: 'fnol.components.address.County of loss',
        defaultMessage: 'County of loss'
    },
    fnolAddressStateOfLoss: {
        id: 'fnol.components.address.State of loss',
        defaultMessage: 'State of loss'
    },
    fnolAddressProvinceOfLoss: {
        id: 'fnol.components.address.Province of loss',
        defaultMessage: 'Province of loss'
    },
    fnolAddressPrefectureOfLoss: {
        id: 'fnol.components.address.Prefecture of loss',
        defaultMessage: 'Prefecture of loss'
    }
});
