import React, {
    useCallback, useContext, useState, useMemo
} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import _ from 'lodash';
import classNames from 'classnames';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { FaqSectionComponent } from 'gw-components-platform-react';
// eslint-disable-next-line import/no-unresolved
import faqConfig from 'faq-config';
import metadata from './FaqPage.metadata.json5';
import styles from './FaqPage.module.scss';

import messages from './FaqPage.messages';

function FaqSideBar(props) {
    const translator = useContext(TranslatorContext);

    const { value, enabled } = props;
    const { id, title } = value;

    const sidebarLinkStyle = classNames(styles.wizardLi, {
        [styles.wizardLiDisabled]: !enabled
    });

    return (
        <li key={id} className={sidebarLinkStyle}>
            <Link to={`/faq#${id}`}>{translator(title)}</Link>
        </li>
    );
}

function checkSafeMatching(phrase = '', query = '') {
    const htmlStrippedPhrase = _.unescape(phrase.replace(/<[^<>]+>/gi, ''));
    return htmlStrippedPhrase.toLocaleLowerCase().includes(query.toLowerCase());
}

const searchTopic = (topic, query, translator) => {
    const questionMatching = checkSafeMatching(
        translator(_.find(messages, { id: topic.question })),
        query
    );
    const answerMatching = checkSafeMatching(
        translator(_.find(messages, { id: topic.long })),
        query
    );
    const linkMatching = checkSafeMatching(
        translator(_.find(messages, { id: _.get(topic, 'link.name', '') })),
        query
    );

    return questionMatching || answerMatching || linkMatching;
};

const filterTopics = (topics, query, translator) => {
    return topics.filter((topic) => searchTopic(topic, query, translator));
};

const getFilteredSections = (sections, query, translator) => {
    return sections
        .map((section) => {
            return { ...section, topics: filterTopics(section.topics, query, translator) };
        })
        .filter((section) => {
            return !_.isEmpty(section.topics);
        });
};

function FaqPage() {
    const translator = useContext(TranslatorContext);
    const [formData, setFormData] = useState({ searchTopic: '' });

    const filteredSections = useMemo(() => {
        return getFilteredSections(faqConfig.sections, formData.searchTopic, translator);
    }, [formData.searchTopic, translator]);

    const readValue = useCallback(
        (id, path = '') => {
            if (id === 'searchTopicField') {
                return _.get(formData, path);
            }

            if (path && path.includes('filteredSections')) {
                const sectionPath = path.replace('filteredSections', 'sections');
                const newFaqObject = { sections: filteredSections };
                return _.get(newFaqObject, sectionPath);
            }

            return _.get(faqConfig, path);
        },
        [filteredSections, formData]
    );

    const writeValue = useCallback(
        (value, path) => {
            const formDataClone = _.cloneDeep(formData);
            _.set(formDataClone, path, value);
            setFormData(formDataClone);
        },
        [formData, setFormData]
    );

    const generatedOverrides = useCallback(() => {
        const searchTopicText = _.get(formData, 'searchTopic');

        const sectionOverrides = faqConfig.sections.map((section, index) => {
            const linkEnabled = filteredSections.some((item) => item.id === section.id);

            return {
                [`faqSectionContainer${index}`]: {
                    searchText: searchTopicText,
                    messages: messages
                },
                [`faqSidebarSectionPageTitle${index}`]: { enabled: linkEnabled }
            };
        });

        return Object.assign({}, ...sectionOverrides);
    }, [filteredSections, formData]);

    const overrideProps = {
        '@field': {
            onValueChange: writeValue
        },
        ...generatedOverrides()
    };

    const resolvers = {
        resolveValue: readValue,
        resolveComponentMap: {
            faqsidebar: FaqSideBar,
            faqsectioncomponent: FaqSectionComponent
        },
        resolveClassNameMap: styles
    };

    return <div>{renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers)}</div>;
}

FaqSideBar.propTypes = {
    value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    }).isRequired,
    enabled: PropTypes.bool.isRequired
};

export default FaqPage;
