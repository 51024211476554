import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
import { DocumentUploadService, DocumentDownloadService } from 'gw-portals-document-js';

export default class FNOLService {
    static createClaim(searchCriteria, captchaToken, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnol'),
            'createClaim', [searchCriteria, captchaToken], additionalHeaders);
    }

    static saveClaim(fnol, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnol'),
            'saveClaim', [fnol], additionalHeaders);
    }

    static getAvailableIncidentTypesForClaim(lossType, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnol'),
            'getAvailableIncidentTypesForClaim', [lossType], additionalHeaders);
    }

    static getAvailableLossCauses(lossType, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnol'),
            'getAvailableLossCauses', [lossType], additionalHeaders);
    }

    static uploadDocument(file, documentMetadata, additionalHeaders = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('claimDocumentUpload'),
            file, documentMetadata, additionalHeaders);
    }

    static claimsRemoveDocument(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnolDocs'),
            'removeDocument',
            params, additionalHeaders);
    }

    static getClaimDocument(documentID, token) {
        const link = DocumentDownloadService.getDocumentLink(getProxiedServiceUrl('claimDownloadDocument'), documentID, token);
        return DocumentDownloadService.getDocument(link, {}, console.error);
    }

    static submitClaim(fnol, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnol'),
            'submitClaim', [fnol], additionalHeaders);
    }

    static getSuggestions(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnolAddress'),
            'getSuggestions', data, additionalHeaders);
    }

    static searchPolicy(searchCriteria, captchaToken, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnol'),
            'searchPolicy', [searchCriteria, captchaToken], additionalHeaders);
    }

    static requestToken(fnolTokenRequest, country, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnol'),
            'requestToken', [fnolTokenRequest, country], additionalHeaders);
    }

    static verifyTokenAndCreateClaim(createClaimRequest, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnol'),
            'verifyTokenAndCreateClaim', [createClaimRequest], additionalHeaders);
    }

    static getAvailableConsents(country, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnol'),
            'getAvailableConsents', [country], additionalHeaders);
    }
}
