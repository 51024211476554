import _ from 'lodash';
import React, {
    useEffect, useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import metadata from './VehicleIncident.metadata.json5';
import summaryMetadata from '../../../summary/incidents/VehicleIncidentSummary.metadata.json5';
import styles from './VehicleIncident.module.scss';
import Person from '../../../Person/Person';
import Vehicle from '../Vehicle/Vehicle';
import Passenger from '../Passenger/Passenger';
import './VehicleIncident.messages';
import SummaryContext from '../../../summary/SummaryContext';

function FNOLVehicleIncident(props) {
    const {
        id,
        onValidate,
        data: incidentVM,
        onValueChange,
        path,
        showErrors
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        registerComponentValidation(() => {
            return incidentVM.aspects.valid && incidentVM.aspects.subtreeValid;
        });
    }, [incidentVM.aspects.subtreeValid, incidentVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (_.isNil(_.get(incidentVM.value, 'isVehicleStolen'))) {
            _.set(incidentVM.value, 'isVehicleStolen', false);
        }
        if (_.isNil(_.get(incidentVM.value, 'isVehicleLocked'))) {
            _.set(incidentVM.value, 'isVehicleLocked', false);
        }
        if (_.isNil(_.get(incidentVM.value, 'isAlcoholNarcoticsPositiveResult'))) {
            _.set(incidentVM.value, 'isAlcoholNarcoticsPositiveResult', false);
        }

        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const addPassenger = useCallback(() => {
        incidentVM.value.createPassenger();
        handleValueChange(incidentVM.value.passengers, 'passengers');
    }, [incidentVM, handleValueChange]);

    const removePassenger = useCallback((passenger, index) => {
        incidentVM.value.removePassenger(passenger.value);
        handleValueChange(incidentVM.value.passengers, 'passengers');
        disregardFieldValidation(`fnolVehicleIncidentPassenger${index}`);
    }, [incidentVM, handleValueChange, disregardFieldValidation]);

    const addDriver = useCallback(() => {
        incidentVM.value.createDriver();
        handleValueChange(incidentVM.value.driver, 'driver');
    }, [incidentVM, handleValueChange]);

    const removeDriver = useCallback(() => {
        _.unset(incidentVM.value, 'driver');
        handleValueChange(incidentVM.value.driver, 'driver');
        disregardFieldValidation('fnolVehicleIncidentDriver');
    }, [incidentVM, handleValueChange, disregardFieldValidation]);

    const generateOverrides = useCallback(() => {
        const overrides = incidentVM.passengers.children.map(
            (passenger, index) => {
                return {
                    [`fnolVehicleIncidentPassenger${index}`]: {
                        onRemovePassenger: () => removePassenger(passenger, index),
                        value: passenger
                    }
                };
            }
        );
        return Object.assign({}, ...overrides);
    }, [incidentVM, removePassenger]);

    const cleanFieldsAndUpdateModel = useCallback((fieldsToClean) => {
        fieldsToClean.forEach((fieldKey) => {
            _.unset(incidentVM.value, fieldKey);
            handleValueChange(incidentVM.value[fieldKey], fieldKey);
        });
    }, [incidentVM, handleValueChange]);

    const cleanThirdPartyFields = useCallback(() => {
        const fieldsToClean = [
            'make',
            'model',
            'fuelType',
            'engineCapacity',
            'numberOfSeats',
            'firstRegistrationDate',
            'vehicleType'
        ];
        cleanFieldsAndUpdateModel(fieldsToClean);
    }, [cleanFieldsAndUpdateModel]);

    const cleanInsuredFields = useCallback(() => {
        const fieldsToClean = [
            'vehicleType'
        ];
        cleanFieldsAndUpdateModel(fieldsToClean);
    }, [cleanFieldsAndUpdateModel]);

    const lossPartyTypeChanged = useCallback((value, changedPath) => {
        if (value === 'insured') {
            cleanThirdPartyFields();
        } else if (value === 'third_party') {
            cleanInsuredFields();
        }
        handleValueChange(value, changedPath);
    }, [handleValueChange, cleanInsuredFields, cleanThirdPartyFields]);

    const cleanVehicleStolenFields = useCallback(() => {
        const fieldsToClean = [
            'theftDetection',
            'lastVehicleUsage',
            'lastVehicleUserFirstName',
            'lastVehicleUserLastName',
            'isVehicleLocked',
            'usedAntiTheftDeviceDesc'
        ];
        cleanFieldsAndUpdateModel(fieldsToClean);
    }, [cleanFieldsAndUpdateModel]);

    const isVehicleStolenChanged = useCallback((value, changedPath) => {
        if (value === false) {
            cleanVehicleStolenFields();
        } else if (value === true) {
            _.set(incidentVM.value, 'isVehicleLocked', true);
        }
        handleValueChange(value, changedPath);
    }, [cleanVehicleStolenFields, handleValueChange, incidentVM]);

    const isDriverPersisted = _.get(incidentVM.value, 'driver.tempID') !== undefined || _.get(incidentVM.value, 'driver.publicID') !== undefined;

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        fnolVehicleIncidentVehicleStolen: {
            onValueChange: isVehicleStolenChanged
        },
        fnolVehicleIncidentVehicleStolenDiv: {
            visible: incidentVM.value.isVehicleStolen
        },
        fnolVehicleIncidentLastVehicleUsage: {
            visible: !readOnly || _.get(incidentVM.value, 'lastVehicleUsage') !== undefined
        },
        fnolVehicleIncidentUsedAntiTheftSystem: {
            visible: !readOnly || _.get(incidentVM.value, 'usedAntiTheftDeviceDesc') !== undefined
        },
        fnolVehicleIncidentLossPartyType: {
            value: _.get(incidentVM.value, 'lossPartyType'),
            onValueChange: lossPartyTypeChanged
        },
        fnolVehicleIncidentAlcoholNarcotics: {
            visible: !incidentVM.value.isVehicleStolen
        },
        fnolVehicleIncidentVehicle: {
            visible: _.get(incidentVM.value, 'lossPartyType') !== undefined,
            isInsuredLoss: _.get(incidentVM.value, 'lossPartyType') === 'insured'
        },
        fnolVehicleIncidentDriver: {
            isEmailPhoneInfoMessageVisible: true,
            visible: isDriverPersisted
        },
        fnolVehicleIncidentAddDriverDiv: {
            visible: !isDriverPersisted
        },
        fnolVehicleIncidentRemoveDriverDiv: {
            visible: isDriverPersisted
        },
        fnolVehicleIncidentSummaryDriversHeader: {
            visible: !incidentVM.value.isVehicleStolen && (!readOnly || (isDriverPersisted))
        },
        fnolVehicleIncidentDriversDiv: {
            visible: !incidentVM.value.isVehicleStolen && (!readOnly || (isDriverPersisted))
        },
        fnolVehicleIncidentSummaryPassengersHeader: {
            visible: !incidentVM.value.isVehicleStolen && (!readOnly || _.get(incidentVM.value, 'passengers').length > 0)
        },
        fnolVehicleIncidentPassengersDiv: {
            visible: !incidentVM.value.isVehicleStolen && (!readOnly || _.get(incidentVM.value, 'passengers').length > 0)
        },
        fnolVehicleIncidentClaimMileage: {
            visible: _.get(incidentVM.value, 'lossPartyType') !== undefined && (!readOnly || _.get(incidentVM.value, 'odomRead') !== undefined)
        },
        fnolVehicleIncidentVehicleType: {
            visible: _.get(incidentVM.value, 'lossPartyType') !== undefined && (!readOnly || _.get(incidentVM.value, 'vehicleType') !== undefined)
        },
        fnolVehicleIncidentVehicleLocation: {
            visible: !readOnly || _.get(incidentVM.value, 'vehicleLocationContacts') !== undefined
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            addPassenger,
            addDriver,
            removeDriver,
            onValidate: setComponentValidation
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            person: Person,
            vehicle: Vehicle,
            passenger: Passenger
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={readOnly ? summaryMetadata.componentContent : metadata.componentContent}
                model={incidentVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

FNOLVehicleIncident.propTypes = {
    data: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    showErrors: PropTypes.bool.isRequired
};

export default FNOLVehicleIncident;
