import moment from 'moment';

function addMonths(dto, monthsToAdd) {
    const date = moment(dto).add(monthsToAdd, 'month');
    return {
        day: date.date(),
        month: date.month(),
        year: date.year()
    };
}

function toDate(dto) {
    return moment(dto).toDate();
}
/**
 * This class is used in conjunction with the Expression Language only. Do not call directly.
 */
export default {
    addMonths,
    toDate
};
