import _ from 'lodash';
import React, {
    useCallback,
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef
} from 'react';
import {
    WizardPage,
    wizardProps,
    WizardPageTemplate
} from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { claimsMessages } from 'gw-capability-claim-react';
import { ModalNextProvider, IconButton } from '@jutro/components';
import { messages as commonMessages } from 'gw-platform-translations';
import {
    CountryLayerService,
    TagManagerService,
    TrackingConstants,
    ContactInfoService
} from 'cnd-portals-util-js';
import { useAppInsights, useStoredCountry } from 'cnd-common-hooks-platform-react';
import StepHeader from '../../components/StepHeader/StepHeader';
import useErrorHandler from '../../hooks/useErrorHandler';
import fnolCommonMessages from '../../FNOL.messages';
import metadata from './AdditionalInformationPage.metadata.json5';
import Officials from '../../components/Officials/Officials';
import Witnesses from '../../components/Witnesses/Witnesses';
import InsuredPerson from '../../components/InsuredPerson/InsuredPerson';
import SchoolInformation from '../../components/SchoolInformation/SchoolInformation';
import Claim from '../../models/Claim';
import styles from './AdditionalInformationPage.module.scss';
import messages from './AdditionalInformationPage.messages';
import INCIDENT_TYPES from '../../utils/IncidentsMapperUtil';

function FNOLAdditionalInformationPage(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const { FNOLService } = useDependencies('FNOLService');
    const { ClaimService } = useDependencies('ClaimService');
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const ErrorHandler = useErrorHandler();
    const [documentType, setDocumentType] = useState(null);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const ALLOWED_DOCUMENT_TYPES = [
        'assessment_Cnd',
        'assistance_Cnd',
        'coinsurance_Cnd',
        'company_Cnd',
        'invoice_Cnd',
        'medical_Cnd',
        'official_Cnd',
        'other',
        'personal_Cnd',
        'property_Cnd',
        'travel_Cnd',
        'vehicle_Cnd'
    ];

    const {
        wizardData: claimVM,
        updateWizardData,
        authHeader
    } = props;

    const {
        onValidate,
        isComponentValid,
        initialValidation,
        disregardFieldValidation
    } = useValidation('AdditionalInformationPage');

    const isInsuredPersonRequiredForIncident = _.get(claimVM, 'isInsuredPersonRequired.value', false);
    const isGroupPolicy = _.get(claimVM, 'isGroupPolicy.value', false);
    const displayInsuredPersonRef = useRef(_.isNil(_.get(claimVM, 'insuredPerson.lastName.value')));
    const appInsights = useAppInsights();

    useEffect(() => {
        if (_.get(claimVM.value, 'isSchoolPolicy', false)) {
            claimVM.value.createSchoolInformation();
        }
        const insuredPerson = _.get(claimVM, 'insuredPerson.value');
        if ((isInsuredPersonRequiredForIncident === true || isGroupPolicy) && _.isNil(insuredPerson)) {
            const newClaimVM = _.clone(claimVM);
            newClaimVM.insuredPerson.value = {};
            updateWizardData(newClaimVM);
        }
    }, [claimVM, isInsuredPersonRequiredForIncident, updateWizardData]);

    const handleSaveClaimData = useCallback(() => {
        setIsLoading(true);
        const claim = claimVM.value;
        return FNOLService.saveClaim(claim)
            .then((response) => {
                claimVM.value = new Claim(response);
                return claimVM;
            })
            .catch((error) => {
                const claimNumber = claimVM.claimNumber.value;
                ErrorHandler.handleError(error, claimNumber);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, ErrorHandler, claimVM]);

    const onNext = useCallback(() => {
        TagManagerService.pushNextButtonClick(TrackingConstants.STEPS.ADDITIONAL_INFORMATION);
        return handleSaveClaimData();
    }, [handleSaveClaimData]);

    const handleError = useCallback(
        (title, message, error) => {
            appInsights.trackException(error, claimVM.claimNumber.value);
            return ModalNextProvider.showAlert({
                title: title,
                message: message,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }, []
    );

    const claimsDocUploadToken = useCallback(async () => {
        try {
            const uploadTokenID = await ClaimService.claimsDocUploadToken([], authHeader);
            return uploadTokenID;
        } catch (e) {
            return handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken,
                e
            );
        }
    }, [ClaimService, authHeader, handleError]);

    const onUploadDocument = useCallback(async (file) => {
        setIsUploadLoading(true);
        const documentMetaDataTemplate = {
            DocUID: '001',
            DocumentType: 'fnol',
            SecurityType: 'unrestricted',
            Status: 'approved',
            Author: 'Policy Holder',
            claimNumber: claimVM.claimNumber.value,
            name: file.name,
            mimeType: file.type,
            sessionID: await claimsDocUploadToken(),
            documentType_Cnd: documentType
        };
        try {
            const data = await FNOLService.uploadDocument(
                file,
                documentMetaDataTemplate,
                authHeader
            );
            const documentsArray = _.get(claimVM, 'documents.value');
            documentsArray.push(data);
            const newClaimVM = _.clone(claimVM);
            _.set(newClaimVM, 'documents.value', documentsArray);
            updateWizardData(newClaimVM);
            setDocumentType(null);
        } catch (error) {
            handleError(
                commonMessages.errorUploadTitle,
                messages.fnolAdditionalInformationFailedUpload,
                error
            );
        } finally {
            setIsUploadLoading(false);
        }
    },
    [
        claimVM,
        claimsDocUploadToken,
        FNOLService,
        authHeader,
        updateWizardData,
        handleError,
        documentType
    ]);

    const onDeleteDocumentIcon = useCallback(
        (e, item) => {
            e.preventDefault();
            const { claimNumber, publicID, name } = item;
            ModalNextProvider.showConfirm({
                title: claimsMessages.confirmationToRemoveTitle,
                // eslint-disable-next-line max-len
                message: translator(fnolCommonMessages.fnolRemoveUploadedDocument, { documentName: name }),
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: claimsMessages.removeConfirmationYes,
                cancelButtonText: claimsMessages.removeConfirmationNo
            }).then(async (results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                setIsUploadLoading(true);
                try {
                    const isDeleteItem = await FNOLService.claimsRemoveDocument(
                        [claimNumber, publicID],
                        authHeader
                    );
                    if (isDeleteItem === false) {
                        setIsUploadLoading(false);
                        return handleError(
                            claimsMessages.deletionFailedTitle,
                            claimsMessages.deletionFailedMessage,
                            'unable to delete document'
                        );
                    }
                    const documentsArray = _.get(claimVM, 'documents.value');
                    const updatedDocArray = documentsArray.filter((documentObj) => {
                        return documentObj.publicID !== publicID;
                    });
                    const newClaimVM = _.clone(claimVM);
                    _.set(newClaimVM, 'documents.value', updatedDocArray);
                    updateWizardData(newClaimVM);
                    setIsUploadLoading(false);
                    return true;
                } catch (documentDeletionError) {
                    setIsUploadLoading(false);
                    return handleError(
                        claimsMessages.removeServiceFailedTitle,
                        claimsMessages.removeServiceFailedMessage,
                        documentDeletionError
                    );
                }
            }, _.noop);
        },
        [translator, FNOLService, authHeader, claimVM, updateWizardData, handleError]
    );

    const getExampleDocumentsData = useCallback(() => {
        const incidents = _.values(INCIDENT_TYPES);
        const incidentsData = _.get(claimVM.value, 'incidentsData');
        const collectionNames = incidents.map((i) => i.collectionName);
        const keys = collectionNames
            .reduce((acc, collectionName) => {
                let incident;
                if (!_.isEmpty(incidentsData[collectionName])) {
                    incident = incidents
                        .find((inc) => inc.collectionName === collectionName);

                    if (incident.constructorClassName === 'GeneralIncident') {
                        const generalIncidents = [];
                        incidentsData[collectionName].forEach((generalIncident) => {
                            if (!_.isNil(generalIncident.carrierCompensated)) {
                                generalIncidents.push('GeneralNonPetIncident');
                            } else {
                                generalIncidents.push('GeneralPetIncident');
                            }
                        });

                        return [...acc, ...generalIncidents];
                    }
                }
                return !incident ? acc : [...acc, incident.constructorClassName];
            }, []);

        return _.uniq(keys)
            .map((key) => ({
                title: `${translator(messages[`fnolAdditionalInformationPage${key}Title`])}`,
                documents: translator(messages[`fnolAdditionalInformationPage${key}Documents`])
            }));
    }, [translator, claimVM.value]);

    const documentTypes = useMemo(() => {
        const types = _.keys(messages).reduce((acc, curr) => {
            return curr.startsWith('fnolAdditionalInformationPageDocumentTypesType') ? { ...acc, [curr]: messages[curr] } : acc;
        }, {});
        return _.values(types);
    }, []);

    const generateDocumentTypesOverrides = useCallback(() => {
        const overrides = documentTypes.map((item, index) => ({
            [`fnolAdditionalInformationPageDocumentTypesLi${index}`]: {
                content: translator(item)
            }
        }));
        return Object.assign({}, ...overrides);
    }, [documentTypes, translator]);

    const generateExampleDocumentsOverrides = useCallback(() => {
        const overrides = getExampleDocumentsData().map((item, index) => {
            return {
                [`fnolAdditionalInformationPageExampleDocumentsRowIncidentName${index}`]: {
                    value: item.title,
                },
                [`fnolAdditionalInformationPageExampleDocumentsRowDocuments${index}`]: {
                    value: item.documents
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [getExampleDocumentsData]);

    const handleDocumentDownload = useCallback(
        (item) => {
            if (_.isNil(item)) {
                return null;
            }
            const { workingPublicID, sessionID } = item;
            FNOLService
                .getClaimDocument(workingPublicID, sessionID);
            return null;
        },
        [FNOLService]
    );

    const removeWitness = useCallback((witness) => {
        const newClaimVM = _.clone(claimVM);
        newClaimVM.value.removeFromWitnesses(witness);
        if (newClaimVM.value.witnesses.length === 0) {
            disregardFieldValidation('fnolAdditionalInformationPageWitnesses');
        }
        updateWizardData(newClaimVM);
    }, [claimVM, updateWizardData, disregardFieldValidation]);

    const removeOfficial = useCallback((official) => {
        const newClaimVM = _.clone(claimVM);
        newClaimVM.value.removeFromOfficials(official);
        if (newClaimVM.value.relatedOfficials.length === 0) {
            disregardFieldValidation('fnolAdditionalInformationPageOfficials');
        }
        updateWizardData(newClaimVM);
    }, [claimVM, updateWizardData, disregardFieldValidation]);

    const getNameColumn = (rowData, index) => {
        const value = _.get(claimVM.value, `documents[${index}].name`);
        return (
            <span className={styles.textWrap}>{value}</span>
        );
    };

    const getTypeColumn = (rowData, index) => {
        const type = _.get(claimVM.value, `documents[${index}].documentType`);
        const value = translator({
            id: `typekey.DocumentType.${type}`,
            defaultMessage: type
        });

        return (
            <span className={styles.textWrap}>{value}</span>
        );
    };

    const getPreviewIcon = (item) => {
        return (
            <IconButton
                icon="mi-archive"
                onClick={() => handleDocumentDownload(item)}
            />
        );
    };

    const getRemoveIcon = (item) => {
        return (
            <IconButton
                icon="mi-delete"
                onClick={(e) => onDeleteDocumentIcon(e, item)}
            />
        );
    };

    const getTableOverrides = () => {
        return {
            fnolAdditionalInformationPageDocumentsTableNameColumn: {
                onCell: getNameColumn
            },
            fnolAdditionalInformationPageDocumentsTableTypeColumn: {
                onCell: getTypeColumn
            },
            fnolAdditionalInformationPageDocumentsTablePreviewColumn: {
                onCell: getPreviewIcon
            },
            fnolAdditionalInformationPageDocumentsTableRemoveColumn: {
                onCell: getRemoveIcon
            }
        };
    };

    const overrides = {
        fnolAdditionalInformationPageLoader: {
            loaded: !isLoading
        },
        fnolAdditionalInformationPageUploadLoader: {
            loaded: !isUploadLoading
        },
        fnolAdditionalInformationPageContainer: {
            visible: !isLoading
        },
        fnolAdditionalInformationPageWitnessesDiv: {
            visible: _.get(claimVM.value, 'witnesses').length > 0
        },
        fnolAdditionalInformationPageWitnesses: {
            onRemoveWitness: removeWitness
        },
        fnolAdditionalInformationPageOfficialsDiv: {
            visible: _.get(claimVM.value, 'relatedOfficials').length > 0
        },
        fnolAdditionalInformationPageOfficials: {
            onRemoveOfficial: removeOfficial
        },
        fnolAdditionalInformationPageUploadDocuments: {
            visible: !isUploadLoading,
            disabled: _.isNull(documentType)
        },
        fnolAdditionalInformationPageExampleDocumentsContainer: {
            data: getExampleDocumentsData()
        },
        fnolAdditionalInformationPageInfo6: {
            href: ContactInfoService.getClaimsInfoWebsiteLink(appCountry),
            target: '_blank'
        },
        fnolAdditionalInformationPageGroupInsuranceInfoContainer: {
            visible: CountryLayerService.showGroupInsuranceTextOnAdditionalInfoPage(appCountry)
        },
        fnolAdditionalInformationPageInfo8: {
            href: ContactInfoService.getClaimsInfoWebsiteLink(appCountry)
        },
        fnolAdditionalInformationPageInsuredPersonContainer: {
            visible: (
                    isInsuredPersonRequiredForIncident 
                        && !_.isNil(_.get(claimVM, 'insuredPerson.value')) 
                        && displayInsuredPersonRef.current
                ) || isGroupPolicy
        },
        fnolAdditionalInformationPageInsuredPerson: {
            relatedContacts: claimVM.value.relatedContacts,
            updateWizardData: updateWizardData
        },
        fnolAdditionalInformationPageDocumentType: {
            value: documentType,
            onValueChange: setDocumentType,
            availableValues: viewModelService.productMetadata.get('cc').types.getTypelist('typekey.DocumentType').codes
                .filter((typecode) => ALLOWED_DOCUMENT_TYPES.includes(typecode.code))
                .map((typecode) => ({
                    ...typecode,
                    name: translator({
                        id: `typekey.DocumentType.${typecode.code}`,
                        defaultMessage: typecode.name
                    })
                })),
        },
        fnolAdditionalInformationPageDocumentsTable: {
            data: _.get(claimVM.value, 'documents'),
            visible: _.get(claimVM.value, 'documents').length > 0
        },
        fnolAdditionalInformationPageDocumentTypesUl: {
            data: documentTypes
        },
        fnolAdditionalInformationAccordionCard: {
            chevron: true,
            header: (
                <span className="smallerText">
                    {translator(messages.fnolAdditionalInformationPageDocumentTypes)}
                </span>
            )
        },
        fnolAdditionalInformationPageStepHeader: {
            claimNumber: _.get(claimVM.value, 'claimNumber'),
            title: translator(messages.fnolAdditionalInformation)
        },
        fnolAdditionalInformationSchoolInformationContainer: {
            visible: _.get(claimVM.value, 'isSchoolPolicy', false)
        },
        ...generateExampleDocumentsOverrides(),
        ...getTableOverrides(),
        ...generateDocumentTypesOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    const addNewOfficial = useCallback(() => {
        claimVM.value.addOfficial();
        _.set(claimVM.value, 'relatedOfficials', claimVM.value.relatedOfficials);
        updateWizardData(claimVM);
    }, [updateWizardData, claimVM]);

    const addNewWitness = useCallback(() => {
        claimVM.value.addWitness();
        _.set(claimVM.value, 'witnesses', claimVM.value.witnesses);
        updateWizardData(claimVM);
    }, [updateWizardData, claimVM]);

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            addNewOfficial,
            addNewWitness,
            onUploadDocument,
        },
        resolveComponentMap: {
            officials: Officials,
            witnesses: Witnesses,
            insuredperson: InsuredPerson,
            schoolinformation: SchoolInformation,
            stepheader: StepHeader
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={resolvers.resolveValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLAdditionalInformationPage.propTypes = wizardProps;
export default withRouter(FNOLAdditionalInformationPage);
