import { CountrySpecificUtil } from 'cnd-common-portals-util-js';

export default {
    getAccountNumberTypes: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.CZ],
                action: () => ['CZECH_LOCAL', 'IBAN']
            },
            {
                countries: [CountrySpecificUtil.Countries.HU],
                action: () => ['GIRO', 'IBAN']
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.PL,
                    CountrySpecificUtil.Countries.RO,
                    CountrySpecificUtil.Countries.BG,
                    CountrySpecificUtil.Countries.SK
                ],
                action: () => ['IBAN']
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    isAccountNumberTypeAvailable: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [
                    CountrySpecificUtil.Countries.CZ,
                    CountrySpecificUtil.Countries.HU
                ],
                action: () => true
            },
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => false
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    isCzechLocalAvailable: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.CZ],
                action: () => true
            },
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => false
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    isGiroAvailable: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.HU],
                action: () => true
            },
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => false
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    getAccountNumberMask: (country, isIban) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => ''
            },
            {
                countries: [CountrySpecificUtil.Countries.BG],
                action: () => 'BG99 cccc 9999 99** **** **'
            },
            {
                countries: [CountrySpecificUtil.Countries.CZ],
                action: () => { return isIban ? 'CZ99 9999 9999 9999 9999 9999' : '9999999999'; }
            },
            {
                countries: [CountrySpecificUtil.Countries.SK],
                action: () => 'SK99 9999 9999 9999 9999 9999'
            },
            {
                countries: [CountrySpecificUtil.Countries.HU],
                action: () => { return isIban ? 'HU99 9999 9999 9999 9999 9999 9999' : '99999999-99999999-99999999'; }
            },
            {
                countries: [CountrySpecificUtil.Countries.PL],
                action: () => 'PL99 9999 9999 9999 9999 9999 9999'
            },
            {
                countries: [CountrySpecificUtil.Countries.RO],
                action: () => 'RO99 cccc **** **** **** ****'
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    }
};
