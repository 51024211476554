import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolBaggageIncidentBaggageType: {
        id: 'fnol.components.baggage-incident.Baggage Type',
        defaultMessage: 'Baggage type'
    },
    fnolBaggageIncidentDelayOnly: {
        id: 'fnol.components.baggage-incident.Delay only?',
        defaultMessage: 'Delay only?'
    },
    fnolBaggageIncidentBaggageMissingFrom: {
        id: 'fnol.components.baggage-incident.Baggage missing from',
        defaultMessage: 'Baggage missing from'
    },
    fnolBaggageIncidentBaggageRecoveredOn: {
        id: 'fnol.components.baggage-incident.Baggage recovered on',
        defaultMessage: 'Baggage recovered on'
    },
    fnolBaggageIncidentCompensatedByCarrier: {
        id: 'fnol.components.baggage-incident.Compensated by Carrier?',
        defaultMessage: 'Compensated by Carrier?'
    },
    fnolBaggageIncidentCarrierCompensatedAmount: {
        id: 'fnol.components.baggage-incident.Amount compensated?',
        defaultMessage: 'Amount compensated'
    },
    fnolBaggageIncidentEstimationReceived: {
        id: 'fnol.components.baggage-incident.Repair estimate received?',
        defaultMessage: 'Repair estimate received?'
    },
    fnolBaggageIncidentEstimatedRepairCost: {
        id: 'fnol.components.baggage-incident.Estimated repair cost',
        defaultMessage: 'Estimated repair cost'
    },
    fnolBaggageIncidentEstimatedRepairCostTooltip: {
        id: 'fnol.components.baggage-incident.Please provide the estimated repair cost for items for which you have received it from specialist/repairman.',
        defaultMessage: 'Please provide the estimated repair cost for items for which you have received it from specialist/repairman.'
    },
    fnolBaggageIncidentContentLineItemsTitle: {
        id: 'fnol.components.baggage-incident.Baggage & Contents line items',
        defaultMessage: 'Baggage & Contents line items'
    },
    fnolBaggageIncidentContentLineItemsAddBtn: {
        id: 'fnol.components.baggage-incident.Add',
        defaultMessage: 'Add'
    },
    fnolBaggageIncidentQuantityColumn: {
        id: 'fnol.components.baggage-incident.Quantity',
        defaultMessage: 'Quantity'
    },
    fnolBaggageIncidentDescriptionColumn: {
        id: 'fnol.components.baggage-incident.Description',
        defaultMessage: 'Description'
    },
    fnolBaggageIncidentCategoryColumn: {
        id: 'fnol.components.baggage-incident.Category',
        defaultMessage: 'Category'
    },
    fnolBaggageIncidentPurchaseCostColumn: {
        id: 'fnol.components.baggage-incident.Purchase Cost',
        defaultMessage: 'Purchase Cost'
    },
    fnolBaggageIncidentPurchaseDateColumn: {
        id: 'fnol.components.baggage-incident.Purchase Date',
        defaultMessage: 'Purchase Date'
    },
    fnolBaggageIncidentDamageDescription: {
        id: 'fnol.components.baggage-incident.Damage description',
        defaultMessage: 'Damage description'
    },
    fnolBaggageIncidentDelayReason: {
        id: 'fnol.components.baggage-incident.Delay reason',
        defaultMessage: 'Delay reason'
    },
    fnolBaggageIncidentIfTheBaggageWas: {
        id: 'fnol.components.baggage-incident.If the baggage was recovered please provide details about the recovery date and time',
        defaultMessage: 'If the baggage was recovered please provide details about the recovery date and time'
    },
    fnolBaggageIncidentProvideHere: {
        id: 'fnol.components.baggage-incident.Provide here a list of personal belongings (documents, clothes, toiletries, camera, laptop, etc.) that have been damaged or lost along with your baggage.',
        defaultMessage: 'Provide here a list of personal belongings (documents, clothes, toiletries, camera, laptop, etc.) that have been damaged or lost along with your baggage.'
    },
    fnolBaggageIncidentAddItems: {
        id: 'fnol.components.baggage-incident.To start filling the table click on the "Add" button, and each time when you want to add a new line.',
        defaultMessage: 'To start filling the table click on the "Add" button, and each time when you want to add a new line.'
    },
    fnolBaggageIncidentCarrierCompensated: {
        id: 'fnol.components.baggage-incident.Carrier compensated amount',
        defaultMessage: 'Carrier compensated amount'
    },
    fnolBaggageIncidentOnTheNextStep: {
        id: 'fnol.components.baggage-incident.On the next step remember to attach proof of ownership of listed items.',
        defaultMessage: 'On the next step remember to attach proof of ownership of listed items.'
    }
});
