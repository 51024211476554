import _ from 'lodash';
import { useMemo } from 'react';
import messages from '../StaticInformation.messages';

const staticInformation = {
    searchPolicyPage: {
        pl: [
            {
                id: messages.searchPolicyPageMessagePL,
                email: messages.searchPolicyPageEmailPL,
                email2: messages.searchPolicyPageEmail2PL
            },
        ],
        ro: [
            {
                id: messages.searchPolicyPageMessageRO,
                linkDescription: messages.searchPolicyPageLinkDescriptionRO,
                link: messages.searchPolicyPageLinkRO
            }
        ],
        sk: [
            {
                id: messages.searchPolicyPageMessageSK,
                email: messages.searchPolicyPageEmailSK
            }
        ],
        cz: [
            {
                id: messages.searchPolicyPageMessageCZ,
                email: messages.searchPolicyPageEmailCZ
            }
        ],
        bg: [
            {
                id: messages.searchPolicyPageMessageBG,
                linkDescription: messages.searchPolicyPageLinkBG,
                link: messages.searchPolicyPageLinkBG
            }
        ]
    },
};

function useStaticInformation(step, country) {
    return useMemo(() => {
        if (!(step && country)) return [];
        const information = _.get(staticInformation, [step, country]);
        if (_.isNil(information)) return [];
        return information;
    }, [step, country]);
}

export default useStaticInformation;
