import PropTypes from 'prop-types';
import React from 'react';
import { ContactInfoService } from 'cnd-portals-util-js';
import { CountryLayerCore } from 'cnd-common-portals-util-js';
import DocumentUploadNoticeAll from './DocumentUploadNotice';
import DocumentUploadNoticePL from './DocumentUploadNotice.PL';
import DocumentUploadNoticeHU from './DocumentUploadNotice.HU';


function DocumentUploadNotice(props) {
    const { country } = props
    const contactEmail = ContactInfoService.getContactEmail(country);

    switch (props.country) {
        case CountryLayerCore.Countries.PL:
            return <DocumentUploadNoticePL {...props} contactEmail={contactEmail} />;
        case CountryLayerCore.Countries.HU:
            return <DocumentUploadNoticeHU {...props} contactEmail={contactEmail} />;
        default:
            return <DocumentUploadNoticeAll {...props} contactEmail={contactEmail} />;
    }
}

DocumentUploadNotice.propTypes = {
    country: PropTypes.string.isRequired
};

export default DocumentUploadNotice;
