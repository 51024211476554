import React, {
    useContext, useState, useCallback, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, InlineLoader, Tooltip } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { pageTemplateProps } from '../prop-types/wizardPropTypes';

import styles from './DigitalWizardPageTemplate.module.scss';
import WizardSidebar from '../components/WizardSidebar';
import MobileWizardSteps from '../components/MobileWizardSteps/MobileWizardSteps';

function renderLoader(isLoading) {
    return isLoading ? <InlineLoader loading={isLoading} className="gw-inline-loader" /> : null;
}

function DigitalWizardPageTemplate(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phone';
    const [showSidebar, setShowSidebar] = useState(false);
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        cancelLabel,
        renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        customMessageModal
    } = props;

    useEffect(() => {
        switch (breakpoint) {
            case 'phone':
                setShowSidebar(false);
                break;
            default:
                setShowSidebar(true);
                break;
        }
    }, [breakpoint]);

    const toggleSideBar = useCallback(() => {
        setShowSidebar(!showSidebar);
    }, [showSidebar]);

    const contextComponent = useCallback(() => {
        return <div className={styles.wizardContext}>{renderContextComponent(wizardSnapshot)}</div>;
    }, [renderContextComponent, wizardSnapshot]);

    const shouldShowButtons = useMemo(() => !(
        showNext === false
        && showPrevious === false
        && showCancel === false
    ), [showCancel, showNext, showPrevious]);

    const isPerformingTransiton = useMemo(() => (
        isLoadingCancel || isLoadingPrevious || isLoadingNext
    ), [isLoadingCancel, isLoadingNext, isLoadingPrevious]);

    const wizardClassName = classNames(styles.wizardMain, {
        [styles.wizardMainShowSidebar]: showSidebar && isPhone
    });

    const nextButton = useMemo(() => (
        <Button
            id="gw-wizard-Next"
            className={styles.nextbutton}
            disabled={disableNext || isPerformingTransiton}
            onClick={onNext}
        >
            {translator(nextLabel)}
            {renderLoader(isLoadingNext)}
        </Button>
    ), [disableNext, isLoadingNext, isPerformingTransiton, nextLabel, onNext, translator]);

    const nextButtonWithTooltip = useMemo(() => (
        <Tooltip placement="bottom" content={nextButtonTooltip}>
            <span>{nextButton}</span>
        </Tooltip>
    ), [nextButton, nextButtonTooltip]);

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;
    const buttonContainerClassName = classNames(styles.navTransitionButtons, {
        [styles.navTransitionButtonOnlyNext]: !showPrevious && isPhone
    });

    return (
        <div className={styles.digitalWizard}>
            {showContextComponent && contextComponent()}
            <div className={wizardClassName}>
                {!isPhone || showSidebar ? (
                    <div className={styles.wizardNavigation}>
                        <WizardSidebar customMessageModal={customMessageModal} />
                        <div className={styles.wizardWidgets}>
                            {renderWidgets(wizardData)}
                        </div>
                    </div>
                ) : null}
                <div className={styles.wizardContent}>
                    {isPhone ? <MobileWizardSteps toggleSideBar={toggleSideBar} /> : null}
                    {showWizardHeader && contextComponent()}
                    {content}
                    <div className={classNames({ [styles.navButtons]: shouldShowButtons })}>
                        {showCancel && (
                            <Button
                                id="gw-wizard-cancel"
                                disabled={disableCancel || isPerformingTransiton}
                                type="tertiary"
                                onClick={onCancel}
                            >
                                {translator(cancelLabel)}
                                {renderLoader(isLoadingCancel)}
                            </Button>
                        )}
                        <div className={buttonContainerClassName}>
                            {showPrevious && (
                                <Button
                                    id="gw-wizard-previous"
                                    disabled={disablePrevious || isPerformingTransiton}
                                    type="secondary"
                                    onClick={onPrevious}
                                >
                                    {translator(previousLabel)}
                                    {renderLoader(isLoadingPrevious)}
                                </Button>
                            )}
                            {showNext && renderNextButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

DigitalWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    renderContextComponent: PropTypes.func,
    /** a function that accepts wizardData to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    nextButtonTooltip: PropTypes.string
};

DigitalWizardPageTemplate.defaultProps = {
    renderContextComponent: () => null,
    renderWidgets: () => null,
    showContextComponent: true,
    showWizardHeader: false,
    nextButtonTooltip: ''
};

export default DigitalWizardPageTemplate;
