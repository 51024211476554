import _ from 'lodash';
import React, {
    useEffect, useContext, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { AddressService } from 'cnd-portals-util-js';
import { MasksUtil } from 'cnd-common-portals-util-js';
import { useValidation } from 'gw-portals-validation-react';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import metadata from './Address.metadata.json5';
import lossLocationReadonlyMetadata from '../summary/AddressSummary/LossLocationAddressSummary.metadata.json5';
import messages from './Address.messages';
import SummaryContext from '../summary/SummaryContext';

export const AddressMode = {
    PERSON_BANK_ACCOUNT_OWNER: "PERSON_BANK_ACCOUNT_OWNER",
    COMPANY_BANK_ACCOUNT_OWNER: "COMPANY_BANK_ACCOUNT_OWNER",
    LOSS_LOCATION: "LOSS_LOCATION"
}

function FNOLAddress(props) {
    const translator = useContext(TranslatorContext);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();


    const {
        id,
        value: addressVM,
        onValueChange,
        onValidate,
        path,
        showErrors,
        mode
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        if (addressVM.country.value === undefined) {
            addressVM.country.value = appCountry;
        }
    }, [addressVM.country.value, appCountry]);

    useEffect(() => {
        registerComponentValidation(() => {
            return addressVM.aspects.valid && addressVM.aspects.subtreeValid;
        });
    }, [addressVM.aspects.subtreeValid, addressVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const availableCountries = useMemo(() => {
        const values = _.get(addressVM, 'country.aspects.availableValues');
        return values && values.map((typecode) => {
            return {
                code: typecode.code,
                name: translator({
                    id: typecode.name,
                    defaultMessage: typecode.name
                })
            };
        });
    }, [addressVM, translator]);

    const cleanCountyOrVoivodeshipField = useCallback(
        (countryCode) => {
            if (!AddressService.isStateVisible(countryCode)) {
                _.unset(addressVM.value, 'state');
                handleValueChange(addressVM.value.state, 'state');
            }
            if (!AddressService.isCountyVisible(countryCode)) {
                _.unset(addressVM.value, 'county');
                handleValueChange(addressVM.value.county, 'county');
            }
        },
        [handleValueChange, addressVM]
    );

    const countryChanged = useCallback(
        (value, changedPath) => {
            cleanCountyOrVoivodeshipField(value);
            handleValueChange(value, changedPath);
        },
        [handleValueChange, cleanCountyOrVoivodeshipField]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        fnolAddressCountry: {
            availableValues: availableCountries,
            onValueChange: countryChanged,
            value: _.get(addressVM.value, 'country'),
            visible: !readOnly || _.get(addressVM.value, 'country') !== undefined
        },
        fnolAddressState: {
            visible: mode !== AddressMode.PERSON_BANK_ACCOUNT_OWNER && mode !== AddressMode.COMPANY_BANK_ACCOUNT_OWNER && AddressService.isStateVisible(_.get(addressVM.value, 'country')) && (!readOnly || _.get(addressVM.value, 'state') !== undefined),
            label: mode === AddressMode.LOSS_LOCATION
                ? translator(messages[`fnolAddress${AddressService.getStateLabelKey(_.get(addressVM.value, 'country'))}OfLoss`])
                : translator(messages[`fnolAddress${AddressService.getStateLabelKey(_.get(addressVM.value, 'country'))}`])
        },
        fnolAddressCounty: {
            visible: AddressService.isCountyVisible(_.get(addressVM.value, 'country')) && (!readOnly || _.get(addressVM.value, 'county') !== undefined)
        },
        fnolAddressApartmentNumber: {
            visible: mode === undefined && (!readOnly || _.get(addressVM.value, 'apartment') !== undefined)
        },
        fnolAddressPostcode: {
            mask: AddressService.getPostalCodeMask(_.get(addressVM.value, 'country')),
            formatChars: MasksUtil.getMaskFormatCharacters(),
            visible: !readOnly && mode !== AddressMode.PERSON_BANK_ACCOUNT_OWNER || _.get(addressVM.value, 'postalCode') !== undefined
        },
        fnolAddressCity: {
            visible: !readOnly && mode !== AddressMode.PERSON_BANK_ACCOUNT_OWNER || _.get(addressVM.value, 'city') !== undefined,
            readOnly
        },
        fnolAddressStreet: {
            readOnly,
            visible: !readOnly && mode !== AddressMode.PERSON_BANK_ACCOUNT_OWNER || _.get(addressVM.value, 'street') !== undefined
        },
        fnolAddressStreetNumber: {
            visible: !readOnly && mode !== AddressMode.PERSON_BANK_ACCOUNT_OWNER || _.get(addressVM.value, 'building') !== undefined
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={readOnly && mode === AddressMode.LOSS_LOCATION
                    ? lossLocationReadonlyMetadata.componentContent
                    : metadata.componentContent}
                model={addressVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

FNOLAddress.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    showErrors: PropTypes.bool.isRequired,
    mode: PropTypes.string
};
FNOLAddress.defaultProps = {
    mode: undefined
};
export default FNOLAddress;
