import {
    useState,
    useCallback,
    useMemo
} from 'react';
import _ from 'lodash';
import { resolveComponentFromName } from '@jutro/uiconfig';

/**
 * Attempts to resolve the string component
 * used in the wizard step. If no match is found or if the `component`
 * is left unchanged
 *
 * @param {WizardStep} stepDefinition a step definition
 * @returns {WizardStep}
 */
function getStepToRender(stepDefinition) {
    const { component, stepProps = {}, ...otherStepDefinitions } = stepDefinition;
    const { template } = stepProps;
    if (!_.isString(component)) {
        // component can be rendered already
        return stepDefinition;
    }
    const { component: resolvedComponent } = resolveComponentFromName(component) || { component };
    const { component: resolvedTemplate } = resolveComponentFromName(template)
        || { component: template };
    return {
        ...otherStepDefinitions,
        component: resolvedComponent,
        stepProps: {
            ...stepProps,
            template: resolvedTemplate
        }
    };
}

/**
 * Increments the `currentStepIndex` without overflowing
 *
 * @param {number} currentStepIndex the current index
 * @param {number} numOfSteps the total number of steps
 * @returns {number} the incremented number
 */
function nextIndex(currentStepIndex, numOfSteps) {
    return _.min([currentStepIndex + 1, numOfSteps - 1]);
}

/**
 * Decrements the `currentStepIndex` without overflowing
 * @param {number} currentStepIndex the current index
 * @returns {number} the decremented number
 */
function previousIndex(currentStepIndex) {
    return _.max([currentStepIndex - 1, 0]);
}

/**
 * Retuns a safe index to jump to.
 * A safe index is one smaller or equal to the `furthestIndexVisited` and greather
 * or equal than 0.
 * If the index is not in range the current step index is returned
 * @param {number} index the step to which to jump to
 * @param {number} currentStepIndex the current step index
 * @param {number} furthestIndexVisited the furthest step already visited
 * @returns {number} a checked index to jump to or the current index
 */
function jumpToIndex(index, currentStepIndex, furthestIndexVisited) {
    const isInRange = _.range(0, furthestIndexVisited + 1) // range is not inclusive
        .includes(index);
    return isInRange ? index : currentStepIndex;
}

/**
 * Replaces steps that are after a certain index with the given ones
 * @param {Array} wizardSteps the current steps for the wizard
 * @param {number} currentStepIndex the index of the current step
 * @param {Array} nextSteps the steps used to replace the existing steps that are
 *                          after the current index
 * @returns {Array}
 */
function replaceSteps(wizardSteps = [], currentStepIndex, nextSteps) {
    return _(wizardSteps)
        .take(currentStepIndex + 1) // index is 0 based
        .value()
        .concat(nextSteps);
}

/**
 * @typedef {Object} WizardStep
 * @property {string} path the relative path at which this step will render
 * @property {string} id the id for the step
 * @property {Object} component the React component to render for this step
 * @property {string} title a string/displayKey describing the title of the page
 * @property {boolean} [visited] whethera step has been visited or not
 * @property {boolean} [visited] whethera step has been submitted or not
 * @property {Object} [stepProps] an object that is passed to the step as properties
 */

/**
 * Returns an array of wizard steps including whether a step has been visited or not
 * @param {Array<WizardStep>} steps the steps in the wizard
 * @param {number} furthestIndexVisited the index of the furthest step in the wizard already visited
 * @param {number} furthestIndexSubmitted the index of the furthest step
 *                                      in the wizard that has been already submitted
 * @returns {Array<WizardStep>}
 */
function withAccessInfo(steps, furthestIndexVisited, furthestIndexSubmitted) {
    return steps
        .map((step, index) => ({
            ...step,
            visited: index <= furthestIndexVisited,
            submitted: _.isNil(furthestIndexSubmitted) ? false : index <= furthestIndexSubmitted
        }));
}

/**
 * @callback parameterlessCallback a function taking no parameters
 *
 * @callback jumpToCallback a function to jump to the next step
 * @param {number} index the index to which to jump to
 *
 * @callback changeNextStepsCallback a function to replace the nexts steps in a wizard
 * @param {Array<WizardStep>} nextSteps steps that should replace those after the current step
 */

/**
 * @typedef {Object} UseWizardStepsHook
 * @property {Array<WizardStep>} steps of the wizard
 * @property {WizardStep} currentStep current step
 * @property {number} currentStepIndex index of the current step
 * @property {parameterlessCallback} goNext function to go to the next step. Index must be
 *                                          between 0 and the furthest step visited
 * @property {parameterlessCallback} goPrevious function to go to the previous step
 * @property {jumpToCallback} jumpTo function to jump to a visited step
 * @property {changeNextStepsCallback} changeNextSteps function to change what are the next steps
 *                                                     in a wizard
 * @property {parameterlessCallback} markStepSubmitted marks a step as submitted
 * @property {parameterlessCallback} clearVisitedStepsAfterCurrent function to clear the visited
 *                                                          status for steps after the current one
 * @property {boolean} isSkipping whether the wizard is skipping pages (goes forward until stopped)
 * @property {parameterlessCallback} stopSkipping a function to stop the wizard from skipping
 */

/**
 * Returns fields to manage
 * @param {Array<WizardStep>} initialSteps the initial steps of this wizard
 * @param {boolean} initialSkipState the initial state for the
 * @returns {UseWizardStepsHook} all the hooks parameters
 */
function useWizardSteps(initialSteps, initialSkipState = false) {
    const [wizardSteps = initialSteps, updateWizardSteps] = useState(undefined);
    const [currentStepIndex, updateCurrentStep] = useState(0);
    const [furthestIndexVisited, updateFurthestIndex] = useState(0);
    const [furthestIndexSubmitted, updateFurthestIndexSubmitted] = useState(undefined);
    const [isSkipping = initialSkipState, updateSkipState] = useState(undefined);

    /*
        Transitions
     */
    const numOfSteps = wizardSteps.length;
    const goNext = useCallback(
        () => {
            const newIndex = nextIndex(currentStepIndex, numOfSteps);
            updateCurrentStep(newIndex);
            updateFurthestIndex(_.max([furthestIndexVisited, newIndex]));
        },
        [currentStepIndex, numOfSteps, furthestIndexVisited]
    );
    const goPrevious = useCallback(
        () => updateCurrentStep(previousIndex(currentStepIndex)),
        [currentStepIndex]
    );
    const jumpTo = useCallback(
        (index) => updateCurrentStep(jumpToIndex(index, currentStepIndex, furthestIndexVisited)),
        [currentStepIndex, furthestIndexVisited]
    );

    /*
        Change steps
     */
    const changeNextSteps = useCallback(
        (nextSteps) => {
            updateWizardSteps(replaceSteps(wizardSteps, currentStepIndex, nextSteps));
            updateFurthestIndex(currentStepIndex);
        },
        [wizardSteps, currentStepIndex]
    );

    /*
        Access
     */
    const clearVisitedStepsAfterCurrent = useCallback(
        () => {
            updateFurthestIndex(currentStepIndex);
            if (furthestIndexSubmitted !== undefined) {
                updateFurthestIndexSubmitted(
                    (currentFurtherIndexSubmitted) => _.min(
                        [currentFurtherIndexSubmitted, currentStepIndex]
                    )
                );
            }
        },
        [currentStepIndex, furthestIndexSubmitted]
    );

    const stepsToRender = useMemo(
        () => wizardSteps.map(getStepToRender),
        [wizardSteps]
    );

    const stepsWithDetails = useMemo(
        () => withAccessInfo(stepsToRender, furthestIndexVisited, furthestIndexSubmitted),
        [stepsToRender, furthestIndexVisited, furthestIndexSubmitted]
    );

    const markStepSubmitted = useCallback(
        () => updateFurthestIndexSubmitted(
            (currentFurtherIndexSubmitted) => _.max(
                [currentFurtherIndexSubmitted, currentStepIndex]
            )
        ),
        [currentStepIndex]
    );

    /*
        Skipping
     */
    const stopSkipping = useCallback(
        () => updateSkipState(false),
        []
    );

    return {
        steps: stepsWithDetails,
        currentStep: stepsWithDetails[currentStepIndex],
        currentStepIndex,
        goNext,
        goPrevious,
        jumpTo,
        changeNextSteps,
        markStepSubmitted,
        clearVisitedStepsAfterCurrent,
        isSkipping,
        stopSkipping,
    };
}

export default useWizardSteps;
