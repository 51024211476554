/* eslint-disable import/no-unresolved */
import _ from 'lodash';
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, DropdownSelectField } from '@jutro/components';
import styles from './CustomHeader.module.scss';

const CustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    locale,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    minYear,
    maxYear
}) => {
    const navigationIconPrevClassName = cx(styles.navigationIconPrev, {
        [styles.navigationIconPrevDisabled]: prevMonthButtonDisabled
    });
    const navigationIconNextClassName = cx(styles.navigationIconNext, {
        [styles.navigationIconNextDisabled]: nextMonthButtonDisabled
    });

    const availableMonths = useMemo(() => {
        const months = [];
        for (let index = 0; index < 12; index += 1) {
            months.push({
                name: locale.localize.month(index, { width: 'full' }),
                code: index
            });
        }
        return months;
    }, [locale]);

    const availableYears = useMemo(() => {
        const range = _.range(minYear, maxYear + 1);
        return _.map(range, (item, index) => ({
            name: item,
            code: index
        }));
    }, [minYear, maxYear]);

    const handleChangeYear = useCallback((value) => {
        const year = availableYears[value].name;
        changeYear(year);
    }, [availableYears, changeYear]);

    return (
        <div className={styles.container}>
            <Icon
                icon="mi-chevron-left"
                tag="button"
                className={navigationIconPrevClassName}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
            />
            <DropdownSelectField
                id="month-dropdown"
                className={styles.monthDropdownSelect}
                showOptional={false}
                usePortal={false}
                value={date.getMonth()}
                availableValues={availableMonths}
                onValueChange={changeMonth}
            />
            <DropdownSelectField
                id="year-dropdown"
                className={styles.yearDropdownSelect}
                searchable
                showOptional={false}
                usePortal={false}
                value={date.getFullYear() - minYear}
                availableValues={availableYears}
                onValueChange={handleChangeYear}
            />
            <Icon
                icon="mi-chevron-right"
                tag="button"
                className={navigationIconNextClassName}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
            />
        </div>
    );
};

CustomHeader.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    changeYear: PropTypes.func.isRequired,
    changeMonth: PropTypes.func.isRequired,
    decreaseMonth: PropTypes.func.isRequired,
    increaseMonth: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    prevMonthButtonDisabled: PropTypes.func.isRequired,
    nextMonthButtonDisabled: PropTypes.func.isRequired,
    minYear: PropTypes.number.isRequired,
    maxYear: PropTypes.number.isRequired
};

export default CustomHeader;
