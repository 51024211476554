import React, {
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './SectionHeader.metadata.json5';
import './SectionHeader.messages';
import styles from './SectionHeader.module.scss';

function SectionHeader(props) {
    const {
        onDeleteClick,
        onOpenClick,
        onEditClick,
        isOpened,
        currentlyOpenedId,
        sectionTitle,
        visibleButtons
    } = props;

    const isButtonVisible = (key) => visibleButtons.includes(key);

    const overrideProps = {
        platformSectionHeaderTitle: {
            content: sectionTitle
        },
        platformSectionHeaderOpenButton: {
            disabled: isOpened,
            visible: isButtonVisible('open')
        },
        platformSectionHeaderDeleteButton: {
            visible: isButtonVisible('delete')
        },
        platformSectionHeaderEditButton: {
            visible: isButtonVisible('edit')
        }
    };


    const deleteClicked = useCallback(
        () => {
            onDeleteClick(currentlyOpenedId);
        },
        [onDeleteClick, currentlyOpenedId]
    );

    const openClicked = useCallback(
        () => {
            onOpenClick(currentlyOpenedId);
        },
        [onOpenClick, currentlyOpenedId]
    );

    const editClicked = useCallback(
        () => {
            onEditClick();
        },
        [onEditClick]
    );

    const resolvers = {
        resolveCallbackMap: {
            deleteClicked,
            openClicked,
            editClicked
        },
        resolveClassNameMap: styles,
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                classNameMap={resolvers.resolveClassNameMap}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

SectionHeader.propTypes = {
    onDeleteClick: PropTypes.func.isRequired,
    onOpenClick: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    currentlyOpenedId: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    onEditClick: PropTypes.func.isRequired,
    visibleButtons: PropTypes.arrayOf(PropTypes.string)
};

SectionHeader.defaultProps = {
    visibleButtons: ['delete', 'open']
};

export default SectionHeader;
