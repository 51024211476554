import React, {
    useEffect, useCallback, useContext, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { AccountNumberService } from 'cnd-portals-util-js';
import { MasksUtil } from 'cnd-common-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import _ from 'lodash';
import messages from './BankAccountDetails.messages';
import metadata from './BankAccountDetails.metadata.json5';
import useErrorTranslator from '../../hooks/useErrorTranslator';
import SummaryContext from '../summary/SummaryContext';
import { useState } from 'react';

const validationErrorMessages = {
    accountNumber: messages.fnolBankAccountDetailsBankInvalidAccountNumber
};

function FNOLBankAccountDetails(props) {
    const {
        id,
        onValidate,
        value: bankAccountDetailsVM,
        onValueChange,
        path,
        policyCountry,
        validationErrors,
    } = props;

    const CZECH_LOCAL_KEY = 'CZECH_LOCAL';
    const GIRO_KEY = 'GIRO';
    const IBAN_KEY = 'IBAN';
    const CZECH_LOCAL_PREFIX_LENGTH = 6;
    const CZECH_LOCAL_ACCOUNT_NUMBER_LENGTH = 10;
    const GIRO_ACCOUNT_NUMBER_LENGTH = 24;
    const translator = useContext(TranslatorContext);
    const translateError = useErrorTranslator(validationErrorMessages);
    const accountNumberTypes = AccountNumberService.getAccountNumberTypes(policyCountry);
    const [bankAccountNumber, setBankAccountNumber] = useState();
    const { readOnly } = useContext(SummaryContext);

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    useEffect(() => {
        registerComponentValidation(() => {
            return bankAccountDetailsVM.aspects.valid && bankAccountDetailsVM.aspects.subtreeValid;
        });
    }, [
        bankAccountDetailsVM.aspects.subtreeValid,
        bankAccountDetailsVM.aspects.valid,
        registerComponentValidation
    ]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = changedPath ? `${path}.${changedPath}` : path;
        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    const isPolicyCountryAndBankCountrySame = useCallback(() => {
        return _.get(bankAccountDetailsVM.value, 'bankAccountCountry') === policyCountry;
    }, [bankAccountDetailsVM, policyCountry]);

    const isCzechLocalAccountType = useCallback(() => {
        return _.get(bankAccountDetailsVM.value, 'bankAccountNumberType') === CZECH_LOCAL_KEY;
    }, [bankAccountDetailsVM]);

    const isGiroAccountType = useCallback(() => {
        return _.get(bankAccountDetailsVM.value, 'bankAccountNumberType') === GIRO_KEY;
    }, [bankAccountDetailsVM]);

    const isIbanAccountType = useCallback(() => {
        return _.get(bankAccountDetailsVM.value, 'bankAccountNumberType') === IBAN_KEY;
    }, [bankAccountDetailsVM]);

    const setDefaultAccountNumberType = useCallback((bankAccountCountry) => {
        if (AccountNumberService.isCzechLocalAvailable(bankAccountCountry)
            && policyCountry === bankAccountCountry) {
            _.set(bankAccountDetailsVM.value, 'bankAccountNumberType', CZECH_LOCAL_KEY);
        } else if (AccountNumberService.isGiroAvailable(bankAccountCountry)
            && policyCountry === bankAccountCountry) {
            _.set(bankAccountDetailsVM.value, 'bankAccountNumberType', GIRO_KEY);
        } else {
            _.set(bankAccountDetailsVM.value, 'bankAccountNumberType', IBAN_KEY);
        }
    }, [bankAccountDetailsVM, policyCountry]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (!_.isNil(_.get(bankAccountDetailsVM.value, 'bankAccountCountry'))) {
            return;
        }
        const defaultBankAccountCountry = _.upperCase(useStoredCountry());
        _.set(bankAccountDetailsVM.value, 'bankAccountCountry', defaultBankAccountCountry);
        setDefaultAccountNumberType(defaultBankAccountCountry);
        handleValueChange(bankAccountDetailsVM.value);
    }, [bankAccountDetailsVM, handleValueChange, setDefaultAccountNumberType]);

    const availableCountries = useMemo(() => {
        const values = _.get(bankAccountDetailsVM, 'bankAccountCountry.aspects.availableValues');
        return values && values.map((typecode) => {
            return {
                code: typecode.code,
                name: translator({
                    id: typecode.name,
                    defaultMessage: typecode.name
                })
            };
        });
    }, [bankAccountDetailsVM, translator]);

    const onBankAccountCountryChange = useCallback((newBankCountry) => {
        setDefaultAccountNumberType(newBankCountry);
        _.unset(bankAccountDetailsVM.value, 'swiftBic');
        _.unset(bankAccountDetailsVM.value, 'accountNumber');
        _.unset(bankAccountDetailsVM.value, 'bankRoutingNumber');
        _.set(bankAccountDetailsVM.value, 'bankAccountCountry', newBankCountry);
        handleValueChange(bankAccountDetailsVM.value);
    }, [handleValueChange, bankAccountDetailsVM, setDefaultAccountNumberType]);

    const onBankAccountNumberTypeChange = useCallback((newBankAccountNumberType) => {
        _.unset(bankAccountDetailsVM.value, 'swiftBic');
        _.unset(bankAccountDetailsVM.value, 'accountNumber');
        _.unset(bankAccountDetailsVM.value, 'bankRoutingNumber');
        _.set(bankAccountDetailsVM.value, 'bankAccountNumberType', newBankAccountNumberType);
        handleValueChange(bankAccountDetailsVM.value);
    }, [handleValueChange, bankAccountDetailsVM]);

    const onBankAccountPrefixBlur = useCallback(() => {
        if (!isCzechLocalAccountType()) {
            return;
        }
        let processedPrefix = _.get(bankAccountDetailsVM.value, 'swiftBic').replaceAll(/[-_]/g, '');
        if (processedPrefix) {
            const zerosToAdd = CZECH_LOCAL_PREFIX_LENGTH - processedPrefix.length;
            if (zerosToAdd > 0) {
                processedPrefix = processedPrefix.padStart(CZECH_LOCAL_PREFIX_LENGTH, '0');
                _.set(bankAccountDetailsVM.value, 'swiftBic', processedPrefix);
                handleValueChange(bankAccountDetailsVM);
            }
        }
    }, [handleValueChange, bankAccountDetailsVM, isCzechLocalAccountType]);

    const addZerosToGiroAccountNumber = useCallback((accountNumber) => {
        let processedAccountNumber = accountNumber;
        if (processedAccountNumber) {
            processedAccountNumber = accountNumber.replaceAll(/[-_]/g, '');
            if (processedAccountNumber.length < 16) return accountNumber;
            const zerosToAdd = GIRO_ACCOUNT_NUMBER_LENGTH - processedAccountNumber.length;
            if (zerosToAdd > 0) {
                processedAccountNumber = processedAccountNumber.padEnd(GIRO_ACCOUNT_NUMBER_LENGTH, '0');
            } else {
                return accountNumber;
            }
            processedAccountNumber = [processedAccountNumber.slice(0, 8), '-', processedAccountNumber.slice(8, 16), '-', processedAccountNumber.slice(16, 24)].join('');
        }
        return processedAccountNumber;
    }, []);

    const addZerosToCzechLocalAccountNumber = useCallback((accountNumber) => {
        let processedAccountNumber = accountNumber;
        if (processedAccountNumber) {
            processedAccountNumber = accountNumber.replaceAll(/[-_]/g, '');
            const zerosToAdd = CZECH_LOCAL_ACCOUNT_NUMBER_LENGTH
                    - processedAccountNumber.length;
            if (zerosToAdd > 0) {
                processedAccountNumber = processedAccountNumber.padStart(CZECH_LOCAL_ACCOUNT_NUMBER_LENGTH, '0');
            } else {
                return accountNumber;
            }
        }
        return processedAccountNumber;
    }, []);

    const onBankAccountNumberChange = useCallback((newValue) => {
        setBankAccountNumber(newValue);
        _.set(bankAccountDetailsVM.value, 'accountNumber', newValue?.replaceAll(' ', ''))
        handleValueChange(bankAccountDetailsVM);
    }, [bankAccountDetailsVM.value, setBankAccountNumber])

    const onBankAccountNumberBlur = useCallback((e) => {
        if (!isCzechLocalAccountType() && !isGiroAccountType()) {
            return;
        }
        const accountNumber = _.get(bankAccountDetailsVM.value, 'accountNumber');
        let processedAccountNumber;
        if (isCzechLocalAccountType()) {
            processedAccountNumber = addZerosToCzechLocalAccountNumber(accountNumber);
        } else if (isGiroAccountType()) {
            processedAccountNumber = addZerosToGiroAccountNumber(accountNumber);
        }
        if (accountNumber !== processedAccountNumber) {
            _.set(bankAccountDetailsVM.value, 'accountNumber', processedAccountNumber);
            handleValueChange(bankAccountDetailsVM);
        }
    }, [
        handleValueChange,
        bankAccountDetailsVM,
        isCzechLocalAccountType,
        isGiroAccountType,
        addZerosToCzechLocalAccountNumber,
        addZerosToGiroAccountNumber
    ]);

    const availableAccountNumberTypes = useMemo(() => {
        const values = _.get(bankAccountDetailsVM, 'bankAccountNumberType.aspects.availableValues');
        return values && values
            .filter((type) => accountNumberTypes.includes(type.code)).map((typecode) => {
                return {
                    code: typecode.code,
                    name: translator({
                        id: typecode.name,
                        defaultMessage: typecode.name
                    })
                };
            });
    }, [bankAccountDetailsVM, translator, accountNumberTypes]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            readOnly
        },
        fnolBankAccountDetailsAccountNumber: {
            value: bankAccountNumber,
            mask: AccountNumberService.getAccountNumberMask(_.get(bankAccountDetailsVM.value, 'bankAccountCountry'), _.get(bankAccountDetailsVM.value, 'bankAccountNumberType') === 'IBAN'),
            formatChars: MasksUtil.getMaskFormatCharacters(),
            showErrors: (translateError(validationErrors?.accountNumber?.path) || []).length > 0,
            validationMessages: _.get(bankAccountDetailsVM, 'accountNumber.aspects.validationMessages', '')
                .concat(translateError(validationErrors?.accountNumber?.path) || []),
            onBlur: onBankAccountNumberBlur,
            onValueChange: onBankAccountNumberChange
        },
        fnolBankAccountDetailsBankAccountNumberType: {
            visible: AccountNumberService.isAccountNumberTypeAvailable(_.get(bankAccountDetailsVM.value, 'bankAccountCountry'))
                && isPolicyCountryAndBankCountrySame(),
            availableValues: availableAccountNumberTypes,
            onValueChange: onBankAccountNumberTypeChange
        },
        fnolBankAccountDetailsAccountCountry: {
            availableValues: availableCountries,
            value: _.get(bankAccountDetailsVM.value, 'bankAccountCountry'),
            onValueChange: onBankAccountCountryChange
        },
        fnolBankAccountDetailsInfo: {
            visible: !readOnly && isIbanAccountType()
        },
        fnolBankAccountDetailsSwiftBic: {
            visible: ((!readOnly && bankAccountDetailsVM.swiftBic.aspects.required)
                || _.get(bankAccountDetailsVM.value, 'swiftBic') !== undefined)
                && !isGiroAccountType() && !isCzechLocalAccountType()
        },
        fnolBankAccountDetailsPrefix: {
            visible: (!readOnly || _.get(bankAccountDetailsVM.value, 'swiftBic') !== undefined)
                && isCzechLocalAccountType(),
            onBlur: onBankAccountPrefixBlur
        },
        fnolBankAccountDetailsBankCode: {
            visible: isCzechLocalAccountType()
        }

    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={bankAccountDetailsVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

FNOLBankAccountDetails.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    policyCountry: PropTypes.string.isRequired,
    validationErrors: PropTypes.objectOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            messages: PropTypes.arrayOf(PropTypes.string).isRequired,
        })
    )
};
FNOLBankAccountDetails.defaultProps = {
    validationErrors: {}
};
export default FNOLBankAccountDetails;
