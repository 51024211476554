import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolPersonFirstName: {
        id: 'fnol.components.person.First Name',
        defaultMessage: 'First Name'
    },
    fnolPersonLastName: {
        id: 'fnol.components.person.Last Name',
        defaultMessage: 'Last Name'
    },
    fnolPersonPhone: {
        id: 'fnol.components.person.Phone',
        defaultMessage: 'Phone'
    },
    fnolPersonEmail: {
        id: 'fnol.components.person.Email',
        defaultMessage: 'Email'
    },
    fnolPersonProvideEmailOrMobileInfo: {
        id: 'fnol.components.person.Please provide an email address or mobile phone, it will be used for the purpose of personal data processing.',
        defaultMessage: 'Please provide an email address or mobile phone, it will be used for the purpose of personal data processing.'
    },
    fnolPersonProvideClaimantContactInfo: {
        id: 'fnol.components.person.To quickly process your claim, please provide your phone number and email below.',
        defaultMessage: 'To quickly process your claim, please provide your phone number and email below.'
    }
});
