import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolSimplePersonFirstName: {
        id: 'fnol.components.simple-person.First Name',
        defaultMessage: 'First Name'
    },
    fnolSimplePersonLastName: {
        id: 'fnol.components.simple-person.Last Name',
        defaultMessage: 'Last Name'
    },
    fnolSimplePersonPhone: {
        id: 'fnol.components.simple-person.Phone',
        defaultMessage: 'Phone'
    },
    fnolSimplePersonEmail: {
        id: 'fnol.components.simple-person.Email',
        defaultMessage: 'Email'
    },
    fnolSimplePersonPhoneOrEmailInfo: {
        id: 'fnol.components.simple-person.Please provide an email address or mobile phone, it will be used for the purpose of personal data processing.',
        defaultMessage: 'Please provide an email address or mobile phone, it will be used for the purpose of personal data processing.'
    },
    fnolSimplePersonProvideClaimantContactInfo: {
        id: 'fnol.components.simple-person.To quickly process your claim, please provide your phone number and email below.',
        defaultMessage: 'To quickly process your claim, please provide your phone number and email below.'
    }
});
