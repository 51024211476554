import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolPaymentDetails: {
        id: 'fnol.components.payment-details.Payment details',
        defaultMessage: 'Payment details'
    },
    fnolPaymentDetailsPagePersonOrCompany: {
        id: 'fnol.components.payment-details.Type of contact',
        defaultMessage: 'Type of contact'
    },
    fnolPaymentDetailsPageNewContact: {
        id: 'fnol.components.payment-details.New Contact',
        defaultMessage: 'New Contact'
    },
    fnolPaymentDetailsPageIndividual: {
        id: 'fnol.components.payment-details.Individual',
        defaultMessage: 'Individual'
    },
    fnolPaymentDetailsPageIndividualEntrepreneurCompany: {
        id: 'fnol.components.payment-details.Individual Entrepreneur/Company',
        defaultMessage: 'Individual Entrepreneur/Company'
    },
    fnolPaymentDetailsPagePaymentReceiver: {
        id: 'fnol.components.payment-details.Payment receiver',
        defaultMessage: 'Payment receiver'
    },
    fnolPaymentDetailsPagePaymentReceiverTooltip: {
        id: 'fnol.components.payment-details.Please let us know, who should receive money, if there will be any indemnity related to this claim.',
        defaultMessage: 'Please let us know, who should receive money, if there will be any indemnity related to this claim.'
    },
    fnolPaymentDetailsPagePaymentReceiverType: {
        id: 'fnol.components.payment-details.Type of payment receiver',
        defaultMessage: 'Type of payment receiver'
    },
    fnolPaymentDetailsPagePaymentReceiverTypeInfo: {
        id: 'fnol.components.payment-details.Type of payment receiver guide',
        defaultMessage: 'Type of payment receiver guide'
    },
    fnolPaymentDetailsPagePaymentReceiverTypeInfo_other: {
        id: 'fnol.components.payment-details.type-of-receiver-info.otherinsured',
        defaultMessage: 'Other - Person or entity covered by relevant policy'
    },
    fnolPaymentDetailsPagePaymentReceiverTypeInfo_claimant: {
        id: 'fnol.components.payment-details.type-of-receiver-info.claimant',
        defaultMessage: 'Claimant - Third Party covered by insured\'s policy'
    },
    fnolPaymentDetailsPagePaymentReceiverTypeInfo_beneficiary_Cnd: {
        id: 'fnol.components.payment-details.type-of-receiver-info.beneficiary',
        defaultMessage: 'Beneficiary - Person or entity that will receive the indemnity in case of insured\'s death'
    },
    fnolPaymentDetailsPagePaymentReceiverTypeInfo_broker_Cnd: {
        id: 'fnol.components.payment-details.type-of-receiver-info.broker',
        defaultMessage: 'Broker - Person or entity (intermediary) who is authorized by insured to receive indemnity'
    },
    fnolPaymentDetailsPagePaymentReceiverTypeInfo_repairshop: {
        id: 'fnol.components.payment-details.type-of-receiver-info.repairshop',
        defaultMessage: 'Repairshop - Person or entity (intermediary) who is authorized by insured to receive indemnity'
    },
    fnolPaymentDetailsPagePaymentReceiverTypeInfo_financialCompany_Cnd: {
        id: 'fnol.components.payment-details.type-of-receiver-info.financialcompany',
        defaultMessage: 'Financial Company - Indemnity should go directly to a financial company based on relevant plegde'
    },
    fnolPaymentDetailsPageReportersContactDetails: {
        id: 'fnol.components.payment-details.Owner of bank account',
        defaultMessage: 'Owner of bank account'
    },
    fnolPaymentDetailsPageCompanyDetails: {
        id: 'fnol.components.payment-details.Company details (bank account owner)',
        defaultMessage: 'Company details (bank account owner)'
    },
    fnolPaymentDetailsBankAccountDetailsTitle: {
        id: 'fnol.components.payment-details.Bank Account Details (where we will send payment)',
        defaultMessage: 'Bank Account Details (where we will send payment)'
    },
    fnolPaymentDetailsBankAccountDetailsErrorTitle: {
        id: 'fnol.components.payment-details.Bank Account Details Error Title',
        defaultMessage: 'Incorrect Bank Account Details'
    },
    fnolPaymentDetailsBankAccountDetailsErrorMessage: {
        id: 'fnol.components.payment-details.Bank Account Details Error Message',
        defaultMessage: 'Please make sure that the Bank Account number provided is correct or enter a valid Bank account number.'
    }
});
