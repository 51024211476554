import React, {
    useState,
    useContext,
    useCallback,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { CoverageDetailsComponent } from 'gw-capability-claim-react';
import metadata from './HOLocationCoverages.metadata.json5';
import styles from './HOLocationCoverages.module.scss';


import messages from './HOLocationCoverages.messages';

function HOLocationCoverages(props) {
    const { value: model } = props;
    const translator = useContext(TranslatorContext);
    const [openItem, setOpenItem] = useState(null);

    const getNameCell = useCallback(
        (item, _index, property) => {
            return <span title={translator(messages.coverage)}>{item[property.id]}</span>;
        },
        [translator]
    );
    const toggleShowHide = useCallback((index) => {
        if (openItem === index) {
            setOpenItem(null); // close the currently opened item
        } else {
            setOpenItem(index);
        }
    }, [openItem]);

    const getHasListedItems = useCallback((location) => {
        if (location) {
            return translator(messages.removeConfirmationYes);
        }
        return translator(messages.removeConfirmationNo);
    }, [translator]);

    const overrides = useMemo(() => {
        const locationOverrides = _.get(model, 'locations', [])
            .reduce((partial, location, index) => ({
                ...partial,
                [`showContentToggleLink${index}`]: {
                    onClick: () => toggleShowHide(index)
                },
                [`contentDetailToggle${index}`]: {
                    icon: openItem === index ? 'caret-up' : 'caret-down',
                    message: openItem === index
                        ? translator(messages.hideLocationCoverages)
                        : translator(messages.showLocationCoverages)
                },
                [`coverageDetailsContainer${index}`]: {
                    visible: openItem === index
                },
                [`locationCoverageDetails${index}`]: {
                    coverages: _.get(location, 'coverages'),
                    coveragesHeader: translator(messages.coveragesForLocation, {
                        selectedLocationNumber: _.get(location, 'locationNumber')
                    })
                },
                [`hasListedItems${index}`]: {
                    value: getHasListedItems(_.get(location, 'hasListedItems'))
                }
            }), {});
        return locationOverrides;
    }, [getHasListedItems, model, openItem, toggleShowHide, translator]);

    if (_.isEmpty(model.locations)) {
        return null;
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            coveragedetailscomponent: CoverageDetailsComponent
        },
        resolveCallbackMap: {
            getNameCell
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={model}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

HOLocationCoverages.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default HOLocationCoverages;
