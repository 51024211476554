import _ from 'lodash';

function PropertyIncident(data) {
    if (data) {
        _.extend(this, data);
    }
}

PropertyIncident.prototype.getTypeCode = function getTypeCode() {
    return 'FixedPropertyIncident';
};

export default PropertyIncident;
