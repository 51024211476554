import PropTypes from 'prop-types';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import React, {
    useContext,
    useCallback,
    useLayoutEffect
} from 'react';
import { useParams } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { FormattedMessage } from 'react-intl';
import { ContactInfoService } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import DocumentUploadNotice from './components/DocumentUploadNotice';
import messages from './ConfirmationPage.messages';
import metadata from './ConfirmationPage.metadata.json5';
import styles from './ConfirmationPage.module.scss';

function ConfirmationPage() {
    const { claimNumber } = useParams();
    const translator = useContext(TranslatorContext);
    const country = useStoredCountry();
    const countryCode = country?.toUpperCase();

    useLayoutEffect(() => {
        document.title = translator(messages.successfulSubmissionTitle) + ' | Colonnade Insurance S.A.';
    }, []);

    const handlePrint = useCallback((e) => {
        e.preventDefault();
        window.print();
    }, []);

    const handleBackToHome = useCallback((e) => {
        e.preventDefault();
        window.location.href = ContactInfoService.getWebsiteLink(countryCode);
    }, [countryCode]);

    const colonnadeWebsiteLink = translator(messages.colonnadeWebsite);

    const overrideProps = {
        fnolConfirmationPageClaimReferenceNumber: {
            visible: !!claimNumber,
            content: translator(messages.referenceNumber, { claimNumber })
        },
        fnolConfirmationPageUploadNotice: {
            country: countryCode
        },
        fnolConfirmationPageListOfDocumentsNotice: {
            content: <FormattedMessage
                {...messages.listOfDocumentsNotice}
                values={{
                    documentsUrl: (
                        <a
                            className={styles.link}
                            href={ContactInfoService.getClaimsInfoWebsiteLink(countryCode)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {colonnadeWebsiteLink}
                        </a>
                    )
                }}
            />
        }
    };

    const resolvers = {
        resolveComponentMap: {
            documentuploadnotice: DocumentUploadNotice
        },
        resolveCallbackMap: {
            handleBackToHome: handleBackToHome,
            handlePrint: handlePrint
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

ConfirmationPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
        replace: PropTypes.func,
    }).isRequired
};

export default ConfirmationPage;
