import React from 'react';
import PropTypes from 'prop-types';

const CustomInput = ({
    id,
    className,
    value,
    currentValue,
    placeholder,
    onClick,
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
}) => {
    return (
        <input
            id={id}
            className={className}
            inputMode="none"
            autoComplete={false}
            placeholder={placeholder}
            value={currentValue || value}
            onClick={onClick}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
        />
    );
};

CustomInput.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date),
    currentValue: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
};

CustomInput.defaultProps = {
    currentValue: undefined,
    value: undefined,
};

export default CustomInput;
