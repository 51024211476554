export const datePickerStyles = {
    dateHeader: 'jut__DatePickerField__dateHeader',
    datePicker: 'jut__DatePickerField__datePicker',
    large: 'jut__DatePickerField__large',
    monthDropdownSelect: 'jut__DatePickerField__monthDropdownSelect',
    navigationNext: 'jut__DatePickerField__navigationNext',
    navigationNextDisabled: 'jut__DatePickerField__navigationNextDisabled',
    navigationPrevious: 'jut__DatePickerField__navigationPrevious',
    navigationPreviousDisabled:
        'jut__DatePickerField__navigationPreviousDisabled',
    readOnly: 'jut__DatePickerField__readOnly',
    small: 'jut__DatePickerField__small',
    yearDropdownSelect: 'jut__DatePickerField__yearDropdownSelect',
};

export const inputFieldStyles = {
    input: 'jut__InputField__input',
    inputLarge: 'jut__InputField__inputLarge',
    inputSmall: 'jut__InputField__inputSmall',
};
