export const Countries = Object.freeze({
    PL: 'PL',
    RO: 'RO',
    BG: 'BG',
    CZ: 'CZ',
    HU: 'HU',
    SK: 'SK'
});

export function callCorrectImpl(variants, country) {
    let countrySpecificVariant = null;
    variants.forEach((variant) => {
        if (variant.countries.find((countryCode) => countryCode === country)) {
            countrySpecificVariant = variant;
        }
    });

    if (!countrySpecificVariant) {
        throw new Error(`There is no variant for country: ${country}`);
    }

    return countrySpecificVariant.action();
}

export default {
    Countries,
    callCorrectImpl
};
