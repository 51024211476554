import _ from 'lodash';
import { EntityUtil } from 'gw-portals-util-js';

function Official(data) {
    if (data) {
        _.extend(this, data);
    }
}

Official.prototype.nextId = () => {
    return EntityUtil.nextId();
};

export default Official;
