import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolSummary: {
        id: 'fnol.components.summary.Summary',
        defaultMessage: 'Summary'
    },
    dialogAddNoteToAdjuster: {
        id: 'fnol.components.summary.Add a Note to the Adjuster',
        defaultMessage: 'Add a Note to the Adjuster'
    },
    fnolNoteErrorHead: {
        id: 'fnol.components.summary.Note Error Head',
        defaultMessage: 'Note submission failed'
    },
    fnolNoteErrorMessage: {
        id: 'fnol.components.summary.Note Error Message',
        defaultMessage: 'Note cannot be processed at this time.'
    },
    fnolSummaryPageBasicInformation: {
        id: 'fnol.wizard.steps.Basic information',
        defaultMessage: 'Basic information'
    },
    fnolSummaryPageContactDetails: {
        id: 'fnol.wizard.steps.Contact Details',
        defaultMessage: 'Contact Details'
    },
    fnolSummaryPagePaymentDetails: {
        id: 'fnol.wizard.steps.Payment Details',
        defaultMessage: 'Payment Details'
    },
    fnolSummaryPageAdditionalInformation: {
        id: 'fnol.wizard.steps.Additional Information',
        defaultMessage: 'Additional Information'
    },
    fnolSummaryPageLossDetails: {
        id: 'fnol.components.summary.Loss details',
        defaultMessage: 'Loss details'
    },
    fnolSummaryPageExpandAll: {
        id: 'fnol.components.summary.Expand all',
        defaultMessage: 'Expand all'
    },
    fnolSummaryPageCollapseAll: {
        id: 'fnol.components.summary.Collapse all',
        defaultMessage: 'Collapse all'
    },
    fnolSummaryTitle: {
        id: 'fnol.components.summary.Summary',
        defaultMessage: 'Summary'
    },
    fnolSummaryHint: {
        id: 'fnol.components.summary.Please review the following information before submitting',
        defaultMessage: 'Please review the following information before submitting'
    },
    fnolSummaryPageSubmitClaim: {
        id: 'fnol.components.summary.Submit Claim',
        defaultMessage: 'Submit Claim'
    },
    fnolSummaryNoteToAdjuster: {
        id: 'fnol.wizard.step.summary.Note to Adjuster',
        defaultMessage: 'Note to Adjuster'
    }
});
