import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useHistory } from 'react-router-dom';
import { useComponentWillMount } from 'cnd-common-hooks-platform-react';
import { ai } from './TelemetryService';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * @param {Object} props
 * @returns {React.Component}
 */
const TelemetryProvider = (props) => {
    const {
        disableTelemetry,
        connectionString,
        after,
        children
    } = props;
    const history = useHistory();
    const reactPluginRef = useRef(null);

    useComponentWillMount(() => {
        const {
            appInsights,
            reactPlugin
        } = ai.initialize(connectionString, history, disableTelemetry);
        reactPluginRef.current = reactPlugin;
        if (typeof after === 'function') after(appInsights, reactPlugin);
    });

    return (
        <AppInsightsContext.Provider value={reactPluginRef.current}>
            {children}
        </AppInsightsContext.Provider>
    );
};


TelemetryProvider.propTypes = {
    history: PropTypes.shape({}).isRequired,
    disableTelemetry: PropTypes.bool,
    connectionString: PropTypes.string.isRequired,
    after: PropTypes.func.isRequired,
    children: PropTypes.node
};

TelemetryProvider.defaultProps = {
    children: null,
    disableTelemetry: false
};

export default TelemetryProvider;
