import React from 'react';
import { InputField } from '@jutro/components';
import PropTypes from 'prop-types';

function ReadOnlyDateValue(props) {
    const { id, value } = props;

    return (
        <InputField
            readOnly
            id={id}
            value={value}
        />
    );
}

ReadOnlyDateValue.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
};

ReadOnlyDateValue.defaultProps = {
    value: undefined
};

export default ReadOnlyDateValue;
