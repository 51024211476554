import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolLossDetails: {
        id: 'fnol.wizard.step.loss-details.Loss details',
        defaultMessage: 'Loss details'
    },
    fnolLossDetailsPageVehicleIncident: {
        id: 'fnol.wizard.step.loss-details.Vehicle',
        defaultMessage: 'Vehicle'
    },
    fnolLossDetailsPageInjuryIncident: {
        id: 'fnol.wizard.step.loss-details.Injury',
        defaultMessage: 'Injury'
    },
    fnolLossDetailsPagePersonalPropertyIncident: {
        id: 'fnol.wizard.step.loss-details.Personal Property',
        defaultMessage: 'Personal Property'
    },
    fnolLossDetailsPagePropertyIncident: {
        id: 'fnol.wizard.step.loss-details.Property',
        defaultMessage: 'Property'
    },
    fnolLossDetailsPageTripIncident: {
        id: 'fnol.wizard.step.loss-details.Trip',
        defaultMessage: 'Trip'
    },
    fnolLossDetailsPageBaggageIncident: {
        id: 'fnol.wizard.step.loss-details.Baggage',
        defaultMessage: 'Baggage'
    },
    fnolLossDetailsPageGeneralIncident: {
        id: 'fnol.wizard.step.loss-details.General',
        defaultMessage: 'General'
    },
    fnolLossDetailsPageVehicleIncidentSectionName: {
        id: 'fnol.wizard.step.loss-details.Vehicle incident',
        defaultMessage: 'Vehicle incident'
    },
    fnolLossDetailsPageInjuryIncidentSectionName: {
        id: 'fnol.wizard.step.loss-details.Injury incident',
        defaultMessage: 'Injury incident'
    },
    fnolLossDetailsPagePersonalPropertyIncidentSectionName: {
        id: 'fnol.wizard.step.loss-details.Personal Property incident',
        defaultMessage: 'Personal Property incident'
    },
    fnolLossDetailsPagePropertyIncidentSectionName: {
        id: 'fnol.wizard.step.loss-details.Property incident',
        defaultMessage: 'Property incident'
    },
    fnolLossDetailsPageTripIncidentSectionName: {
        id: 'fnol.wizard.step.loss-details.Trip incident',
        defaultMessage: 'Trip incident'
    },
    fnolLossDetailsPageBaggageIncidentSectionName: {
        id: 'fnol.wizard.step.loss-details.Baggage incident',
        defaultMessage: 'Baggage incident'
    },
    fnolLossDetailsPageGeneralIncidentSectionName: {
        id: 'fnol.wizard.step.loss-details.General incident',
        defaultMessage: 'General incident'
    },
    fnolLossDetailsPageDeleteIncidentModalMessage: {
        id: 'fnol.wizard.step.loss-details.Are you sure you want to delete the incident?',
        defaultMessage: 'Are you sure you want to delete the incident?'
    },
    fnolLossDetailsPageDeleteIncidentModalTitle: {
        id: 'fnol.wizard.step.loss-details.Delete incident',
        defaultMessage: 'Delete incident'
    },
    fnolLossDetailsPageWhatHappened: {
        id: 'fnol.wizard.step.loss-details.What happened?',
        defaultMessage: 'What happened?'
    },
    fnolLossDetailsPageWhatHappenedPlaceholder: {
        id: 'fnol.wizard.step.loss-details.Provide here a brief description of your claim. Don\'t put any personal data into this field.',
        defaultMessage: 'Provide here a brief description of your claim. Don\'t put any personal data into this field.'
    },
    fnolLossDetailsPageInfo1: {
        id: 'fnol.wizard.step.loss-details.Now you can register details about your incidents.',
        defaultMessage: 'Now you can register details about your incidents.'
    },
    fnolLossDetailsPageInfo2: {
        id: 'fnol.wizard.step.loss-details.Click on "Add incident" and then you will be able to choose which of the incidents available for your policy you want to register.',
        defaultMessage: 'Click on "Add incident" and then you will be able to choose which of the incidents available for your policy you want to register.'
    },
    fnolLossDetailsPageInfo3: {
        id: 'fnol.wizard.step.loss-details.Remember that you can register multiple events if they have the same loss location and loss cause provided during the previous step.',
        defaultMessage: 'Remember that you can register multiple events if they have the same loss location and loss cause provided during the previous step.'
    },
    fnolLossDetailsPageSectionIncidentsGuide: {
        id: 'fnol.wizard.step.loss-details.Incident types guide',
        defaultMessage: 'Incident types guide'
    },
    fnolLossDetailsPageIncidentsGuideInjuryIncident: {
        id: 'fnol.components.incidents-guide.Injury - incident about injury',
        defaultMessage: 'Injury - incident about injury'
    },
    fnolLossDetailsPageIncidentsGuidePersonalPropertyIncident: {
        id: 'fnol.components.incidents-guide.Personal Property - incident about property',
        defaultMessage: 'Personal Property - incident about personal property'
    },
    fnolLossDetailsPageIncidentsGuidePropertyIncident: {
        id: 'fnol.components.incidents-guide.Property - incident about property',
        defaultMessage: 'Property - incident about property'
    },
    fnolLossDetailsPageIncidentsGuideBaggageIncident: {
        id: 'fnol.components.incidents-guide.Baggage - incident about baggage',
        defaultMessage: 'Baggage - incident about baggage'
    },
    fnolLossDetailsPageIncidentsGuideTripIncident: {
        id: 'fnol.components.incidents-guide.Trip - incident about trip',
        defaultMessage: 'Trip - incident about trip'
    },
    fnolLossDetailsPageIncidentsGuideGeneralIncident: {
        id: 'fnol.components.incidents-guide.General - incident about general',
        defaultMessage: 'General - incident about general'
    },
    fnolLossDetailsPageIncidentsGuideVehicleIncident: {
        id: 'fnol.components.incidents-guide.Vehicle - incident about vehicle',
        defaultMessage: 'Vehicle - incident about vehicle'
    },
    fnolLossDetailsPageAddIncident: {
        id: 'fnol.wizard.step.loss-details.Add incident',
        defaultMessage: 'Add incident'
    },
    fnolLossDetailsPageInfo4: {
        id: 'fnol.wizard.step.loss-details.In the next step, you will be able to attach documents related to your insurance and reported incidents.',
        defaultMessage: 'In the next step, you will be able to attach documents related to your insurance and reported incidents.'
    }
});
