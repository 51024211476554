import _ from 'lodash';
import { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';

export default function useErrorTranslator(validationErrorMessages = {}) {
    const translator = useContext(TranslatorContext);

    return function translateError(path) {
        const message = validationErrorMessages[path];
        if (_.isNil(message)) return undefined;
        return [translator(message)];
    };
}
