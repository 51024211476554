/* eslint-disable class-methods-use-this */
import { service } from '@jutro/services';
import { Contract, types } from '@jutro/contract';

export const SERVICE_ID = 'country-service-cnd';

export const CountryServiceInterface = Contract.specify({
    getStoredCountry: types.func,
    saveCountry: types.func
});

class CountryServiceImpl {
    constructor() {
        this.country = localStorage.getItem('selectedCountry');
    }

    /**
     * Returns the stored country of the portal
     * @returns {string | null}
     */
    getStoredCountry() {
        return this.country;
    }

    /**
     * Stores the country of the portal.
     * @param {string} country - The country id
     */
    saveCountry(country) {
        this.country = country;
        localStorage.setItem('selectedCountry', country);
    }

    static register() {
        service(SERVICE_ID, {
            implements: CountryServiceInterface
        })(CountryServiceImpl);
    }
}

export default CountryServiceImpl;
