import { Countries, callCorrectImpl } from './CountrySpecificUtil';

export default {
    getPhoneNumberMask: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [Countries.Other],
                action: () => ''
            },
            {
                countries: [
                    Countries.PL,
                    Countries.RO,
                    Countries.CZ,
                    Countries.SK
                ],
                action: () => '999 999 999'
            },
            {
                countries: [
                    Countries.BG,
                ],
                action: () => '999 999 990'
            },
            {
                countries: [
                    Countries.HU,
                ],
                action: () => '99 999 9999'
            }
        ];

        return callCorrectImpl(variants, country);
    }
};
