import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolAdditionalInformation: {
        id: 'fnol.additional-information.Additional information',
        defaultMessage: 'Additional information'
    },
    fnolAdditionalInformationWitnesses: {
        id: 'fnol.additional-information.Witnesses',
        defaultMessage: 'Witnesses'
    },
    fnolAdditionalInformationOfficials: {
        id: 'fnol.additional-information.Officials',
        defaultMessage: 'Officials'
    },
    fnolAdditionalInformationWitnessesOrOtherParties: {
        id: 'fnol.additional-information.Witnesses or Other Parties',
        defaultMessage: 'Witnesses or Other Parties'
    },
    fnolAdditionalInformationInsuredPerson: {
        id: 'fnol.additional-information.Insured Person',
        defaultMessage: 'Insured Person'
    },
    fnolAdditionalInformationPageInfo1: {
        id: 'fnol.components.additional-information.Please attach as many documents as possible to expedite the process of handling your claim.',
        defaultMessage: 'Please attach as many documents as possible to expedite the process of handling your claim.'
    },
    fnolAdditionalInformationPageInfo2: {
        id: 'fnol.components.additional-information.Below is a list of the most common documents relevant to the incident(s) being reported.',
        defaultMessage: 'Below is a list of the most common documents relevant to the incident(s) being reported.'
    },
    fnolAdditionalInformationPageInfo3: {
        id: 'fnol.components.additional-information.If additional documents will be needed, the adjuster will contact you directly.',
        defaultMessage: 'If additional documents will be needed, the adjuster will contact you directly.'
    },
    fnolAdditionalInformationPageInfo4: {
        id: 'fnol.components.additional-information.Here you can find the most frequent documents needed for your incident:',
        defaultMessage: 'Here you can find the most frequent documents needed for your incident:'
    },
    fnolAdditionalInformationPageInfo5: {
        id: 'fnol.components.additional-information.To find a complete list of documents needed to properly and quickly handle claims, go to ',
        defaultMessage: 'To find a complete list of documents needed to properly and quickly handle claims, go to '
    },
    fnolAdditionalInformationPageInfoPL1: {
        id: 'fnol.components.additional-information.In case of group or school insurance',
        defaultMessage: 'W przypadku zgłoszenia z grupowego ubezpieczenia NNW lub szkolnego (dotyczy jedynie osób, które nie posiadają certyfikatu potwierdzającego przystąpienie do szkolnego ubezpieczenia grupowego) niezbędne jest załączenie zaświadczenia potwierdzającego ubezpieczenie, wystawionego na druku Colonnade (prosimy o pobranie z '
    },
    fnolAdditionalInformationPageInfoPL2: {
        id: 'fnol.components.additional-information.Colonnade page submit claim section URL',
        defaultMessage: 'http://www.colonnade.pl/zglos-szkode'
    },
    fnolAdditionalInformationPageInfoPL3: {
        id: 'fnol.components.additional-information.Form should be filled and stamped by person working on behalf of Policy Holder',
        defaultMessage: ' z sekcji Wymagane dokumenty i przydatne kontakty). Druk powinien zostać uzupełniony i podstemplowany przez osobę z ramienia Ubezpieczającego, upoważnioną do potwierdzenia ubezpieczenia.'
    },
    fnolAdditionalInformationPageInjuryIncidentDocuments: {
        id: 'fnol.components.additional-information.Copy of complete medical documentation related to injury/accident containing the diagnosis and conducted treatment, Bills for the costs of treatment and/or payment confirmations, Death certificate, autopsy result, and police report if made',
        defaultMessage: 'Copy of complete medical documentation related to injury/accident containing the diagnosis and conducted treatment, Bills for the costs of treatment and/or payment confirmations, Death certificate, autopsy result, and police report if made'
    },
    fnolAdditionalInformationPageVehicleIncidentDocuments: {
        id: 'fnol.components.additional-information.Copy of Driver´s Licence (driver at the time of incident), vehicle registration certificate, Copy of official police report, if made, Photos of broken parts before dismantling, Copy of repair protocol with list of broken parts, estimated repair price/detailed repair calculation, Copy of repair bill/ invoice, payment confirmations',
        defaultMessage: 'Copy of Driver´s Licence (driver at the time of incident), vehicle registration certificate, Copy of official police report, if made, Photos of broken parts before dismantling, Copy of repair protocol with list of broken parts, estimated repair price/detailed repair calculation, Copy of repair bill/ invoice, payment confirmations'
    },
    fnolAdditionalInformationPagePersonalPropertyIncidentDocuments: {
        id: 'fnol.components.additional-information.Detailed list of damaged/lost items including date of purchase, purchase price, Copy of documents confirming the ownership of the damaged/lost equipment (e.g. purchase invoices, contracts, photos), Confirmation of event reporting to relevant institutions (depending on the type of event, e.g. theft - police; flooding/fire - administration/fire brigade), Repairs expertise, Repairs invoice, Invoice for replacing the appliances',
        defaultMessage: 'Detailed list of damaged/lost items including date of purchase, purchase price, Copy of documents confirming the ownership of the damaged/lost equipment (e.g. purchase invoices, contracts, photos), Confirmation of event reporting to relevant institutions (depending on the type of event, e.g. theft - police; flooding/fire - administration/fire brigade), Repairs expertise, Repairs invoice, Invoice for replacing the appliances'
    },
    fnolAdditionalInformationPagePropertyIncidentDocuments: {
        id: 'fnol.components.additional-information.Pictures of damages, Copy of documents confirming the ownership of damaged/lost property (e.g. purchase invoices, contracts), Copy of estimation with repair amount indicated, invoices for paid repairs and payment confirmations, Confirmation of reporting the incident to appropriate institutions (e.g. theft - police; flooding/fire - fire brigade)',
        defaultMessage: 'Pictures of damages, Copy of documents confirming the ownership of damaged/lost property (e.g. purchase invoices, contracts), Copy of estimation with repair amount indicated, invoices for paid repairs and payment confirmations, Confirmation of reporting the incident to appropriate institutions (e.g. theft - police; flooding/fire - fire brigade)'
    },
    fnolAdditionalInformationPageGeneralNonPetIncidentDocuments: {
        id: 'fnol.components.additional-information.Purchase receipt, invoices certifying the replacement costs of damaged belongings, payment confirmations, Police report, hospital protocol, Any other evidence in the possession of the Insured',
        defaultMessage: 'Purchase receipt, invoices certifying the replacement costs of damaged belongings, payment confirmations, Police report, hospital protocol, Any other evidence in the possession of the Insured'
    },
    fnolAdditionalInformationPageGeneralPetIncidentDocuments: {
        id: 'fnol.components.additional-information.Pedigree, vaccination book/certificates, Veterinary report, Veterinary bill, payment confirmation',
        defaultMessage: 'Pedigree, vaccination book/certificates, Veterinary report, Veterinary bill, payment confirmation'
    },
    fnolAdditionalInformationPageTripIncidentDocuments: {
        id: 'fnol.components.additional-information.Ticket, signed travel contract, or other confirmation of insured trip/accommodation, Confirmation of the trip cancellation to the travel organizer with the reason for cancellation (e.g. medical documentation, police report), Confirmation of the delay by the carrier or airline, or proof of the original Travel and re-departure with the original ticket, reservation, and new boarding pass, Carrier\'s report with the decision on granting/refusing the compensation, Receipts, invoices for expenses related to flight delays/cancellation',
        defaultMessage: 'Ticket, signed travel contract, or other confirmation of insured trip/accommodation, Confirmation of the trip cancellation to the travel organizer with the reason for cancellation (e.g. medical documentation, police report), Confirmation of the delay by the carrier or airline, or proof of the original Travel and re-departure with the original ticket, reservation, and new boarding pass, Carrier\'s report with the decision on granting/refusing the compensation, Receipts, invoices for expenses related to flight delays/cancellation'
    },
    fnolAdditionalInformationPageBaggageIncidentDocuments: {
        id: 'fnol.components.additional-information.Invoices and receipts proving the purchase of lost items, Proof from the airline/carrier of the compensation paid, Boarding pass, copy of plane ticket, Police reports if made, Repair invoice or a professional certificate that the item cannot be repaired, payment confirmations',
        defaultMessage: 'Invoices and receipts proving the purchase of lost items, Proof from the airline/carrier of the compensation paid, Boarding pass, copy of plane ticket, Police reports if made, Repair invoice or a professional certificate that the item cannot be repaired, payment confirmations'
    },
    fnolAdditionalInformationPageGeneralIncidentTitle: {
        id: 'fnol.components.additional-information.General Incident',
        defaultMessage: 'General Incident'
    },
    fnolAdditionalInformationPageGeneralPetIncidentTitle: {
        id: 'fnol.components.additional-information.General Incident (Pet)',
        defaultMessage: 'General Incident (Pet)'
    },
    fnolAdditionalInformationPageGeneralNonPetIncidentTitle: {
        id: 'fnol.components.additional-information.General Incident (Non-Pet)',
        defaultMessage: 'General Incident (Non-Pet)'
    },
    fnolAdditionalInformationPageVehicleIncidentTitle: {
        id: 'fnol.components.additional-information.Vehicle Incident',
        defaultMessage: 'Vehicle Incident'
    },
    fnolAdditionalInformationPageBaggageIncidentTitle: {
        id: 'fnol.components.additional-information.Baggage Incident',
        defaultMessage: 'Baggage Incident'
    },
    fnolAdditionalInformationPageInjuryIncidentTitle: {
        id: 'fnol.components.additional-information.Injury Incident',
        defaultMessage: 'Injury Incident'
    },
    fnolAdditionalInformationPageTripIncidentTitle: {
        id: 'fnol.components.additional-information.Trip Incident',
        defaultMessage: 'Trip Incident'
    },
    fnolAdditionalInformationPagePersonalPropertyIncidentTitle: {
        id: 'fnol.components.additional-information.Personal Property Incident',
        defaultMessage: 'Personal Property Incident'
    },
    fnolAdditionalInformationPagePropertyIncidentTitle: {
        id: 'fnol.components.additional-information.Property Incident',
        defaultMessage: 'Property Incident'
    },
    fnolAdditionalInformationPageAllowedFiles: {
        id: 'fnol.components.additional-information.Supported file formats: .png, .bmp, .jpeg, .mp4, .mov, .wav, .xlsx, .docx, .pdf',
        defaultMessage: 'Supported file formats: .png, .bmp, .jpeg, .mp4, .mov, .wav, .xlsx, .docx, .pdf'
    },
    fnolAdditionalInformationPageStep1Info: {
        id: 'fnol.components.additional-information.Step 1: Choose the document type',
        defaultMessage: 'Step 1: Choose the document type'
    },
    fnolAdditionalInformationPageStep2Info: {
        id: 'fnol.components.additional-information.Step 2: Upload document',
        defaultMessage: 'Step 2: Upload document'
    },
    fnolAdditionalInformationPageDocumentsTableNameColumn: {
        id: 'fnol.components.additional-information.Document name',
        defaultMessage: 'Document name'
    },
    fnolAdditionalInformationPageDocumentsTableTypeColumn: {
        id: 'fnol.components.additional-information.Document type',
        defaultMessage: 'Document type'
    },
    fnolAdditionalInformationPageDocumentsTablePreviewColumn: {
        id: 'fnol.components.additional-information.View/Download',
        defaultMessage: 'View/Download'
    },
    fnolAdditionalInformationPageDocumentsTableRemoveColumn: {
        id: 'fnol.components.additional-information.Remove',
        defaultMessage: 'Remove'
    },
    fnolAdditionalInformationPageInfo6: {
        id: 'fnol.components.additional-information.To upload the document, first you need to choose its type using the drop-down list. Description of available types is presented in the section "Document types guide".',
        defaultMessage: 'To upload the document, first you need to choose its type using the drop-down list. Description of available types is presented in the section "Document types guide".'
    },
    fnolAdditionalInformationPageDocumentTypes: {
        id: 'fnol.components.additional-information.Document types guide',
        defaultMessage: 'Document types guide'
    },
    fnolAdditionalInformationPageDocumentTypesTypeAssessment: {
        id: 'fnol.components.additional-information.Assessment - documents of assessment',
        defaultMessage: 'Assessment - documents of assessment '
    },
    fnolAdditionalInformationPageDocumentTypesTypeAssistance: {
        id: 'fnol.components.additional-information.Assistance - documents connected to the Assistance services',
        defaultMessage: 'Assistance - documents connected to the Assistance services'
    },
    fnolAdditionalInformationPageDocumentTypesTypeCoinsurance: {
        id: 'fnol.components.additional-information.Coinsurance - documents of the coinsurance policy',
        defaultMessage: 'Coinsurance - documents of the coinsurance policy '
    },
    fnolAdditionalInformationPageDocumentTypesTypeCompany: {
        id: 'fnol.components.additional-information.Company - documents connected to the company (copy of the signature title, etc.)',
        defaultMessage: 'Company - documents connected to the company (copy of the signature title, etc.)'
    },
    fnolAdditionalInformationPageDocumentTypesTypeInvoice: {
        id: 'fnol.components.additional-information.Invoice - proof of payment, Invoices, bills, quotes',
        defaultMessage: 'Invoice - proof of payment, Invoices, bills, quotes'
    },
    fnolAdditionalInformationPageDocumentTypesTypeMedical: {
        id: 'fnol.components.additional-information.Medical - documents connected to medical service (diagnosis, medical expertise report, final report, etc.)',
        defaultMessage: 'Medical - documents connected to medical service (diagnosis, medical expertise report, final report, etc.)'
    },
    fnolAdditionalInformationPageDocumentTypesTypeOfficial: {
        id: 'fnol.components.additional-information.Official - official report, police final decision, court judgment etc.',
        defaultMessage: 'Official - official report, police final decision, court judgment etc.'
    },
    fnolAdditionalInformationPageDocumentTypesTypeOther: {
        id: 'fnol.components.additional-information.Other - any other documents that do not match the rest of the categories',
        defaultMessage: 'Other - any other documents that do not match the rest of the categories'
    },
    fnolAdditionalInformationPageDocumentTypesTypePersonal: {
        id: 'fnol.components.additional-information.Personal - personal documents, ID card, Driver\'s license, birth certificate, etc',
        defaultMessage: 'Personal - personal documents, ID card, Driver\'s license, birth certificate, etc'
    },
    fnolAdditionalInformationPageDocumentTypesTypeProperty: {
        id: 'fnol.components.additional-information.Property - contract of sale, proof of ownership license, etc.',
        defaultMessage: 'Property - contract of sale, proof of ownership license, etc.'
    },
    fnolAdditionalInformationPageDocumentTypesTypeTravel: {
        id: 'fnol.components.additional-information.Travel - documents connected to trip like a boarding pass, flight ticket, etc.',
        defaultMessage: 'Travel - documents connected to trip like a boarding pass, flight ticket, etc.'
    },
    fnolAdditionalInformationPageDocumentTypesTypeVehicle: {
        id: 'fnol.components.additional-information.Vehicle - traffic license, register card, contract of rental/sale, etc.',
        defaultMessage: 'Vehicle - traffic license, register card, contract of rental/sale, etc.'
    },
    fnolAdditionalInformationPageInfo7: {
        id: 'fnol.components.additional-information.Remember that the "Power of Attorney" document is recommended to attach',
        defaultMessage: 'Remember that the "Power of Attorney" document is recommended to attach'
    },
    fnolAdditionalInformationDocuments: {
        id: 'fnol.components.additional-information.Documents',
        defaultMessage: 'Documents'
    },
    fnolAdditionalInformationFailedUpload: {
        id: 'fnol.components.additional-information.Failed to upload this file. Please try again later or send the document to the email address that will be provided in the claim confirmation message.',
        defaultMessage: 'Failed to upload this file. Please try again later or send the document to the email address that will be provided in the claim confirmation message.'
    },
    fnolAdditionalInformationColonnadeWebsite: {
        id: 'fnol.components.additional-information.COLONNADE WEBSITE',
        defaultMessage: 'COLONNADE WEBSITE'
    },
    fnolAdditionalInformationSchoolPortection: {
        id: 'fnol.components.additional-information.School protection',
        defaultMessage: 'School protection'
    }
});
