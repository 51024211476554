import _ from 'lodash';
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import { TranslatorContext } from '@jutro/locale';
import {
    WizardPage,
    wizardProps,
    WizardPageTemplate
} from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { CountryLayerService, TagManagerService, TrackingConstants } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import StepHeader from '../../components/StepHeader/StepHeader';
import Claim from '../../models/Claim';
import Person from '../../components/Person/Person';
import Consents from '../../components/Consents/Consents';
import SimplePerson from '../../components/SimplePerson/SimplePerson';
import Company from '../../components/Company/Company';
import useErrorHandler from '../../hooks/useErrorHandler';
import metadata from './ContactDetailsPage.metadata.json5';
import styles from './ContactDetailsPage.module.scss';
import messages from './ContactDetailsPage.messages';
import useValidationErrors from '../../hooks/useValidationErrors';
import { CND_DTO_VALIDATION_ERROR } from '../../constants/errorCodes';

const VM_PREFIX = 'claimantData';

function FNOLContactDetailsPage(props) {
    const translator = useContext(TranslatorContext);
    const [isLoading, setIsLoading] = useState(false);
    const [
        filterValidationErrors,
        setValidationErrors,
        resetValidationError
    ] = useValidationErrors();
    const { FNOLService } = useDependencies('FNOLService');
    const [reporter, setReporter] = useState('');
    const [availableRelatedContacts, setAvailableRelatedContacts] = useState([]);
    const [isAnyRelatedContact, setIsAnyRelatedContact] = useState(false);
    const ErrorHandler = useErrorHandler();
    const CREATE_NEW_OPTION_CODE = 'new';
    const INDIVIDUAL_CODE = 'individual';
    const CLAIMANT_ROLE_CODE = 'claimant';
    const REPORTER_ROLE_CODE = 'reporter';
    const SELFINSURED_CODE = 'self';
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const {
        wizardData: claimVM,
        updateWizardData
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        initialValidation,
        disregardFieldValidation,
        registerComponentValidation
    } = useValidation('ContactDetailsPage');

    useEffect(() => {
        registerComponentValidation(() => {
            return claimVM.claimantData.aspects.valid && claimVM.claimantData.aspects.subtreeValid;
        });
    }, [
        claimVM.claimantData.aspects.valid,
        claimVM.claimantData.aspects.subtreeValid,
        registerComponentValidation
    ]);

    const handleSaveClaimData = useCallback(() => {
        setIsLoading(true);
        return FNOLService.saveClaim(claimVM.value)
            .then((response) => {
                claimVM.value = new Claim(response);
                claimVM.value.createClaimantData();
                return claimVM;
            })
            .catch((error) => {
                const { appErrorCode, appData } = error;
                if (appErrorCode === CND_DTO_VALIDATION_ERROR) {
                    setValidationErrors(appData);
                } else {
                    const claimNumber = claimVM.claimNumber.value;
                    ErrorHandler.handleError(error, claimNumber);
                }
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, ErrorHandler, claimVM, setValidationErrors]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(claimVM, `${VM_PREFIX}.${path}`, value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const onValueChange = useCallback((value, path) => {
        writeValue(value, path);
        resetValidationError(`${VM_PREFIX}.${path}`);
    }, [writeValue, resetValidationError]);

    const setDefaultFieldValues = useCallback(
        () => {
            const defaultRelationToCompany = _.get(claimVM.claimantData.value, 'company') !== undefined ? 'primarycontactfor' : undefined;
            _.set(claimVM.claimantData.value, 'relationToCompany', defaultRelationToCompany);
            if (!isAnyRelatedContact) {
                _.set(claimVM.claimantData.value, 'relationToInsured', SELFINSURED_CODE);
            }
        },
        [claimVM, isAnyRelatedContact]
    );

    const generateRelatedContactName = useCallback((rolePerson) => {
        const name = `${rolePerson.contact.firstName} ${rolePerson.contact.lastName}`;
        const roleNames = [];
        rolePerson.roles.forEach(role => {
            if (role && role !== CLAIMANT_ROLE_CODE) {
                roleNames.push(translator({ id: `typekey.ContactRole.${role}` }));
            }
        });
        const roles = roleNames.length ? ` (${roleNames.join(', ')})` : '';
        return `${name}${roles}`;
    }, []);

    const hasRole = (roleContact, role) => roleContact.roles.find(r => r === role);

    const chooseDefaultRelatedContactIfReporterExists = useCallback(
        () => {
            const alreadyChosenReporter = claimVM.value.relatedContacts
                .find((roleContact) => hasRole(roleContact, REPORTER_ROLE_CODE));
            if (alreadyChosenReporter) {
                setReporter(alreadyChosenReporter.contact.publicID);
            }
        },
        [setReporter, claimVM]
    );

    const createAvailableOptions = useCallback(() => {
        const availableOptions = claimVM.value.relatedContacts.filter(e => e.contact).map((rolePerson) => (
            {
                code: rolePerson.contact.publicID,
                name: generateRelatedContactName(rolePerson)
            }
        ));
        const createNewOption = {
            code: CREATE_NEW_OPTION_CODE,
            name: translator(messages.fnolContactDetailsPageNewContact)
        };
        availableOptions.push(createNewOption);
        setAvailableRelatedContacts(availableOptions);
    }, [generateRelatedContactName, claimVM.value.relatedContacts, translator]);

    useEffect(() => {
        setIsAnyRelatedContact(claimVM.value.relatedContacts.length > 0);
        createAvailableOptions();
        chooseDefaultRelatedContactIfReporterExists();
    }, [claimVM.value.relatedContacts,
        createAvailableOptions,
        chooseDefaultRelatedContactIfReporterExists]);

    const claimantTypeChanged = useCallback(
        (value, changedPath) => {
            writeValue(value, changedPath);
            setReporter('');
            const { claimantData } = claimVM;
            if (claimantData.value.claimantType === INDIVIDUAL_CODE) {
                claimantData.value.removeCompanyAndRepresentative();
                if (!isAnyRelatedContact) {
                    claimantData.value.createIndividual();
                }
                disregardFieldValidation('fnolContactDetailsCompany');
                disregardFieldValidation('fnolContactDetailsCompanyRepresentative');
            } else {
                claimantData.value.removeIndividual();
                if (!isAnyRelatedContact) {
                    claimantData.value.createCompanyAndRepresentative();
                }
                disregardFieldValidation('fnolContactDetailsPerson');
            }
            setDefaultFieldValues();
            updateWizardData(claimVM);
        },
        [
            claimVM,
            updateWizardData,
            writeValue,
            isAnyRelatedContact,
            setDefaultFieldValues,
            disregardFieldValidation
        ]
    );

    useEffect(() => {
        claimVM.value.createClaimantData();
        if (!_.get(claimVM.value, 'claimantData.claimantType')) {
            claimantTypeChanged(INDIVIDUAL_CODE, 'claimantType');
        }
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const relatedContactChanged = useCallback(
        (value) => {
            setReporter(value);
            const { relatedContacts, claimantData } = claimVM;
            let chosenOption = {};
            let relatedCompany;
            if (value !== CREATE_NEW_OPTION_CODE) {
                const roleContact = relatedContacts.children.filter(c => c.contact)
                    .find((c) => c.contact.value.publicID === value);
                chosenOption = roleContact.contact.value;
                relatedCompany = roleContact.relatedCompany.value;
            }
            if (claimantData.value.claimantType === INDIVIDUAL_CODE) {
                claimantData.value.createIndividual(chosenOption);
            } else {
                claimantData.value.createCompanyAndRepresentative(chosenOption, relatedCompany);
            }
            setDefaultFieldValues();
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData, setDefaultFieldValues]
    );

    const onNext = useCallback(() => {
        TagManagerService.pushNextButtonClick(TrackingConstants.STEPS.CONTACT_DETAILS);
        return handleSaveClaimData();
    }, [handleSaveClaimData]);

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        fnolContactDetailsPageLoader: {
            loaded: !isLoading
        },
        fnolContactDetailsPageContainer: {
            visible: !isLoading
        },
        fnolContactDetailsPagePersonOrCompany: {
            value: _.get(claimVM.value, 'claimantData.claimantType'),
            onValueChange: claimantTypeChanged
        },
        fnolContactDetailsPageRelatedContacts: {
            value: reporter,
            visible: isAnyRelatedContact && _.get(claimVM.value, 'claimantData.claimantType') !== undefined,
            availableValues: availableRelatedContacts,
            onValueChange: relatedContactChanged
        },
        fnolContactDetailsPerson: {
            value: _.get(claimVM, 'claimantData.individual'),
            visible: _.get(claimVM.value, 'claimantData.individual') !== undefined,
            validationErrors: filterValidationErrors('claimantData.individual'),
            isEmailNotificationInfoVisible: true,
            isClaimantContactInfoMessageVisible: CountryLayerService.isClaimantContactInfoMessageVisible(appCountry)
        },
        fnolContactDetailsReporterContactDetailsTitle: {
            visible: _.get(claimVM.value, 'claimantData.companyRepresentative') !== undefined || _.get(claimVM.value, 'claimantData.individual') !== undefined
        },
        fnolContactDetailsOnlyAdultPerson: {
            visible: _.get(claimVM.value, 'claimantData.companyRepresentative') !== undefined || _.get(claimVM.value, 'claimantData.individual') !== undefined
        },
        fnolContactDetailsCompanyRepresentative: {
            value: _.get(claimVM, 'claimantData.companyRepresentative'),
            visible: _.get(claimVM.value, 'claimantData.companyRepresentative') !== undefined,
            isEmailNotificationInfoVisible: true,
            validationErrors: filterValidationErrors('claimantData.companyRepresentative'),
            countryCode: _.get(claimVM, 'claimantData.company.address.value.country'),
            isClaimantContactInfoMessageVisible: CountryLayerService.isClaimantContactInfoMessageVisible(appCountry)
        },
        fnolContactDetailsCompanyDetailsTitle: {
            visible: _.get(claimVM.value, 'claimantData.company') !== undefined
        },
        fnolContactDetailsCompany: {
            value: _.get(claimVM, 'claimantData.company'),
            visible: _.get(claimVM.value, 'claimantData.company') !== undefined
        },
        fnolContactDetailsRelationToCompany: {
            value: _.get(claimVM.value, 'claimantData.relationToCompany'),
            visible: _.get(claimVM.value, 'claimantData.companyRepresentative') !== undefined
        },
        fnolContactDetailsRelationToInsured: {
            value: _.get(claimVM.value, 'claimantData.relationToInsured'),
            visible: _.get(claimVM.value, 'claimantData.companyRepresentative') !== undefined || _.get(claimVM.value, 'claimantData.individual') !== undefined
        },
        fnolContactDetailsPageStepHeader: {
            claimNumber: _.get(claimVM.value, 'claimNumber'),
            title: translator(messages.fnolContactDetails)
        },
        fnolContactDetailsPageConsents: {
            value: _.get(claimVM, 'consents'),
            locationFilter: 'fnol_step_5'
        },
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            person: Person,
            simpleperson: SimplePerson,
            company: Company,
            stepheader: StepHeader,
            consents: Consents
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM.claimantData}
                resolveValue={resolvers.resolveValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                overrideProps={overrides}
                onValidationChange={setComponentValidation}
                onValueChange={onValueChange}
            />
        </WizardPage>
    );
}

FNOLContactDetailsPage.propTypes = wizardProps;
export default withRouter(FNOLContactDetailsPage);
