import {
    useCallback, useState
} from 'react';
import PropTypes from 'prop-types';

import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import { FNOLService } from 'cnd-capability-customfnol';
import { StaticInformationService, TagManagerService, TrackingConstants } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import SearchPolicy from '../../components/SearchPolicy/SearchPolicy';
import StaticInformation from '../../components/StaticInformation/StaticInformation';
import useErrorHandler from '../../hooks/useErrorHandler';

import metadata from './SearchPolicyPage.metadata.json5';
import styles from './SearchPolicyPage.module.scss';

function FNOLSearchPolicyPage(props) {
    const [isLoading, setIsLoading] = useState(false);
    const { history } = props;
    const ErrorHandler = useErrorHandler();
    const POLICY_NOT_FOUND_ERROR_CODE = 603;
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const overrideProps = {
        fnolSearchPolicyPageLoader: {
            loaded: !isLoading
        },
        fnolSearchPolicyViewContainer: {
            visible: !isLoading
        },
        fnolSearchPolicyStaticInformationViewContainer: {
            visible: !isLoading
                && StaticInformationService.isVisibleOnSearchPolicyPage(appCountry)
        },
        fnolSearchPolicyStaticInformation: {
            step: 'searchPolicyPage',
            country: appCountry
        }
    };

    const startToSearch = useCallback((data, captchaToken) => (
        FNOLService.searchPolicy(data, captchaToken)
            .then(
                (policySearchResult) => {
                    return history.push({
                        pathname: '/sms-verification',
                        state: {
                            redirectPath: '/',
                            policySearchResult
                        }
                    });
                }
            )
    ), [history]);

    const startProcess = useCallback((data, captchaToken) => {
        setIsLoading(true);
        TagManagerService.pushSearchButtonClick(TrackingConstants.STEPS.POLICY_SEARCH);
        return startToSearch(data, captchaToken)
            .catch((err) => {
                if (err?.appErrorCode === POLICY_NOT_FOUND_ERROR_CODE) {
                    history.push({
                        pathname: '/contact-call-center'
                    });
                } else {
                    ErrorHandler.handleError(err, data.policyNumber);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [ErrorHandler, history, startToSearch]);

    const resolvers = {
        resolveComponentMap: {
            searchpolicy: SearchPolicy,
            staticinformation: StaticInformation
        },
        resolveCallbackMap: {
            startProcess
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}
FNOLSearchPolicyPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withViewModelService(FNOLSearchPolicyPage);
