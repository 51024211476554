import React, { useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PropertyIncident.metadata.json5';
import summaryMetadata from '../../summary/incidents/PropertyIncidentSummary.metadata.json5';
import SummaryContext from '../../summary/SummaryContext';
import './PropertyIncident.messages';

function FNOLPropertyIncident(props) {
    const {
        data: incidentVM,
        onValueChange,
        path,
        id,
        onValidate,
        policyCurrency,
        showErrors
    } = props;

    const {
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const YES_CODE = 'Yes';
    const NO_CODE = 'No';

    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        if (_.isNil(_.get(incidentVM.value, 'alreadyRepaired'))) {
            _.set(incidentVM.value, 'alreadyRepaired', false);
        }

        if (_.isNil(_.get(incidentVM.value, 'repairEstimationReceived'))) {
            _.set(incidentVM.value, 'repairEstimationReceived', NO_CODE);
        }

        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        registerComponentValidation(() => {
            return incidentVM.aspects.valid && incidentVM.aspects.subtreeValid;
        });
    }, [incidentVM.aspects.subtreeValid, incidentVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const onRepairEstimationReceivedChange = (value) => {
        handleValueChange(value, 'repairEstimationReceived');
        if (value !== YES_CODE && !_.get(incidentVM.value, 'estimatedRepairCost.amount')) {
            handleValueChange(undefined, 'estimatedRepairCost');
        }
    };

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left',
            showErrors,
            readOnly
        },
        fnolPropertyIncidentEstimationReceived: {
            value: _.get(incidentVM.value, 'repairEstimationReceived'),
            onValueChange: onRepairEstimationReceivedChange
        },
        fnolPropertyIncidentEstimatedRepairCost: {
            visible: _.get(incidentVM.value, 'repairEstimationReceived') === YES_CODE,
            defaultCurrency: policyCurrency
        },
        fnolPropertyIncidentLossEstimation: {
            defaultCurrency: policyCurrency,
            visible: !readOnly || _.get(incidentVM.value, 'lossEstimation') !== undefined
        }
    };

    const resolvers = {
        resolveCallbackMap: {}
    };

    return (
        <div>
            <ViewModelForm
                uiProps={readOnly ? summaryMetadata.componentContent : metadata.componentContent}
                model={incidentVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLPropertyIncident.propTypes = {
    value: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.shape(() => {}).isRequired,
    path: PropTypes.shape('').isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    policyCurrency: PropTypes.string.isRequired,
    showErrors: PropTypes.bool.isRequired
};

export default FNOLPropertyIncident;
