import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolDefaultErrorPageTitle: {
        id: 'fnol.components.default-error-page.Try Again Later',
        defaultMessage: 'Try Again Later'
    },
    fnolDefaultErrorPageError: {
        id: 'fnol.components.default-error-page.Error',
        defaultMessage: 'Error'
    },
    fnolDefaultErrorPageSubtitle: {
        id: 'fnol.components.default-error-page.There was an error during the process.',
        defaultMessage: 'There was an error during the process.'
    },
    fnolDefaultErrorPageInfo: {
        id: 'fnol.components.default-error-page.Please try again later, or call our Call Center using the number { phonenumber } to finish claim registration.',
        defaultMessage: 'Please try again later, or call our Call Center using the number { phonenumber } to finish claim registration.'
    },
    fnolDefaultErrorPageSubinfo: {
        id: 'fnol.components.default-error-page.Use the claim draft number: { claimDraftNumber } to retrieve already provided data',
        defaultMessage: 'Use the claim draft number: { claimDraftNumber } to retrieve already provided data'
    }
});
