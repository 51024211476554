import { cloneDeep as _cloneDeep, extend as _extend } from 'lodash';
import { EntityUtil } from 'gw-portals-util-js';
import Person from './Person';
import SimplePerson from './SimplePerson';
import Company from './Company';

function ClaimantData(data) {
    if (data) {
        _extend(this, data);
    }
}

ClaimantData.prototype.nextId = () => {
    return EntityUtil.nextId();
};

ClaimantData.prototype.removeIndividual = function removeIndividual() {
    this.individual = undefined;
};

ClaimantData.prototype.removeCompanyAndRepresentative = function removeCompanyAndRepresentative() {
    this.companyRepresentative = undefined;
    this.company = undefined;
};

ClaimantData.prototype.createIndividual = function createIndividual(p) {
    const copiedPerson = _cloneDeep(p);
    const individual = new Person(copiedPerson);
    individual.publicID = undefined;
    individual.tempID = EntityUtil.nextId();
    this.individual = individual;
    return individual;
};

ClaimantData.prototype.createCompanyAndRepresentative = function createCompanyAndRepresentative(c, relatedCompany) {
    const copiedContact = _cloneDeep(c);
    const companyRepresentative = new SimplePerson(copiedContact);
    companyRepresentative.publicID = undefined;
    companyRepresentative.tempID = EntityUtil.nextId();
    this.companyRepresentative = companyRepresentative;

    const copiedRelatedCompany = _cloneDeep(relatedCompany);
    const company = new Company(copiedRelatedCompany);
    if (!company.publicID) {
        company.tempID = EntityUtil.nextId();
    }
    this.company = company;
};

export default ClaimantData;
