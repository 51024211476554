import { defineMessages } from 'react-intl';

export default defineMessages({
    accountTitle: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Account:',
        defaultMessage: 'Account:'
    },
    wc7WorkersComp: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Workers Compensation Quote With QuoteID',
        defaultMessage: 'Workers\' Compensation Quote ({quoteNumber})'
    },
    commercialProperty: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Commercial Property Quote With QuoteID',
        defaultMessage: 'Commercial Property Quote ({quoteNumber})'
    },
    personalAuto: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.PersonalAuto Quote With QuoteID',
        defaultMessage: 'Personal Auto Quote ({quoteNumber})'
    },
    businessOwners: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.BusinessOwners Quote With QuoteID',
        defaultMessage: 'Business Owners Quote  ({quoteNumber})'
    },
    homeowners: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.HomeOwners Quote With QuoteID',
        defaultMessage: 'Home Owners Quote ({quoteNumber})'
    },
    hopHomeowners: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.HOPHomeOwners Quote With QuoteID',
        defaultMessage: 'Home Owners Quote ({quoteNumber})'
    },
    homeownersPolicyChange: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Policy Changes - Homeowners',
        defaultMessage: 'Policy Changes - Homeowners ({quoteNumber})'
    },
    hopHomeownersPolicyChange: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Policy Changes - HOPHomeowners',
        defaultMessage: 'Policy Changes - Homeowners ({quoteNumber})'
    },
    businessOwnersPolicyChange: {
        id: 'endorsement.directives.templates.bop.Policy Changes - Business Owners',
        defaultMessage: 'Business Owners {quoteNumber} - Policy Change {jobId}'
    },
    businessOwnersRenewal: {
        id: 'endorsement.directives.templates.bop.Policy Renewal - Business Owners',
        defaultMessage: 'Businessowners {quoteNumber} - Renewal {jobId}'
    },
    commercialPropertyPolicyChange: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Policy Changes - Commercial Property',
        defaultMessage: 'Commercial Property {quoteNumber} - Policy Change {jobId}'
    },
    commercialPropertyRenewal: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Policy Renewal - Commercial Property',
        defaultMessage: 'Commercial Property {quoteNumber} - Renewal {jobId}'
    },
    personalAutoPolicyChange: {
        id: 'endorsement.directives.templates.pa.Policy Changes - Personal Auto',
        defaultMessage: 'Policy Changes - Personal Auto ({quoteNumber})'
    },
    fnolNewClaim: {
        id: 'fnol.common.views.fnol.New Claim',
        defaultMessage: 'New Claim'
    },
    fnolDraftNumber: {
        id: 'fnol.common.views.fnol.Draft number',
        defaultMessage: 'Draft number'
    },
    fnolDraftNumberTooltip: {
        id: 'fnol.common.views.fnol.A draft number given during the claim submission process. If you experience problems during the application form, you can use this number when calling the Call Center to retrieve the information you previously provided and complete the process over the phone.',
        defaultMessage: 'A draft number given during the claim submission process. If you experience problems during the application form, you can use this number when calling the Call Center to retrieve the information you previously provided and complete the process over the phone.'
    },
});
