import { useRef, useCallback, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppInsights } from 'cnd-common-hooks-platform-react';
import FNOLContext from '../components/Context/Context';

export const ERROR_PAGE_PATH = '/error';

function useErrorHandler() {
    const history = useHistory();
    const appInsights = useAppInsights();
    const constants = useContext(FNOLContext);
    // eslint-disable-next-line no-unused-vars
    const handleError = useCallback((error, claimNumber) => {
        appInsights.trackException(error, claimNumber);
        history.replace(constants.ERROR_PAGE_PATH, { claimNumber });
    }, [constants, history]);
    const handlers = useRef({ handleError });

    useEffect(() => {
        handlers.current = { handleError };
    }, [handleError]);

    return handlers.current;
}

export default useErrorHandler;
