import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolOfficialsType: {
        id: 'fnol.officials.Type',
        defaultMessage: 'Type'
    },
    fnolOfficialsDepartment: {
        id: 'fnol.officials.Name of Department',
        defaultMessage: 'Name of Department'
    },
    fnolOfficialsReport: {
        id: 'fnol.officials.Report number',
        defaultMessage: 'Report number'
    },
    fnolOfficialsRemove: {
        id: 'fnol.officials.Remove',
        defaultMessage: 'Remove'
    }
});
