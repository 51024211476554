import { CountrySpecificUtil } from 'cnd-common-portals-util-js';

export default {
    isVisibleOnSearchPolicyPage: (country) => {
        if (country === undefined) {
            return false;
        }
        const variants = [
            {
                countries: [
                    CountrySpecificUtil.Countries.PL,
                    CountrySpecificUtil.Countries.RO,
                    CountrySpecificUtil.Countries.SK,
                    CountrySpecificUtil.Countries.CZ,
                    CountrySpecificUtil.Countries.BG,
                ],
                action: () => true
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.HU,
                    CountrySpecificUtil.Countries.Other
                ],
                action: () => false
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    }
};