import _ from 'lodash';
import React, {
    useEffect, useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import Address from '../Address/Address';
import metadata from './Person.metadata.json5';
import SummaryContext from '../summary/SummaryContext';
import './Person.messages';

function FNOLPerson(props) {
    const {
        id,
        onValidate,
        value: personVM,
        onValueChange,
        path,
        isEmailPhoneInfoMessageVisible,
        isEmailNotificationInfoVisible,
        isClaimantContactInfoMessageVisible,
        showErrors,
        validationErrors,
        mode
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        registerComponentValidation(() => {
            return personVM.aspects.valid && personVM.aspects.subtreeValid;
        });
    }, [personVM.aspects.subtreeValid, personVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }

            if (changedPath.startsWith('address.country')) {
                if (_.isEmpty(_.get(personVM, 'phone.number.value'))) {
                    _.set(personVM, 'phone.countryCode', value);
                }
            }
        },
        [onValueChange, path, personVM]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        fnolPersonPhone: {
            visible: !readOnly || !_.isEmpty(_.get(personVM, 'phone.number.value')),
            showErrors: !_.isNil(validationErrors.phone),
            validationMessages: validationErrors.phone?.messages || personVM.phone.number.aspects.validationMessages,
            readOnly
        },
        fnolPersonProvideEmailOrMobileInfo: {
            visible: !readOnly && isEmailPhoneInfoMessageVisible
        },
        fnolPersonProvideClaimantContactInfo: {
            visible: !readOnly && isClaimantContactInfoMessageVisible
        },
        fnolPersonEmailNotificationInfo: {
            visible: isEmailNotificationInfoVisible && !readOnly
        },
        fnolPersonEmail: {
            visible: !readOnly || _.get(personVM.value, 'email') !== undefined,
            validationMessages: validationErrors.email?.messages || personVM.email.aspects.validationMessages,
            showErrors: !_.isNil(validationErrors.email)
        },
        fnolPersonAddress: {
            mode: mode
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
        resolveComponentMap: {
            address: Address
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={personVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLPerson.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    isEmailPhoneInfoMessageVisible: PropTypes.bool,
    isEmailNotificationInfoVisible: PropTypes.bool,
    isClaimantContactInfoMessageVisible: PropTypes.bool,
    showErrors: PropTypes.bool.isRequired,
    validationErrors: PropTypes.objectOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            messages: PropTypes.arrayOf(PropTypes.string).isRequired,
        })
    ),
    mode: PropTypes.string
};
FNOLPerson.defaultProps = {
    isEmailPhoneInfoMessageVisible: false,
    isEmailNotificationInfoVisible: false,
    isClaimantContactInfoMessageVisible: false,
    validationErrors: {}
};

export default FNOLPerson;
