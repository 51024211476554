import _ from 'lodash';
import React, {
    useEffect, useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import SummaryContext from '../summary/SummaryContext';
import metadata from './SchoolInformation.metadata.json5';
import './SchoolInformation.messages';

function SchoolInformation(props) {
    const {
        id,
        onValidate,
        value: schoolInformationVM,
        onValueChange,
        path,
        showErrors,
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        registerComponentValidation(() => {
            return schoolInformationVM.aspects.valid && schoolInformationVM.aspects.subtreeValid;
        });
    }, [schoolInformationVM.aspects.subtreeValid, schoolInformationVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path, schoolInformationVM]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        fnolSchoolInformationComponentPremiumPaidConfirmation: {
            labelPosition: 'top'
        },
        fnolSchoolInformationComponentNoReimbursementReceived: {
            labelPosition: 'top'
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={schoolInformationVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

SchoolInformation.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    showErrors: PropTypes.bool.isRequired,
};

export default SchoolInformation;
