import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolContactDetailsSummaryPersonWhoRegistersClaim: {
        id: 'fnol.components.contact-details-summary.Person who registers the claim',
        defaultMessage: 'Person who registers the claim'
    },
    fnolContactDetailsSummaryCompanyPersonTitle: {
        id: 'fnol.components.contact-details-summary.Contact details',
        defaultMessage: 'Contact details'
    }
});
