import React from 'react';
import { ServiceManager } from '@jutro/services';
import { CurrencyField } from '@jutro/components';

function Currency(props) {
    const localeService = ServiceManager.getService('locale-service');
    // Cnd custom - prevent from overwriting with default currency if already set
    const { defaultCurrency } = props;
    return (
        <CurrencyField
            {...props}
            defaultCurrency={defaultCurrency || localeService.getDefaultCurrencyCode()}
        />
    );
}

Currency.propTypes = CurrencyField.propTypes;
Currency.defaultProps = CurrencyField.defaultProps;

export default Currency;
