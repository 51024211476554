import _ from 'lodash';
import { EntityUtil } from 'gw-portals-util-js';
import SimplePerson from './SimplePerson';
import Person from './Person';

function VehicleIncident(data) {
    if (data) {
        _.extend(this, data);
    }
    this.vehicle = this.vehicle || {};
}

VehicleIncident.prototype.getTypeCode = function getTypeCode() {
    return 'VehicleIncident';
};

VehicleIncident.prototype.createDriver = function createDriver() {
    const driver = new Person();
    driver.tempID = EntityUtil.nextId();
    this.driver = driver;
    return driver;
};

VehicleIncident.prototype.createPassenger = function createPassenger() {
    if (!this.passengers) {
        this.passengers = [];
    }
    const passenger = new SimplePerson();
    passenger.tempID = EntityUtil.nextId();
    this.passengers.push(passenger);
    return passenger;
};

VehicleIncident.prototype.removePassenger = function removePassenger(passenger) {
    const idx = _.indexOf(this.passengers, passenger);
    if (idx >= 0) {
        this.passengers.splice(idx, 1);
    }
};

export default VehicleIncident;
