import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolSmsVerificationCancelAlert: {
        id: 'fnol.sms-verification-page.Are you sure you want to cancel sms verification? All previously provided information will be lost',
        defaultMessage: 'Are you sure you want to cancel sms verification? All previously provided information will be lost'
    },
    fnolSmsVerificationInfoPleaseProvide: {
        id: 'fnol.sms-verification-page.Please provide your phone number for verification',
        defaultMessage: 'Please provide your phone number for verification'
    },
    fnolSmsVerificationInfoSent: {
        id: 'fnol.sms-verification-page.We send sms with code to phone number',
        defaultMessage: 'We send sms with code to phone number'
    },
    fnolSmsVerificationConfirmationCode: {
        id: 'fnol.sms-verification-page.Confirmation code',
        defaultMessage: 'Confirmation code'
    },
    fnolSmsVerificationSendAnotherSms: {
        id: 'fnol.sms-verification-page.Send another sms',
        defaultMessage: 'Send another sms'
    },
    fnolSmsVerificationInfoNotReceiving: {
        id: 'fnol.sms-verification-page.Not receiving a confirmation code',
        defaultMessage: 'Not receiving a confirmation code'
    },
    fnolSmsVerificationLi1: {
        id: 'fnol.sms-verification-page.Please check if you provided valid phone number and it\'s in the coverage',
        defaultMessage: 'Please check if you provided valid phone number and it\'s in the coverage'
    },
    fnolSmsVerificationLi2: {
        id: 'fnol.sms-verification-page.There might be problems on your phone number provider. Please contact their support team',
        defaultMessage: 'There might be problems on your phone number provider. Please contact their support team'
    },
    fnolSmsVerificationLi3: {
        id: 'fnol.sms-verification-page.You can contact Call Center for help',
        defaultMessage: 'You can contact Call Center for help'
    },
    fnolSmsVerificationClickHere: {
        id: 'fnol.sms-verification-page.Click here to proceed',
        defaultMessage: 'Click here to proceed'
    },
    fnolSmsVerificationErrorTooManyClaimRequests: {
        id: 'fnol.sms-verification-page.Service unavailable please try again later',
        defaultMessage: 'Service unavailable please try again later'
    },
    fnolSmsVerificationErrorTooManyTokenRequests: {
        id: 'fnol.sms-verification-page.You can send only 1 sms requests per 60 seconds and not more than 3 sms requests total per 10 minutes',
        defaultMessage: 'You can send only 1 sms requests per 60 seconds and not more than 3 sms requests total per 10 minutes'
    },
    fnolSmsVerificationErrorInvalidToken: {
        id: 'fnol.sms-verification-page.Code doesn\'t match. Please try again',
        defaultMessage: 'Code doesn\'t match. Please try again'
    },
    fnolSmsVerificationErrorTokenExpired: {
        id: 'fnol.sms-verification-page.The confirmation transaction has expired, please return to the previous page and try again',
        defaultMessage: 'The confirmation transaction has expired, please return to the previous page and try again'
    },
    fnolSmsVerificationConsent: {
        id: 'fnol.sms-verification-page.phone-step.By clicking the submit button below, I hereby agree that provided phone number will be used for sms verification.',
        defaultMessage: 'By clicking the submit button below, I hereby agree that provided phone number will be used for sms verification.'
    },
    fnolSmsVerificationNext: {
        id: 'fnol.sms-verification-page.Next',
        defaultMessage: 'Next'
    },
    fnolSmsVerificationBack: {
        id: 'fnol.sms-verification-page.Back',
        defaultMessage: 'Back'
    },
    fnolSmsVerificationTitle: {
        id: 'fnol.components.sms-verification-page.SMS Verification',
        defaultMessage: 'SMS Verification'
    }
});
