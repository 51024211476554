import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolInsuredPersonFirstName: {
        id: 'fnol.components.insured-person.First Name',
        defaultMessage: 'First Name'
    },
    fnolInsuredPersonLastName: {
        id: 'fnol.components.insured-person.Last Name',
        defaultMessage: 'Last Name'
    },
    fnolInsuredPersonLastName: {
        id: 'fnol.components.insured-person.Date of birth',
        defaultMessage: 'Date of birth'
    },
    fnolInsuredPersonHeader: {
        id: 'fnol.components.insured-person.Insured Person',
        defaultMessage: 'Insured Person'
    },
    fnolInsuredPersonNewContact: {
        id: 'fnol.components.insured-person.New Contact',
        defaultMessage: 'New Contact'
    }
});
