import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class NoteService {
    static getClaimNotes(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnolNote'),
            'getClaimNotes', [claimNumber], additionalHeaders);
    }

    static createClaimNote(claimNumber, sessionUUID, noteDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnolNote'),
            'createClaimNote', [claimNumber, sessionUUID, noteDTO], additionalHeaders);
    }

    static updateClaimNote(claimNumber, sessionUUID, note, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnolNote'),
            'updateClaimNote', [claimNumber, sessionUUID, note], additionalHeaders);
    }

    static deleteClaimNote(claimId, sessionUUID, noteId, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customfnolNote'),
            'deleteClaimNote', [claimId, sessionUUID, noteId], additionalHeaders);
    }
}
