import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolPersonalPropertyIncidentPersonalPropertyType: {
        id: 'fnol.components.personal-property-incident.Personal Property Type',
        defaultMessage: 'Personal property type'
    },
    fnolPersonalPropertyIncidentCompensatedByThirdParty: {
        id: 'fnol.components.personal-property-incident.Compensated by Third Party?',
        defaultMessage: 'Compensated by Third Party?'
    },
    fnolPersonalPropertyIncidentCarrierCompensatedAmount: {
        id: 'fnol.components.personal-property-incident.Amount compensated',
        defaultMessage: 'Amount compensated'
    },
    fnolPersonalPropertyIncidentEstimationReceived: {
        id: 'fnol.components.personal-property-incident.Repair estimate received?',
        defaultMessage: 'Repair estimate received?'
    },
    fnolPersonalPropertyIncidentEstimatedRepairCost: {
        id: 'fnol.components.personal-property-incident.Estimated repair cost',
        defaultMessage: 'Estimated repair cost'
    },
    fnolPersonalPropertyIncidentContentLineItemsTitle: {
        id: 'fnol.components.personal-property-incident.Personal Property & Contents line items',
        defaultMessage: 'Personal Property & Contents line items'
    },
    fnolPersonalPropertyIncidentContentLineItemsAddBtn: {
        id: 'fnol.components.personal-property-incident.Add',
        defaultMessage: 'Add'
    },
    fnolPersonalPropertyIncidentQuantityColumn: {
        id: 'fnol.components.personal-property-incident.Quantity',
        defaultMessage: 'Quantity'
    },
    fnolPersonalPropertyIncidentDescriptionColumn: {
        id: 'fnol.components.personal-property-incident.Description',
        defaultMessage: 'Description'
    },
    fnolPersonalPropertyIncidentCategoryColumn: {
        id: 'fnol.components.personal-property-incident.Category',
        defaultMessage: 'Category'
    },
    fnolPersonalPropertyIncidentPurchaseCostColumn: {
        id: 'fnol.components.personal-property-incident.Purchase Cost',
        defaultMessage: 'Purchase Cost'
    },
    fnolPersonalPropertyIncidentPurchaseDateColumn: {
        id: 'fnol.components.personal-property-incident.Purchase Date',
        defaultMessage: 'Purchase Date'
    },
    fnolPersonalPropertyIncidentDamageDescription: {
        id: 'fnol.components.personal-property-incident.Damage description',
        defaultMessage: 'Damage description'
    },
    fnolPersonalPropertyIncidentHint: {
        id: 'fnol.components.personal-property-incident.To start filling the table click on the "Add" button, and each time when you want to add a new line.',
        defaultMessage: 'To start filling the table click on the "Add" button, and each time when you want to add a new line.'
    },
    fnolPersonPropertyIncidentOnTheNext: {
        id: 'fnol.components.personal-property-incident.On the next step remember to attach proof of ownership of listed items.',
        defaultMessage: 'On the next step remember to attach proof of ownership of listed items.'
    },
    fnolPersonPropertyItems: {
        id: 'fnol.components.personal-property-incident.Personal Property Items',
        defaultMessage: 'Personal Property Items'
    }
});
