import _ from 'lodash';
import React, {
    useEffect, useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { Button } from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { PhoneNumberWithCountryCode } from 'cnd-common-components-platform-react';
import metadata from './Witnesses.metadata.json5';
import SummaryContext from '../summary/SummaryContext';
import messages from './Witnesses.messages';
import styles from './Witnesses.module.scss';

function FNOLWitnesses(props) {
    const {
        id,
        value: contactsVM,
        onValueChange,
        onValidate,
        onRemoveWitness
    } = props;

    const {
        isComponentValid,
        registerComponentValidation,
        onValidate: setComponentValidation,
        disregardFieldValidation
    } = useValidation(id);

    const translator = useContext(TranslatorContext);
    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        registerComponentValidation(() => {
            return contactsVM.aspects.valid && contactsVM.aspects.subtreeValid;
        });
    }, [contactsVM.aspects.subtreeValid, contactsVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `witnesses.children.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange]
    );

    const removeWitness = useCallback((item, index) => {
        onRemoveWitness(item);
        disregardFieldValidation(`fnolWitnessesItem${index}Phone`);
    }, [disregardFieldValidation, onRemoveWitness]);

    const getRemoveComponent = useCallback(({ index }) => {
        const item = _.get(contactsVM, `children.${index}.value`);

        return (
            <Button
                className={styles.deleteButton}
                icon="mi-delete"
                onClick={() => removeWitness(item, index)}
            >
                {translator(messages.fnolWitnessesRemove)}
            </Button>
        );
    }, [contactsVM, removeWitness, translator]);

    const generateOverrides = useCallback(() => {
        const overrides = contactsVM.children.map(
            (official, index) => {
                return {
                    [`fnolWitnessesRemove${index}`]: {
                        visible: !readOnly
                    },
                    [`fnolWitnessesFirstname${index}`]: {
                        validationMessages: official.firstName.aspects.validationMessages,
                    },
                    [`fnolWitnessesLastname${index}`]: {
                        validationMessages: official.lastName.aspects.validationMessages,
                    },
                    [`fnolWitnessesEmail${index}`]: {
                        validationMessages: official.email.aspects.validationMessages,
                    },
                    [`fnolWitnessesPhone${index}`]: {
                        onValidate: setComponentValidation,
                        value: official.phone
                    }
                };
            }
        );
        return Object.assign({}, ...overrides);
    }, [contactsVM, readOnly, setComponentValidation]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            phone: PhoneNumberWithCountryCode,
            remove: getRemoveComponent
        }
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            readOnly
        },
        fnolWitnesses: {
            data: contactsVM.children
        },
        ...generateOverrides()
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={contactsVM.children}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

FNOLWitnesses.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onRemoveWitness: PropTypes.func.isRequired
};
export default FNOLWitnesses;
