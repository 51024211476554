import _ from 'lodash';

function SimplePerson(data) {
    if (data) {
        _.extend(this, data);
    }
    this.phone = this.phone || {};
}

export default SimplePerson;
