import _ from 'lodash';
import React, {
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './Consents.metadata.json5';
import './Consents.messages';

function Consents(props) {
    const {
        value: consentsVM,
        path,
        onValueChange,
        onValidate,
        readOnly,
        locationFilter
    } = props;
    const [areConsentsVisible, setAreConsentsVisible] = useState(false);

    const {
        isComponentValid,
        registerComponentValidation,
        onValidate: setComponentValidation
    } = useValidation('Consents');

    useEffect(() => {
        registerComponentValidation(() => {
            let areConsentsValid = true;

            if (consentsVM.children !== undefined && consentsVM.children.length > 0) {
                areConsentsValid = !consentsVM.children.some((consent) => {
                    const { location, mandatory, selected } = consent.value;
                    return location === locationFilter && (mandatory && !selected);
                });
            }
            return areConsentsValid;
        });
    }, [consentsVM.children, registerComponentValidation, locationFilter]);

    useEffect(() => {
        if (consentsVM.children !== undefined && !_.isEmpty(consentsVM.children)) {
            const isAnyConsentVisible = consentsVM.value
                .some((consent) => consent.location === locationFilter);
            setAreConsentsVisible(isAnyConsentVisible);
        }
    // execute only once to check if consents section is visible
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'Consents');
        }
    }, [isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.children.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const generateConsentsOverrides = useCallback(() => {
        if (areConsentsVisible) {
            return consentsVM.value.reduce((all, consent, index) => {
                return {
                    ...all,
                    [`consentRow${index}`]: {
                        label: consent.description,
                        readOnly,
                        required: consent.mandatory,
                        visible: consent.location === locationFilter,
                        value: consent.selected,
                        showRequired: consent.mandatory
                    }
                };
            }, {});
        }

        return null;
    }, [areConsentsVisible, consentsVM.value, readOnly, locationFilter]);

    const overrideProps = {
        '@field': {
            readOnly
        },
        fnolConsentsContainer: {
            visible: areConsentsVisible
        },
        fnolConsentsRows: {
            data: consentsVM?.children
        },
        ...generateConsentsOverrides()
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={consentsVM.children}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            onValidationChange={setComponentValidation}
        />
    );
}

Consents.propTypes = {
    path: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    locationFilter: PropTypes.string.isRequired
};

export default Consents;
