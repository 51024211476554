import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolVehicleIncidentRemovePassengerButton: {
        id: 'fnol.components.vehicle-incident.passenger.Remove passenger',
        defaultMessage: 'Remove passenger'
    },
    fnolVehicleIncidentPassengerFirstName: {
        id: 'fnol.components.vehicle-incident.passenger.First Name',
        defaultMessage: 'First Name'
    },
    fnolVehicleIncidentPassengerLastName: {
        id: 'fnol.components.vehicle-incident.passenger.Last Name',
        defaultMessage: 'Last Name'
    },
    fnolVehicleIncidentPassengerPhone: {
        id: 'fnol.components.vehicle-incident.passenger.Phone',
        defaultMessage: 'Phone'
    },
    fnolVehicleIncidentPassengerEmail: {
        id: 'fnol.components.vehicle-incident.passenger.Email',
        defaultMessage: 'Email'
    }
});
