/* eslint-disable import/prefer-default-export */
export const labelStyles = {
    fieldLabel: 'jut__FieldLabel__fieldLabel',
    fieldLabelContainer: 'jut__FieldLabel__fieldLabelContainer',
    fieldLabelContainerLarge: 'jut__FieldLabel__fieldLabelContainerLarge',
    fieldLabelLarge: 'jut__FieldLabel__fieldLabelLarge',
    fieldLabelSmall: 'jut__FieldLabel__fieldLabelSmall',
    left: 'jut__FieldLabel__left',
    optional: 'jut__FieldLabel__optional',
    primaryLabel: 'jut__FieldLabel__primaryLabel',
    readOnly: 'jut__FieldLabel__readOnly',
    requiredSymbol: 'jut__FieldLabel__requiredSymbol',
    right: 'jut__FieldLabel__right',
    secondaryLabel: 'jut__FieldLabel__secondaryLabel',
    themeRoot: 'jut__FieldLabel__themeRoot',
    top: 'jut__FieldLabel__top'
};
