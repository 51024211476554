import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import SummarySection from '../SummarySection/SummarySection';
import SummaryContext from '../SummaryContext';
import IncidentMapperUtil from '../../../utils/IncidentsMapperUtil';
import metadata from './IncidentsListSummary.metadata.json5';
import messages from './IncidentsListSummary.messages';
import styles from './IncidentsListSummary.module.scss';

const FNOLIncidentsListSummary = (props) => {
    const { value: claim, isExpandAllButtonVisible } = props;
    const translator = useContext(TranslatorContext);
    const [isExpanded, setIsExpanded] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const claimData = {
        incidentsData: _.get(claim, 'incidentsData_Cnd',
            _.get(claim, 'incidentsData')),
        ...claim
    };
    const claimVM = viewModelService.create(
        claimData,
        'cc',
        'com.colonnade.edge.capabilities.claim.customfnol.dto.FnolDTO',
    );

    const IncidentList = () => {
        const incidentTypes = _.values(IncidentMapperUtil);
        const incidentsDataVM = _.get(claimVM, 'incidentsData');
        const list = incidentTypes
            .map((i) => {
                return incidentsDataVM[i.collectionName].children.map((incidentVM) => (
                    <SummarySection
                        value={incidentVM}
                        isExpanded
                        sectionTitle={`${translator(messages[`incidentList${i.constructorClassName}SectionTitle`])}`}
                        component={i.componentClass}
                        contentComponentProps={{
                            data: incidentVM,
                            policyCurrency: _.get(claimVM.value, 'policyCurrency')
                        }}
                        isIncident
                    />
                ));
            })
            .flatMap((x) => x);
        return (
            <>
                {list}
            </>
        );
    };

    const resolvers = {
        resolveComponentsMap: {
            incidentlist: IncidentList
        },
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            readOnly: true
        },
        expandAllbuttonContainer: {
            visible: isExpandAllButtonVisible,
        },
        expandAllButton: {
            content: isExpanded
                ? translator(messages.incidentListCollapseAll)
                : translator(messages.incidentListExpandAll),
            onClick: () => setIsExpanded(!isExpanded)
        },
        whatHappened: {
            visible: isExpanded,
            value: _.get(claim.incidentsData, 'description')
        },
        incidentList: {
            visible: isExpanded
        }
    };

    return (
        <SummaryContext.Provider
            value={{
                readOnly: true
            }}
        >
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentsMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </SummaryContext.Provider>
    );
};

FNOLIncidentsListSummary.propTypes = {
    value: PropTypes.shape({}).isRequired,
    isExpandAllButtonVisible: PropTypes.bool.isRequired
};

export default FNOLIncidentsListSummary;
