import React, {
    useCallback, useState, useContext, useEffect, useMemo, useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { ViewModelServiceContext, ViewModelForm, withViewModelService } from 'gw-portals-viewmodel-react';
import { useHistory } from 'react-router-dom';
import { ModalNextProvider } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { messages as platformMessages } from 'gw-platform-translations';
import { FNOLService } from 'cnd-capability-customfnol';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';

import metadata from './SmsVerificationPage.metadata.json5';
import styles from './SmsVerificationPage.module.scss';

import PhoneStep from '../../components/SmsVerification/PhoneStep';
import TokenStep from '../../components/SmsVerification/TokenStep';

import messages from './SmsVerificationPage.messages';
import useValidationErrors from '../../hooks/useValidationErrors';
import {
    CND_DTO_VALIDATION_ERROR
} from '../../constants/errorCodes';

function FNOLSmsVerificationPage() {
    const [isLoading, setIsLoading] = useState(false);
    const translator = useContext(TranslatorContext);
    const [step, setStep] = useState(0);
    const [tokenRequestVM, setTokenRequestVM] = useState(null);
    const [claimRequestVM, setClaimRequestVM] = useState(null);
    const [isConsentApproved, setIsConsentApproved] = useState(false);
    const [error, setError] = useState(null);
    const country = useStoredCountry();
    const countryCode = country?.toUpperCase();

    const Errors = useMemo(() => ({
        CND_FNOL_TOKEN_VALIDATION_ERROR:
            translator(messages.fnolSmsVerificationErrorInvalidToken),
        CND_TOO_MANY_FNOL_REQUESTS_ERROR:
            translator(messages.fnolSmsVerificationErrorTooManyClaimRequests),
        CND_FNOL_TOKEN_REQUEST_ERROR:
            translator(messages.fnolSmsVerificationErrorTooManyTokenRequests),
        CND_FNOL_TOKEN_EXPIRY_ERROR:
            translator(messages.fnolSmsVerificationErrorTokenExpired)
    }), [translator]);

    const [
        filterValidationErrors, setValidationErrors, resetValidationError
    ] = useValidationErrors();

    const {
        onValidate,
        isComponentValid
    } = useValidation('SmsVerification');

    const history = useHistory();
    const viewModelService = useContext(ViewModelServiceContext);

    useLayoutEffect(() => {
        document.title = translator(messages.fnolSmsVerificationTitle) + ' | Colonnade Insurance S.A.';
    }, []);

    useEffect(() => {
        document.title = translator(messages.fnolSmsVerificationTitle) + ' | Colonnade Insurance S.A.';
        const policySearchResult = _.get(history, 'location.state.policySearchResult');
        if (!policySearchResult) {
            history.push({
                pathname: '/'
            });
            return;
        }

        const tokenRequest = viewModelService.create(
            {
                ...policySearchResult,
                phone: {
                    countryCode
                }
            },
            'cc',
            'com.colonnade.edge.capabilities.claim.customfnol.policy.verify.dto.FnolTokenRequestDTO'
        );

        const claimRequest = viewModelService.create(
            {
                ...policySearchResult,
                token: ''
            },
            'cc',
            'com.colonnade.edge.capabilities.claim.customfnol.dto.CreateClaimRequestDTO'
        );

        setTokenRequestVM(tokenRequest);
        setClaimRequestVM(claimRequest);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const requestToken = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            await FNOLService.requestToken(tokenRequestVM.value, countryCode);
            setStep(1);
        } catch (e) {
            const appErrorCode = e?.appErrorCode;
            if (appErrorCode === CND_DTO_VALIDATION_ERROR) {
                setValidationErrors(e.appData);
            } else if (Errors[e.errorCode] !== undefined) {
                setError(Errors[e.errorCode]);
            } else {
                history.push({
                    pathname: '/contact-call-center'
                });
            }
        } finally {
            setIsLoading(false);
        }
    }, [setStep, setIsLoading, tokenRequestVM, countryCode, Errors, setValidationErrors, history]);

    const verifyTokenAndCreateClaim = useCallback(async () => {
        setError(null);
        setIsLoading(true);
        try {
            const claimData = await FNOLService.verifyTokenAndCreateClaim(claimRequestVM.value);
            history.push({
                pathname: '/fnol',
                state: {
                    redirectPath: '/',
                    claimData
                }
            });
        } catch (e) {
            if (Errors[e.errorCode] !== undefined) {
                setError(Errors[e.errorCode]);
            } else {
                history.push({
                    pathname: '/contact-call-center'
                });
            }
        } finally {
            setIsLoading(false);
        }
    }, [setIsLoading, claimRequestVM, history, Errors]);

    const STEP_CALLBACKS = [
        requestToken,
        verifyTokenAndCreateClaim
    ];

    const onBackClick = useCallback(async () => {
        const results = await ModalNextProvider.showConfirm({
            title: platformMessages.cancelModel,
            message: translator(messages.fnolSmsVerificationCancelAlert),
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: platformMessages.ok,
            cancelButtonText: platformMessages.cancelModel
        });
        if (results === 'confirm') {
            history.push('/');
        }
    }, [translator, history]);

    const overrideProps = {
        fnolSmsVerificationPageLoader: {
            loaded: !isLoading
        },
        fnolSmsVerificationPageContainer: {
            visible: !isLoading
        },
        fnolSmsVerificationPagePhone: {
            visible: step === 0 && !_.isNil(tokenRequestVM),
            model: tokenRequestVM,
            setTokenRequestVM,
            onValidate,
            resetValidationError,
            filterValidationErrors
        },
        fnolSmsVerificationPageToken: {
            visible: step === 1 && !_.isNil(tokenRequestVM?.phone) && !_.isNil(claimRequestVM),
            model: claimRequestVM,
            setClaimRequestVM,
            onValidate,
            tokenRequestVM: tokenRequestVM,
            sendSms: requestToken
        },
        fnolSmsVerificationNextButton: {
            onClick: STEP_CALLBACKS[step],
            disabled: !isComponentValid || !isConsentApproved
        },
        fnolSmsVerificationPageConsent: {
            visible: step === 0,
            value: isConsentApproved,
            onValueChange: setIsConsentApproved
        },
        fnolSmsVerificationCancelButton: {
            onClick: onBackClick
        },
        fnolSmsVerificationErrorText: {
            visible: error !== null,
            content: error
        }
    };

    const resolvers = {
        resolveComponentMap: {
            phonestep: PhoneStep,
            tokenstep: TokenStep
        },
        resolveCallbackMap: {
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}
FNOLSmsVerificationPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withViewModelService(FNOLSmsVerificationPage);
