const GTM_IDS = {
    CZ: 'GTM-WSFQWTH',
    SK: 'GTM-K8HXSBJ',
    PL: 'GTM-M4C4QGZ',
    HU: 'GTM-WRLVDXQ',
    BG: 'GTM-PST8DJG',
    RO: 'GTM-TJ4B32G'
};

const STEPS = {
    POLICY_SEARCH: 'policySearch',
    BASIC_INFORMATION: 'basicInformation',
    LOSS_DETAIL: 'lossDetail',
    ADDITIONAL_INFORMATION: 'additionalInformation',
    CONTACT_DETAILS: 'contactDetails',
    PAYMENT_DETAILS: 'paymentDetails',
    LOSS_CONFIRMATION: 'lossConfirmation'
}

const BUTTON_ACTION = {
    NEXT: 'next',
    SUBMIT: 'submit',
    SEARCH: 'search'
}

export default {
    BUTTON_ACTION,
    GTM_IDS,
    STEPS
};