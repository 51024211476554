import { useAppInsightsContext, SeverityLevel } from 'cnd-common-hooks-platform-react';

function useAppInsights() {
    const appInsights = useAppInsightsContext();
    // eslint-disable-next-line no-unused-vars
    const trackException = (error, refObjectId) => {
        const errorMsg = _.isObject(error) ? JSON.stringify(error) : error;
        appInsights.trackException({
            error: `id: ${refObjectId}, message: ${errorMsg}`,
            severityLevel: SeverityLevel.Error
        });
    }

    return {
        trackException
    }
}

export default useAppInsights;