import _ from 'lodash';
import React, {
    useEffect, useContext, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { DropdownSelectField, IconButton, Button } from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import metadata from './Officials.metadata.json5';
import SummaryContext from '../summary/SummaryContext';
import messages from './Officials.messages';
import styles from './Officials.module.scss';

function FNOLOfficials(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const {
        id,
        value: officialsVM,
        onValueChange,
        onValidate,
        onRemoveOfficial
    } = props;

    const {
        isComponentValid,
        registerComponentValidation,
        onValidate: setComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);
    const isPhone = breakpoint === 'phone';

    useEffect(() => {
        registerComponentValidation(() => {
            return officialsVM.aspects.valid && officialsVM.aspects.subtreeValid;
        });
    }, [officialsVM.aspects.subtreeValid, officialsVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `relatedOfficials.children.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange]
    );

    const getOfficialTypeComponent = useCallback((rowData) => {
        const { index, label, labelPosition } = rowData;
        const availableValues = _.get(officialsVM, `children.${index}.officialType.aspects.availableValues`)
            .map((typecode) => ({
                code: typecode.code,
                name: translator({
                    id: `typekey.OfficialType.${typecode.code}`,
                    defaultMessage: typecode.name
                })
            }));
        const value = _.get(officialsVM.value, `[${index}].officialType`);
        return (
            <DropdownSelectField
                value={value}
                label={index === 0 || (isPhone && !readOnly) ? label : null}
                labelPosition={labelPosition}
                readOnly={readOnly}
                id={`fnolOfficialsItem${index}OfficialType`}
                availableValues={availableValues}
                onValueChange={(val) => handleValueChange(val, `${index}.officialType`)}
            />
        );
    }, [officialsVM, isPhone, handleValueChange, translator, readOnly]);

    const getRemoveComponent = useCallback(({ index }) => {
        const item = _.get(officialsVM, `children.${index}.value`);
        const Component = isPhone ? Button : IconButton;
        const classes = isPhone ? styles.deleteButton : styles.deleteIcon;

        return (
            <Component
                className={isPhone || index === 0 ? classes : null}
                icon="mi-delete"
                onClick={() => onRemoveOfficial(item)}
            >
                {translator(messages.fnolOfficialsRemove)}
            </Component>
        );
    }, [officialsVM, isPhone, translator, onRemoveOfficial]);

    const generateOverrides = useCallback(() => {
        const overrides = officialsVM.children.map(
            (official, index) => {
                const override = {
                    [`fnolOfficialsRemove${index}`]: {
                        visible: !readOnly
                    },
                    [`fnolOfficialsReport${index}`]: {
                        visible: readOnly ? !_.isNil(_.get(officialsVM, `children.${index}.value.reportNumber`)) : true,
                    }
                };
                if (index === 0 || (isPhone && !readOnly)) {
                    return override;
                }
                return {
                    ...override,
                    [`fnolOfficialsNameOfDepartment${index}`]: {
                        label: null
                    },
                    [`fnolOfficialsReport${index}`]: {
                        label: null
                    }
                };
            }
        );
        return Object.assign({}, ...overrides);
    }, [officialsVM, isPhone, readOnly]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            officialType: getOfficialTypeComponent,
            remove: getRemoveComponent
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly,
            showOptional: true,
        },
        fnolOfficialsTable: {
            className: readOnly ? styles.fnolOfficialsReadOnlyContainer : styles.fnolOfficialsContainer
        },
        fnolOfficialsRows: {
            data: officialsVM.children
        },
        ...generateOverrides()
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={officialsVM.children}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

FNOLOfficials.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onRemoveOfficial: PropTypes.func.isRequired
};
export default FNOLOfficials;
