import _ from 'lodash';
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import {
    WizardPage,
    wizardProps,
    WizardPageTemplate,
    WizardContext
} from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { ModalNextProvider } from '@jutro/components';
import { SectionHeader } from 'cnd-common-components-platform-react';
import { TagManagerService, TrackingConstants } from 'cnd-portals-util-js';
import { useAppInsights } from 'cnd-common-hooks-platform-react';
import StepHeader from '../../components/StepHeader/StepHeader';
import useErrorHandler from '../../hooks/useErrorHandler';
import commonMessages from '../../FNOL.messages';
import messages from './SummaryPage.messages';
import AdjusterContent from './Component/AdjusterContent';
import AddNotePopup from './AddNotePopup';
import IncidentsList from '../../components/summary/IncidentsListSummary/IncidentsListSummary';
import SummarySection from '../../components/summary/SummarySection/SummarySection';
import BasicInformationSummary from '../../components/summary/BasicInformationSummary/BasicInformationSummary';
import ContactDetailsSummary from '../../components/summary/ContactDetailsSummary/ContactDetailsSummary';
import PaymentDetailsSummary from '../../components/summary/PaymentDetailsSummary/PaymentDetailsSummary';
import AdditionalInformationSummary from '../../components/summary/AdditionalInformationSummary/AdditionalInformationSummary';
import Consents from '../../components/Consents/Consents';
import metadata from './SummaryPage.metadata.json5';
import SummaryContext from '../../components/summary/SummaryContext';
import styles from './SummaryPage.module.scss';
import useSummarySnapshotHandler from '../../hooks/useSummarySnapshotHandler';

function FNOLSummaryPage(props) {
    const { history } = props;
    const translator = useContext(TranslatorContext);
    const [isLoading, setIsLoading] = useState(false);
    const [popupNotesVM, updatePopupNotesVM] = useState(null);
    const { FNOLService } = useDependencies('FNOLService');
    const { NoteService } = useDependencies('NoteService');
    const viewModelService = useContext(ViewModelServiceContext);
    const { jumpTo } = useContext(WizardContext);
    const ErrorHandler = useErrorHandler();
    const [isExpanded, setIsExpanded] = useState(true);
    const [printContext, setPrintContext] = useState(false);
    const { handleScreenSnapshot } = useSummarySnapshotHandler('#fnolSummaryPageContainer');

    const {
        wizardData: claimVM,
        updateWizardData
    } = props;

    const {
        onValidate,
        isComponentValid,
        initialValidation
    } = useValidation('SummaryPage');

    const appInsights = useAppInsights();

    const newNoteViewModelObj = (initialData) => {
        return {
            note: viewModelService.create(
                initialData.note,
                'cc',
                'edge.capabilities.note.dto.NoteDTO'
            )
        };
    };

    const initializeNotesVM = () => {
        if (!_.isEmpty(claimVM.adjusterNote.value)) {
            const getNotesVM = newNoteViewModelObj({
                note: {
                    claimNumber: claimVM.claimNumber.value,
                    subject: claimVM.adjusterNote.subject.value,
                    body: claimVM.adjusterNote.body.value
                }
            });
            return updatePopupNotesVM(getNotesVM.note);
        }

        const getNotesVM = newNoteViewModelObj({
            note: {
                claimNumber: claimVM.claimNumber.value,
                subject: '',
                body: ''
            }
        });
        return updatePopupNotesVM(getNotesVM.note);
    };

    useEffect(() => {
        initializeNotesVM();
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showModal = useCallback(
        async (getNotesVM) => {
            const componentProps = {
                title: translator(messages.dialogAddNoteToAdjuster),
                actionBtnLabel: commonMessages.platformOk,
                cancelBtnLabel: commonMessages.platformCancel,
                notesVM: getNotesVM,
                viewModelService: viewModelService
            };

            const results = await ModalNextProvider.showModal(
                <AddNotePopup {...componentProps} />
            );

            return results;
        },
        [translator, viewModelService]
    );

    const handlePrintContext = useCallback(async () => {
        setIsExpanded(true);
        return setPrintContext(true, () => { return new Promise((resolve) => { resolve('print context updated'); }); });
    }, []);

    const handleSubmitClaim = useCallback(async () => {
        TagManagerService.pushSubmitButtonClick(TrackingConstants.STEPS.LOSS_CONFIRMATION);
        await handlePrintContext();
        await handleScreenSnapshot(claimVM.claimNumber.value);
        setIsLoading(true); // cannot be set before screenshot is made
        return FNOLService.submitClaim(claimVM.value)
            .then((response) => {
                claimVM.value = response;
                const claimNumber = _.get(claimVM, 'value.claimNumber');
                return history.push({
                    pathname: `/confirmation/${claimNumber}`
                });
            })
            .catch((error) => {
                const draftNumber = _.get(claimVM, 'value.claimNumber');
                ErrorHandler.handleError(error, draftNumber);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, ErrorHandler, claimVM, history, handlePrintContext, handleScreenSnapshot]);

    const onNext = useCallback(() => {
        return handleSubmitClaim();
    }, [handleSubmitClaim]);

    const updateNoteData = useCallback(
        (noteVM) => {
            setIsLoading(true);
            const claimNumber = _.get(claimVM, 'value.claimNumber');
            const sessionUUID = _.get(claimVM, 'sessionUUID.value');
            const note = { subject: '', ...noteVM.value };
            return NoteService.updateClaimNote(claimNumber, sessionUUID, note)
                .then((response) => {
                    _.set(claimVM, 'adjusterNote.value', response);
                    updateWizardData(claimVM);
                    _.set(noteVM, 'value', response);
                    updatePopupNotesVM(noteVM);
                })
                .catch((error) => {
                    appInsights.trackException(error, claimNumber);
                    ModalNextProvider.showAlert({
                        title: messages.fnolNoteErrorHead,
                        message: messages.fnolNoteErrorMessage,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                    return false;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [claimVM, updateWizardData, NoteService]
    );

    const handleEditNote = useCallback(() => {
        showModal(popupNotesVM).then((updatedVM) => {
            const modifiedVM = viewModelService.clone(updatedVM);
            updateNoteData(modifiedVM);
        }).catch(_.noop);
    }, [popupNotesVM, showModal, updateNoteData, viewModelService]);

    const handleDeleteNote = useCallback(() => {
        setIsLoading(true);
        const claimNumber = _.get(claimVM, 'value.claimNumber');
        const noteId = _.get(claimVM, 'value.adjusterNote.publicID');
        const sessionUUID = _.get(claimVM, 'sessionUUID.value');
        return NoteService.deleteClaimNote(claimNumber, sessionUUID, noteId)
            .then((response) => {
                _.set(claimVM, 'adjusterNote.value', response);
                updateWizardData(claimVM);
                _.set(popupNotesVM, 'value', response);
                updatePopupNotesVM(popupNotesVM);
            })
            .catch((error) => {
                appInsights.trackException(error, claimNumber);
                ModalNextProvider.showAlert({
                    title: messages.fnolNoteErrorHead,
                    message: messages.fnolNoteErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [claimVM, popupNotesVM, updateWizardData, NoteService]);

    const handleConsentsChange = useCallback(
        (value, changedPath) => {
            const newClaimVM = _.clone(claimVM);
            _.set(newClaimVM, `${changedPath}.value`, value);
            updateWizardData(newClaimVM);
        },
        [claimVM, updateWizardData]
    );

    const saveNoteData = useCallback(
        (noteVM) => {
            setIsLoading(true);
            const claimNumber = _.get(claimVM, 'value.claimNumber');
            const sessionUUID = _.get(claimVM, 'sessionUUID.value');
            const note = { subject: '', ...noteVM.value };
            return NoteService.createClaimNote(claimNumber, sessionUUID, note)
                .then((response) => {
                    _.set(claimVM, 'adjusterNote.value', response);
                    updateWizardData(claimVM);
                    _.set(noteVM, 'value', response);
                    updatePopupNotesVM(noteVM);
                })
                .catch((error) => {
                    appInsights.trackException(error, claimNumber);
                    ModalNextProvider.showAlert({
                        title: messages.fnolNoteErrorHead,
                        message: messages.fnolNoteErrorMessage,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                    return false;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [claimVM, updateWizardData, NoteService]
    );

    const addNotesToAdjuster = useCallback(() => {
        showModal(popupNotesVM).then((updatedVM) => {
            const modifiedVM = viewModelService.clone(updatedVM);
            saveNoteData(modifiedVM);
        }).catch(_.noop);
    }, [popupNotesVM, saveNoteData, showModal, viewModelService]);

    const hasNote = _.get(popupNotesVM, 'body.value', '').length > 0;

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            readOnly: true
        },
        fnolSummaryPageLoader: {
            loaded: !isLoading
        },
        fnolSummaryPageContainer: {
            visible: !isLoading
        },
        fnolSummaryHint: {
            visible: !printContext
        },
        fnolSummaryButtonsSection: {
            visible: !printContext
        },
        adjusterNoteDiv: {
            visible: hasNote,
            printContext
        },
        addNotebtnDiv: {
            visible: !hasNote && !printContext
        },
        adjusterContent: {
            handleEditNote,
            handleDeleteNote,
            claimVM,
            printContext
        },
        fnolSummaryPageBasicInformationSection: {
            sectionTitle: translator(messages.fnolSummaryPageBasicInformation),
            onEditClick: () => jumpTo(0),
            component: BasicInformationSummary,
            isExpanded,
            isIncident: false,
            value: claimVM,
            printContext
        },
        fnolSummaryPageContactDetailsSection: {
            sectionTitle: translator(messages.fnolSummaryPageContactDetails),
            component: ContactDetailsSummary,
            onEditClick: () => jumpTo(3),
            isExpanded,
            isIncident: false,
            value: claimVM,
            printContext
        },
        fnolSummaryPagePaymentDetailsSection: {
            sectionTitle: translator(messages.fnolSummaryPagePaymentDetails),
            component: PaymentDetailsSummary,
            onEditClick: () => jumpTo(4),
            isExpanded,
            isIncident: false,
            value: claimVM,
            printContext
        },
        fnolSummaryPageAdditionalInformationSection: {
            sectionTitle: translator(messages.fnolSummaryPageAdditionalInformation),
            component: AdditionalInformationSummary,
            onEditClick: () => jumpTo(2),
            isExpanded,
            isIncident: false,
            value: claimVM,
            visible: _.get(claimVM.value, 'documents').length > 0 ||
                _.get(claimVM.value, 'relatedOfficials').length > 0 ||
                _.get(claimVM.value, 'witnesses').length > 0 ||
                _.get(claimVM.value, 'insuredPerson') !== undefined,
            printContext
        },
        fnolSummaryPageIncidentsListSection: {
            sectionTitle: translator(messages.fnolSummaryPageLossDetails),
            component: IncidentsList,
            onEditClick: () => jumpTo(1),
            isExpanded,
            isIncident: false,
            value: claimVM.value,
            isExpandAllButtonVisible: false,
            contentComponentProps: {
                isExpandAllButtonVisible: false
            },
            printContext
        },
        fnolSummaryPageExpandAllButton: {
            content: isExpanded
                ? translator(messages.fnolSummaryPageCollapseAll)
                : translator(messages.fnolSummaryPageExpandAll),
            onClick: () => setIsExpanded(!isExpanded),
            visible: !printContext
        },
        fnolSummaryHeader: {
            sectionTitle: translator(messages.fnolSummaryTitle),
            visibleButtons: [],
        },
        fnolSummaryLossDetailsHeader: {
            sectionTitle: translator(messages.fnolSummaryPageLossDetails)
        },
        fnolSummarySectionWhatHappened: {
            readOnly: true,
            value: _.get(claimVM.incidentsData.value, 'description')
        },
        fnolSummaryPageStepHeader: {
            claimNumber: _.get(claimVM.value, 'claimNumber'),
            title: translator(messages.fnolSummary),
            printContext: printContext
        },
        fnolSummaryPageConsents: {
            value: _.get(claimVM, 'consents'),
            locationFilter: 'fnol_step_6',
            readOnly: false,
            onValidate,
            onValueChange: handleConsentsChange
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveComponentMap: {
            adjustercontent: AdjusterContent,
            summarysection: SummarySection,
            sectionheader: SectionHeader,
            stepheader: StepHeader,
            consents: Consents
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            addNotesToAdjuster: addNotesToAdjuster
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            template={WizardPageTemplate}
            nextLabel={translator(messages.fnolSummaryPageSubmitClaim)}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <SummaryContext.Provider
                value={{
                    readOnly: true
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={claimVM}
                    resolveValue={resolvers.resolveValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    overrideProps={overrides}
                    onValidationChange={onValidate}
                    onModelChange={updateWizardData}
                />
            </SummaryContext.Provider>
        </WizardPage>
    );
}

FNOLSummaryPage.propTypes = wizardProps;
export default withRouter(FNOLSummaryPage);
