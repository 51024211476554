import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import styles from './StepHeader.module.scss';
import messages from './StepHeader.messages';

function StepHeader(props) {
    const { title: stepTitle, claimNumber, printContext } = props;
    const translator = useContext(TranslatorContext);

    const generateClaimHeader = useCallback(() => {
        return printContext ? (
            <h2 className={styles.pageTitle}>
                {stepTitle}
            </h2>
        ) : (
            <h2 className={styles.pageTitle}>
                {stepTitle}
                <span className={styles.claimsWizardSpanNumber}>
                    <Tooltip
                        placement="bottom"
                        content={translator(messages.stepHeaderClaimDraftNumberTooltip)}
                    >
                        <span className={styles.claimsWizardSpanNumberLabel}>
                            {translator(messages.stepHeaderClaimDraftNumber)}
                            :&nbsp;
                            {claimNumber}
                        </span>
                    </Tooltip>
                </span>
            </h2>
        );
    }, [translator, stepTitle, claimNumber, printContext]);


    return (
        <div>
            {generateClaimHeader()}
        </div>
    );
}

StepHeader.propTypes = {
    title: PropTypes.string.isRequired,
    claimNumber: PropTypes.string.isRequired,
    printContext: PropTypes.bool
};

StepHeader.defaultProps = {
    printContext: undefined
};
export default StepHeader;
