import moment from 'moment';
import React, {
    useRef,
    useEffect,
    useState,
    useCallback,
    useContext,
    useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { ServiceManager } from '@jutro/services';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import metadata from './SearchPolicy.metadata.json5';
import styles from './SearchPolicy.module.scss';
import messages from './SearchPolicy.messages';

function FNOLSearchPolicy(props) {
    const { onStartProcess } = props;

    const isRecaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED !== 'false';
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

    const localeService = ServiceManager.getService('locale-service');
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);

    const {
        onValidate,
        isComponentValid
    } = useValidation('SearchPolicy');

    const captchaRef = useRef();

    const [policyCriteriaVM, setPolicyCriteriaVM] = useState({});
    const [isHuman, setIsHuman] = useState(false);
    const [isLossDateInThePast, setLossDateInThePast] = useState(true);
    const selectedCountry = useStoredCountry();

    useLayoutEffect(() => {
        document.title = translator(messages.fnolSearchPolicyTitle) + ' | Colonnade Insurance S.A.';
    }, []);

    useEffect(() => {
        const data = viewModelService.create(
            {
                policyNumber: '',
                claimantType: 'person',
                country: selectedCountry
            },
            'cc',
            'com.colonnade.edge.capabilities.claim.customfnol.policy.dto.PolicySearchCriteriaDTO'
        );

        return setPolicyCriteriaVM(data);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchPolicy = useCallback(() => {
        let captchaToken = '';
        if (isRecaptchaEnabled) {
            if (!captchaRef.current) return;
            captchaToken = captchaRef.current.getValue();
        }
        onStartProcess(policyCriteriaVM.value, captchaToken);
    }, [isRecaptchaEnabled, onStartProcess, policyCriteriaVM]);

    const onValueChange = useCallback((value, path) => {
        const updatedVM = viewModelService.clone(policyCriteriaVM);
        _.set(updatedVM.value, path, value);

        setPolicyCriteriaVM(updatedVM);
    }, [policyCriteriaVM, viewModelService]);

    const handlePolicyLossDateChange = useCallback((value) => {
        let lossDateInThePast = true;
        if (value) {
            lossDateInThePast = moment(value).isBefore(moment());
        }
        setLossDateInThePast(lossDateInThePast);
        onValueChange(value, 'lossDate');
    }, [onValueChange]);

    const handleCaptchaChange = useCallback((value) => {
        setIsHuman(value != null);
    }, [setIsHuman]);

    const overrideProps = {
        fnolSearchPolicyLossDate: {
            validationMessages: (!isLossDateInThePast && [translator(messages.fnolLossDateMustBeInThePass)]) || policyCriteriaVM.lossDate?.aspects?.validationMessages,
            onValueChange: handlePolicyLossDateChange
        },
        fnolSearchPolicyStartButton: {
            disabled: !isComponentValid || (isRecaptchaEnabled && !isHuman)
        },
        fnolBotProtectionContainer: {
            visible: isRecaptchaEnabled
        },
        fnolBotProtection: {
            ref: captchaRef,
            sitekey: recaptchaKey,
            hl: localeService.getStoredLanguage(),
            onChange: handleCaptchaChange,
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            searchPolicy,
            onValueChange
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={policyCriteriaVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={onValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLSearchPolicy.propTypes = {
    onStartProcess: PropTypes.func.isRequired
};
export default FNOLSearchPolicy;
