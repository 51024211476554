function getParentsPath(obj, parentsPath = '') {
    let output = parentsPath;
    const { _parent: parent, _propertyName: propertyName } = obj;
    if (parent) {
        output = getParentsPath(parent, `> ${propertyName} ${parentsPath}`);
    }
    return output;
}

function logInfo(obj) {
    // eslint-disable-next-line no-console
    console.debug('%c Invalid element: ', 'color: red', getParentsPath(obj), obj, JSON.stringify(obj.aspects.validationMessages));
}

export function logInvalidElements(tree) {
    Object.keys(tree).forEach((key) => {
        if (tree[key] && tree[key].aspects && key !== '_parent') {
            const { _propertyName: propertyName } = tree[key];
            if (
                tree[key].aspects.subtreeValid === false
                && tree[key].aspects.valid === false
                && propertyName
            ) {
                logInfo(tree[key]);
            } else if (tree[key].aspects.subtreeValid === false && propertyName) {
                if (tree[key].children) {
                    tree[key].children.forEach((child) => logInvalidElements(child));
                } else {
                    logInvalidElements(tree[key]);
                }
            }
        }
    });

    if (!tree.aspects.valid) {
        logInfo(tree);
    }
}


function logInvalidNodes(scannedObject) {
    // eslint-disable-next-line no-console
    console.debug('%c Errors during model validation. Root value:', 'color: darkorange', scannedObject.value);
    if (!scannedObject.aspects.valid) {
        logInfo(scannedObject);
    }
    Object.keys(scannedObject).forEach((name) => {
        if (scannedObject[name] && scannedObject[name].aspects) {
            if (scannedObject[name].aspects.subtreeValid === false) {
                logInvalidElements(scannedObject[name]);
            }
        }
    });
}

export default {
    logInvalidNodes
};
