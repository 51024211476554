import _ from 'lodash';
import { EntityUtil } from 'gw-portals-util-js';
import IncidentsData from './IncidentsData';
import Official from './Official';
import SimplePerson from './SimplePerson';
import CheckPayeeData from './CheckPayeeData';
import ClaimantData from './ClaimantData';
import SchoolInformation from './SchoolInformation';

function Claim(data) {
    if (data) {
        _.extend(this, data);
        this.lossLocation = data.lossLocation || {};

        if (Array.isArray(data.relatedOfficials)) {
            this.relatedOfficials = data.relatedOfficials.map((official) => new Official(official));
        }
        if (Array.isArray(data.witnesses)) {
            this.witnesses = this.witnesses.map((witness) => new SimplePerson(witness));
        }
        if (data.incidentsData) {
            this.incidentsData = new IncidentsData(data.incidentsData);
        }
    } else {
        this.lossLocation = {};
        this.lossDate = new Date().toJSON();
    }

    this.relatedOfficials = this.relatedOfficials || [];
    this.witnesses = this.witnesses || [];
}

Claim.prototype.createSchoolInformation = function createSchoolInformation() {
    this.schoolInformation = new SchoolInformation(this.schoolInformation);
};

Claim.prototype.createClaimantData = function createClaimantData() {
    this.claimantData = new ClaimantData(this.claimantData);
};

Claim.prototype.createCheckPayeeData = function createCheckPayeeData() {
    this.checkPayeeData = new CheckPayeeData(this.checkPayeeData);
};

Claim.prototype.createIncidentsData = function createIncidentsData() {
    this.incidentsData = new IncidentsData(this.incidentsData);
};

Claim.prototype.addOfficial = function addOfficial() {
    const official = new Official();
    official.tempID = EntityUtil.nextId();
    this.relatedOfficials.push(official);
};

Claim.prototype.removeFromOfficials = function removeFromOfficials(official) {
    const idx = _.indexOf(this.relatedOfficials, official);
    if (idx >= 0) {
        this.relatedOfficials.splice(idx, 1);
    }
};

Claim.prototype.addWitness = function addWitness() {
    const witness = new SimplePerson();
    witness.tempID = EntityUtil.nextId();
    this.witnesses.push(witness);
};

Claim.prototype.removeFromWitnesses = function removeFromWitnesses(witness) {
    const idx = _.indexOf(this.witnesses, witness);
    if (idx >= 0) {
        this.witnesses.splice(idx, 1);
    }
};

Claim.prototype.nextId = () => {
    return EntityUtil.nextId();
};

export default Claim;
