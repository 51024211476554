import React, {
    useEffect, useCallback, useContext
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import SimplePerson from '../../../SimplePerson/SimplePerson';
import styles from './Passenger.module.scss';
import metadata from './Passenger.metadata.json5';
import './Passenger.messages';
import SummaryContext from '../../../summary/SummaryContext';

function FNOLPassenger(props) {
    const {
        id,
        onValidate,
        value: passengerVM,
        onValueChange,
        path,
        onRemovePassenger,
        showErrors
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        registerComponentValidation(() => {
            return passengerVM.aspects.valid && passengerVM.aspects.subtreeValid;
        });
    }, [passengerVM.aspects.subtreeValid, passengerVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const removePassenger = useCallback(() => {
        onRemovePassenger(path);
    }, [path, onRemovePassenger]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            readOnly
        },
        fnolVehicleIncidentPassengerSimplePerson: {
            value: passengerVM,
            isPhoneOrEmailInfoVisible: true,
            showErrors
        },
        fnolContact: {
            value: passengerVM
        },
        fnolVehicleIncidentRemovePassengerDiv: {
            visible: !readOnly
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            removePassenger,
            onValidate: setComponentValidation
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            simpleperson: SimplePerson
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={passengerVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

FNOLPassenger.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    onRemovePassenger: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired
};

export default FNOLPassenger;
