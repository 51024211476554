import React, { useCallback, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './TripIncident.metadata.json5';
import summaryMetadata from '../../summary/incidents/TripIncidentSummary.metadata.json5';
import SummaryContext from '../../summary/SummaryContext';
import styles from './TripIncident.module.scss';
import messages from './TripIncident.messages';

const LOSS_RELATED_TO_TYPES = {
    TRANSPORTATION: 'transportation',
    ACCOMMODATION: 'accommodation'
};

const { ACCOMMODATION, TRANSPORTATION } = LOSS_RELATED_TO_TYPES;

function FNOLTripIncident(props) {
    const {
        data: incidentVM,
        onValueChange,
        path,
        id,
        policyCurrency,
        onValidate,
        isTripOnPolicy,
        showErrors
    } = props;


    const translator = useContext(TranslatorContext);
    const { readOnly } = useContext(SummaryContext);

    const {
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = changedPath ? `${path}.${changedPath}` : path;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    useEffect(() => {
        registerComponentValidation(() => {
            return incidentVM.aspects.valid && incidentVM.aspects.subtreeValid;
        });
    }, [incidentVM.aspects.subtreeValid, incidentVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const lossRelatedToIncludes = (key) => _.get(incidentVM.value, 'lossRelatedTo').includes(key);

    const onEarlyReturnOnlyChange = useCallback((value) => {
        if (value) {
            _.unset(incidentVM.value, 'realDepartureDate');
            _.unset(incidentVM.value, 'realArrivalDate');
        }
        _.set(incidentVM.value, 'earlyReturnOnly', value);
        handleValueChange(incidentVM.value);
    }, [handleValueChange, incidentVM]);

    const onTransportationCancellationChange = useCallback((value) => {
        if (value) {
            _.unset(incidentVM.value, 'earlyReturnOnly');
            _.unset(incidentVM.value, 'realDepartureDate');
            _.unset(incidentVM.value, 'realArrivalDate');
            _.unset(incidentVM.value, 'earlyReturnDate');
        } else if (value === false) {
            _.unset(incidentVM.value, 'transportationCancellationFees');
            _.set(incidentVM.value, 'earlyReturnOnly', true);
        }
        _.set(incidentVM.value, 'transportationCancellation', value);
        handleValueChange(incidentVM.value);
    }, [handleValueChange, incidentVM]);

    const onAccommodationCancellationChange = useCallback((value) => {
        if (!value) {
            _.unset(incidentVM.value, 'accommodationCancellationFees');
        }
        _.set(incidentVM.value, 'accommodationCancellation', value);
        handleValueChange(incidentVM.value);
    }, [handleValueChange, incidentVM]);

    const onLossRelatedToChange = useCallback((value) => {
        const prev = _.get(incidentVM.value, 'lossRelatedTo');
        const ON_REMOVE_CONDITIONS = {
            transportation: () => {
                _.unset(incidentVM.value, 'originalTransportationType');
                _.unset(incidentVM.value, 'earlyReturnOnly');
                _.unset(incidentVM.value, 'transportationCancellation');
                _.unset(incidentVM.value, 'scheduledDepartureDate');
                _.unset(incidentVM.value, 'scheduledArrivalDate');
                _.unset(incidentVM.value, 'realDepartureDate');
                _.unset(incidentVM.value, 'realArrivalDate');
                _.unset(incidentVM.value, 'earlyReturnDate');
                _.unset(incidentVM.value, 'transportationCancellationFees');
            },
            accommodation: () => {
                _.unset(incidentVM.value, 'accommodationCancellation');
                _.unset(incidentVM.value, 'accommodationCancellationFees');
            }
        };

        prev.forEach((type) => {
            if (!value.includes(type)) {
                ON_REMOVE_CONDITIONS[type]();
            }
        });

        _.set(incidentVM.value, 'lossRelatedTo', value);
        if (_.get(incidentVM.value, 'lossRelatedTo').includes(TRANSPORTATION) && _.isNil(_.get(incidentVM.value, 'transportationCancellation'))) {
            _.set(incidentVM.value, 'transportationCancellation', true);
        }

        if (_.get(incidentVM.value, 'lossRelatedTo').includes(ACCOMMODATION) && _.isNil(_.get(incidentVM.value, 'accommodationCancellation'))) {
            _.set(incidentVM.value, 'accommodationCancellation', true);
        }
        handleValueChange(incidentVM.value);
    }, [incidentVM.value, handleValueChange]);

    const getTooltipMessages = useCallback(() => {
        const cancellationOnly = _.get(incidentVM.value, 'transportationCancellation');
        const earlyReturnOnly = _.get(incidentVM.value, 'earlyReturnOnly');
        const result = {
            schedulesDates: {}
        };
        if (cancellationOnly && !earlyReturnOnly) {
            result.schedulesDates.departureDate = messages.fnolTripIncidentScheduledDepartureDateTooltip1;
            result.schedulesDates.arrivalDate = messages.fnolTripIncidentScheduledArrivalDateTooltip1;
        } else if (!cancellationOnly && earlyReturnOnly) {
            result.schedulesDates.departureDate = messages.fnolTripIncidentScheduledDepartureDateTooltip2;
            result.schedulesDates.arrivalDate = messages.fnolTripIncidentScheduledArrivalDateTooltip2;
        } else {
            result.schedulesDates.departureDate = messages.fnolTripIncidentScheduledDepartureDateTooltip3;
            result.schedulesDates.arrivalDate = messages.fnolTripIncidentScheduledArrivalDateTooltip3;
        }
        return result;
    }, [incidentVM.value]);

    const isEmptyInSummaryMode = (field) => {
        return readOnly && _.isNil(_.get(incidentVM.value, field));
    };

    const availableRegions = useMemo(() => {
        const values = _.get(incidentVM, 'tripRegion.aspects.availableValues');
        return values && values.map((typecode) => {
            return {
                code: typecode.code,
                name: translator({
                    id: typecode.name,
                    defaultMessage: typecode.name
                })
            };
        });
    }, [incidentVM, translator]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        fnolTripIncidentTransportationCancellation: {
            onValueChange: onTransportationCancellationChange
        },
        fnolTripIncidentEarlyReturnOnly: {
            onValueChange: onEarlyReturnOnlyChange,
            visible: _.get(incidentVM.value, 'transportationCancellation') === false
        },
        fnolTripIncidentTransportationDetailsContainer: {
            visible: lossRelatedToIncludes(TRANSPORTATION)
        },
        fnolTripIncidentAccommodationDetailsContainer: {
            visible: lossRelatedToIncludes(ACCOMMODATION)
        },
        fnolTripIncidentLossRelatedTo: {
            onValueChange: onLossRelatedToChange
        },
        fnolTripIncidentLossRelatedToTypes: {
            readOnly: true,
            value: _.get(incidentVM.value, 'lossRelatedTo')
                .map((code) => translator({
                    id: `typekey.LossRelatedToType_Cnd.${code}`,
                    defaultMessage: code
                })).join(', ')
        },
        fnolTripIncidentTripStartDate: {
            visible: !isTripOnPolicy && !isEmptyInSummaryMode('tripStartDate')
        },
        fnolTripIncidentTripEndDate: {
            visible: !isTripOnPolicy && !isEmptyInSummaryMode('tripEndDate')
        },
        fnolTripIncidentTripRegion: {
            visible: !isTripOnPolicy && !isEmptyInSummaryMode('tripRegion'),
            availableValues: availableRegions,
            value: _.get(incidentVM.value, 'tripRegion')
        },
        fnolTripIncidentScheduledDepartureDate: {
            visible: !isEmptyInSummaryMode('scheduledDepartureDate'),
            tooltip: {
                text: readOnly ? null : getTooltipMessages().schedulesDates.departureDate
            }
        },
        fnolTripIncidentScheduledArrivalDate: {
            visible: !isEmptyInSummaryMode('scheduledArrivalDate'),
            tooltip: {
                text: readOnly ? null : getTooltipMessages().schedulesDates.arrivalDate
            }
        },
        fnolTripIncidentRealDatesContainer: {
            visible: _.get(incidentVM.value, 'transportationCancellation') === false
        },
        fnolTripIncidentRealDepartureDate: {
            visible: _.get(incidentVM.value, 'earlyReturnOnly') === false && !isEmptyInSummaryMode('realDepartureDate')
        },
        fnolTripIncidentRealArrivalDate: {
            visible: _.get(incidentVM.value, 'earlyReturnOnly') === false && !isEmptyInSummaryMode('realArrivalDate')
        },
        fnolTripIncidentRoundTripDelays: {
            visible: _.get(incidentVM.value, 'earlyReturnOnly') === false && _.get(incidentVM.value, 'transportationCancellation') === false 
        },
        fnolTripIncidentAccommodationCancellation: {
            visible: !isEmptyInSummaryMode('accommodationCancellation'),
            onValueChange: onAccommodationCancellationChange
        },
        fnolTripIncidentAccommodationCancellationFees: {
            visible: _.get(incidentVM.value, 'accommodationCancellation') === true && !isEmptyInSummaryMode('accommodationCancellationFees'),
            defaultCurrency: policyCurrency
        },
        fnolTripIncidentEarlyReturnDate: {
            visible: !isEmptyInSummaryMode('earlyReturnDate')
        },
        fnolTripIncidentTransportationCancellationFees: {
            visible: _.get(incidentVM.value, 'transportationCancellation') === true && !isEmptyInSummaryMode('transportationCancellationFees'),
            defaultCurrency: policyCurrency
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveClassNameMap: styles
    };

    return (
        <div>
            <ViewModelForm
                uiProps={readOnly ? summaryMetadata.componentContent : metadata.componentContent}
                model={incidentVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

FNOLTripIncident.propTypes = {
    value: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.shape(() => {}).isRequired,
    path: PropTypes.shape('').isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    isTripOnPolicy: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired
};

export default FNOLTripIncident;
