import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolBasicInformation: {
        id: 'fnol.wizard.step.basic-information.Basic information',
        defaultMessage: 'Basic information'
    },
    fnolBasicInformationPolicyNumber: {
        id: 'fnol.wizard.step.basic-information.Policy Number',
        defaultMessage: 'Policy Number'
    },
    fnolBasicInformationPageLossType: {
        id: 'fnol.wizard.step.basic-information.Loss type',
        defaultMessage: 'Loss type'
    },
    fnolBasicInformationLossTypeDisclaimer: {
        id: "fnol.wizard.step.basic-information.Select Personal property in case of PET, please.",
        defaultMessage: "Select Personal property in case of PET, please."
    },
    fnolBasicInformationLossCause: {
        id: 'fnol.wizard.step.basic-information.Loss cause',
        defaultMessage: 'Loss cause'
    },
    fnolBasicInformationLossLocationHeader: {
        id: 'fnol.wizard.step.basic-information.Loss Location',
        defaultMessage: 'Loss Location'
    }
});
