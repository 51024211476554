import React, {
    useEffect, useCallback, useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import metadata from './Vehicle.metadata.json5';
import './Vehicle.messages';
import SummaryContext from '../../../summary/SummaryContext';

function FNOLVehicle(props) {
    const {
        id,
        onValidate,
        value: vehicleVM,
        onValueChange,
        path,
        isInsuredLoss
    } = props;

    const {
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);

    useEffect(() => {
        registerComponentValidation(() => {
            return vehicleVM.aspects.valid && vehicleVM.aspects.subtreeValid;
        });
    }, [vehicleVM.aspects.subtreeValid, vehicleVM.aspects.valid, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            readOnly
        },
        fnolVehicleIncidentVehicleFuelType: {
            visible: !isInsuredLoss && (!readOnly || !_.isNil(vehicleVM.value.fuelType))
        },
        fnolVehicleIncidentVehicleMake: {
            visible: !isInsuredLoss
        },
        fnolVehicleIncidentVehicleModel: {
            visible: !isInsuredLoss
        },
        fnolVehicleIncidentVehicleEngineCapacity: {
            visible: !isInsuredLoss && (!readOnly || !_.isNil(vehicleVM.value.engineCapacity))
        },
        fnolVehicleIncidentVehicleNumberOfSeats: {
            visible: !isInsuredLoss && (!readOnly || !_.isNil(vehicleVM.value.numberOfSeats))
        },
        fnolVehicleIncidentVehicleFirstRegistrationDate: {
            visible: !isInsuredLoss && (!readOnly || !_.isNil(vehicleVM.value.firstRegistrationDate))
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={vehicleVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={onValidate}
            />
        </div>
    );
}

FNOLVehicle.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    isInsuredLoss: PropTypes.bool.isRequired
};
export default FNOLVehicle;
