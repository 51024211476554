import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolWitnessesFirstname: {
        id: 'fnol.witnesses.First Name',
        defaultMessage: 'First Name'
    },
    fnolWitnessesLastname: {
        id: 'fnol.witnesses.Last Name',
        defaultMessage: 'Last Name'
    },
    fnolWitnessesEmail: {
        id: 'fnol.witnesses.Email',
        defaultMessage: 'Email'
    },
    fnolWitnessesPhone: {
        id: 'fnol.witnesses.Phone',
        defaultMessage: 'Phone'
    },
    fnolWitnessesRemove: {
        id: 'fnol.witnesses.Remove',
        defaultMessage: 'Remove'
    }
});
